<div class="modal fade" id="modalFlightSearch" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content" *ngIf="browser">
            <div class="modal-header">
                <button id="closeButtonFlight" type="button" class="close" (click)="onClose()">&times;</button>
                <div class="col-xs-12 col-sm-12 col-md-12 modal-title">
                    Flight Finder Tool
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="modal-body">
                <div class="row" *ngIf="data.Booking.PickUp">
                    <div class="title">Landing at {{ data.Booking.PickUp.Fulladdress }}</div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-12">
                        <div class="ui-control">
                            <label>Departure Time</label>
                            <xr-datetime #FlightDateTime [hasLabel]="false" [hasIcon]="false" glyphicon="arrow" [showHeader]="false" [scrollOnTop]="false" classInput="ui-input" iconRemove="ui-icon-remove" editorId="flight-datetime" placeholder="Departure Time" [showHeaderMinute]="false" [(date)]="item.DateTime" (objectChange)="onSelectDate($event)" [stepMinute]="5" dateTitle="Outbound journey">
                            </xr-datetime>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-xs-12 col-sm-12">
                        <div class="ui-control">
                            <label>Airline</label>
                            <xr-airlinelist #FlightAirline [hasLabel]="false" [hasIcon]="false" glyphicon="arrow" [scrollOnTop]="false" classInput="ui-input airline" editorId="flight-airline-list" placeholder="Airline" (onChangeItem)="onChangedAirline($event)" (onSelectItem)="onSelectedAirline($event)" [selectedItem]="item.Airline" invalidMessage="Airline is required">
                            </xr-airlinelist>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12">
                        <div class="ui-control">
                            <label>Departing from (airport) - optional</label>
                            <xr-airportlist #FlightAirport [hasLabel]="false" [hasIcon]="false" glyphicon="arrow" [scrollOnTop]="false" classInput="ui-input airport" editorId="flight-airport-list" placeholder="Departing from (airport) - optional" (onChangeItem)="onChangedAirport($event)" (onSelectItem)="onSelectedAirport($event)" [selectedItem]="item.Airport" invalidMessage="Arriving from airport is required">
                            </xr-airportlist>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="center-button" style="text-align: center;">
                    <button type="button" class="ui-button" (click)="onSubmit()">
                        <span class="glyphicon glyphicon-refresh glyphicon-refresh-animate" *ngIf="onSubmiting"></span>
                        Find My Flight
                    </button>
                </div>
                <div class="result" *ngIf="onSubmited">
                    <div class="inner">
                        <div class="row" *ngIf="flightBestMatchs">
                            <h5>Best match</h5>
                            <ul *ngFor="let item of flightBestMatchs">
                                <li (click)="onSelectedFlight(item)">{{ item.FullAddress }}</li>
                            </ul>
                        </div>
                        <div class="row" *ngIf="flightOptions">
                            <h5>Possible options</h5>
                            <ul *ngFor="let item of flightOptions">
                                <li (click)="onSelectedFlight(item)">{{ item.FullAddress }}</li>
                            </ul>
                        </div>
                        <div class="row">
                            <h5>Still can't find your flight?</h5>
                            <div class="desc">No problem, you can enter your flight number and our team will manually check it
                            </div>
                            <a *ngIf="!enterFlightNumber" (click)="onEnterFlightNumber()">Enter Flight Number</a>
                            <div class="ui-control" *ngIf="enterFlightNumber">
                                <div class="ui-input-inner">
                                    <xr-textbox #FlightNumber [hasLabel]="false" [hasLabel]="false" [requied]="false" [invalid]="false" [scrollOnTop]="false" classInput="ui-input" title="Flight number" editorId="flight-search-number" (onChange)="onChangeFlightNumber()" [model]="data.Booking.Flight.Number" [editorType]="data.EDITOR_TYPE.TEXT">
                                    </xr-textbox>
                                </div>
                                <button type="button" class="ui-button ui-button-ok" (click)="onSubmitFlightNumber()">Ok</button>
                                <button type="button" class="ui-button ui-button-cancel" (click)="onEnterFlightNumber()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>