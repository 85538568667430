export class InvoiceReport {
    code: number;
    message: string;
    fileext: string;
    invoice: string;
    filename: string;
}

export class InvoiceReportItem {
    Code: number;
    Message: string;
    FileExt: string;
    Invoice: string;
    FileName: string;

    public static ToItem(entity: InvoiceReport): InvoiceReportItem {
        if (entity == null) return null;
        var item = new InvoiceReportItem();
        item.Code = entity.code;
        item.FileExt = entity.fileext;
        item.Message = entity.message;
        item.Invoice = entity.invoice;
        item.FileName = entity.filename;
        return item;
    }
}
