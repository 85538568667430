import { LocationItem } from "./location";
import { AppConfig } from "../app.config";
import { AdditionalPriceItem } from "./additionalprice";

export class Vehicle {
    image: string;
    typename: string;
    vehtypeid: number;
    maxluggage: number;
    description: string;
    noofhandbags: number;
    noofluggeges: number;
    noofpassengers: number;
}

export class VehicleItem {
    Id: number;
    Name: string;
    Image: string;
    Valid: boolean;
    Price?: number;
    MaxLuggage: number;
    Description: string;
    NoOfHandbags: number;
    NoOfLuggeges: number;
    PriceString?: string;
    NoOfPassengers: number;
    OnwardPriceString?: string;
    ReturnPriceString?: string;

    constructor() {
        this.NoOfLuggeges = 0;
        this.NoOfPassengers = 0;
    }

    public static ToItem(entity: Vehicle): VehicleItem {
        if (entity == null) return null;
        var item = new VehicleItem();
        item.Valid = true;
        item.Image = entity.image;
        item.Id = entity.vehtypeid;
        item.Name = entity.typename;
        item.Description = entity.description;
        item.NoOfHandbags = entity.noofhandbags;
        item.NoOfLuggeges = entity.noofluggeges;
        item.NoOfPassengers = entity.noofpassengers;
        item.MaxLuggage = entity.maxluggage || entity.noofluggeges;
        return item;
    }

    public static ToItems(entities: Vehicle[]): VehicleItem[] {
        if (entities == null || entities.length == 0) return new Array();
        let items = new Array();
        entities.forEach(element => {
            items.push(VehicleItem.ToItem(element));
        });
        return items;
    }

    public static ToDropDownItem(item: VehicleItem, price: AdditionalPriceItem = null): any {
        if (!item) return {
            label: '',
            value: item,
            selected: false,
        };
        let fare = price
            ? (price.TotalFare ? price.TotalFare : (price.Fare + price.ReturnFare))
            : 0,
            fareString = (Math.round(fare * 100) / 100).toFixed(2);
        return {
            label: '<span>' + item.Name + '</span>' +
                '<span><img src="' + AppConfig.ASSETS + 'editor/adult.png">x' + item.NoOfPassengers + '</span>' +
                '<span><img src="' + AppConfig.ASSETS + 'editor/luggage.png">x' + item.NoOfLuggeges + '</span>' +
                '<span><img src="' + AppConfig.ASSETS + 'editor/handbag.png">x' + item.NoOfHandbags + '</span>' +
                (price ? ' <span class="vehicle-price"> - <b>£ ' + fareString + '</b></span>' : ''),
            selected: false,
            value: item,
        };
    }

    public static ToDropDownItems(items: VehicleItem[], prices: AdditionalPriceItem[] = null): any[] {
        let result: any[] = [];
        items.forEach(element => {
            let price = prices && prices.length > 0
                ? prices.find(c => c.VehTypeId == element.Id)
                : null;
            result.push(VehicleItem.ToDropDownItem(element, price));
        });
        return result;
    }
}

export class TrackVehicle {
    time: Date;
    lat: number;
    lng: number;
    code: number;
    name: string;
    state: string;
    jobid: number;
    photo: string;
    status: string;
    etaetc: number;
    message: string;
    vehmake: string;
    driverid: number;
    driverId: number;
    vehmodel: string;
    vehregno: string;
    distance: number;
    coordinates: any;
    vehcolour: string;
    jobpartid: number;
    jobpoartid: number;
    currentlat: number;
    currentlng: number;
    tfllicence: string;
    drivername: string;
    drivernumber: string;
    drivermobile: string;
    currentlocation: string;
    drivercurrentlat: number;
    drivercurrentlng: number;
    distancedescription: string;
}

export class TrackVehicleItem {
    Code: number;
    Name: string;
    Type: string;
    JobId: number;
    State: string;
    Photo: string;
    Status: string;
    EtaEtc: number;
    Message: string;
    VehMake: string;
    Distance: number;
    VehModel: string;
    VehRegno: string;
    DriverId: number;
    VehColour: string;
    JobPartId: number;
    Traveltime: string;
    CurrentLat: number;
    CurrentLng: number;
    TflLicence: string;
    SignalrType: string;
    DriverNumber: string;
    DriverMobile: string;
    CurrentLocation: string;
    RoutePath: LocationItem[];
    DistanceDescription: string;

    public static ToItem(entity: TrackVehicle, type?: string): TrackVehicleItem {
        if (entity == null) return null;
        var item = new TrackVehicleItem();
        item.Type = type;
        item.Code = entity.code;
        item.Photo = entity.photo;
        item.JobId = entity.jobid;
        item.State = entity.state;
        item.Status = entity.status;
        item.Message = entity.message;
        item.VehMake = entity.vehmake;
        item.VehModel = entity.vehmodel;
        item.VehRegno = entity.vehregno;
        item.Distance = entity.distance;
        item.VehColour = entity.vehcolour;
        item.TflLicence = entity.tfllicence;
        item.EtaEtc = Math.ceil(entity.etaetc);
        item.DriverNumber = entity.drivernumber;
        item.DriverMobile = entity.drivermobile;
        item.Name = entity.name || entity.drivername;
        item.CurrentLocation = entity.currentlocation;
        item.DriverId = entity.driverid || entity.driverId;
        item.DistanceDescription = entity.distancedescription;
        item.JobPartId = entity.jobpoartid || entity.jobpartid;
        item.RoutePath = LocationItem.ToItemsFromNumber(entity.coordinates);
        item.CurrentLat = entity.currentlat || entity.drivercurrentlat || entity.lat;
        item.CurrentLng = entity.currentlng || entity.drivercurrentlng || entity.lng;
        return item;
    }

    public static ToItems(entities: TrackVehicle[]): TrackVehicleItem[] {
        if (entities == null || entities.length == 0) return new Array();
        let items = new Array();
        entities.forEach(element => {
            items.push(TrackVehicleItem.ToItem(element));
        });
        return items;
    }
}
