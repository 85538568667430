import { AppConfig } from '../../../app.config';
import { Subscription } from 'rxjs';
import { AirportItem } from '../../../domain/airport';
import { ApiService } from '../../../services/apiService';
import { DataService } from '../../../services/dataService';
import { UtilService } from '../../../services/utilService';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'xr-airportlist',
    templateUrl: 'airportlist.html',
    styleUrls: ['airportlist.scss']
})

export class XrAirportListComponent implements OnInit {
    loading: boolean;
    showNode: boolean;
    value: string = '';
    valid: boolean = true;
    found: boolean = true;
    parentId: string = '';
    arrIcons: Array<string>;
    acceptAny: boolean = true;
    requestSearch: Subscription;
    panelVisible: boolean = false;

    icon: string = '';
    iconPlane: string = '';
    iconRemove: string = '';
    iconLoading: string = '';
    iconInvalid: string = '';
    iconSubstract: string = '';

    glyphiconUp: string;
    glyphiconDown: string;

    @Input() editorId: string;
    @Input() dropdown: boolean;
    @Input() glyphicon: string;
    @Input() placeholder: string;
    @Input() scrollId: string = '';
    @Input() invalidMessage: string;
    @Input() classInput: string = '';
    @Input() hasIcon: boolean = true;
    @Input() selectedItem: AirportItem;
    @Input() disabled: boolean = false;
    @Input() hasLabel: boolean = false;
    @Input() scrollOnTop: boolean = false;
    @Input() checkInvalid: boolean = true;
    @Input() hasIconEdit: boolean = false;
    @Input() hasIconSubstract: boolean = false;
    @Input() icons: string = 'dropdown,search';

    @Output() onBlurItem = new EventEmitter();
    @Output() onClearItem = new EventEmitter();
    @Output() onSelectItem = new EventEmitter();
    @Output() onChangeItem = new EventEmitter();
    @Output() onRemoveItem = new EventEmitter();
    @Output() onUnSelectItem = new EventEmitter();

    ngOnInit() {
        this.iconRemove = 'ui-icon-remove';
        this.iconSubstract = 'ui-icon-substract';
        this.parentId = this.editorId + '-airportlist';
        this.icon = AppConfig.ASSETS + 'editor/via.png';
        this.iconPlane = AppConfig.ASSETS + 'editor/img/plane.png';
        this.iconInvalid = AppConfig.ASSETS + 'editor/notfound.png';
        this.iconLoading = AppConfig.ASSETS + 'editor/preloader.gif';

        if (!this.glyphicon) this.glyphicon = 'menu';
        this.glyphiconUp = 'glyphicon-' + this.glyphicon + '-up';
        this.glyphiconDown = 'glyphicon-' + this.glyphicon + '-down';

        if (this.selectedItem) this.value = this.selectedItem.FullAddress;
        if (this.classInput && this.hasLabel) this.classInput = this.classInput + ' hasLabel';
    }

    constructor(public service: ApiService, public data: DataService, public util: UtilService) {
        this.found = true;
        this.loading = false;
        this.showNode = false;
        this.arrIcons = this.icons.split(',');
    }

    /// Pickup address
    Items: any[];

    searchItems() {
        this.loading = true;
        this.selectedItem = null;
        this.onChangeItem.emit({ data: this.selectedItem });
        if (!this.value || this.value.length <= 1) {
            this.loading = false;
        } else {
            if (this.requestSearch) {
                this.requestSearch.unsubscribe();
            }
            this.requestSearch = this.service.searchAirports(this.value).subscribe(
                (items: any[]) => {
                    if (this.checkInvalid) {
                        if (items == null || items.length == 0) {
                            this.found = false;
                            this.showInvalid();
                            this.hidePanel();
                        } else {
                            this.showPanel();
                            this.found = true;
                            this.hideInvalid();
                            this.Items = items;
                        }
                    }
                    this.loading = false;
                });
        }
    }
    handleBlurItem() {
        if (this.checkInvalid) {
            if (!this.value || this.value.length == 0) {
                this.hideInvalid();
            } else {
                if (this.selectedItem) {
                    this.hideInvalid();
                } else {
                    this.showInvalid();
                }
            }
        }
    }
    handleClearClick() {
        if (this.disabled)
            return;
        this.clearInput();
        this.hideInvalid();
        this.onClearItem.emit();
    }
    handleInputClick() {
        if (this.disabled) return;
        this.panelVisible = !this.panelVisible;
        if (!this.Items || this.Items.length == 0) {
            this.searchItems();
        }
        this.scrollPoint();
    }
    handleRemoveClick() {
        if (this.disabled)
            return;
        this.onRemoveItem.emit();
    }
    panelIsVisible(): boolean {
        return this.panelVisible;
    }
    selectItemEvent(item: any) {
        this.hideInvalid();
        this.scrollPoint();
        this.selectedItem = item;
        this.onSelectItem.emit({ data: item });
        this.value = this.selectedItem && this.selectedItem.FullAddress;
        this.hidePanel();
    }

    hidePanel() {
        this.panelVisible = false;
    }
    showPanel() {
        this.panelVisible = true;
    }
    clearInput() {
        this.value = '';
        this.selectedItem = null;
        this.panelVisible = false;
    }
    hideInvalid() {
        this.valid = true;
    }
    showInvalid(message: string = '') {
        this.valid = false;
        if (!message) this.invalidMessage = message;
    }

    scrollPoint() {
        if (this.util.IsMobile()) {
            if (this.scrollOnTop) {
                this.util.ScrollToSticky(this.parentId, false);
            }
        }
    }
    checkSelectedItem() {
        return this.selectedItem && this.selectedItem.FullAddress;
    }
}
