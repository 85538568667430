import { Subscription } from 'rxjs';
import { ChangeDetectionStrategy, Component, OnDestroy, afterNextRender } from '@angular/core';
import { ApiService } from './../../../services/apiService';
import { UtilService } from './../../../services/utilService';
import { DataService, EventBooking } from './../../../services/dataService';

@Component({
    selector: 'quotation-dialog',
    styles: [`
        .quotation-message { font-size: 16px; margin: 10px 40px 40px 40px; }
    `],
    templateUrl: './quotation.dialog.component.html',
    host: {
        '(document:keydown)': 'keyDownFunction($event)'
    }
})

export class QuotationDialogComponent implements OnDestroy {
    browser: boolean;
    bookingTime: Date;
    onSubmiting: boolean;
    BookingItemError: Subscription = null;
    BookingItemComplete: Subscription = null;

    ngOnDestroy() {
        if (this.BookingItemError != null) this.BookingItemError.unsubscribe();
        if (this.BookingItemComplete != null) this.BookingItemComplete.unsubscribe();
    }

    constructor(public service: ApiService, public data: DataService, public util: UtilService) {
        this.onSubmiting = false;
        if (this.BookingItemError == null) {
            this.BookingItemError = this.data.BookingItemError.subscribe(() => {
                this.onSubmiting = false;
            });
        }
        if (this.BookingItemComplete == null) {
            this.BookingItemComplete = this.data.BookingItemComplete.subscribe(() => {
                this.onSubmiting = false;
            });
        }
        afterNextRender(() => {
            this.browser = true;
        })
    }

    onSubmit() {
        if (!this.data.validTimeExpired()) {
            return;
        }

        /// Submit
        this.onSubmiting = true;
        this.data.Booking.Time.IsValid = this.data.validTimeExpired();
        this.data.BookingItemChange.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.REQUOTE));
        this.data.ModalType = '';
    }

    onCancel() {
        /// Close all popup
        this.data.ModalType = '';
        this.util.HideAllModal();
        this.data.BookingItemClear.emit();
    }

    onSelectDate($event: any) {
        if ($event != null) {
            this.data.Booking.Time.PickUpType = $event.Type;
            this.data.Booking.Time.Time = new Date($event.Date);
            this.data.Booking.Time.IsValid = this.data.validTimeExpired(true);
            if (!this.data.Booking.Time.IsValid) {
                if (this.data.Booking.Quotation != null)
                    this.data.Booking.Quotation.TotalFare = 0;
                if (this.data.Expried != null) {
                    if (this.data.UpdateTimeout != null) this.data.UpdateTimeout.unsubscribe();
                    this.data.Expried.Counter = 0;
                }

                var that = this;
                setTimeout(function () {
                    that.util.ResizeMap();
                }, 100);
            }
        }
    }

    keyDownFunction(event: any) {
        if (this.data.ModalType == this.data.MODAL_TYPE.QUOTATION) {
            if (event.keyCode == 13) {
                this.onSubmit();
            }
        }
    }
}