export class IpInfo {
    public ip: string;
    public loc: string;
    public org: string;
    public city: string;
    public region: string;
    public country: string;
    public hostname: string;
    public timezone: string;
}

export class IpInfoItem {
    public Ip: string;
    public City: string;
    public Region: string;
    public Country: string;
    public Location: string;

    constructor() {
        this.Country = 'gb';
    }

    public static ToItem(entity: IpInfo): IpInfoItem {
        if (entity == null) return null;
        let item = new IpInfoItem();
        item.Ip = entity.ip;
        item.City = entity.city;
        item.Location = entity.loc;
        item.Region = entity.region;
        item.Country = entity.country;
        return item;
    }
}