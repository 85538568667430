<div class="top-header">
    <div class="container">
        <div class="d-flex">
            <div>&nbsp;</div>
            <div class="information">
                <a href="tel:01223737300" style="color:#fff" class="phone-number">
                    <i aria-hidden="true" class="fas fa-phone"></i> 01223 737300
                </a>
                <a href="mailto:info@hyflytaxis.co.uk" style="color:#fff" class="email-address">
                    <i aria-hidden="true" class="fas fa-envelope"></i> info&#64;hyflytaxis.co.uk
                </a>
                <a href="mailto:accounts@hyflytaxis.co.uk" style="color:#fff" class="email-address">
                    <i aria-hidden="true" class="fas fa-envelope"></i> accounts&#64;hyflytaxis.co.uk
                </a>
            </div>
        </div>
    </div>
</div>