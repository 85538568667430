<div class="modal fade" id="modalSignIn" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button id="closeButton" type="button" class="close" (click)="onClose()">&times;</button>
                <div class="modal-title">
                    <div class="col-xs-6 col-sm-6 col-md-6 signin" (click)="choiceSignIn(data.SIGNIN_TYPE.SIGNIN)" [ngClass]="data.SignInType == data.SIGNIN_TYPE.SIGNIN ? 'active' : ''">
                        SIGN IN
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 signup" (click)="choiceSignIn(data.SIGNIN_TYPE.SIGNUP)" [ngClass]="data.SignInType == data.SIGNIN_TYPE.SIGNUP ? 'active' : ''">
                        SIGN UP
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div [ngClass]="data.SignInType == data.SIGNIN_TYPE.SIGNIN ? 'show' : 'hide'" class="modal-body" *ngIf="data.SignIn != null">
                <div class="ui-control">
                    <xr-textbox #SignInEmail title="Email" [requied]="true" [invalid]="true" placeholder="Email" classInput="ui-input" editorId="signin-email" editorName="signin-email" [model]="data.SignIn.Email" (onChange)="onInputChange(data)" [editorType]="data.EDITOR_TYPE.EMAIL">
                    </xr-textbox>
                </div>
                <div class="ui-control">
                    <xr-textbox #SignInPassword title="Password" [requied]="true" [invalid]="true" classInput="ui-input" placeholder="Password" editorId="signin-password" editorName="signin-password" [model]="data.SignIn.Password" (onChange)="onInputChange(data)" [editorType]="data.EDITOR_TYPE.PASSWORD">
                    </xr-textbox>
                </div>
                <div class="api-invalid" *ngIf="submitMessageSignIn != ''">
                    {{submitMessageSignIn}}
                </div>
                <div class="center-button" style="margin-bottom: 30px;">
                    <button type="button" class="ui-button" (click)="onSignIn()">
                        <span *ngIf="!onSubmitingSignIn">
                            SIGN IN
                        </span>
                        <span *ngIf="onSubmitingSignIn">
                            <span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> Processing
                        </span>
                    </button>
                </div>
                <div class="row-footer">
                    <div class="col-xs-6" style="padding: 0;">
                        <label class="ui-checkbox control--checkbox">
                            Remember me
                            <input type="checkbox" [(ngModel)]="data.SignIn.RememberMe" />
                            <span class="control__indicator"></span>
                        </label>
                    </div>
                    <div class="col-xs-6 modal-link" style="padding: 0;text-align: right;">
                        <a (click)="openModal()">Forgot password?</a>
                    </div>
                    <div style="clear: both;"></div>
                </div>
            </div>
            <div [ngClass]="data.SignInType == data.SIGNIN_TYPE.SIGNUP ? 'show' : 'hide'" class="modal-body" *ngIf="data.SignUp != null">
                <div class="row">
                    <div class="col-xs-12 col-sm-6">
                        <div class="ui-control">
                            <xr-textbox #SignUpFirstName [requied]="true" title="First name" classInput="ui-input" placeholder="First name" editorId="signup-firstname" editorName="signup-firstname" [model]="data.SignUp.Firstname" (onChange)="onInputChange(data)" [editorType]="data.EDITOR_TYPE.TEXT">
                            </xr-textbox>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6">
                        <div class="ui-control">
                            <xr-textbox #SignUpLastName title="Last name" [requied]="true" classInput="ui-input" placeholder="Last name" editorId="signup-lastname" editorName="signup-lastname" [model]="data.SignUp.Surname" (onChange)="onInputChange(data)" [editorType]="data.EDITOR_TYPE.TEXT">
                            </xr-textbox>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-6">
                        <div class="ui-control">
                            <xr-textbox #SignUpPhone title="Mobile" [requied]="true" [invalid]="false" placeholder="Mobile" classInput="ui-input" editorId="signup-phone" editorName="signup-phone" [model]="data.SignUp.Phone" (onChange)="onInputChange(data)" [editorType]="data.EDITOR_TYPE.PHONE">
                            </xr-textbox>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6">
                        <div class="ui-control">
                            <xr-textbox #SignUpEmail title="Email" [requied]="true" [invalid]="true" placeholder="Email" classInput="ui-input" editorId="signup-email" editorName="signup-email" [model]="data.SignUp.Email" (onChange)="onInputChange(data)" [editorType]="data.EDITOR_TYPE.EMAIL">
                            </xr-textbox>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-6">
                        <div class="ui-control">
                            <xr-textbox #SignUpPassword title="Password" [requied]="true" [invalid]="true" classInput="ui-input" placeholder="Password" editorId="signup-password" editorName="signup-password" [model]="data.SignUp.Password" (onChange)="onInputChange(data)" [editorType]="data.EDITOR_TYPE.PASSWORD">
                            </xr-textbox>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6">
                        <div class="ui-control">
                            <xr-textbox #SignUpConfirmPassword [requied]="true" [invalid]="true" classInput="ui-input" title="Confirm password" placeholder="Confirm password" (onChange)="onInputChange(data)" editorId="signup-confirmpassword" editorName="signup-confirmpassword" [model]="data.SignUp.ConfirmPassword"
                                [editorType]="data.EDITOR_TYPE.CONFIRMPASSWORD">
                            </xr-textbox>
                        </div>
                    </div>
                    <div style="clear: both;"></div>
                </div>

                <div class="center-button">
                    <div class="api-invalid" *ngIf="submitMessageSignUp != ''">
                        {{submitMessageSignUp}}
                    </div>
                    <button type="submit" class="ui-button" (click)="onSignUp()">
                        <span *ngIf="!onSubmitingSignUp">
                            SIGN UP
                        </span>
                        <span *ngIf="onSubmitingSignUp">
                            <span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> Processing
                        </span>
                    </button>
                </div>
                <div class="row-policy">By signing up you agreed to our <b>privacy policy & term of service</b></div>
            </div>
        </div>

    </div>
</div>