import { LocationItem } from './location';
import { AppConfig } from './../app.config';
import { Address, AddressItem } from './address';

export class Configuration {
    email: string;
    about: string;
    phone: string;
    skypeid: string;
    baselat: number;
    baselng: number;
    company: string;
    address: string;
    showpet: boolean;
    publickey: string;
    searchapi: string;
    mapsource: string;
    chatapiurl: string;
    servertime: string;
    bingapikey: string;
    airports: Address[];
    twitterlink: string;
    minleadtime: number;
    quotetimeout: number;
    facebooklink: string;
    googleapikey: string;
    autoopenchat: boolean;
    bingmapapikey: string;
    paymentgateway: string;
    googlepluslink: string;
    iscashallowed: boolean;
    iscardallowed: boolean;
    paypalclientid: string;
    stripepublickey: string;
    ispaypalallowed: boolean;
    showsignupfirst: boolean;
    cancancelbooking: boolean;
    showlargechildseat: boolean;
    showsmallchildseat: boolean;
    showotherequipments: boolean;
    defaultpaymentmethod: string;

    constructor() {
        this.about = '';
        this.phone = '';
        this.email = '';
        this.skypeid = '';
        this.company = '';
        this.address = '';
        this.airports = [];
        this.chatapiurl = '';
        this.bingapikey = '';
        this.twitterlink = '';
        this.googleapikey = '';
        this.quotetimeout = 10;
        this.facebooklink = '';
        this.baselng = -0.25366;
        this.bingmapapikey = '';
        this.googlepluslink = '';
        this.baselat = 51.536743;
        this.searchapi = 'xoera';
        this.autoopenchat = false;
        this.iscashallowed = false;
        this.iscardallowed = false;
        this.ispaypalallowed = false;
        this.showsignupfirst = false;
        this.cancancelbooking = true;
        this.defaultpaymentmethod = AppConfig.PAYMENT_TYPE;
    }
}

export class ConfigurationItem {
    Email: string;
    About: string;
    Phone: string;
    Skype: string;
    Company: string;
    IsLoad: boolean;
    Address: string;
    ShowPet: boolean;
    SearchApi: string;
    PublicKey: string;
    SkypeLink: string;
    MapSource: string;
    ServerTime: string;
    ChatApiUrl: string;
    BingApiKey: string;
    TwitterLink: string;
    MinLeadTime: number;
    QuoteTimeout: number;
    FacebookLink: string;
    GoogleApiKey: string;
    AutoOpenChat: boolean;
    PaymentGateway: string;
    IsCashAllowed: boolean;
    IsCardAllowed: boolean;
    GoogleplusLink: string;
    PaypalClientId: string;
    Airports: AddressItem[];
    StripePublicKey: string;
    ShowSignupFirst: boolean;
    IsPaypalAllowed: boolean;
    CanCancelBooking: boolean;
    BaseLocation: LocationItem;
    ShowSmallChildSeat: boolean;
    ShowLargeChildSeat: boolean;
    DefaultPaymentMethod: string;
    ShowOtherEquipments: boolean;

    constructor() {
        this.About = '';
        this.Skype = ' ';
        this.Phone = ' ';
        this.Email = ' ';
        this.Company = '';
        this.Address = '';
        this.Airports = [];
        this.IsLoad = false;
        this.ChatApiUrl = '';
        this.SkypeLink = ' ';
        this.BingApiKey = '';
        this.TwitterLink = '';
        this.QuoteTimeout = 10;
        this.FacebookLink = '';
        this.GoogleApiKey = '';
        this.GoogleplusLink = '';
        this.PaypalClientId = '';
        this.IsCashAllowed = false;
        this.IsCardAllowed = false;
        this.IsPaypalAllowed = false;
        this.CanCancelBooking = true;
        this.DefaultPaymentMethod = AppConfig.PAYMENT_TYPE;
        this.BaseLocation = new LocationItem(51.536743, -0.015247);
    }

    public static ToItem(entity: Configuration): ConfigurationItem {
        if (entity == null) return new ConfigurationItem();
        var item = new ConfigurationItem();
        item.IsLoad = true;
        item.About = entity.about;
        item.Phone = entity.phone;
        item.Email = entity.email;
        item.Skype = entity.skypeid;
        item.Address = entity.address;
        item.Company = entity.company;
        item.ShowPet = entity.showpet;
        item.MapSource = entity.mapsource;
        item.SearchApi = entity.searchapi;
        item.PublicKey = entity.publickey;
        item.ServerTime = entity.servertime;
        item.ChatApiUrl = entity.chatapiurl;
        item.MinLeadTime = entity.minleadtime;
        item.TwitterLink = entity.twitterlink;
        item.QuoteTimeout = entity.quotetimeout;
        item.FacebookLink = entity.facebooklink;
        item.GoogleApiKey = entity.googleapikey;
        item.AutoOpenChat = entity.autoopenchat;
        item.GoogleplusLink = entity.googlepluslink;
        item.PaymentGateway = entity.paymentgateway;
        item.StripePublicKey = entity.stripepublickey;
        item.SkypeLink = "skype:" + item.Skype + "?chat";
        item.ShowSmallChildSeat = entity.showsmallchildseat;
        item.ShowLargeChildSeat = entity.showlargechildseat;
        item.Airports = AddressItem.ToItems(entity.airports);
        item.ShowOtherEquipments = entity.showotherequipments;
        item.PaypalClientId = entity.paypalclientid || 'test';
        item.BaseLocation = new LocationItem(entity.baselat, entity.baselng);
        item.IsCashAllowed = entity.iscashallowed ? entity.iscashallowed : false;
        item.IsCardAllowed = entity.iscardallowed ? entity.iscardallowed : false;
        item.IsPaypalAllowed = entity.ispaypalallowed ? entity.ispaypalallowed : false;
        item.ShowSignupFirst = entity.showsignupfirst ? entity.showsignupfirst : false;
        item.CanCancelBooking = entity.cancancelbooking ? entity.cancancelbooking : false;
        item.DefaultPaymentMethod = entity.defaultpaymentmethod ? entity.defaultpaymentmethod : AppConfig.PAYMENT_TYPE;
        item.BingApiKey = entity.bingapikey || entity.bingmapapikey || 'AuPUbNkDipB7ZkSacA6fNTJmcHY3mVRvsIxPHUNCK9KEdKu3VULEMKo4xmK2MMKf';
        return item;
    }
}
