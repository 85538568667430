declare var jQuery: any;
import { DataService } from './../../../services/dataService';
import { UtilService } from './../../../services/utilService';
import { ConstantHelper } from '../../../helper/constant.helper';
import { Component, Input, Output, EventEmitter, AfterViewChecked, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'xr-textbox',
    templateUrl: 'textbox.html',
    styleUrls: ['textbox.scss'],
    encapsulation: ViewEncapsulation.None
})

export class XrTextboxComponent implements AfterViewChecked, OnInit {
    iconId: string;
    parentId: string;
    error: string = '';
    type: string = 'text';
    hasLabelForce = false;
    first: boolean = false;

    glyphiconUp: string;
    glyphiconDown: string;

    @Input() model: any;
    @Input() title: string;
    @Input() dialingCode: any;
    @Input() editorId: string;
    @Input() glyphicon: string;
    @Input() editorName: string;
    @Input() editorType: number;
    @Input() placeholder: string;
    @Input() invalidMessage: string;
    @Input() iconFirst: string = '';
    @Input() requied: boolean = true;
    @Input() invalid: boolean = true;
    @Input() classInput: string = '';
    @Input() maxlength: number = 255;
    @Input() hasIcon: boolean = false;
    @Input() readonly: boolean = false;
    @Input() disabled: boolean = false;
    @Input() hasLabel: boolean = false;
    @Input() scrollInModal: string = '';
    @Input() scrollOnTop: boolean = false;
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @Output() onKeyPressEnter: EventEmitter<any> = new EventEmitter();
    @Output() onDialingCodeChange: EventEmitter<any> = new EventEmitter();

    ngOnInit() {
        this.iconId = this.editorId + '-icon';
        this.parentId = this.editorId + '-input';
        if (!this.placeholder) this.placeholder = this.title;
        if (!this.editorName) this.editorName = this.editorId;
        this.hasIcon = this.iconFirst && this.iconFirst != '';
        if (this.hasLabel) {
            this.hasLabelForce = this.hasLabel;
            this.classInput = this.classInput + ' hasLabel';
            this.hasLabel = this.editorType != this.data.EDITOR_TYPE.PHONE && this.editorType != this.data.EDITOR_TYPE.COUNTRY;
        }
        if (this.hasIcon) {
            this.classInput = this.classInput + ' hasicon';
        }
        switch (this.editorType) {
            case this.data.EDITOR_TYPE.TEXT:
            case this.data.EDITOR_TYPE.POSTCODE:
            case this.data.EDITOR_TYPE.TEXTAREA:
                this.type = 'text';
                break;
            case this.data.EDITOR_TYPE.COUNTRY:
                this.type = 'search';
                this.readonly = true;
                setTimeout(() => {
                    if (this.model == null || this.model.length == 0) {
                        let data = jQuery('#' + this.editorId).countrySelect("getSelectedCountryData");
                        if (data && data.name) this.model = data.name;
                        else this.model = 'United Kingdom';
                    }
                }, 1000);
                break;
            case this.data.EDITOR_TYPE.PHONE:
                this.type = 'tel';
                setTimeout(() => {
                    if (this.model) {
                        if (!ConstantHelper.REGEX_NUMBER.test(this.model)) {
                            this.error = this.title + ' is invalid';
                            jQuery('#' + this.parentId).addClass('error');
                            jQuery('#' + this.parentId).removeClass('valid');
                        } else {
                            jQuery('#' + this.parentId).addClass('valid');
                            jQuery('#' + this.parentId).removeClass('error');
                        }
                    }

                }, 1000);
            case this.data.EDITOR_TYPE.CARDCVC:
            case this.data.EDITOR_TYPE.CARDNUMBER:
                this.type = 'tel';
                break;
            case this.data.EDITOR_TYPE.PASSWORD:
            case this.data.EDITOR_TYPE.CONFIRMPASSWORD:
                this.type = 'password';
                break;
            case this.data.EDITOR_TYPE.EMAIL:
                this.type = 'email';
                break;
        }
        if (!this.glyphicon) this.glyphicon = 'menu';
        this.glyphiconUp = 'glyphicon-' + this.glyphicon + '-up';
        this.glyphiconDown = 'glyphicon-' + this.glyphicon + '-down';
    }

    ngAfterViewChecked() {
        if (!this.first) {
            this.first = true;
            switch (this.editorType) {
                case this.data.EDITOR_TYPE.PHONE: {
                    this.util.InitEditorPhone(this.editorId, this.dialingCode, this.model);
                    if (this.hasLabelForce) {
                        var div = jQuery('#' + this.editorId).parent('div');
                        jQuery(div).append('<label class="label-phone-number">' + this.placeholder + '</label>');
                    }
                }
                    break;
                case this.data.EDITOR_TYPE.COUNTRY: {
                    this.util.InitEditorCountries(this.editorId);
                    if (this.hasLabelForce) {
                        jQuery('#' + this.editorId).removeAttr('readonly');
                        var div = jQuery('#' + this.editorId).parent('div');
                        jQuery(div).append('<label class="label-country-number">' + this.placeholder + '</label>');
                    }
                }
                    break;
                case this.data.EDITOR_TYPE.CARDNUMBER: {
                    if (jQuery('#' + this.editorId))
                        jQuery('#' + this.editorId).payment('formatCardNumber');
                }
                    break;
                case this.data.EDITOR_TYPE.CARDCVC: {
                    if (jQuery('#' + this.editorId))
                        jQuery('#' + this.editorId).payment('formatCardCVC');
                }
                    break;
            }
        }
    }

    constructor(public data: DataService, public util: UtilService) {

    }

    onBlur() {
        if (this.editorType == this.data.EDITOR_TYPE.PHONE)
            this.onDialingCodeChange.emit();
        if (this.requied) {
            if (!this.model || this.model.trim().length == 0) {
                this.error = this.invalidMessage && this.invalidMessage.length > 0
                    ? this.invalidMessage
                    : this.title + ' is required';
                jQuery('#' + this.parentId).addClass('error');
                jQuery('#' + this.parentId).removeClass('valid');
                return false;
            } else {
                jQuery('#' + this.parentId).addClass('valid');
                jQuery('#' + this.parentId).removeClass('error');
            }
        }
        var valid = true;
        var message = '';
        if (this.invalid) {
            if (this.model && this.model.length > 0) {
                switch (this.editorType) {
                    case this.data.EDITOR_TYPE.POSTCODE: {
                        if (!ConstantHelper.REGEX_LETTER_NUMBER.test(this.model)) {
                            valid = false;
                        }
                        if (this.model.indexOf(' ') == 0) {
                            valid = false;
                        }
                        if (this.model.lastIndexOf(' ') == this.model.length - 1) {
                            valid = false;
                        }
                        if (this.model.length > 9) {
                            valid = false;
                        }
                    }
                        break;
                    case this.data.EDITOR_TYPE.EMAIL: {
                        if (!ConstantHelper.REGEX_EMAIL.test(this.model)) {
                            valid = false;
                        }
                    }
                        break;
                    case this.data.EDITOR_TYPE.PHONE: {
                        if (!ConstantHelper.REGEX_NUMBER.test(this.model)) {
                            valid = false;
                        }
                        if (this.model.indexOf('0') == 0) {
                            valid = false;
                        }
                        if (this.model.indexOf(' ') >= 0) {
                            valid = false;
                        }
                        if (!jQuery('#' + this.editorId).intlTelInput('isValidNumber')) {
                            valid = false;
                        }
                    }
                        break;
                    case this.data.EDITOR_TYPE.PASSWORD: {
                        if (this.model.trim().length < 6 || this.model.trim().length > 20) {
                            message = this.title + '  must be at least 6 characters long';
                            valid = false;
                        }
                    }
                        break;
                    case this.data.EDITOR_TYPE.CARDNUMBER: {
                        this.model = jQuery('#' + this.editorId).val();
                        if (!jQuery.payment.validateCardNumber(this.model)) {
                            valid = false;
                        }
                    }
                        break;
                    case this.data.EDITOR_TYPE.CONFIRMPASSWORD: {
                        if (this.model.trim().length < 6 || this.model.trim().length > 20) {
                            message = this.title + '  must be at least 6 characters long';
                            valid = false;
                        }
                    }
                        break;
                }
            }
            if (valid) {
                jQuery('#' + this.parentId).addClass('valid');
                jQuery('#' + this.parentId).removeClass('error');
            } else {
                this.error = message == '' ? this.title + ' is invalid' : message;
                jQuery('#' + this.parentId).addClass('error');
                jQuery('#' + this.parentId).removeClass('valid');
            }
        }
        return valid;
    }

    onFocus() {
        if (this.util.IsMobile()) {
            if (this.scrollOnTop) {
                if (this.scrollInModal != '')
                    this.util.ScrollToStickyInModal(this.editorId, this.scrollInModal);
                else
                    this.util.ScrollToSticky(this.editorId, false);
            }
        }
    }

    onClick() {
        if (this.editorType == this.data.EDITOR_TYPE.COUNTRY) {
            if (this.util.IsMobile()) {
                if (this.scrollOnTop)
                    this.util.ScrollToSticky(this.editorId, false);
            }
        }
    }

    onInput() {
        jQuery('#' + this.parentId).addClass('valid');
        jQuery('#' + this.parentId).removeClass('error');
        switch (this.editorType) {
            case this.data.EDITOR_TYPE.CARDNUMBER: {
                var cardType = jQuery.payment.cardType(this.model);
                if (cardType && cardType.length > 0) {
                    jQuery('#' + this.iconId).addClass('show');
                    jQuery('#' + this.iconId).addClass(cardType);
                } else {
                    jQuery('#' + this.iconId).removeClass('show');
                }
            }
                break;
        }
    }

    onInputChange() {
        this.onChange.emit(this.model);
    }

    onKeyup() {
        if (this.editorType == this.data.EDITOR_TYPE.PHONE) {
            if (!this.model) return;
            if (this.model.length == 0) return;

            if (/\D/g.test(this.model))
                this.model = this.model.replace(/\D/g, '');
            if (this.model.indexOf('0') == 0)
                this.model = this.model.replace('0', '');
        }
    }

    onKeypress(event) {
        if (this.editorType == this.data.EDITOR_TYPE.PHONE) {
            if (isNaN(event.key))
                event.preventDefault();
            else if (event.key == '0') {
                if (!this.model || this.model.length == 0) {
                    event.preventDefault();
                }
            }
        }
        if (event.key == 'Enter') {
            this.onKeyPressEnter.emit();
        }
    }

    checkValid() {
        return this.onBlur();
    }

    hideInvalid() {
        this.error = '';
        jQuery('#' + this.parentId).addClass('valid');
        jQuery('#' + this.parentId).removeClass('error');
    }

    showInvalid(message: string) {
        this.error = message;
        jQuery('#' + this.parentId).addClass('error');
        jQuery('#' + this.parentId).removeClass('valid');
    }

    setDialingCode() {
        var phone = this.model;
        var editorId = this.editorId;
        var dialCode = this.dialingCode;
        if (dialCode || phone) {
            phone = (phone.indexOf('+') == 0 || !dialCode) ? phone : (dialCode + phone);
            jQuery('#' + editorId).intlTelInput("setNumber", phone);
            var index = 0;
            var interval = window.setInterval(function () {
                index += 1;
                if (index >= 5)
                    window.clearInterval(interval);

                if (jQuery('#' + editorId).length > 0) {
                    var value = jQuery('#' + editorId).val();
                    while (value.indexOf(' ') >= 0) value = value.replace(' ', '');
                    jQuery('#' + editorId).val(value);
                }
            }, 1000);
        }
    }
}
