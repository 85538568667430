export class Location {
    lat: number;
    lng: number;
}

export class LocationItem {
    Latitude: number;
    Longitude: number;

    constructor(latitude: number, longitude: number) {
        this.Latitude = latitude;
        this.Longitude = longitude;
    }

    public static ToItem(entity: Location): LocationItem {
        if (entity == null) return null;
        return new LocationItem(entity.lat, entity.lng);
    }

    public static ToItems(entities: Location[]): LocationItem[] {
        if (entities == null || entities.length == 0) return new Array();
        let items = new Array();
        entities.forEach(element => {
            items.push(LocationItem.ToItem(element));
        });
        return items;
    }

    public static ToItemFromNumber(entity: Location): LocationItem {
        if (entity == null) return null;
        return new LocationItem(entity.lat, entity.lng);
    }

    public static ToItemsFromNumber(entities: Array<number[]>): LocationItem[] {
        if (entities == null || entities.length == 0) return new Array();
        let items = new Array();
        entities.forEach(element => {
            items.push(new LocationItem(element[1], element[0]));
        });
        return items;
    }
}
