<div class="modal fade" id="modalConfirm" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button id="closeButtonAlert" type="button" class="close" (click)="onClose()">&times;</button>
                <div class="col-xs-12 col-sm-12 col-md-12 modal-title">
                    {{ dialog.Title }}
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="modal-body">
                <p style="margin-top: 20px; text-align: center;">{{ dialog.Content }}</p>
                <div class="center-button">
                    <button type="button" class="ui-button" (click)="onOk()" [disabled]="loading">
                        <span *ngIf="!loading">
                            OK
                        </span>
                        <span *ngIf="loading">
                            <span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> Processing
                        </span>
                    </button>
                    <button type="button" class="ui-button btn-cancel" (click)="onCancel()">
                        <span>
                            CANCEL
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>