import { NgModule } from '@angular/core';
import { AppRouting } from './app.routing';
import { AppComponent } from './app.component';
import { MapService } from './services/mapSerive';
import { ApiService } from './services/apiService';
import { DataService } from './services/dataService';
import { UtilService } from './services/utilService';
import { SharedModule } from './modules/shared.module';
import { BrowserModule  } from '@angular/platform-browser';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        AppRouting,
        SharedModule,
        BrowserModule
    ],
    providers: [ApiService, DataService, UtilService, MapService],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
    }
}