export class ConstantHelper {
    public static COUNTRY_MAP = {
        AD: 'EUR',
        AE: 'AED',
        AF: 'AFN',
        AG: 'XCD',
        AI: 'XCD',
        AL: 'ALL',
        AM: 'AMD',
        AN: 'ANG',
        AO: 'AOA',
        AR: 'ARS',
        AS: 'USD',
        AT: 'EUR',
        AU: 'AUD',
        AW: 'AWG',
        AX: 'EUR',
        AZ: 'AZN',
        BA: 'BAM',
        BB: 'BBD',
        BD: 'BDT',
        BE: 'EUR',
        BF: 'XOF',
        BG: 'BGN',
        BH: 'BHD',
        BI: 'BIF',
        BJ: 'XOF',
        BL: 'EUR',
        BM: 'BMD',
        BN: 'BND',
        BO: 'BOB',
        BQ: 'USD',
        BR: 'BRL',
        BS: 'BSD',
        BT: 'BTN',
        BV: 'NOK',
        BW: 'BWP',
        BY: 'BYN',
        BZ: 'BZD',
        CA: 'CAD',
        CC: 'AUD',
        CD: 'CDF',
        CF: 'XAF',
        CG: 'XAF',
        CH: 'CHF',
        CI: 'XOF',
        CK: 'NZD',
        CL: 'CLP',
        CM: 'XAF',
        CN: 'CNY',
        CO: 'COP',
        CR: 'CRC',
        CU: 'CUP',
        CV: 'CVE',
        CW: 'ANG',
        CX: 'AUD',
        CY: 'EUR',
        CZ: 'CZK',
        DE: 'EUR',
        DJ: 'DJF',
        DK: 'DKK',
        DM: 'XCD',
        DO: 'DOP',
        DZ: 'DZD',
        EC: 'USD',
        EE: 'EUR',
        EG: 'EGP',
        EH: 'MAD',
        ER: 'ERN',
        ES: 'EUR',
        ET: 'ETB',
        FI: 'EUR',
        FJ: 'FJD',
        FK: 'FKP',
        FM: 'USD',
        FO: 'DKK',
        FR: 'EUR',
        GA: 'XAF',
        GB: 'GBP',
        GD: 'XCD',
        GE: 'GEL',
        GF: 'EUR',
        GG: 'GBP',
        GH: 'GHS',
        GI: 'GIP',
        GL: 'DKK',
        GM: 'GMD',
        GN: 'GNF',
        GP: 'EUR',
        GQ: 'XAF',
        GR: 'EUR',
        GS: 'GBP',
        GT: 'GTQ',
        GU: 'USD',
        GW: 'XOF',
        GY: 'GYD',
        HK: 'HKD',
        HM: 'AUD',
        HN: 'HNL',
        HR: 'HRK',
        HT: 'HTG',
        HU: 'HUF',
        ID: 'IDR',
        IE: 'EUR',
        IL: 'ILS',
        IM: 'GBP',
        IN: 'INR',
        IO: 'USD',
        IQ: 'IQD',
        IR: 'IRR',
        IS: 'ISK',
        IT: 'EUR',
        JE: 'GBP',
        JM: 'JMD',
        JO: 'JOD',
        JP: 'JPY',
        KE: 'KES',
        KG: 'KGS',
        KH: 'KHR',
        KI: 'AUD',
        KM: 'KMF',
        KN: 'XCD',
        KP: 'KPW',
        KR: 'KRW',
        KW: 'KWD',
        KY: 'KYD',
        KZ: 'KZT',
        LA: 'LAK',
        LB: 'LBP',
        LC: 'XCD',
        LI: 'CHF',
        LK: 'LKR',
        LR: 'LRD',
        LS: 'LSL',
        LT: 'LTL',
        LU: 'EUR',
        LV: 'LVL',
        LY: 'LYD',
        MA: 'MAD',
        MC: 'EUR',
        MD: 'MDL',
        ME: 'EUR',
        MF: 'EUR',
        MG: 'MGA',
        MH: 'USD',
        MK: 'MKD',
        ML: 'XOF',
        MM: 'MMK',
        MN: 'MNT',
        MO: 'MOP',
        MP: 'USD',
        MQ: 'EUR',
        MR: 'MRO',
        MS: 'XCD',
        MT: 'EUR',
        MU: 'MUR',
        MV: 'MVR',
        MW: 'MWK',
        MX: 'MXN',
        MY: 'MYR',
        MZ: 'MZN',
        NA: 'NAD',
        NC: 'XPF',
        NE: 'XOF',
        NF: 'AUD',
        NG: 'NGN',
        NI: 'NIO',
        NL: 'EUR',
        NO: 'NOK',
        NP: 'NPR',
        NR: 'AUD',
        NU: 'NZD',
        NZ: 'NZD',
        OM: 'OMR',
        PA: 'PAB',
        PE: 'PEN',
        PF: 'XPF',
        PG: 'PGK',
        PH: 'PHP',
        PK: 'PKR',
        PL: 'PLN',
        PM: 'EUR',
        PN: 'NZD',
        PR: 'USD',
        PS: 'ILS',
        PT: 'EUR',
        PW: 'USD',
        PY: 'PYG',
        QA: 'QAR',
        RE: 'EUR',
        RO: 'RON',
        RS: 'RSD',
        RU: 'RUB',
        RW: 'RWF',
        SA: 'SAR',
        SB: 'SBD',
        SC: 'SCR',
        SD: 'SDG',
        SE: 'SEK',
        SG: 'SGD',
        SH: 'SHP',
        SI: 'EUR',
        SJ: 'NOK',
        SK: 'EUR',
        SL: 'SLL',
        SM: 'EUR',
        SN: 'XOF',
        SO: 'SOS',
        SR: 'SRD',
        ST: 'STD',
        SV: 'SVC',
        SX: 'ANG',
        SY: 'SYP',
        SZ: 'SZL',
        TC: 'USD',
        TD: 'XAF',
        TF: 'EUR',
        TG: 'XOF',
        TH: 'THB',
        TJ: 'TJS',
        TK: 'NZD',
        TL: 'USD',
        TM: 'TMT',
        TN: 'TND',
        TO: 'TOP',
        TR: 'TRY',
        TT: 'TTD',
        TV: 'AUD',
        TW: 'TWD',
        TZ: 'TZS',
        UA: 'UAH',
        UG: 'UGX',
        UM: 'USD',
        US: 'USD',
        UY: 'UYU',
        UZ: 'UZS',
        VA: 'EUR',
        VC: 'XCD',
        VE: 'VEF',
        VG: 'USD',
        VI: 'USD',
        VN: 'VND',
        VU: 'VUV',
        WF: 'XPF',
        WS: 'WST',
        YE: 'YER',
        YT: 'EUR',
        ZA: 'ZAR',
        ZM: 'ZMK',
        ZW: 'ZWL'
    };
    public static CURRENCY_MAP = {
        AFA: {
            name: 'Afghanistan Afghani (AFA)',
            symbolFormat: '{#} ؋'
        },
        ALL: {
            name: 'Albanian Lek (ALL)',
            symbolFormat: '{#} L'
        },
        DZD: {
            name: 'Algerian Dinar (DZD)',
            symbolFormat: '{#} DA'
        },
        AOA: {
            name: 'Angolan New Kwanza (AOA)',
            symbolFormat: '{#} Kz'
        },
        ARS: {
            name: 'Argentine Peso (ARS)',
            symbolFormat: '${#}'
        },
        AMD: {
            name: 'Armenian Dram (AMD)',
            symbolFormat: '{#}֏'
        },
        AWG: {
            name: 'Aruba Florin (AWG)',
            symbolFormat: 'Afl {#}'
        },
        AUD: {
            name: 'Australian Dollar (AUD)',
            symbolFormat: 'AU${#}'
        },
        AZM: {
            name: 'Azerbaijani Manat (AZM)',
            symbolFormat: '₼{#}'
        },
        BSD: {
            name: 'Bahamian Dollar (BSD)',
            symbolFormat: 'B${#}'
        },
        BHD: {
            name: 'Bahraini Dinar (BHD)',
            symbolFormat: '{#} BD'
        },
        BDT: {
            name: 'Bangladesh Taka (BDT)',
            symbolFormat: '৳{#}'
        },
        BBD: {
            name: 'Barbados Dollar (BBD)',
            symbolFormat: 'Bds${#}'
        },
        BYR: {
            name: 'Belarus Ruble (BYR)',
            symbolFormat: 'Br {#}'
        },
        BZD: {
            name: 'Belize Dollar (BZD)',
            symbolFormat: 'BZ${#}'
        },
        BMD: {
            name: 'Bermuda Dollar (BMD)',
            symbolFormat: '${#}'
        },
        BTN: {
            name: 'Bhutan Ngultrum (BTN)',
            symbolFormat: 'Nu.{#}'
        },
        BOB: {
            name: 'Bolivian Boliviano (BOB)',
            symbolFormat: 'Bs. {#}'
        },
        BAM: {
            name: 'Bosnian Marka (BAM)',
            symbolFormat: '{#} KM'
        },
        BWP: {
            name: 'Botswana Pula (BWP)',
            symbolFormat: 'P {#}'
        },
        BRL: {
            name: 'Brazilian Real (BRL)',
            symbolFormat: 'R${#}'
        },
        GBP: {
            name: 'British Pound (GBP)',
            symbolFormat: '£ {#}'
        },
        BND: {
            name: 'Brunei Dollar (BND)',
            symbolFormat: 'B${#}'
        },
        BGN: {
            name: 'Bulgarian Lev (BGN)',
            symbolFormat: 'BGN {#}'
        },
        BIF: {
            name: 'Burundi Franc (BIF)',
            symbolFormat: '{#} FBu'
        },
        KHR: {
            name: 'Cambodia Riel (KHR)',
            symbolFormat: 'KHR {#}'
        },
        CAD: {
            name: 'Canadian Dollar (CAD)',
            symbolFormat: 'C${#}'
        },
        CVE: {
            name: 'Cape Verde Escudo (CVE)',
            symbolFormat: '{#} CVE'
        },
        KYD: {
            name: 'Cayman Islands Dollar (KYD)',
            symbolFormat: 'CI${#}'
        },
        XOF: {
            name: 'CFA Franc (BCEAO) (XOF)',
            symbolFormat: 'XOF {#}'
        },
        XAF: {
            name: 'CFA Franc (BEAC) (XAF)',
            symbolFormat: 'XAF {#}'
        },
        CLP: {
            name: 'Chilean Peso (CLP)',
            symbolFormat: 'CLP${#}'
        },
        CNY: {
            name: 'Chinese Yuan (CNY)',
            symbolFormat: '¥{#}'
        },
        COP: {
            name: 'Colombian Peso (COP)',
            symbolFormat: 'COL${#}'
        },
        KMF: {
            name: 'Comoros Franc (KMF)',
            symbolFormat: 'CF {#}'
        },
        CDF: {
            name: 'Congolese Franc (CDF)',
            symbolFormat: 'FC {#}'
        },
        CRC: {
            name: 'Costa Rica Colon (CRC)',
            symbolFormat: '₡{#}'
        },
        HRK: {
            name: 'Croatian Kuna (HRK)',
            symbolFormat: '{#} kn'
        },
        CUP: {
            name: 'Cuban Peso (CUP)',
            symbolFormat: '${#}'
        },
        CZK: {
            name: 'Czech Koruna (CZK)',
            symbolFormat: '{#} Kč'
        },
        DKK: {
            name: 'Danish Krone (DKK)',
            symbolFormat: '{#} Kr.'
        },
        DJF: {
            name: 'Dijibouti Franc (DJF)',
            symbolFormat: '{#} Fdj'
        },
        DOP: {
            name: 'Dominican Peso (DOP)',
            symbolFormat: 'RD${#}'
        },
        XCD: {
            name: 'East Caribbean Dollar (XCD)',
            symbolFormat: '${#}'
        },
        EGP: {
            name: 'Egyptian Pound (EGP)',
            symbolFormat: 'E&pound; {#}'
        },
        SVC: {
            name: 'El Salvador Colon (SVC)',
            symbolFormat: '₡{#}'
        },
        ERN: {
            name: 'Eritrea Nakfa (ERN)',
            symbolFormat: '{#} Nkf'
        },
        ETB: {
            name: 'Ethiopian Birr (ETB)',
            symbolFormat: '{#} Br'
        },
        EUR: {
            name: 'Euro (EUR)',
            symbolFormat: '&euro;{#}'
        },
        FKP: {
            name: 'Falkland Islands Pound (FKP)',
            symbolFormat: 'FK&pound; {#}'
        },
        FJD: {
            name: 'Fiji Dollar (FJD)',
            symbolFormat: 'FJ${#}'
        },
        GMD: {
            name: 'Gambian Dalasi (GMD)',
            symbolFormat: 'GMD {#}'
        },
        GEL: {
            name: 'Georgian Lari (GEL)',
            symbolFormat: '₾{#}'
        },
        GHC: {
            name: 'Ghanian Cedi (GHC)',
            symbolFormat: 'GH₵ {#}'
        },
        GIP: {
            name: 'Gibraltar Pound (GIP)',
            symbolFormat: '&pound;{#}'
        },
        XAU: {
            name: 'Gold Ounces (XAU)',
            symbolFormat: 'XAU {#}'
        },
        GTQ: {
            name: 'Guatemala Quetzal (GTQ)',
            symbolFormat: '{#} Q'
        },
        GGP: {
            name: 'Guernsey Pound (GGP)',
            symbolFormat: '&pound;{#}'
        },
        GNF: {
            name: 'Guinea Franc (GNF)',
            symbolFormat: 'GNF {#}'
        },
        GYD: {
            name: 'Guyana Dollar (GYD)',
            symbolFormat: 'GY${#}'
        },
        HTG: {
            name: 'Haiti Gourde (HTG)',
            symbolFormat: 'HTG {#}'
        },
        HNL: {
            name: 'Honduras Lempira (HNL)',
            symbolFormat: 'HNL {#}'
        },
        HKD: {
            name: 'Hong Kong Dollar (HKD)',
            symbolFormat: 'HK${#}'
        },
        HUF: {
            name: 'Hungarian Forint (HUF)',
            symbolFormat: '{#} Ft'
        },
        ISK: {
            name: 'Iceland Krona (ISK)',
            symbolFormat: '{#} kr'
        },
        INR: {
            name: 'Indian Rupee (INR)',
            symbolFormat: '₹{#}'
        },
        IDR: {
            name: 'Indonesian Rupiah (IDR)',
            symbolFormat: 'Rp {#}'
        },
        IRR: {
            name: 'Iran Rial (IRR)',
            symbolFormat: 'IRR {#}'
        },
        IQD: {
            name: 'Iraqi Dinar (IQD)',
            symbolFormat: 'IQD {#}'
        },
        IMP: {
            name: 'Isle of Man Pound (IMP)',
            symbolFormat: '&pound;{#}'
        },
        ILS: {
            name: 'Israeli Shekel (ILS)',
            symbolFormat: '₪{#}'
        },
        JMD: {
            name: 'Jamaican Dollar (JMD)',
            symbolFormat: 'JA${#}'
        },
        JPY: {
            name: 'Japanese Yen (JPY)',
            symbolFormat: '&yen;{#}'
        },
        JEP: {
            name: 'Jersey Pound (JEP)',
            symbolFormat: '&pound;{#}'
        },
        JOD: {
            name: 'Jordanian Dinar (JOD)',
            symbolFormat: 'JOD {#}'
        },
        KZT: {
            name: 'Kazakhstan Tenge (KZT)',
            symbolFormat: '₸{#}'
        },
        KES: {
            name: 'Kenyan Shilling (KES)',
            symbolFormat: 'KSh {#}'
        },
        KRW: {
            name: 'Korean Won (KRW)',
            symbolFormat: '₩{#}'
        },
        KWD: {
            name: 'Kuwaiti Dinar (KWD)',
            symbolFormat: '{#} KD'
        },
        KGS: {
            name: 'Kyrgyzstan Som (KGS)',
            symbolFormat: 'KGS {#}'
        },
        LAK: {
            name: 'Lao Kip (LAK)',
            symbolFormat: '₭{#}'
        },
        LBP: {
            name: 'Lebanese Pound (LBP)',
            symbolFormat: 'LBP {#}'
        },
        LSL: {
            name: 'Lesotho Loti (LSL)',
            symbolFormat: 'LSL {#}'
        },
        LRD: {
            name: 'Liberian Dollar (LRD)',
            symbolFormat: 'L${#}'
        },
        LYD: {
            name: 'Libyan Dinar (LYD)',
            symbolFormat: '{#} LD'
        },
        MOP: {
            name: 'Macau Pataca (MOP)',
            symbolFormat: 'MOP${#}'
        },
        MKD: {
            name: 'Macedonian Denar (MKD)',
            symbolFormat: 'MKD {#}'
        },
        MGF: {
            name: 'Malagasy Franc (MGF)',
            symbolFormat: 'MGF {#}'
        },
        MWK: {
            name: 'Malawi Kwacha (MWK)',
            symbolFormat: 'MK {#}'
        },
        MYR: {
            name: 'Malaysian Ringgit (MYR)',
            symbolFormat: 'RM {#}'
        },
        MVR: {
            name: 'Maldives Rufiyaa (MVR)',
            symbolFormat: 'MVR {#}'
        },
        MRO: {
            name: 'Mauritania Ougulya (MRO)',
            symbolFormat: 'MRO {#}'
        },
        MUR: {
            name: 'Mauritius Rupee (MUR)',
            symbolFormat: 'Rs {#}'
        },
        MXN: {
            name: 'Mexican Peso (MXN)',
            symbolFormat: '${#}'
        },
        MDL: {
            name: 'Moldovan Leu (MDL)',
            symbolFormat: 'MDL {#}'
        },
        MNT: {
            name: 'Mongolian Tugrik (MNT)',
            symbolFormat: 'MNT {#}'
        },
        MAD: {
            name: 'Moroccan Dirham (MAD)',
            symbolFormat: 'MAD {#}'
        },
        MZM: {
            name: 'Mozambique Metical (MZM)',
            symbolFormat: 'MZM {#}'
        },
        MMK: {
            name: 'Myanmar Kyat (MMK)',
            symbolFormat: 'K{#}'
        },
        NAD: {
            name: 'Namibian Dollar (NAD)',
            symbolFormat: 'N${#}'
        },
        NPR: {
            name: 'Nepalese Rupee (NPR)',
            symbolFormat: 'रू{#}'
        },
        ANG: {
            name: 'Neth Antilles Guilder (ANG)',
            symbolFormat: 'ƒ{#}'
        },
        NZD: {
            name: 'New Zealand Dollar (NZD)',
            symbolFormat: 'NZ${#}'
        },
        NIO: {
            name: 'Nicaragua Cordoba (NIO)',
            symbolFormat: 'C${#}'
        },
        NGN: {
            name: 'Nigerian Naira (NGN)',
            symbolFormat: '₦{#}'
        },
        KPW: {
            name: 'North Korean Won (KPW)',
            symbolFormat: '₩{#}'
        },
        NOK: {
            name: 'Norwegian Krone (NOK)',
            symbolFormat: '{#} kr'
        },
        OMR: {
            name: 'Omani Rial (OMR)',
            symbolFormat: 'OMR {#}'
        },
        XPF: {
            name: 'Pacific Franc (XPF)',
            symbolFormat: '₣{#}'
        },
        PKR: {
            name: 'Pakistani Rupee (PKR)',
            symbolFormat: 'Rs {#}'
        },
        XPD: {
            name: 'Palladium Ounces (XPD)',
            symbolFormat: 'XPD {#}'
        },
        PAB: {
            name: 'Panama Balboa (PAB)',
            symbolFormat: 'B/. {#}'
        },
        PGK: {
            name: 'Papua New Guinea Kina (PGK)',
            symbolFormat: 'K {#}'
        },
        PYG: {
            name: 'Paraguayan Guarani (PYG)',
            symbolFormat: '₲{#}'
        },
        PEN: {
            name: 'Peruvian Nuevo Sol (PEN)',
            symbolFormat: 'S/{#}'
        },
        PHP: {
            name: 'Philippine Peso (PHP)',
            symbolFormat: '₱{#}'
        },
        XPT: {
            name: 'Platinum Ounces (XPT)',
            symbolFormat: 'XPT {#}'
        },
        PLN: {
            name: 'Polish Zloty (PLN)',
            symbolFormat: '{#}zł'
        },
        QAR: {
            name: 'Qatar Rial (QAR)',
            symbolFormat: 'QAR {#}'
        },
        ROL: {
            name: 'Romanian Leu (ROL)',
            symbolFormat: 'RON {#}'
        },
        RUB: {
            name: 'Russian Rouble (RUB)',
            symbolFormat: '{#}₽'
        },
        RWF: {
            name: 'Rwanda Franc (RWF)',
            symbolFormat: 'FRw {#}'
        },
        WST: {
            name: 'Samoa Tala (WST)',
            symbolFormat: 'WS${#}'
        },
        STD: {
            name: 'Sao Tome Dobra (STN)',
            symbolFormat: 'STN {#}'
        },
        SAR: {
            name: 'Saudi Arabian Riyal (SAR)',
            symbolFormat: 'SAR {#}'
        },
        SCR: {
            name: 'Seychelles Rupee (SCR)',
            symbolFormat: 'SCR {#}'
        },
        SLL: {
            name: 'Sierra Leone Leone (SLL)',
            symbolFormat: 'SLL {#}'
        },
        XAG: {
            name: 'Silver Ounces (XAG)',
            symbolFormat: 'XAG {#}'
        },
        SGD: {
            name: 'Singapore Dollar (SGD)',
            symbolFormat: 'S${#}'
        },
        SBD: {
            name: 'Solomon Islands Dollar (SBD)',
            symbolFormat: 'SI${#}'
        },
        SOS: {
            name: 'Somali Shilling (SOS)',
            symbolFormat: 'SOS {#}'
        },
        ZAR: {
            name: 'South African Rand (ZAR)',
            symbolFormat: 'R{#}'
        },
        LKR: {
            name: 'Sri Lanka Rupee (LKR)',
            symbolFormat: 'Rs {#}'
        },
        SHP: {
            name: 'St Helena Pound (SHP)',
            symbolFormat: '&pound;{#}'
        },
        SSP: {
            name: 'Sudanese Pound (SSP)',
            symbolFormat: 'SSP {#}'
        },
        SRD: {
            name: 'Surinam Dollar (SRD)',
            symbolFormat: 'Sr${#}'
        },
        SZL: {
            name: 'Swaziland Lilageni (SZL)',
            symbolFormat: 'E{#}'
        },
        SEK: {
            name: 'Swedish Krona (SEK)',
            symbolFormat: '{#} kr'
        },
        CHF: {
            name: 'Swiss Franc (CHF)',
            symbolFormat: '{#} Fr.'
        },
        SYP: {
            name: 'Syrian Pound (SYP)',
            symbolFormat: 'SYP {#}'
        },
        TWD: {
            name: 'Taiwan Dollar (TWD)',
            symbolFormat: 'NT${#}'
        },
        TZS: {
            name: 'Tanzanian Shilling (TZS)',
            symbolFormat: 'TZS {#}'
        },
        THB: {
            name: 'Thai Baht (THB)',
            symbolFormat: '฿{#}'
        },
        TOP: {
            name: "Tonga Pa'anga (TOP)",
            symbolFormat: 'T${#}'
        },
        TTD: {
            name: 'Trinidad&Tobago Dollar (TTD)',
            symbolFormat: 'TT${#}'
        },
        TND: {
            name: 'Tunisian Dinar (TND)',
            symbolFormat: 'TND {#}'
        },
        TRL: {
            name: 'Turkish Lira (TRL)',
            symbolFormat: '₺{#}'
        },
        TMM: {
            name: 'Turkmen Manat (TMM)',
            symbolFormat: 'TMM {#}'
        },
        USD: {
            name: 'U.S. Dollar (USD)',
            symbolFormat: '${#}'
        },
        AED: {
            name: 'UAE Dirham (AED)',
            symbolFormat: 'AED {#}'
        },
        UGX: {
            name: 'Ugandan Shilling (UGX)',
            symbolFormat: 'USh {#}'
        },
        UAH: {
            name: 'Ukraine Hryvnia (UAH)',
            symbolFormat: '₴{#}'
        },
        UYU: {
            name: 'Uruguayan New Peso (UYU)',
            symbolFormat: '${#}'
        },
        UZS: {
            name: 'Uzbekistani Sum (UZS)',
            symbolFormat: '{#} soʻm'
        },
        VUV: {
            name: 'Vanuatu Vatu (VUV)',
            symbolFormat: '{#}VT'
        },
        VEB: {
            name: 'Venezuelan Bolivar (VEB)',
            symbolFormat: '{#} Bs.'
        },
        VND: {
            name: 'Vietnam Dong (VND)',
            symbolFormat: '{#}₫'
        },
        YER: {
            name: 'Yemen Riyal (YER)',
            symbolFormat: 'YER {#}'
        },
        YUM: {
            name: 'Yugoslav Dinar (YUM)',
            symbolFormat: 'YUM {#}'
        },
        ZRN: {
            name: 'Zaire New Zaire (ZRN)',
            symbolFormat: 'ZRN {#}'
        },
        ZMK: {
            name: 'Zambian Kwacha (ZMK)',
            symbolFormat: 'ZMK {#}'
        },
        ZWD: {
            name: 'Zimbabwe Dollar (ZWD)',
            symbolFormat: 'Z${#}'
        }
    };
    public static ADDRESS_FIX = [];
    public static REGEX_NUMBER = /^\d+$/;
    public static REGEX_LETTER_NUMBER = /^[_\- 0-9a-zA-Z]+$/i;
    public static REGEX_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;
}