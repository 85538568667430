import { ConstantHelper } from "./constant.helper";

export class FormatCurrencyHelper {

    public static formatCurrency = (value: any, currencyAbbr: any) => {
        const currency = FormatCurrencyHelper.getCurrency(currencyAbbr);

        if (currency) {
            return currency.symbolFormat
                .replace(/&(\w+);/, (match: any, p1: any) => FormatCurrencyHelper.currencySymbolMap[p1] || p1)
                .replace('{#}', value);
        }
        return `${value} ${currencyAbbr}`;
    };
    public static formatLocaleCurrency = (value: any, currency: any = 'GBP', options = {}): string => {
        if (!currency) currency = 'GBP';
        const parsedValue = parseFloat(value);
        const finalOptions = { abbreviate: false, autoFixed: true, ...options };
        const { abbreviate, autoFixed } = finalOptions;
        let locale = (<any>finalOptions).locale;
        const digitCount = FormatCurrencyHelper.getFixedDigitCount(value, autoFixed);

        const abbrResult = abbreviate
            ? FormatCurrencyHelper.abbreviateNumericValue(parsedValue)
            : undefined;
        const localeOptionsSupported =
            typeof Intl == 'object' && Intl && typeof Intl.NumberFormat == 'function';

        if (!localeOptionsSupported) {
            return FormatCurrencyHelper.formatCurrency(abbrResult ? abbrResult.string : parsedValue, currency);
        }

        if (!locale) {
            locale = typeof window !== 'undefined' && window !== null && window.navigator && window.navigator.language ? window.navigator.language : 'en-US';
        }

        if (abbrResult) {
            const format = abbrResult.rawValue.toLocaleString(locale, {
                minimumFractionDigits: digitCount,
                maximumFractionDigits: digitCount
            });

            const localeCurr = abbrResult.rawValue.toLocaleString(locale, {
                minimumFractionDigits: digitCount,
                maximumFractionDigits: digitCount,
                currency,
                style: 'currency'
            });

            return localeCurr.replace(format, `${format}${abbrResult.symbol}`);
        }

        return parsedValue.toLocaleString(locale, {
            minimumFractionDigits: digitCount,
            maximumFractionDigits: digitCount,
            currency,
            style: 'currency'
        });
    };

    private static numericAbbr = {
        thousand: { symbol: 'k', value: 1000 },
        million: { symbol: 'm', value: 1000000 },
        billion: { symbol: 'b', value: 1000000000 },
        trillion: { symbol: 't', value: 1000000000000 }
    };
    private static currencySymbolMap = {
        pound: '\xA3',
        euro: '\u20AC',
        yen: '\xA5'
    };
    private static abbreviateNumericValue = value => {
        let symbol = '';
        let abbrValue = value;

        if (value / FormatCurrencyHelper.numericAbbr.trillion.value > 1) {
            abbrValue = value / FormatCurrencyHelper.numericAbbr.trillion.value;
            symbol = FormatCurrencyHelper.numericAbbr.trillion.symbol;
        } else if (value / FormatCurrencyHelper.numericAbbr.billion.value > 1) {
            abbrValue = value / FormatCurrencyHelper.numericAbbr.billion.value;
            symbol = FormatCurrencyHelper.numericAbbr.billion.symbol;
        } else if (value / FormatCurrencyHelper.numericAbbr.million.value > 1) {
            abbrValue = value / FormatCurrencyHelper.numericAbbr.million.value;
            symbol = FormatCurrencyHelper.numericAbbr.million.symbol;
        } else if (value / FormatCurrencyHelper.numericAbbr.thousand.value > 1) {
            abbrValue = value / FormatCurrencyHelper.numericAbbr.thousand.value;
            symbol = FormatCurrencyHelper.numericAbbr.thousand.symbol;
        }

        return {
            rawValue: abbrValue,
            symbol,
            string: `${abbrValue.toFixed(value >= 1000 ? 1 : 2)}${symbol}`
        };
    };
    private static getFixedDigitCount = (value: any, autoFixed: any) => {
        if (value >= 1000 && autoFixed) {
            return 0;
        }

        if (value - Math.floor(value) === 0 && autoFixed) {
            return 0;
        }

        return 2;
    };
    private static getCurrency = (currencyAbbr: string) => ConstantHelper.CURRENCY_MAP[currencyAbbr];
}