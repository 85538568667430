<div [id]="parentId" class="text-input textWapper" [ngClass]="{'valid' : editorType == data.EDITOR_TYPE.COUNTRY}" (click)="onClick()">
    <span class="ui-icon" *ngIf="hasIcon">
        <img [src]="iconFirst" />
    </span>
    <span [id]="iconId" class="card-type-icon" *ngIf="editorType == data.EDITOR_TYPE.CARDNUMBER"></span>
    <input *ngIf="editorType != data.EDITOR_TYPE.TEXTAREA"
           required=""
           [type]="type"
           [id]="editorId"
           (blur)="onBlur()"
            class="ui-input"
           (focus)="onFocus()"
           [(ngModel)]="model"
           (input)="onInput()"
           [disabled]="disabled"
           [maxlength]="maxlength"
           [className]="classInput"
           (change)="onInputChange()"
           (keypress)="onKeypress($event)"
           [readonly]="readonly ? 'readonly' : ''"
           [placeholder]="hasLabelForce && !disabled ? '' : placeholder"
           (keyup)="editorType == data.EDITOR_TYPE.PHONE ? onKeyup($event) : ''"
           [ngClass]="{'hasLabel' : hasLabel || hasLabelForce, 'edited' : model, 'hasicon' : hasIcon }" />
        <textarea *ngIf="editorType == data.EDITOR_TYPE.TEXTAREA"
            rows="3"
            required=""
            [id]="editorId"
            class="ui-input"
            (blur)="onBlur()"
            (focus)="onFocus()"
            [(ngModel)]="model"
            (input)="onInput()"
            [disabled]="disabled"
            [maxlength]="maxlength"
            [className]="classInput"
            (change)="onInputChange()"
            [readonly]="readonly ? 'readonly' : ''"
            [placeholder]="hasLabel ? '' : placeholder"
            [ngClass]="{'hasLabel' : hasLabel || hasLabelForce, 'edited' : model }">
        </textarea>
    <label *ngIf="hasLabel" [ngClass]="hasIcon ? '' : 'nopadding'">
        {{ placeholder }}
    </label>
    <div class="ui-invalid">{{ error }}</div>
</div>