import { Component } from '@angular/core';
import { AppConfig } from '../../../app.config';
import { DataService } from '../../../services/dataService';
import { UtilService } from './../../../services/utilService';

@Component({
    selector: 'xr-footer',
    templateUrl: "./footer.component.hyflytaxis.html"
})

export class XrFooterComponent {
    iconLogo: string;
    iconEmail: string;
    iconPhone: string;

    constructor(
        public util: UtilService,
        public data: DataService) {
        this.iconLogo = AppConfig.ASSETS + 'logo/logobg.png';
        this.iconEmail = AppConfig.ASSETS + 'icons/email.png';
        this.iconPhone = AppConfig.ASSETS + 'icons/telephone.png';
    }
}
