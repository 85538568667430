import { MapType } from "./domain/enum/map.type";

export class AppConfig {
	public static API: string;
    public static NAME: string;
    public static TITLE: string;
    public static ASSETS: string;
    public static MAP_TYPE: MapType;
	public static SOURCE_TYPE: number;
    public static PAYMENT_TYPE: string;
    public static TEMPLATE_TYPE: string;
    public static TEMPLATE_CAR_TYPE: string;
    public static WIZARD_BUTTON_TYPE: string;
	public static APILICENCE: string = '//xoeraapis.com/licence/licence';
}