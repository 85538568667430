import { NgModule } from '@angular/core';
import { CarPipe } from '../pipe/car.pipe';
import { FormsModule } from '@angular/forms';
import { TimexPipe } from '../pipe/time.pipe';
import { CommonModule } from '@angular/common';
import { DatexPipe } from '../pipe/datetime.pipe';
import { TruncatePipe } from '../pipe/truncate.pipe';
import { ClickOutsideModule } from 'ng-click-outside';
import { HttpClientModule } from '@angular/common/http';
import { SanitizeUrlPipe } from '../pipe/sanitizeurl.pipe';
import { SanitizeHtmlPipe } from '../pipe/sanitizehtml.pipe';
import { WrapperDirective } from '../directives/wrapper.directive';
import { XrTextboxComponent } from '../components/editors/textbox/textbox';
import { XrMenuComponent } from '../components/toolbar/menu/menu.component';
import { XrCheckboxComponent } from '../components/editors/checkbox/checkbox';
import { XrDateTimeComponent } from '../components/editors/datetime/datetime';
import { XrDropdownComponent } from '../components/editors/dropdown/dropdown';
import { XrHeaderComponent } from '../components/toolbar/header/header.component';
import { XrFooterComponent } from '../components/toolbar/footer/footer.component';
import { XrPagingComponent } from '../components/toolbar/paging/paging.component';
import { XrFlightListComponent } from '../components/editors/flightlist/flightlist';
import { PickCardComponent } from './booking/components/pickcard/pickcard.component';
import { NoteDialogComponent } from '../components/dialog/note/note.dialog.component';
import { CardDialogComponent } from '../components/dialog/card/card.dialog.component';
import { XrAirportListComponent } from '../components/editors/ariportlist/airportlist';
import { XrAirlineListComponent } from '../components/editors/airlinelist/airlinelist';
import { PickFloatComponent } from './booking/components/pickfloat/pickfloat.component';
import { PromoDialogComponent } from '../components/dialog/promo/promo.dialog.component';
import { AlertDialogComponent } from '../components/dialog/alert/alert.dialog.component';
import { XrAutoCompleteComponent } from '../components/editors/autocomplete/autocomplete';
import { SignInDialogComponent } from '../components/dialog/signin/signin.dialog.component';
import { ForgotDialogComponent } from '../components/dialog/forgot/forgot.dialog.component';
import { FlightDialogComponent } from '../components/dialog/flight/flight.dialog.component';
import { ConfirmDialogComponent } from '../components/dialog/confirm/confirm.dialog.component';
import { ContactDialogComponent } from '../components/dialog/contact/contact.dialog.component';
import { QuotationDialogComponent } from '../components/dialog/quotation/quotation.dialog.component';
import { MenuDashboardComponent } from '../components/toolbar/menu.dashboard/menu.dashboard.component';
import { Flight360DialogComponent } from '../components/dialog/flight.360/flight.360.dialog.component';
import { FlightSearchDialogComponent } from '../components/dialog/flight.search/flight.search.dialog.component';
import { PaymentMethodDialogComponent } from '../components/dialog/payment.method/payment.method.dialog.component';
import { PaymentProcessDialogComponent } from '../components/dialog/payment.process/payment.process.dialog.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        HttpClientModule,
        ClickOutsideModule
    ],
    declarations: [
        CarPipe,
        DatexPipe,
        TimexPipe,
        TruncatePipe,
        SanitizeUrlPipe,
        XrMenuComponent,
        WrapperDirective,
        SanitizeHtmlPipe,
        XrPagingComponent,
        XrHeaderComponent,
        XrFooterComponent,
        PickCardComponent,
        PickFloatComponent,
        XrTextboxComponent,
        NoteDialogComponent,
        XrCheckboxComponent,
        XrDateTimeComponent,
        XrDropdownComponent,
        CardDialogComponent,
        AlertDialogComponent,
        PromoDialogComponent,
        FlightDialogComponent,
        SignInDialogComponent,
        ForgotDialogComponent,
        XrFlightListComponent,
        ContactDialogComponent,
        ConfirmDialogComponent,
        MenuDashboardComponent,
        XrAirportListComponent,
        XrAirlineListComponent,
        XrAutoCompleteComponent,
        QuotationDialogComponent,
        Flight360DialogComponent,
        FlightSearchDialogComponent,
        PaymentMethodDialogComponent,
        PaymentProcessDialogComponent
    ],
    exports: [
        CarPipe,
        DatexPipe,
        TimexPipe,
        FormsModule,
        CommonModule,
        TruncatePipe,
        SanitizeUrlPipe,
        XrMenuComponent,
        HttpClientModule,
        SanitizeHtmlPipe,
        WrapperDirective,
        XrPagingComponent,
        XrHeaderComponent,
        XrFooterComponent,
        PickCardComponent,
        PickFloatComponent,
        XrTextboxComponent,
        ClickOutsideModule,
        NoteDialogComponent,
        CardDialogComponent,
        XrCheckboxComponent,
        XrDropdownComponent,
        XrDateTimeComponent,
        AlertDialogComponent,
        PromoDialogComponent,
        XrFlightListComponent,
        FlightDialogComponent,
        SignInDialogComponent,
        ForgotDialogComponent,
        ContactDialogComponent,
        ConfirmDialogComponent,
        MenuDashboardComponent,
        XrAirportListComponent,
        XrAirlineListComponent,
        XrAutoCompleteComponent,
        QuotationDialogComponent,
        Flight360DialogComponent,
        FlightSearchDialogComponent,
        PaymentMethodDialogComponent,
        PaymentProcessDialogComponent
    ]
})
export class SharedModule { }
