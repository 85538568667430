import { ApiService } from './../../../services/apiService';
import { UtilService } from './../../../services/utilService';
import { DataService } from './../../../services/dataService';
import { XrTextboxComponent } from '../../editors/textbox/textbox';
import { ChangeDetectionStrategy, Component, ViewChild, afterNextRender } from '@angular/core';

@Component({
    selector: 'promo-dialog',
    templateUrl: './promo.dialog.component.html',
    host: {
        '(document:keydown)': 'keyDownFunction($event)'
    }
})

export class PromoDialogComponent {
    browser: boolean;
    loading: boolean = false;
    submitMessage: string = '';
    @ViewChild('PromoCode') PromoCode: XrTextboxComponent;

    constructor(public service: ApiService, public data: DataService, public util: UtilService) {
        afterNextRender(() => {
            this.browser = true;
        })
    }

    onSubmit() {
        /// Valid
        let valid = this.validSubmit();
        if (!valid) {
            return;
        }

        // Set value
        this.data.Booking.Promo.PromoCode = this.PromoCode.model;

        /// Submit
        this.loading = true;
        this.submitMessage = '';
        this.service.promoCode(this.data.Booking)
            .subscribe(model => {
                this.loading = false;
                if (model.Code == 1) {
                    this.data.Booking.Promo = model;
                    this.util.HideModal(this.data.MODAL_TYPE.PROMO);
                } else {
                    this.submitMessage = model.Code == 0 ? 'Something went wrong, please try again.' : model.Message;
                }
            });
    }

    validSubmit(): boolean {
        var valid1 = this.PromoCode.checkValid();
        return valid1;
    }

    onInputChange(data: any) {
        this.submitMessage = '';
    }

    onClose() {
        this.submitMessage = '';
        this.PromoCode.hideInvalid();
        this.PromoCode.model = this.data.Booking.Promo.PromoCode;
        this.util.HideModal(this.data.MODAL_TYPE.PROMO);
        this.data.ModalType = '';
    }

    keyDownFunction(event: any) {
        if (this.data.ModalType == this.data.MODAL_TYPE.PROMO) {
            if (event.keyCode == 13) {
                this.onSubmit();
            }
        }
    }
}