export class Airport {
    code: string;
    name: string;
    origin: string;
    airport: string;
    country: string;
    iata_code: string;
    icao_code: string;
    city_name: string;
    airport_name: string;
    country_name: string;
}

export class AirportItem {
    Code: string;
    Name: string;
    City?: string;
    Airport?: string;
    Country?: string;
    IataCode?: string;
    IconPath?: string;
    FullAddress: string;
    ClassIconFlag?: string;
    CountryLowerCase?: string;

    public static ToItem(entity: Airport): AirportItem {
        if (entity == null) return null;
        let item = new AirportItem();
        item.City = entity.city_name;
        item.Airport = entity.airport;
        item.IataCode = entity.iata_code;
        item.Code = entity.code || entity.icao_code;
        item.Name = entity.name || entity.airport_name;
        item.Country = entity.country || entity.country_name;
        item.FullAddress = item.Code.length > 0
            ? item.Code + " - " + item.Name
            : item.Name;
        item.FullAddress = item.IataCode && item.IataCode.length > 0
            ? item.IataCode + " - " + item.FullAddress
            : item.FullAddress;
        if (item.City) item.FullAddress += ' - ' + item.City;
        if (item.Country) item.FullAddress += ' - ' + item.Country;
        //item.IconPath = 'http://www.geonames.org/flags/x/' + item.Country.toLowerCase() + '.gif';
        item.IconPath = '';
        if (item.Country.length <= 5) {
            item.CountryLowerCase = item.Country.toLowerCase();
            item.ClassIconFlag = 'flag flag-' + item.Country.toLowerCase();
        }
        return item;
    }

    public static ToItems(entities: Airport[]): AirportItem[] {
        if (entities == null || entities.length == 0) return null;
        let items = new Array();
        entities.forEach(element => {
            items.push(AirportItem.ToItem(element));
        });
        return items;
    }
}
