declare var jQuery: any;
import { AppConfig } from '../../../app.config';
import { SignIn } from '../../../domain/object/signin';
import { SignUp } from '../../../domain/object/signup';
import { Component, afterNextRender } from '@angular/core';
import { UtilService } from './../../../services/utilService';
import { DataService } from './../../../services/dataService';

@Component({
    selector: 'xr-header',
    templateUrl: "./header.component.hyflytaxis.html"
})
export class XrHeaderComponent {
    browser: boolean;
    iconLogo: string;
    iconPhone: string;
    iconEmail: string;
    iconLogin: string;
    iconTelePhone: string;

    constructor(public data: DataService, public util: UtilService) {
        this.iconLogo = AppConfig.ASSETS + 'logo/logo.png';
        this.iconEmail = AppConfig.ASSETS + 'icons/email.png';
        this.iconLogin = AppConfig.ASSETS + 'icons/login.png';
        this.iconPhone = AppConfig.ASSETS + 'icons/phone-call.png';
        this.iconTelePhone = AppConfig.ASSETS + 'icons/telephone.png';
        afterNextRender(() => {
            this.browser = true;
        })
    }

    onOpenModal() {
        if (this.data.SignIn == null) this.data.SignIn = new SignIn();
        if (this.data.SignUp == null) this.data.SignUp = new SignUp();
        this.util.ShowModal(this.data.MODAL_TYPE.SIGNIN);
        this.data.SignInNavigate = '';
    }

    onOpenMenu() {
        var dropdown = jQuery('#header .dropdown-menu');
        if (dropdown) {
            if (dropdown.is(':visible')) {
                dropdown.hide();
            } else {
                dropdown.show();
            }
        }
    }

    scrollTo(anchor: string, outsite: boolean = false) {
        if (!outsite) {
            this.util.ScrollTo(anchor);
        }
        else {
            window.location.href = '/' + anchor;
        }
    }
}