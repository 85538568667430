<div class="modal fade" id="modalPromo" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content" *ngIf="browser">
            <div class="modal-header">
                <button id="closeButtonPromo" type="button" class="close" (click)="onClose()">&times;</button>
                <div class="col-xs-12 col-sm-12 col-md-12 modal-title">
                    <span class="glyphicon glyphicon-gift"></span> Add Promo Code
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="modal-body">
                <div class="ui-control">
                    <xr-textbox #PromoCode [requied]="true" [invalid]="false" title="Promo code" classInput="ui-input" editorId="promo-code" editorName="promo-code" (onChange)="onInputChange(data)" [model]="data.Booking.Promo.PromoCode" [editorType]="data.EDITOR_TYPE.TEXT">
                    </xr-textbox>
                </div>
                <div class="center-button" style="text-align: center;">
                    <div class="api-invalid" *ngIf="submitMessage != ''">
                        {{submitMessage}}
                    </div>
                    <button type="button" class="ui-button" (click)="onSubmit()" [disabled]="loading">
                        <span *ngIf="!loading">
                            OK
                        </span>
                        <span *ngIf="loading">
                            <span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> Processing
                        </span>
                    </button>
                    <button type="button" class="ui-button btn-cancel" (click)="onClose()">
                        <span>
                            Cancel
                        </span>
                    </button>
                </div>
                <div style="clear: both;"></div>
            </div>
        </div>
    </div>
</div>