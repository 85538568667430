import * as _ from 'lodash';
import { AppConfig } from '../../../app.config';
import { VehicleItem } from '../../../domain/vehicle';
import { UtilService } from './../../../services/utilService';
import { PositionType } from '../../../domain/enum/position.type';
import { DropDownType } from '../../../domain/enum/dropdown.type';
import { DropDownItem } from '../../../domain/object/dropdownitem';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'xr-dropdown',
    templateUrl: 'dropdown.html',
    styleUrls: ['dropdown.scss']
})
export class XrDropdownComponent implements OnInit {
    items: any[];
    parentId: string;
    icon: string = '';
    innerHtml: string;
    valid: boolean = true;
    PositionType = PositionType;
    panelVisible: boolean = false;

    glyphiconUp: string;
    glyphiconDown: string;

    @Input() model: any;
    @Input() options: any[];
    @Input() editorId: string;
    @Input() iconFirst: string;
    @Input() glyphicon: string;
    @Input() type: DropDownType;
    @Input() placeholder: string;
    @Input() invalidMessage: string;
    @Input() classInput: string = '';
    @Input() hasLabel: boolean = false;
    @Input() scrollInModal: string = '';
    @Input() scrollOnTop: boolean = false;
    @Input() alwayHidePanel: boolean = false;
    @Input() position: PositionType = PositionType.Bottom;

    @Output() selectedChange: EventEmitter<any>;
    @Output() togglePanel: EventEmitter<Boolean>;

    constructor(public util: UtilService) {
        this.togglePanel = new EventEmitter<Boolean>()
        this.selectedChange = new EventEmitter();
    }

    ngOnInit() {
        this.parentId = this.editorId + '-dropdown';
        this.setSelectedItem(this.model);
        if (!this.glyphicon) this.glyphicon = 'menu';
        this.glyphiconUp = this.glyphicon == 'triangle'
            ? 'glyphicon-' + this.glyphicon + '-top'
            : 'glyphicon-' + this.glyphicon + '-up';
        this.glyphiconDown = this.glyphicon == 'triangle'
            ? 'glyphicon-' + this.glyphicon + '-bottom'
            : 'glyphicon-' + this.glyphicon + '-down';
    }

    public onSelectedChange(item: any) {
        if (item) {
            this.hidePanel();
            this.model = item.value;
            switch (this.type) {
                case DropDownType.Car: {
                    if (this.model) {
                        this.initItemCar(this.model);
                    }
                }
                    break;
                case DropDownType.String: {
                    this.initItemString(this.model);
                }
                    break;
                case DropDownType.LabelValue: {
                    this.initItemLabelValue(this.model);
                }
                    break;
            }
            this.selectedChange.emit(this.model);
        }
    }

    initItemString(item: string = null) {
        this.innerHtml = item;
        if (this.options) {
            this.items = _.cloneDeep(this.options);
            this.items.forEach((it: any) => {
                it.selected = false;
                if (item != null && item == it.value) {
                    it.selected = true;
                    this.innerHtml = it ? it.label : '';
                }
            });
        }
    }

    initItemCar(item: VehicleItem = null) {
        this.innerHtml = VehicleItem.ToDropDownItem(item).label;
        if (this.options) {
            this.items = _.cloneDeep(this.options);
            this.items.forEach((it: any) => {
                it.selected = false;
                if (item && item.Id == it.value.Id)
                    it.selected = true;
            });
        }
    }

    initItemLabelValue(item: DropDownItem = null) {
        if (this.options) {
            this.items = _.cloneDeep(this.options);
            this.items.forEach((it: any) => {
                it.selected = false;
                if (item && (item == it.value || item.value == it.value)) {
                    it.selected = true;
                    this.innerHtml = it ? it.label : '';
                }
            });
        }
    }

    handleInputClick() {
        this.panelVisible = !this.panelVisible;
        this.togglePanel.emit(this.panelVisible);
        this.scrollPoint();
    }

    hidePanel(focusHide: boolean = false) {
        if (!this.alwayHidePanel)
            this.panelVisible = false;
        if (focusHide)
            this.panelVisible = false;
    }
    showPanel() {
        this.panelVisible = true;
    }
    scrollPoint() {
        if (this.util.IsMobile()) {
            if (this.scrollOnTop) {
                this.util.ScrollToSticky(this.parentId, false);
            }
        }
    }

    public onClick(): void {
        if (this.util.IsMobile()) {
            if (this.scrollOnTop) {
                if (this.scrollInModal != '')
                    this.util.ScrollToStickyInModal(this.editorId, this.scrollInModal);
                else
                    this.util.ScrollToSticky(this.editorId, false);
            }
        }
    }

    hideInvalid() {
        this.valid = true;
    }
    showInvalid(message: string = '') {
        this.valid = false;
        if (!message) this.invalidMessage = message;
    }

    public panelIsVisible(): boolean {
        return this.panelVisible;
    }

    public setSelectedText(text: string) {
        this.innerHtml = text;
    }

    public reloadDataSource(options: any[], itemModel: any) {
        this.options = options;
        this.items = _.cloneDeep(this.options);
        this.setSelectedItem(itemModel);
    }

    public setSelectedItem(itemModel: any = null) {
        this.model = itemModel;
        switch (this.type) {
            case DropDownType.Car: {
                if (this.model) {
                    this.icon = AppConfig.ASSETS + "editor/car.png";
                    let item = <VehicleItem>this.model;
                    this.initItemCar(item);
                } else {
                    this.initItemCar();
                }
            }
                break;
            case DropDownType.String: {
                if (this.model != null) {
                    let item = <string>this.model;
                    this.initItemString(item);
                    this.icon = '';
                } else {
                    this.initItemString();
                }
            }
                break;
            case DropDownType.LabelValue: {
                let item = this.model && <DropDownItem>this.model;
                this.initItemLabelValue(item);
                this.icon = '';
            }
                break;
        }
    }
}
