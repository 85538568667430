import { Booking } from './booking';

export class Promotion {
    code: number;
    mode: string;
    phone: string;
    email: string;
    custid: number;
    surname: string;
    message: string;
    picklat: number;
    picklng: number;
    dofflat: number;
    dofflng: number;
    bookerid: number;
    promocode: string;
    vehtypeid: number;
    firstname: string;
    vehicletype: string;
    dialingcode: string;
    pickpostcode: string;
    droppostcode: string;
    pickcategory: string;
    doffcategory: string;
    pickupaddress: string;
    countryalpha2: string;
    dropoffaddress: string;
    changesallowed: boolean;
    smsmailinglist: boolean;
    emailmailinglist: boolean;
}

export class PromotionItem {
    Code: number;
    Mode: string;
    Phone: string;
    Email: string;
    Message: string;
    PickLat: number;
    PickLng: number;
    DoffLat: number;
    DoffLng: number;
    LastName: string;
    BookerId: number;
    PromoCode: string;
    VehtypeId: number;
    FirstName: string;
    CustomerId: number;
    VehicleType: string;
    DialingCode: string;
    PickPostCode: string;
    DropPostCode: string;
    PickCategory: string;
    DoffCategory: string;
    PickupAddress: string;
    CountryAlpha2: string;
    DropoffAddress: string;
    SmsMailingList: string;
    ChangesAllowed: boolean;
    EmailMailingList: string;

    public static ToItem(entity: Promotion): PromotionItem {
        if (entity == null) return null;
        var item = new PromotionItem();
        item.Code = entity.code;
        item.Mode = entity.mode;
        item.Phone = entity.phone;
        item.Email = entity.email;
        item.Message = entity.message;
        item.PickLat = entity.picklat;
        item.PickLng = entity.picklng;
        item.DoffLat = entity.dofflat;
        item.DoffLng = entity.dofflng;
        item.LastName = entity.surname;
        item.BookerId = entity.bookerid;
        item.CustomerId = entity.custid;
        item.PromoCode = entity.promocode;
        item.VehtypeId = entity.vehtypeid;
        item.FirstName = entity.firstname;
        item.VehicleType = entity.vehicletype;
        item.DialingCode = entity.dialingcode;
        item.PickPostCode = entity.pickpostcode;
        item.DropPostCode = entity.droppostcode;
        item.PickCategory = entity.pickcategory;
        item.DoffCategory = entity.doffcategory;
        item.PickupAddress = entity.pickupaddress;
        item.CountryAlpha2 = entity.countryalpha2;
        item.ChangesAllowed = entity.changesallowed;
        item.DropoffAddress = entity.dropoffaddress;
        item.SmsMailingList = entity.smsmailinglist ? entity.smsmailinglist.toString() : 'false';
        item.EmailMailingList = entity.emailmailinglist ? entity.smsmailinglist.toString() : 'false';
        return item;
    }
}
