import { ProfileItem } from './../domain/profile';

export class Contact {
    public Name: string;
    public Note: string;
    public Phone: string;
    public Email: string;
    public OurRef: string;
    public BookerId: number;
    public LastName: string;
    public Password: string;
    public FirstName: string;
    public CustomerId: number;
    public AsAccount: boolean;
    public DialingCode: string;
    public PasswordHash: string;
    public get PhoneNumber(): string {
        var phone = this.DialingCode + this.Phone;
        while (phone.indexOf('+') >= 0) 
            phone = phone.replace('+', '');
        return '+' + phone;
    }

    constructor() {
        this.Note = '';
        this.Name = '';
        this.Email = '';
        this.Phone = '';
        this.OurRef = '';
        this.BookerId = 0;
        this.LastName = '';
        this.FirstName = '';
        this.CustomerId = 0;
        this.DialingCode = '';
    }

    public static ToItemFromContact(entity: Contact): Contact {
        if (entity == null) return null;
        let item = new Contact();
        item.Note = entity.Note;
        item.Email = entity.Email;
        item.Phone = entity.Phone;
        item.OurRef = entity.OurRef;
        item.BookerId = entity.BookerId;
        item.LastName = entity.LastName;
        item.FirstName = entity.FirstName;
        item.CustomerId = entity.CustomerId;
        item.DialingCode = entity.DialingCode;
        item.Name = entity.FirstName;
        if (entity.LastName && entity.LastName.length > 0)
            item.Name += ' ' + entity.LastName;
        if (item.Phone.startsWith('0')) item.Phone = item.Phone.substr(1);
        return item;
    }

    public static ToItemFromProfile(entity: ProfileItem): Contact {
        if (entity == null) return null;
        let item = new Contact();
        item.Note = '';
        item.OurRef = '';
        item.Email = entity.Email;
        item.Phone = entity.Phone;
        item.CustomerId = entity.Id;
        item.BookerId = entity.BookerId;
        item.LastName = entity.LastName;
        item.FirstName = entity.FirstName;
        item.DialingCode = entity.DialingCode;
        item.Name = entity.FirstName;
        if (entity.LastName && entity.LastName.length > 0)
            item.Name += ' ' + entity.LastName;
        if (item.Phone.startsWith('0')) item.Phone = item.Phone.substr(1);
        return item;
    }
}
