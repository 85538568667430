import { ViaAddress } from './address';
import { LocationItem } from './location';
import { Driver, DriverItem } from './driver';
import { AdditionalPrice, AdditionalPriceItem } from './additionalprice';
import { FormatCurrencyHelper } from '../helper/format.currency.helper';

export class Quotation {
    code: number;
    fare: number;
    pklat: number;
    pklng: number;
    dolat: number;
    dolng: number;
    message: string;
    vehtype: string;
    gbpfare: number;
    vehtypeid: number;
    totalfare: number;
    traveltime: number;
    returnfare: number;
    currencycode: string;
    gbptotalfare: number;
    routedistance: number;
    nearestdriver: Driver;
    gbpreturnfare: number;
    returntraveltime: number;
    routepath: Array<number[]>;
    viaaddresses: ViaAddress[];
    returnroutedistance: number;
    returnroutepath: Array<number[]>;
    additionalprices: AdditionalPrice[];
}

export class RouteDirection {
    distance: number;
    duration: number;
    geometry: Geometry;
}

export class QuotationItem {
    Code: number;
    Message: string;
    GbpFare: number;
    TotalFare: number;
    TravelTime: number;
    JourneyTime: number;
    GbpTotalFare: number;
    PickUp: LocationItem;
    CurrencyCode: string;
    DropOff: LocationItem;
    RouteDistance: number;
    GbpReturnFare: number;
    OnwardFare: number = 0;
    TotalFareString: string;
    RoutePath: LocationItem[];
    ReturnJourneyTime: number;
    GbpTotalFareString: string;
    ReturnTotalFare: number = 0;
    ReturnRouteDistance: number;
    ReturnRoutePath: LocationItem[];
    AdditionalPrices: AdditionalPriceItem[];

    constructor() {
        this.TotalFare = 0;
        this.TravelTime = 0;
        this.JourneyTime = 0;
        this.RouteDistance = 0;
        this.TotalFareString = '';
    }

    public static ToItem(quotation: Quotation): QuotationItem {
        if (quotation == null) return new QuotationItem();
        let item = new QuotationItem();
        item.Code = quotation.code;
        item.GbpFare = quotation.gbpfare;
        item.OnwardFare = quotation.fare;
        item.Message = quotation.message;
        item.TotalFare = quotation.totalfare;
        item.JourneyTime = quotation.traveltime;
        item.GbpTotalFare = quotation.gbptotalfare;
        item.ReturnTotalFare = quotation.returnfare;
        item.RouteDistance = quotation.routedistance;
        item.GbpReturnFare = quotation.gbpreturnfare;
        item.CurrencyCode = quotation.currencycode || 'GBP';
        item.ReturnJourneyTime = quotation.returntraveltime;
        item.TravelTime = quotation.nearestdriver.traveltime;
        item.ReturnRouteDistance = quotation.returnroutedistance;
        item.PickUp = new LocationItem(quotation.pklat, quotation.pklng);
        item.DropOff = new LocationItem(quotation.dolat, quotation.dolng);
        item.RoutePath = LocationItem.ToItemsFromNumber(quotation.routepath);
        item.AdditionalPrices = AdditionalPriceItem.ToItems(quotation.additionalprices);
        item.ReturnRoutePath = LocationItem.ToItemsFromNumber(quotation.returnroutepath);
        item.GbpTotalFareString = FormatCurrencyHelper.formatLocaleCurrency(item.GbpTotalFare, 'GBP');
        item.TotalFareString = FormatCurrencyHelper.formatLocaleCurrency(item.TotalFare, item.CurrencyCode);
        return item;
    }

    public static ToItemFromDirection(entity: RouteDirection): QuotationItem {
        if (entity == null) return new QuotationItem();
        let item = new QuotationItem();
        item.TravelTime = entity.duration;
        item.RouteDistance = entity.distance;
        item.RoutePath = LocationItem.ToItemsFromNumber(entity.geometry.coordinates);
        return item;
    }

    public static ToItemFromDriver(driver: DriverItem): QuotationItem {
        if (driver == null) return new QuotationItem();
        let item = new QuotationItem();
        item.TotalFare = 0;
        item.JourneyTime = 0;
        item.RouteDistance = 0;
        item.Code = driver.Code;
        item.RoutePath = new Array();
        item.Message = driver.Message;
        item.ReturnRoutePath = new Array();
        item.TravelTime = driver.TravelTime;
        item.PickUp = new LocationItem(0, 0);
        item.DropOff = new LocationItem(0, 0);
        return item;
    }
}

export class Geometry {
    type: string;
    coordinates: any;
}
