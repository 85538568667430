export class Expried {
    Counter: number;

    constructor() {
        this.Counter = 0;
    }

    public get Minute(): number {
        return this.Counter >= 0 ? parseInt((this.Counter / 60).toString()) : 0;
    }

    public get Second(): number {
        return this.Counter >= 0 ? parseInt((this.Counter % 60).toString()) : 0;
    }
}
