<div [id]="parentId" class="autocompleteWapper" [ngClass]="valid ? 'valid' : 'error'">
    <div (clickOutside)="hidePanel()">
        <span class="ui-autocomplete ui-widget airport">
            <input type="text" autocomplete="off" [(ngModel)]="value" [disabled]="disabled" (input)="searchItems()"
                (blur)="handleBlurItem()" (click)="handleInputClick()" [placeholder]="hasLabel ? '' : placeholder"
                [ngClass]="{'edited' : value, 'hasLabel': hasLabel, 'hasIcon': hasIcon }"
                class="ui-input ui-autocomplete-input ui-inputtext ui-corner-all ui-state-default ui-widget">
            <label *ngIf="hasLabel">{{ placeholder }}</label>
        </span>
        <span class="ui-icon" *ngIf="hasIcon">
            <img [src]="icon" />
        </span>
        <span *ngIf="iconRemove && checkSelectedItem()" class="ui-icon icon-second" (click)="handleClearClick()">
            <span [ngClass]="iconRemove">x</span>
        </span>
        <span class="ui-icon" [ngClass]="iconSubstract ? 'icon-second' : 'icon-last'"
            [style.display]="loading ? '' : 'none'">
            <img class="ui-icon-preload" [src]="iconLoading" />
        </span>
        <span class="ui-icon" [ngClass]="iconSubstract ? 'icon-second' : 'icon-last'"
            [style.display]="!loading && !found && !valid ? '' : 'none'">
            <img class="ui-icon-notfound" [src]="iconInvalid" />
        </span>
        <span class="ui-icon" [ngClass]="iconSubstract ? 'icon-second' : 'icon-last'"
            [style.display]="!loading && valid ? '' : 'none'" (click)="handleInputClick()">
            <span class="ui-icon-dropdown glyphicon" [ngClass]="panelVisible ? glyphiconUp : glyphiconDown"></span>
        </span>
        <div class="ui-autocomplete-panel ui-widget-content ui-corner-all ui-shadow country-select inside"
            *ngIf="Items && Items.length > 0" [ngClass]="{'active' : panelVisible }">
            <div class="flag-dropdown">
                <ul
                    class="ui-autocomplete-items ui-autocomplete-list ui-widget-content ui-widget ui-corner-all country-list">
                    <li class="ui-autocomplete-list-item country" *ngFor="let item of Items"
                        (click)="selectItemEvent(item)">
                        <div class="flag" *ngIf="item.CountryLowerCase" [ngClass]="item.CountryLowerCase"></div>
                        <span class="country-name">{{item.FullAddress}}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="hint-invalid"><span>{{ invalidMessage }}</span></div>
    </div>
</div>