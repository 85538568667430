<div class="modal fade" id="modalPaymentProcess" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <div class="col-xs-12 col-sm-12 col-md-12 modal-title">
                    <span>Please wait</span>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="modal-body">
                <div>
                    <div class="spinner black">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div>
                </div>
                <p style="margin-top: 20px; text-align: center; line-height: 24px; font-size: 20px;">
                    <span [innerHTML]="data.PaymentProcessItem.Description"></span>
                </p>
            </div>
        </div>
    </div>
</div>