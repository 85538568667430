import { ChangeDetectionStrategy, Component, afterNextRender } from '@angular/core';
import { AppConfig } from '../../../app.config';
import { ApiService } from '../../../services/apiService';
import { UtilService } from '../../../services/utilService';
import { DataService } from '../../../services/dataService';

@Component({
    selector: 'payment-method-dialog',
    templateUrl: './payment.method.dialog.component.html'
})

export class PaymentMethodDialogComponent {
    browser: boolean;
    iconCard: string;
    iconPaypal: string;
    iconPayment: string;
    iconCardWhite: string;

    constructor(public service: ApiService, public data: DataService, public util: UtilService) {
        this.iconPayment = AppConfig.ASSETS + 'icons/icon-19.png';
        this.iconCard = AppConfig.ASSETS + 'paymentmethod/card.png';
        this.iconPaypal = AppConfig.ASSETS + 'paymentmethod/paypal.png';
        this.iconCardWhite = AppConfig.ASSETS + 'paymentmethod/card_white.png';
        afterNextRender(() => {
            this.browser = true;
        })
    }

    onSubmit() {
        /// Submit
        this.util.HideModal(this.data.MODAL_TYPE.PAYMENTMETHOD);
    }

    onClose() {
        this.util.HideModal(this.data.MODAL_TYPE.PAYMENTMETHOD);
        this.data.ModalType = '';
    }

    choicePaymentType(choice: string) {
        this.data.Booking.PaymentType = choice;
    }

    getClassName(type: string) {
        var className = '';
        if (this.data.PaymentTypeItems) {
            className = this.data.PaymentTypeItems.length == 1
                ? 'col-xs-12'
                : this.data.PaymentTypeItems.length == 2
                    ? 'col-xs-6'
                    : 'col-xs-3';
        }
        className += ' payment-item';
        if (type == this.data.PAYMENT_TYPE.CASH) {
            className += this.data.Booking.PaymentType.toLowerCase() == this.data.PAYMENT_TYPE.CASH ? ' active' : '';
        } else if (type == this.data.PAYMENT_TYPE.CARD) {
            className += this.data.Booking.PaymentType.toLowerCase() == this.data.PAYMENT_TYPE.CARD ? ' active' : '';
        } else if (type == this.data.PAYMENT_TYPE.PAYPAL) {
            className += this.data.Booking.PaymentType.toLowerCase() == this.data.PAYMENT_TYPE.PAYPAL ? ' active' : '';
        }
        return className;
    }
}