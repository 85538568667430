import { FormatCurrencyHelper } from "../helper/format.currency.helper";

export class Promo {
    code: number;
    value: number;
    message: string;
    promocodeid: number;
}

export class PromoItem {
    Code: number;
    Value: number;
    Message: string;
    PromoCode: string;
    PromoCodeId: number;
    ValueString: string;

    public static ToItem(entity: Promo, promoCode: string, currencyCode: string = 'GBP'): PromoItem {
        if (entity == null) return null;
        var item = new PromoItem();
        item.Code = entity.code;
        item.Value = entity.value;
        item.PromoCode = promoCode;
        item.Message = entity.message;
        item.PromoCodeId = entity.promocodeid;
        item.ValueString = FormatCurrencyHelper.formatLocaleCurrency(entity.value, currencyCode);
        return item;
    }
}
