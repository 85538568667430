export class Dialog {
    Type: string;
    Title: string;
    Content: string;
    OkFunction: () => void;

    constructor(content: string, title: string = 'Message', type: string = '', accept: any = null) {
        this.Title = title;
        this.Content = content;
        this.OkFunction = accept;
        this.Type =  type == '' ? 'dialog' : type;
        if (content.indexOf('An error occurred while updating the entries.') >= 0)
            this.Content = 'Something went wrong, please try again.';
    }
}

export class ConfirmDialog {
    Title: string;
    Content: string;
    OkFunction: () => void;
    CancelFunction: () => void;

    constructor(content: string, title: string = 'Question', accept: any = null, reject: any = null) {
        this.Title = title;
        this.Content = content;
        this.OkFunction = accept;
        this.CancelFunction = reject;
    }
}
