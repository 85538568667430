<div class="bodyWapper">
    <card-dialog></card-dialog>
    <promo-dialog></promo-dialog>
    <alert-dialog></alert-dialog>
    <flight-dialog></flight-dialog>
    <signin-dialog></signin-dialog>
    <confirm-dialog></confirm-dialog>
    <contact-dialog></contact-dialog>
    <forgot-password></forgot-password>
    <quotation-dialog></quotation-dialog>
    <flight-search-dialog></flight-search-dialog>
    <payment-method-dialog></payment-method-dialog>
    <payment-process-dialog></payment-process-dialog>

    <xr-header *ngIf="!data.LiteBooking"></xr-header>
    <xr-menu *ngIf="!data.LiteBooking"></xr-menu>
    <router-outlet></router-outlet>
    <xr-footer *ngIf="!data.LiteBooking && !paymentPage"></xr-footer>
</div>