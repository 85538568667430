<div class="modal fade" id="modalCard" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content" *ngIf="browser">
            <div class="modal-header">
                <button id="closeButtonCard" type="button" class="close" (click)="onClose()">&times;</button>
                <div class="col-xs-12 col-sm-12 col-md-12 modal-title">
                    <span>
                        <img [src]="iconCard" />
                    </span>Card Details
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="modal-body" *ngIf="data.Payment != null" style="padding: 10px 20px;">
                <form class="panel-body" autocomplete="on" style="padding: 0;">
                    <div class="card-row-info" style="padding: 0 0 10px 0;">
                        <div class="col-xs-5 col-sm-4" style="text-align: center;">
                            <div *ngIf="util.IsShowUpdateTimeout(data.Booking)">
                                <div style="padding: 5px; color: #fff;">
                                    Time remaining
                                </div>
                                <div style="font-weight: bold;">
                                    <p style="color: #fff;">
                                        {{data.Expried.Minute | number:'2.0' }}:{{ data.Expried.Second | number:'2.0'}}
                                    </p>
                                </div>
                            </div>
                            <div *ngIf="data.Booking.Bref && data.Booking.Bref.length > 0">
                                <div style="padding: 5px; color: #fff;">
                                    Bref
                                </div>
                                <div style="font-weight: bold;">
                                    <p style="color: #fff;">{{ data.Booking.Bref }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-7 col-sm-4" style="text-align: center;">
                            <h2 class="price" style="color: #fff;">
                                {{ util.GetTotalFare() }}
                            </h2>
                        </div>
                        <div class="col-xs-12 col-sm-4 card-paypal">
                            <button type="button" class="ui-button" (click)="onSubmitPaypal($event)" style="padding: 0 10px;margin: 0;" *ngIf="data.Configuration.IsPaypalAllowed">
                                <img [src]="iconPaypal" />
                            </button>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="row card-row-input" style="margin-bottom: 10px; margin-top: 10px;" *ngIf="data.Configuration.PaymentGateway != 'stripe'">
                        <div class="ui-control">
                            <div class="col-xs-12 col-sm-6" style="margin-top: 10px;">
                                <xr-textbox #PaymentName [invalid]="true" [requied]="true" title="Name on Card" classInput="ui-input" [model]="data.Payment.Name" editorId="dialog-payment-card-name" [editorType]="data.EDITOR_TYPE.TEXT" (onChange)="onInputChange('PaymentName')">
                                </xr-textbox>
                            </div>
                            <div class="col-xs-12 col-sm-6" style="margin-top: 10px;">
                                <xr-textbox #PaymentCardNumber [invalid]="true" [requied]="true" title="Card Number" classInput="ui-input" [model]="data.Payment.CardNumber" editorId="dialog-payment-card-number" [editorType]="data.EDITOR_TYPE.CARDNUMBER" (onChange)="onInputChange('PaymentCardNumber')">
                                </xr-textbox>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="ui-control">
                            <div class="col-sm-4" style="margin-top: 10px;">
                                <xr-dropdown [type]="DropDownType.String" classInput="ui-input" [options]="expiryMonths" placeholder="Expiry Month" [model]="data.Payment.ExpiryMonth" (selectedChange)="onSelectedMonth($event)">
                                </xr-dropdown>
                            </div>
                            <div class="col-sm-4" style="margin-top: 10px;">
                                <xr-dropdown [type]="DropDownType.String" classInput="ui-input" [options]="expiryYears" placeholder="Expiry Year" [model]="data.Payment.ExpiryYear" (selectedChange)="onSelectedYear($event)">
                                </xr-dropdown>
                            </div>
                            <div class="col-sm-4" style="margin-top: 10px;">
                                <xr-textbox #PaymentCVC title="CVC" [invalid]="true" [requied]="true" classInput="ui-input" [model]="data.Payment.CVC" editorId="dialog-payment-card-cvc" [editorType]="data.EDITOR_TYPE.CARDCVC" (onChange)="onInputChange('PaymentCVC')">
                                </xr-textbox>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div class="row card-row-input stripe-wapper" style="margin-bottom: 10px; margin-top: 10px;" *ngIf="data.Configuration.PaymentGateway == 'stripe'">
                        <div class="ui-control">
                            <div class="col-xs-12 col-sm-6" style="margin-top: 10px;">
                                <xr-textbox #PaymentName [invalid]="true" [requied]="true" title="Name on Card" classInput="ui-input" [model]="data.Payment.Name" editorId="dialog-payment-card-name" [editorType]="data.EDITOR_TYPE.TEXT" (onChange)="onInputChange('PaymentName')">
                                </xr-textbox>
                            </div>
                            <div class="col-xs-12 col-sm-6" style="margin-top: 10px;">
                                <div id="payment-popup-card-number"></div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="ui-control" style="margin-top: 10px;">
                            <div class="col-sm-6">
                                <div id="payment-popup-card-expiry"></div>
                            </div>
                            <div class="col-sm-6">
                                <div id="payment-popup-card-cvc"></div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div style="font-weight: bold;  font-size: 16px;">Billing Address</div>
                    <div class="row card-row-input">
                        <div class="ui-control">
                            <div class="col-xs-12 col-sm-6" style="margin-top: 10px;">
                                <xr-textbox #PaymentCountry title="Country" [invalid]="false" [requied]="false" classInput="ui-input" [model]="data.Payment.Country" editorId="dialog-payment-country" [editorType]="data.EDITOR_TYPE.COUNTRY" (onChange)="onInputChange('PaymentCountry')">
                                </xr-textbox>
                            </div>
                            <div class="col-xs-12 col-sm-6" style="margin-top: 10px;">
                                <xr-textbox #PaymentPostalCode [invalid]="true" [requied]="true" title="Postal code" classInput="ui-input" [model]="data.Payment.PostalCode" [editorType]="data.EDITOR_TYPE.TEXT" editorId="dialog-payment-postal-code" (onChange)="onInputChange('PaymentPostalCode')">
                                </xr-textbox>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="ui-control">
                            <div class="col-xs-12" style="margin-top: 10px;">
                                <xr-textbox #PaymentAddressLine1 [invalid]="true" [requied]="true" title="Address Line" classInput="ui-input" [model]="data.Payment.AddressLine1" [editorType]="data.EDITOR_TYPE.TEXT" editorId="dialog-payment-address-line-1" (onChange)="onInputChange('PaymentAddressLine1')">
                                </xr-textbox>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="ui-control">
                            <div class="col-xs-12 col-sm-6" style="margin-top: 10px;">
                                <xr-textbox #PaymentAddressLine2 [invalid]="true" [requied]="false" classInput="ui-input" title="Address Line 2" [model]="data.Payment.AddressLine2" [editorType]="data.EDITOR_TYPE.TEXT" editorId="dialog-payment-address-line-2" (onChange)="onInputChange('PaymentAddressLine2')">
                                </xr-textbox>
                            </div>
                            <div class="col-xs-12 col-sm-6" style="margin-top: 10px;">
                                <xr-textbox #PaymentCity title="City" [invalid]="true" [requied]="true" classInput="ui-input" [model]="data.Payment.City" editorId="dialog-payment-city" [editorType]="data.EDITOR_TYPE.TEXT" (onChange)="onInputChange('PaymentCity')">
                                </xr-textbox>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </form>
            </div>
            <div>
                <div id="card-dialog-message" class="api-invalid"></div>
                <div class="center-button">
                    <button (click)="onSubmit()" type="button" class="ui-button" id="btnConfirmDialog">
                        <span>
                            Pay now
                        </span>
                    </button>
                    <button type="button" class="ui-button btn-cancel" (click)="onClose()">
                        <span>
                            Cancel
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>