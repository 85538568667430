declare var jQuery: any;
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Dialog } from './domain/dialog';
import { AppConfig } from './app.config';
import { PromoItem } from './domain/promo';
import { PaymentItem } from './domain/payment';
import { MapType } from './domain/enum/map.type';
import { MapService } from './services/mapSerive';
import { QuotationItem } from './domain/quotation';
import { ApiService } from './services/apiService';
import { UtilService } from './services/utilService';
import { PickupType } from './domain/enum/pickup.type';
import { environment } from '../environments/environment';
import { DataService, EventBooking } from './services/dataService';
import { Component, OnInit, afterNextRender } from '@angular/core';

// Setting AppConfig
AppConfig.API = environment.api;
AppConfig.NAME = environment.name;
AppConfig.TITLE = environment.title;
AppConfig.ASSETS = 'assets/images/';
AppConfig.SOURCE_TYPE = environment.source;
AppConfig.PAYMENT_TYPE = environment.payment;
AppConfig.TEMPLATE_TYPE = environment.template;
AppConfig.TEMPLATE_CAR_TYPE = environment.templateCar;
AppConfig.MAP_TYPE = environment.mapType ? environment.mapType : MapType.Google;
AppConfig.WIZARD_BUTTON_TYPE = environment.wizardButton ? environment.wizardButton : '';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    host: {
        '(document:mouseup)': 'clickOutSideMenu($event)',
        '(document:touchend)': 'clickOutSideMenu($event)'
    },
})
export class AppComponent implements OnInit {
    timeStart: Date;
    paymentPage: boolean;
    lastCalled: Date = null;
    appName = environment.name;
    requestQuotation: Subscription;

    constructor(
        public router: Router,
        public data: DataService,
        public util: UtilService,
        public service: ApiService,
        public mapService: MapService) {
        /// Event
        this.data.PaymentProcess.subscribe(() => {
            /// Check expired
            if (this.data.Expried.Counter <= 0 && this.data.ManualTimeout) {
                this.data.BookingItemTimeout.emit();
                return;
            }
            this.util.PaymentBooking();
        });
        this.data.SigninComplete.subscribe(() => {
            if (this.data.Profile !== null) {
                this.service.getAddressBook(this.data.Profile.Id).subscribe(model => {
                    this.data.AddressBooks = model;
                });
                this.data.ModalType = '';

                if (this.data.Booking.Quotation) {
                    this.calcQuotation(null);
                }
            }
        });
        this.data.SignoutComplete.subscribe(() => {
            /// Clear all data
            this.data.SignIn = null;
            this.data.SignUp = null;
            this.data.Profile = null;
            this.data.PaymentToken = null;
            this.data.Payment = PaymentItem.InitItem();
            if (this.data.Booking !== null) {
                this.data.Booking.Promo = new PromoItem();
            }

            if (this.data.validAddress() && this.data.validDateTime()) {
                if (!this.data.validQuotation()) {
                    this.data.BookingItemChange.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.REQUOTE));
                }
            }

            // HideAllPopup
            this.util.HideAllModal();

            // Router
            if (this.router.url.indexOf('profile') >= 0 ||
                this.router.url.indexOf('invoice') >= 0 ||
                this.router.url.indexOf('tracking') >= 0 ||
                this.router.url.indexOf('confirmed') >= 0 ||
                this.router.url.indexOf('dashboard') >= 0 ||
                this.router.url.indexOf('commission') >= 0 ||
                this.router.url.indexOf('addressbook') >= 0 ||
                this.router.url.indexOf('bookingdetail') >= 0 ||
                this.router.url.indexOf('bookinghistory') >= 0) {
                this.util.GoToPage(this.data.PAGE_TYPE.HOME);
            }

            if (this.data.Booking.Quotation) {
                this.calcQuotation(null);
            }
        });
        this.data.BookingItemClear.subscribe(() => {
            this.data.Booking.PickUp = null;
            this.util.RemoveMarkers(this.data.MAP_ELEMENT.MAP, -1);
            this.util.RemoveMarkers(this.data.MAP_ELEMENT.MAP_FLOAT, -1);

            this.data.Booking.PickDown = null;
            this.util.RemoveMarkers(this.data.MAP_ELEMENT.MAP, 100);
            this.util.RemoveMarkers(this.data.MAP_ELEMENT.MAP_FLOAT, 100);

            if (this.data.Booking.ViaAddress != null && this.data.Booking.ViaAddress.length >= 0) {
                for (let i = 0; i < this.data.Booking.ViaAddress.length; i++) {
                    this.util.RemoveMarkers(this.data.MAP_ELEMENT.MAP, i);
                    this.util.RemoveMarkers(this.data.MAP_ELEMENT.MAP_FLOAT, i);
                }
            }
            this.data.Booking.ViaAddress = null;

            this.data.Booking.Promo = null;
            this.data.Booking.Flight = null;
            this.data.Booking.ReturnFlight = null;
            this.data.Booking.WaitAndReturn = false;
            this.data.Payment = PaymentItem.InitItem();

            // Reset timer
            this.data.Booking.Time.Time = null;
            this.data.Booking.ReturnDate = null;
            this.data.Booking.Time.IsValid = true;
            this.data.Booking.Time.EarliestTime = new Date();
        });
        this.data.BookingItemTimeout.subscribe(() => {
            // Reset couter timeout
            if (this.data.UpdateTimeout !== null) this.data.UpdateTimeout.unsubscribe();

            /// Clear all data
            if (this.data.Booking !== null) {
                this.data.Booking.Step = 1;
                this.data.Booking.Note = '';
                this.data.Booking.Bref = '';
                this.data.Booking.Promo = null;
                this.data.Booking.Flight = null;
                this.data.Booking.ReturnRef = '';
                this.data.Booking.ReturnFlight = null;
                if (this.data.Booking.Quotation !== null) {
                    this.data.Booking.Quotation.TotalFare = 0;
                    this.data.Booking.Quotation.OnwardFare = 0;
                    this.data.Booking.Quotation.ReturnTotalFare = 0;
                    this.data.Booking.Quotation.AdditionalPrices = [];
                }
            }
            this.data.storeBooking();

            /// Show popup
            let href = window.location.href;
            if (href.indexOf(this.data.PAGE_TYPE.BOOKINGALONE) >= 0) {
                if (href.indexOf('bookinginfo') < 0 && href.indexOf('bookinghistory') < 0 && href.indexOf('bookingdetail') < 0) {
                    if (this.data.Booking !== null) {
                        this.data.Booking.Time.IsValid = this.data.validTimeExpired(true);
                        this.data.Booking.Time.IsShow = !this.data.Booking.Time.IsValid;
                    }
                    this.util.HideModal(this.data.MODAL_TYPE.ALERT);
                    this.util.HideModal(this.data.MODAL_TYPE.FLIGHT);
                    this.util.HideModal(this.data.MODAL_TYPE.CONTACT);
                    this.util.HideModal(this.data.MODAL_TYPE.NOTEDRIVER);
                    this.util.HideModal(this.data.MODAL_TYPE.PAYMENTPROCESS);

                    this.util.ShowModal(this.data.MODAL_TYPE.QUOTATION);
                }
            }
        });
        this.data.BookingItemError.subscribe((model: any) => {
            // Clear markers
            let event = <EventBooking>model;
            if (event.Target == this.data.TARGET_EVENT_TYPE.PICKUP) {
                this.util.RemoveMarkers(this.data.MAP_ELEMENT.MAP, -1);
                this.util.RemoveMarkers(this.data.MAP_ELEMENT.MAP_FLOAT, -1);
            }
            else if (event.Target == this.data.TARGET_EVENT_TYPE.DROPOFF) {
                this.util.RemoveMarkers(this.data.MAP_ELEMENT.MAP, 100);
                this.util.RemoveMarkers(this.data.MAP_ELEMENT.MAP_FLOAT, 100);
            }
            else if (event.Target == this.data.TARGET_EVENT_TYPE.VIAADDRESS) {
                if (this.data.Booking.ViaAddress.length >= event.Index) {
                    this.util.RemoveMarkers(this.data.MAP_ELEMENT.MAP, event.Index);
                    this.util.RemoveMarkers(this.data.MAP_ELEMENT.MAP_FLOAT, event.Index);
                }
                this.data.FlightPath = this.util.DrawRoute(this.data.MAP_ELEMENT.MAP, this.data.Booking);
                this.data.FlightPath = this.util.DrawRoute(this.data.MAP_ELEMENT.MAP_FLOAT, this.data.Booking);
                this.data.FlightReturnPath = this.util.DrawReturnRoute(this.data.MAP_ELEMENT.MAP, this.data.Booking);
                this.data.FlightReturnPath = this.util.DrawReturnRoute(this.data.MAP_ELEMENT.MAP_FLOAT, this.data.Booking);
            }

            // Reset couter timeout
            if (this.data.UpdateTimeout !== null) this.data.UpdateTimeout.unsubscribe();
            this.util.UpdateProcessButton(false);
            this.data.Expried.Counter = 0;

            if (this.data.Booking.Quotation !== null) {
                this.data.Booking.Quotation.TotalFare = 0;
                this.data.Booking.Quotation.OnwardFare = 0;
                this.data.Booking.Quotation.ReturnTotalFare = 0;
                this.data.Booking.Quotation.AdditionalPrices = [];
            }

            if (event.Target == this.data.TARGET_EVENT_TYPE.DATETIME && this.data.validAddress())
                this.util.ScrollToSticky(this.data.STICKY_TYPE.PICKDATETIME);
        });
        this.data.BookingItemChange.subscribe((model: any) => {
            // Reset couter timeout
            if (this.data.UpdateTimeout !== null) this.data.UpdateTimeout.unsubscribe();
            this.data.Expried.Counter = 0;

            if (this.data.validTimeExpired() &&
                this.data.validReturnDate() &&
                this.data.Booking.PickUp &&
                this.data.Booking.PickDown)
                this.util.UpdateProcessButton(true);
            let event = <EventBooking>model;
            if (this.data.Booking.Promo == null)
                this.data.Booking.Promo = new PromoItem();
            switch (event.Target) {
                case this.data.TARGET_EVENT_TYPE.PICKUP:
                    {
                        this.resetRoutePath();
                        if (this.data.Booking.ChangesAllowed == false)
                            this.data.Booking.Promo = new PromoItem();
                        this.data.Booking.Quotation.TravelTime = 0;
                        if (this.data.validPickUp()) {
                            if (this.data.validPickDown()) {
                                if (this.data.validViaAddress() && this.data.validReturnDate()) {
                                    this.data.TimeCallQuotation = null;
                                    this.calcQuotation(event);
                                } else this.emitEventBookingComplete(event);
                            }
                            else this.calcTravelTime(event);
                        } else this.emitEventBookingComplete(event);
                    }
                    break;
                case this.data.TARGET_EVENT_TYPE.DROPOFF:
                case this.data.TARGET_EVENT_TYPE.VIAADDRESS:
                    {
                        this.resetRoutePath();
                        if (this.data.validPickUp()) {
                            if (this.data.validPickDown()) {
                                if (this.data.validViaAddress() && this.data.validReturnDate()) {
                                    this.data.TimeCallQuotation = null;
                                    this.calcQuotation(event);
                                } else this.emitEventBookingComplete(event);
                            }
                            else if (this.data.Booking.Quotation.TravelTime == 0)
                                this.calcTravelTime(event);
                            else this.emitEventBookingComplete(event);
                        } else this.emitEventBookingComplete(event);
                    }
                    break;
                case this.data.TARGET_EVENT_TYPE.VEHICLE:
                    {
                        this.resetPriceFare();
                        if (this.data.validAddress() && this.data.validReturnDate()) {
                            this.calcQuotation(event);
                        } else this.emitEventBookingComplete(event);
                    }
                    break;
                case this.data.TARGET_EVENT_TYPE.SINGLE:
                case this.data.TARGET_EVENT_TYPE.REQUOTE:
                case this.data.TARGET_EVENT_TYPE.DATETIME:
                case this.data.TARGET_EVENT_TYPE.PICKTIME:
                case this.data.TARGET_EVENT_TYPE.WAITRETURN:
                    {
                        this.resetPrice();
                        if (this.data.validAddress() && this.data.validReturnDate()) {
                            this.data.TimeCallQuotation = null;
                            this.calcQuotation(event);
                        } else this.emitEventBookingComplete(event);
                    }
                    break;
                case this.data.TARGET_EVENT_TYPE.RETURNDATE:
                    {
                        this.resetPrice();
                        if (this.data.validAddress() && this.data.validReturnDate()) {
                            this.data.TimeCallQuotation = null;
                            this.calcQuotation(event);
                        } else {
                            this.emitEventBookingComplete(event);
                            if (this.requestQuotation) {
                                this.requestQuotation.unsubscribe();
                            }
                        }
                    }
                    break;
            }
        });
        this.data.BookingItemComplete.subscribe((model: any) => {
            // Unsubscribe
            if (this.data.UpdateTimeout !== null) this.data.UpdateTimeout.unsubscribe();

            // UpdateTimeExpired
            if (this.data.validAddress()) {
                if (this.data.Booking.Time.PickUpType == PickupType.Now) {
                    this.data.Booking.Time.IsValid = true;
                    this.intervalUpdateTimeout();
                } else if (this.data.Booking.Time.PickUpType == PickupType.Unknow) {
                    this.data.Booking.Time.IsValid = false;
                    if (this.data.Booking.Quotation !== null)
                        this.data.Booking.Quotation.TotalFare = 0;
                } else if (this.data.Booking.Time.Time !== null) {
                    if (this.data.validTimeExpired(true)) {
                        this.intervalUpdateTimeout();
                    } else {
                        this.data.Booking.Time.IsValid = false;
                        if (this.data.Booking.Quotation !== null)
                            this.data.Booking.Quotation.TotalFare = 0;
                    }
                } else {
                    if (this.data.Booking.Quotation !== null)
                        this.data.Booking.Quotation.TotalFare = 0;
                }
            }
            this.util.UpdateProcessButton(false);

            // update marker && router
            setTimeout(() => {
                if (!this.data.Map || !this.data.Markers) {
                    /// Create markers
                    this.util.InitMarkers(this.data.MAP_ELEMENT.MAP, this.data.Booking, true);
                    this.util.InitMarkers(this.data.MAP_ELEMENT.MAP_FLOAT, this.data.Booking, false);

                    // Draw route
                    this.data.FlightPath = this.util.DrawRoute(this.data.MAP_ELEMENT.MAP, this.data.Booking);
                    this.data.FlightPath = this.util.DrawRoute(this.data.MAP_ELEMENT.MAP_FLOAT, this.data.Booking);
                    if (this.data.validDrawReturnPath()) {
                        this.data.FlightReturnPath = this.util.DrawReturnRoute(this.data.MAP_ELEMENT.MAP, this.data.Booking);
                        this.data.FlightReturnPath = this.util.DrawReturnRoute(this.data.MAP_ELEMENT.MAP_FLOAT, this.data.Booking);
                    }
                }

                if (model) {
                    let event = <EventBooking>model;
                    if (event.Target == this.data.TARGET_EVENT_TYPE.REQUOTE) {
                        // Close dialog
                        this.util.HideModal(this.data.MODAL_TYPE.QUOTATION);
                    } else {
                        if (this.data.Map == null ||
                            event.Target == this.data.TARGET_EVENT_TYPE.PICKUP ||
                            event.Target == this.data.TARGET_EVENT_TYPE.DROPOFF ||
                            event.Target == this.data.TARGET_EVENT_TYPE.VIAADDRESS) {
                            /// Create markers
                            this.util.InitMarkers(this.data.MAP_ELEMENT.MAP, this.data.Booking, true);
                            this.util.InitMarkers(this.data.MAP_ELEMENT.MAP_FLOAT, this.data.Booking, false);

                            // Draw route
                            this.data.FlightPath = this.util.DrawRoute(this.data.MAP_ELEMENT.MAP, this.data.Booking);
                            this.data.FlightPath = this.util.DrawRoute(this.data.MAP_ELEMENT.MAP_FLOAT, this.data.Booking);
                        }
                    }
                }

                // Draw return path
                if (this.data.validDrawReturnPath()) {
                    this.data.FlightReturnPath = this.util.DrawReturnRoute(this.data.MAP_ELEMENT.MAP, this.data.Booking);
                    this.data.FlightReturnPath = this.util.DrawReturnRoute(this.data.MAP_ELEMENT.MAP_FLOAT, this.data.Booking);
                }
            }, 500);
        });

        afterNextRender(async () => {
            this.paymentPage = window.location.href.indexOf('payment.html') >= 0;
            let sandbox = this.util.ToQueryParam(window.location.href, "sandbox");
            this.data.SandBox = sandbox ? sandbox.toString().toLowerCase() == 'true' : false;

            let runmode = this.util.ToQueryParam(window.location.href, "runmode");
            this.data.RunModeTest = runmode ? runmode.toString().toLowerCase() == 'test' : false;
            if (window.location.href.indexOf('quickbooking') < 0) {
                /// AddressBooks
                if (this.data.Profile !== null &&
                    (this.data.AddressBooks == null || this.data.AddressBooks.length == 0)) {
                    this.service.getAddressBook(this.data.Profile.Id).subscribe(model => {
                        this.data.AddressBooks = model;
                    });
                }

                /// DateTime
                if (this.data.Booking !== null && this.data.validAddress()) {
                    this.data.Booking.Time.IsValid = this.data.validTimeExpired(true);
                    this.data.Booking.Time.IsShow = !this.data.Booking.Time.IsValid;
                    if (this.data.validAll() && this.data.validTimeExpired()) {
                        this.data.BookingItemChange.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.REQUOTE));
                    } else if (!this.data.validTimeExpired()) {
                        let that = this;
                        setTimeout(function () {
                            that.util.ScrollToSticky(that.data.STICKY_TYPE.PICKDATETIME);
                        }, 500);
                    }
                }
            }
            if (window.location.href.indexOf('litebooking') >= 0) {
                this.data.LiteBooking = true;
            }
            if (window.location.href.indexOf('quickbooking') > 0) {
                if (jQuery('xr-menu')) jQuery('xr-menu').hide();
                if (jQuery('xr-header')) jQuery('xr-header').hide();
                if (jQuery('xr-footer')) jQuery('xr-footer').hide();
                this.util.InitIpInfo();
                this.util.InitDateRanges();
                this.util.InitVehicleTypes();
                this.util.InitConfiguration();

                if (!this.util.IsMobile()) {
                    let interval = setInterval(async () => {
                        if (this.data.LoadedConfiguration) {
                            clearInterval(interval);
                            await this.loadScriptMaps();
                            setTimeout(() => this.data.LoadedScriptMap = true, 1000);
                        }
                    }, 100);
                }
            } else {
                if (jQuery('xr-menu')) jQuery('xr-menu').show();
                if (jQuery('xr-header')) jQuery('xr-header').show();
                if (jQuery('xr-footer')) jQuery('xr-footer').show();
                this.util.InitIpInfo();
                this.util.InitDateRanges();
                this.util.InitVehicleTypes();
                this.util.InitConfiguration();

                if (!this.util.IsMobile()) {
                    let interval = setInterval(async () => {
                        if (this.data.LoadedConfiguration) {
                            clearInterval(interval);
                            await this.loadScriptMaps();
                            setTimeout(() => this.data.LoadedScriptMap = true, 1000);
                        }
                    }, 100);
                }
            }
        });
    }

    ngOnInit() {
        this.data.FirstLoad = true;
    }

    clickOutSideMenu(e: any) {
        let container = jQuery('ul.dropdown-menu');
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            container.hide();
        }

        container = jQuery('div.dropdown-more-options');
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            container.hide();
        }
    }

    resetPrice() {
        this.data.Booking.Quotation.TotalFare = 0;
        this.data.Booking.Quotation.OnwardFare = 0;
        this.data.Booking.Quotation.JourneyTime = 0;
        this.data.Booking.Quotation.ReturnTotalFare = 0;
        this.data.Booking.Quotation.AdditionalPrices = [];
        this.data.UpdateVehicleValid.emit();
    }

    resetPriceFare() {
        this.data.Booking.Quotation.TotalFare = 0;
        this.data.Booking.Quotation.OnwardFare = 0;
        this.data.Booking.Quotation.ReturnTotalFare = 0;
    }

    resetRoutePath() {
        this.data.Booking.Quotation.TotalFare = 0;
        this.data.Booking.Quotation.OnwardFare = 0;
        this.data.Booking.Quotation.JourneyTime = 0;
        this.data.Booking.Quotation.RoutePath = null;
        this.data.Booking.Quotation.RouteDistance = 0;
        this.data.Booking.Quotation.ReturnTotalFare = 0;
        this.data.Booking.Quotation.ReturnRoutePath = null;
        this.data.Booking.Quotation.ReturnRouteDistance = 0;
    }

    async loadScriptMaps() {
        let searchType = this.data.Configuration.SearchApi;
        if (searchType == 'xoera' && !this.data.Configuration.MapSource) {
            if (this.data.Configuration.BingApiKey)
                await this.mapService.mapLoading(this.data.Configuration.BingApiKey, MapType.Bing);
            if (this.data.Configuration.GoogleApiKey)
                await this.mapService.mapLoading(this.data.Configuration.GoogleApiKey, MapType.Google);
        } else {
            if (this.data.Configuration.MapSource == 'bing' || searchType == 'bing')
                await this.mapService.mapLoading(this.data.Configuration.BingApiKey, MapType.Bing);
            if (this.data.Configuration.MapSource == 'google' || searchType == 'google')
                await this.mapService.mapLoading(this.data.Configuration.GoogleApiKey, MapType.Google);
        }
    }

    intervalUpdateTimeout() {
        // if (this.data.validAll()) {
        //     if (this.data.Expried == null) this.data.Expried = new Expried();
        //     this.data.Expried.Counter = this.data.Configuration.QuoteTimeout * 60;
        //     this.data.ManualTimeout = false;
        //     this.timeStart = new Date();

        //     this.data.UpdateTimeout = Observable.interval(1000).subscribe(() => {
        //         if (this.data.validAll()) {
        //             let timeNow = new Date();
        //             let seconds = Math.abs((timeNow.getTime() - this.timeStart.getTime()) / 1000);
        //             this.timeStart = timeNow;
        //             this.data.Expried.Counter = this.data.Expried.Counter - seconds;
        //             if (this.data.Expried.Counter <= 0) {
        //                 if (!this.data.ManualTimeout)
        //                     this.data.BookingItemTimeout.emit();
        //             }
        //         } else {
        //             this.data.Expried.Counter = 0;
        //         }
        //     });
        // }
    }

    emitEventBookingComplete(event: any) {
        let eventComplete = this.data.BookingItemComplete;
        setTimeout(function () {
            eventComplete.emit(event);
        }, 100);
    }

    calcTravelTime(event: EventBooking) {
        if (this.requestQuotation) {
            this.requestQuotation.unsubscribe();
        }
        this.requestQuotation = this.service.nearestDriver(this.data.Booking.PickUp).subscribe((model: any) => {
            if (model.Code == 1) {
                if (this.data.Booking.PickUp !== null) {
                    this.data.Booking.Quotation = QuotationItem.ToItemFromDriver(model);
                    if (this.data.Booking.Quotation.TravelTime < this.data.Configuration.MinLeadTime)
                        this.data.Booking.Quotation.TravelTime = this.data.Configuration.MinLeadTime;
                }
                this.data.storeBooking();
                this.data.BookingItemComplete.emit(event);
            } else {
                event.Message = !model || model.Code == 0 ? this.data.ERROR_MESSAGE : model.Message;
                this.data.BookingItemError.emit(event);
                this.data.ShowDialog.emit(new Dialog(event.Message));
            }
        });
    }

    calcQuotation(event: EventBooking) {
        if (this.requestQuotation) {
            this.requestQuotation.unsubscribe();
        }
        let calcRoute: boolean = true;
        if (this.data.Booking.Quotation &&
            this.data.Booking.Quotation.RoutePath &&
            this.data.Booking.Quotation.JourneyTime &&
            this.data.Booking.Quotation.RoutePath.length > 0) {
            if (this.data.Booking.HasReturn) {
                if (this.data.Booking.Quotation.ReturnRoutePath &&
                    this.data.Booking.Quotation.ReturnJourneyTime &&
                    this.data.Booking.Quotation.ReturnRoutePath.length > 0) {
                    calcRoute = false;
                }
            } else calcRoute = false;
        }
        let calcDriver: boolean = true;
        if (this.data.Booking.Quotation.TravelTime > 0) {
            calcDriver = false;
        }

        let needRequestQuotation: boolean = false,
            timeCallQuotation = this.data.TimeCallQuotation,
            additionalPrices = this.data.Booking.Quotation && this.data.Booking.Quotation.AdditionalPrices;
        if (!timeCallQuotation || !additionalPrices || additionalPrices.length == 0)
            needRequestQuotation = true;
        else {
            let currentTime = (new Date()).getTime(),
                seconds = (currentTime - timeCallQuotation) / 1000;
            if (seconds >= 60)
                needRequestQuotation = true;
            else {
                needRequestQuotation = true;
                if (this.data.PrevBookerId == this.data.Booking.Contact.BookerId) {
                    for (let i = 0; i < additionalPrices.length; i++) {
                        let itemPrice = additionalPrices[i],
                            vehicleId = this.data.Booking.Vehicle && this.data.Booking.Vehicle.Id;
                        if (itemPrice.VehTypeId == vehicleId) {
                            this.data.Booking.Quotation.ReturnTotalFare = itemPrice.ReturnFare;
                            this.data.Booking.Quotation.TotalFare = itemPrice.TotalFare > 0
                                ? itemPrice.TotalFare
                                : itemPrice.Fare + itemPrice.ReturnFare;
                            this.data.Booking.Quotation.OnwardFare = itemPrice.Fare;
                            this.data.BookingItemComplete.emit(event);
                            return;
                        }
                    }
                } else {
                    this.resetPrice();
                    this.util.UpdateProcessButton(true);
                }
            }
        }
        if (needRequestQuotation) {
            this.data.PrevBookerId = this.data.Booking.Contact.BookerId;
            if (this.data.Booking && this.data.Booking.PickUp && this.data.Booking.PickDown) {
                let paymentMethod = this.data.Booking.PaymentType || this.data.Configuration.DefaultPaymentMethod;
                this.requestQuotation = this.service.quotation(this.data.Booking, calcRoute, calcDriver, this.data.CurrencyCode, paymentMethod)
                    .subscribe((model: QuotationItem) => {
                        if (!model) return;

                        this.data.CurrencyCodeSupport = model.CurrencyCode;
                        if (model.Code == 1 || model.Code == 4) {
                            if (this.data.Booking.PickUp !== null && this.data.Booking.PickDown !== null) {
                                if (calcDriver) {
                                    this.data.Booking.Quotation.TravelTime = model.TravelTime;
                                    if (this.data.Booking.Quotation.TravelTime < this.data.Configuration.MinLeadTime)
                                        this.data.Booking.Quotation.TravelTime = this.data.Configuration.MinLeadTime;
                                }
                                if (calcRoute) {
                                    this.data.Booking.Quotation.RoutePath = model.RoutePath;
                                    this.data.Booking.Quotation.RouteDistance = model.RouteDistance;
                                    this.data.Booking.Quotation.ReturnRoutePath = model.ReturnRoutePath;
                                    this.data.Booking.Quotation.ReturnRouteDistance = model.ReturnRouteDistance;
                                }

                                this.data.Booking.Quotation.PickUp = model.PickUp;
                                this.data.Booking.Quotation.DropOff = model.DropOff;
                                this.data.Booking.Quotation.JourneyTime = model.JourneyTime;
                                this.data.Booking.Quotation.AdditionalPrices = model.AdditionalPrices;
                                this.data.Booking.Quotation.ReturnJourneyTime = model.ReturnJourneyTime;
                                let vehicleId = this.data.Booking.VehicleId || this.data.Booking.Vehicle.Id,
                                    additionalPrice = this.data.Booking.Quotation.AdditionalPrices &&
                                        this.data.Booking.Quotation.AdditionalPrices.find(c => c.VehTypeId == vehicleId);
                                if (additionalPrice) {
                                    this.data.Booking.Quotation.GbpFare = additionalPrice.Fare;
                                    this.data.Booking.Quotation.OnwardFare = additionalPrice.Fare;
                                    this.data.Booking.Quotation.GbpReturnFare = additionalPrice.ReturnFare;
                                    this.data.Booking.Quotation.ReturnTotalFare = additionalPrice.ReturnFare;
                                    this.data.Booking.Quotation.CurrencyCode = additionalPrice.CurrencyCode || model.CurrencyCode;
                                    this.data.Booking.Quotation.TotalFare = additionalPrice.TotalFare || (additionalPrice.Fare + additionalPrice.ReturnFare);
                                    this.data.Booking.Quotation.GbpTotalFare = additionalPrice.TotalFare || (additionalPrice.Fare + additionalPrice.ReturnFare);
                                }
                                if (this.data.Booking.Quotation.TravelTime == 0) {
                                    this.data.Booking.Quotation.TravelTime = model.TravelTime;
                                    if (this.data.Booking.Quotation.TravelTime < this.data.Configuration.MinLeadTime)
                                        this.data.Booking.Quotation.TravelTime = this.data.Configuration.MinLeadTime;
                                }
                                if (this.data.Booking.Promo != null &&
                                    this.data.Booking.Promo.PromoCode != null &&
                                    this.data.Booking.Promo.PromoCode.length > 0) {
                                    this.service.promoCode(this.data.Booking)
                                        .subscribe(model => {
                                            if (model.Code == 1) {
                                                this.data.Booking.Promo = model;
                                                this.util.HideModal(this.data.MODAL_TYPE.PROMO);
                                            } else {
                                                let error = model.Code == 0 ? 'Something went wrong, please try again.' : model.Message;
                                                this.data.ShowDialog.emit(new Dialog(error, 'Error'));
                                            }
                                        });
                                }
                            }
                            this.data.storeBooking();
                            this.data.UpdatePrice.emit();
                            this.data.BookingItemComplete.emit(event);
                            this.data.TimeCallQuotation = (new Date()).getTime();
                        } else if (model.Code == 3 || model.Code == 6) {
                            event.Message = model.Message;
                            event.Target = this.data.TARGET_EVENT_TYPE.PICKUP;
                            this.data.BookingItemError.emit(event);
                            this.data.ShowDialog.emit(new Dialog(event.Message));
                        } else {
                            event.Message = !model || model.Code == 0 ? this.data.ERROR_MESSAGE : model.Message;
                            this.data.BookingItemError.emit(event);
                            this.data.ShowDialog.emit(new Dialog(event.Message));
                        }
                    });
            }
        }
    }
}
