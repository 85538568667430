import { AirlineItem } from './airline';
import { AirportItem } from './airport';
import { TimeItem } from './object/timeitem';
import { UtilityHelper } from '../helper/utility.helper';

export class Flight {
    public Number: string;
    public LandingTime: Date;
    public WaitingTime: number;
    public AirportCode: string;
    public AirportText: string;
    public LandingTimeMin: Date;
    public Airport: AirportItem;
    public LandingTimeWait: Date;
    public ArrivingFrom: TimeItem;
    public IsFlightDetail: boolean;
    public IncludedWaiting: boolean;

    public get AirportName(): string {
        var flightAirport = this.AirportText;
        if (flightAirport == null || flightAirport.length == 0) {
            flightAirport = this.Airport != null ? this.Airport.Name : '';
        }
        return flightAirport;
    }

    constructor() {
        this.Number = '';
        this.Airport = null;
        this.WaitingTime = 0;
        this.AirportText = '';
        this.AirportCode = '';
        this.IsFlightDetail = false;
        this.ArrivingFrom = new TimeItem();
    }
}

export class FlightResult {
    ident: string;
    route: string;
    origin: string;
    diverted: string;
    filed_ete: string;
    originCity: string;
    filed_time: number;
    originName: string;
    destination: string;
    aircrafttype: string;
    filed_time_dt: string;
    filed_altitude: number;
    destinationName: string;
    destinationCity: string;
    actualarrivaltime: number;
    filed_airspeed_kts: number;
    filed_airspeed_mach: string;
    filed_departuretime: string;
    actualdeparturetime: number;
    estimatedarrivaltime: number;
    actualarrivaltime_dt: string;
    filed_departuretime_dt: string;
    actualdeparturetime_dt: string;
    estimatedarrivaltime_dt: string;
}

export class FlightResultItem {
    Number: string;
    LandingTime: Date;
    FullAddress: string;    
    AirportText: string;
    AirportCode: string;

    private static ToDateTimeString(date: Date): string {
        if (!date) return '';
        if (date == null) return '';
        if (typeof date == 'string')
            date = new Date(date);

        var message = '';
        var month = date.getMonth() + 1;
        message += (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
        message += '/' + (month < 10 ? '0' + month : month);
        message += '/' + date.getFullYear();
        message += ' ' + (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
        message += ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
        return message;
    }

    public static ToItem(entity: FlightResult): FlightResultItem {
        if (entity == null) return null;
        let item = new FlightResultItem();
        item.Number = entity.ident;
        item.AirportCode = entity.destination;
        item.AirportText = entity.destinationName + ' Airport';
        if (entity.actualarrivaltime_dt)
            item.LandingTime = new Date(entity.actualarrivaltime_dt);
        else if (entity.estimatedarrivaltime_dt)
            item.LandingTime = new Date(entity.estimatedarrivaltime_dt);
        else if (entity.filed_time_dt)
            item.LandingTime = new Date(entity.filed_time_dt);
        let dateTimeString = this.ToDateTimeString(item.LandingTime);
        item.FullAddress = entity.ident + ' ' + entity.destinationName + ' ' + entity.destinationCity + ' [' + dateTimeString + ']';
        return item;
    }

    public static ToItems(entities: FlightResult[]): FlightResultItem[] {
        if (entities == null || entities.length == 0) return null;
        let items = new Array();
        entities.forEach(element => {
            items.push(FlightResultItem.ToItem(element));
        });
        return items;
    }

    public static AdvanceToItem(entity: FlightAdvanceResult): FlightResultItem {
        if (entity == null) return null;
        let item = new FlightResultItem();
        item.Number = entity.ident;
        item.AirportCode = entity.destination;
        item.AirportText = entity.destination_airport;
        if (entity.arrivaltime_dt)
            item.LandingTime = new Date(entity.arrivaltime_dt);
        else if (entity.departuretime_dt)
            item.LandingTime = new Date(entity.departuretime_dt);
        let dateString = UtilityHelper.ToFlightTimeNoSuffix(item.LandingTime),
            timeString = UtilityHelper.ToFlightTimeJustTime(item.LandingTime);
        item.FullAddress = entity.ident + ' / ' + entity.origin + ' → ' + entity.destination + ' / ' + dateString + ' / ' + timeString;
        return item;
    }

    public static AdvanceToItems(entities: FlightAdvanceResult[]): FlightResultItem[] {
        if (entities == null || entities.length == 0) return null;
        let items = new Array();
        entities.forEach(element => {
            items.push(FlightResultItem.AdvanceToItem(element));
        });
        return items;
    }
}

export class FlightParamSearch {
    DateTime: Date;
    PickUpCode: string;
    Airport: AirportItem;
    Airline: AirlineItem;
}

export class FlightAdvanceResult {
    ident: string;
    origin: string;
    arrivaltime: number;
    destination: string;
    aircrafttype: string;
    actual_ident: string;
    meal_service: string;
    departuretime: number;
    arrivaltime_dt: string;
    departuretime_dt: string;
    seats_cabin_first: number;
    seats_cabin_coach: number;
    destination_airport: string;
    seats_cabin_business: number;
}
