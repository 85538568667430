import * as _ from 'lodash';
import { Flight } from './flight';
import { Contact } from './contact';
import { PriceItem } from './price';
import { PromoItem } from './promo';
import { VehicleItem } from './vehicle';
import { TimeLineItem } from './timeline';
import { LocationItem } from './location';
import { QuotationItem } from './quotation';
import { PromotionItem } from './promotion';
import { AppConfig } from './../app.config';
import { TimeItem } from './object/timeitem';
import { PickupType } from './enum/pickup.type';
import { Address, AddressItem } from './address';
import { FormatCurrencyHelper } from '../helper/format.currency.helper';

export class Booking {
    Step: number;
    Note: string;
    Bref: string;
    Mode: string;
    OrgData: any;
    Photo: string;
    Time: TimeItem;
    Flight: Flight;
    Minute: number;
    Status: string;
    Message: string;
    IsPaid: boolean;
    ReturnDate: Date;
    AuthCode: string;
    Promo: PromoItem;
    Price: PriceItem;
    Contact: Contact;
    VehicleItem: any;
    Customer: Contact;
    VehicleId: number;
    IsExpand: boolean;
    ReturnRef: string;
    BookerRef: string;
    HasReturn: boolean;
    Confirmed: boolean;
    PickUpNote: string;
    ContactDb: Contact;
    PaymentType: string;
    ContactType: string;
    PickUp: AddressItem;
    ClientSecret: string;
    ReturnFlight: Flight;
    Vehicle: VehicleItem;
    PickDownNote: string;
    TrackingCode: string;
    OnwardSingle: boolean;
    PickDown: AddressItem;
    WaitAndReturn: boolean;
    Options: BookingOption;
    TimeLinePercent: number;
    Quotation: QuotationItem;
    ReturnEarliestTime: Date;
    PaypalOrderResponse: any;
    ViaAddress: AddressItem[];
    TimeLineItems: TimeLineItem[];
    ReturnTimeLinePercent: number;
    ChangesAllowed: boolean = true;
    ReturnTimeLineItems: TimeLineItem[];
    
    constructor(paymentType: string = '') {
        this.Step = 1;
        this.Bref = '';
        this.Mode = '';
        this.Note = '';
        this.Minute = 15;
        this.Price = null;
        this.PickUp = null;
        this.AuthCode = '';
        this.ReturnRef = '';
        this.Vehicle = null;
        this.IsPaid = false;
        this.PickDown = null;
        this.PickUpNote = '';
        this.TrackingCode = '';
        this.Confirmed = false;
        this.PickDownNote = '';
        this.ViaAddress = null;
        this.ReturnDate = null;
        this.HasReturn = false;
        this.OnwardSingle = true;
        this.ChangesAllowed = true;
        this.Time = new TimeItem();
        this.WaitAndReturn = false;
        this.Flight = new Flight();
        this.Promo = new PromoItem();
        this.Contact = new Contact();
        this.Customer = new Contact();
        this.ReturnFlight = new Flight();
        this.Options = new BookingOption();
        this.Quotation = new QuotationItem();
        this.Time.PickUpType = PickupType.Later;
        this.PaymentType = paymentType && paymentType.length > 0
            ? paymentType
            : AppConfig.PAYMENT_TYPE;
    }

    get TimeText() {
        if (this.Time == null) return '';
        if (this.Time.Time == null) return '';
        var time = this.Time.Time;
        var day = time.getDate(); var dayText = day < 10 ? '0' + day : day;
        var hours = time.getHours(); var hoursText = hours < 10 ? '0' + hours : hours;
        var month = time.getMonth() + 1; var monthText = month < 10 ? '0' + month : month;
        var minutes = time.getMinutes(); var minutesText = minutes < 10 ? '0' + minutes : minutes;
        return dayText + '/' + monthText + '/' + time.getFullYear() + ' ' + hoursText + ':' + minutesText;
    }

    set TimeText(e) {
        var dateText = e.split(' ')[0];
        var day = parseInt(dateText.split('/')[0]);
        var month = parseInt(dateText.split('/')[1]);
        var year = parseInt(dateText.split('/')[2]);

        var timeText = e.split(' ')[1];
        var hour = parseInt(timeText.split(':')[0]);
        var minute = parseInt(timeText.split(':')[1]);
        if (this.Time == null) this.Time = new TimeItem();
        this.Time.Time = new Date(year, month, day, hour, minute, 0);
    }

    public static ToItem(entity: BookingInfo, custId: number): Booking {
        if (entity == null) return null;
        let item = new Booking();

        item.Mode = '';
        item.IsExpand = false;
        item.Note = entity.notes;
        item.Photo = entity.photo;
        item.ChangesAllowed = true;
        item.Status = entity.status;
        item.BookerRef = entity.bookerref;
        item.IsPaid = entity.ispaid == null ? true : entity.ispaid;
        item.Bref = entity.jobpartid && entity.jobpartid.toString();
        item.PaymentType = entity.paymentmethod || entity.paymentMethod;

        item.Flight = new Flight();
        item.Flight.Number = entity.flightnumber;
        item.Flight.ArrivingFrom = new TimeItem();
        item.Flight.ArrivingFrom.PickUpType = PickupType.Later;
        item.Flight.AirportText = entity.arrivingfromairportname;
        item.Flight.AirportCode = entity.arrivingfromairportcode;
        if (entity.expflightarrival && entity.expflightarrival.length > 0)
            item.Flight.ArrivingFrom.Time = new Date(entity.expflightarrival);

        item.Options = new BookingOption();
        item.Quotation = new QuotationItem();
        item.Quotation.TotalFare = entity.totalfare;
        item.Quotation.GbpTotalFare = entity.gbptotalfare;
        item.Quotation.CurrencyCode = entity.currencycode;
        item.Quotation.GbpTotalFareString = FormatCurrencyHelper.formatLocaleCurrency(entity.gbptotalfare, 'GBP');
        item.Quotation.TotalFareString = FormatCurrencyHelper.formatLocaleCurrency(entity.totalfare, entity.currencycode);

        item.Time = new TimeItem();
        item.Time.Time = new Date(entity.pickupdatetime);
        item.Time.PickUpType = entity.now ? PickupType.Now : PickupType.Later;

        item.Contact = new Contact();
        item.Contact.CustomerId = custId;
        item.Contact.Name = entity.passengername;
        item.Contact.Phone = entity.passengerphone;
        item.Contact.Email = entity.passengeremail;
        item.Contact.DialingCode = entity.passengerdialingcode ? entity.passengerdialingcode : '';
        item.ContactDb = <Contact>_.cloneDeep(item.Contact);

        item.Customer = new Contact();
        item.Customer.Name = entity.custname;
        item.Customer.Phone = entity.custphone;
        item.Customer.Email = entity.custemail;
        item.Customer.DialingCode = entity.custcountryalpha2code ? entity.custcountryalpha2code : '';

        item.PickUp = new AddressItem();
        item.PickUp.Latitude = entity.picklat;
        item.PickUp.Longitude = entity.picklng;
        item.PickUp.Postcode = entity.pickpostcode;
        item.PickUp.Fulladdress = entity.pickupaddress;
        item.PickUp.Category = entity.pickcategory == null ? '' : entity.pickcategory;

        item.PickDown = new AddressItem();
        item.PickDown.Latitude = entity.dofflat;
        item.PickDown.Longitude = entity.dofflng;
        item.PickDown.Postcode = entity.droppostcode;
        item.PickDown.Fulladdress = entity.dropoffaddress;
        item.PickDown.Category = entity.doffcategory == null ? '' : entity.doffcategory;

        item.Vehicle = new VehicleItem();
        item.VehicleId = entity.vehtypeid;
        item.Vehicle.Id = entity.vehtypeid;
        item.Vehicle.Name = entity.vehicletype;
        item.Vehicle.NoOfLuggeges = entity.suitcases;
        item.Vehicle.NoOfPassengers = entity.passengers;

        item.ViaAddress = entity.vialocations;
        return item;
    }

    public static ToItemFromBookingPayment(entity: BookingPayment): Booking {
        if (entity == null) return null;
        let item = new Booking();

        item.Step = 4;
        item.Mode = '';
        item.IsExpand = false;
        item.ChangesAllowed = true;
        item.Message = entity.message;
        item.Bref = entity.jobpartid && entity.jobpartid.toString();
        item.PaymentType = entity.paymentmethod && entity.paymentmethod.toLowerCase();
        item.ClientSecret = entity.clientsecret && entity.clientsecret.replace('"', '').replace('"', '');

        item.Flight = new Flight();
        item.Flight.ArrivingFrom = new TimeItem();
        item.Flight.ArrivingFrom.PickUpType = PickupType.Later;

        item.Options = new BookingOption();
        item.Quotation = new QuotationItem();

        item.Time = new TimeItem();
        item.Time.PickUpType = PickupType.Later;
        item.Time.Time = new Date(entity.bookingdate);

        item.Contact = new Contact();
        item.Contact.Name = entity.name;
        item.Contact.Phone = entity.phone;
        item.ContactDb = <Contact>_.cloneDeep(item.Contact);

        item.Customer = new Contact();
        item.Customer.Name = entity.name;
        item.Customer.Phone = entity.phone;

        item.PickUp = new AddressItem();
        item.PickUp.Valid = true;
        item.PickUp.Fulladdress = entity.pickupaddress;

        item.PickDown = new AddressItem();
        item.PickDown.Valid = true;
        item.PickDown.Fulladdress = entity.dropoffaddress;

        item.Vehicle = new VehicleItem();
        item.Vehicle.Valid = true
        item.Vehicle.Name = entity.vehicletype;

        item.Quotation = new QuotationItem();
        item.Quotation.TotalFare = entity.price;
        item.PaypalOrderResponse = entity.paypalorderresponse;
        item.OrgData = entity;
        return item;
    }

    public static ToItems(entities: BookingInfo[], custId: number): Booking[] {
        if (entities == null || entities.length == 0) return new Array<Booking>();
        let items = new Array();
        entities.forEach(element => {
            items.push(Booking.ToItem(element, custId));
        });
        return items;
    }

    public static ToItemFromPromotion(entity: PromotionItem): Booking {
        if (entity == null) return null;
        let item = new Booking();

        item.Mode = entity.Mode;
        item.VehicleId = entity.VehtypeId;
        item.Options = new BookingOption();
        item.ChangesAllowed = entity.ChangesAllowed;

        item.Time = new TimeItem();
        item.Time.Time = null;
        item.Time.PickUpType = PickupType.Later;

        // PickUp
        item.PickUp = new AddressItem();
        item.PickUp.Latitude = entity.PickLat;
        item.PickUp.Longitude = entity.PickLng;
        item.PickUp.Category = entity.PickCategory;
        item.PickUp.Postcode = entity.PickPostCode;
        item.PickUp.Fulladdress = entity.PickupAddress;

        // PickDown
        item.PickDown = new AddressItem();
        item.PickDown.Latitude = entity.DoffLat;
        item.PickDown.Longitude = entity.DoffLng;
        item.PickDown.Category = entity.DoffCategory;
        item.PickDown.Postcode = entity.DropPostCode;
        item.PickDown.Fulladdress = entity.DropoffAddress;
        return item;
    }

    public static ToItemFromBookingParam(entity: BookingParam): Booking {
        if (entity == null) return null;
        let item = new Booking();

        item.Mode = '';
        item.ChangesAllowed = true;
        item.VehicleId = entity.vehicleid;
        item.TrackingCode = entity.trackingcode;
        item.Options = entity.options ? entity.options : new BookingOption();

        item.Time = new TimeItem();
        if (entity.datetime != null) {
            item.Time.Time = new Date(entity.datetime);
            item.Time.PickUpType = entity.isASAP == 'true' ? PickupType.Now : PickupType.Later;
        } else {
            if (entity.isASAP == 'true') {
                item.Time.Time = new Date();
                item.Time.PickUpType = PickupType.Now;
            } else {
                item.Time.Time = null;
                item.Time.PickUpType = PickupType.Later;
            }
        }

        if (entity.pickup != null) {
            item.PickUp = new AddressItem();
            item.PickUp.Category = entity.pickup.category;
            item.PickUp.Postcode = entity.pickup.postcode;
            item.PickUp.Latitude = entity.pickup.latitude;
            item.PickUp.Longitude = entity.pickup.longitude;
            item.PickUp.Fulladdress = entity.pickup.fulladdress;
        }

        if (entity.dropoff != null) {
            item.PickDown = new AddressItem();
            item.PickDown.Category = entity.dropoff.category;
            item.PickDown.Postcode = entity.dropoff.postcode;
            item.PickDown.Latitude = entity.dropoff.latitude;
            item.PickDown.Longitude = entity.dropoff.longitude;
            item.PickDown.Fulladdress = entity.dropoff.fulladdress;
        }
        return item;
    }

    public static ToItemFromBookingInfoItem(entity: BookingInfoItem): Booking {
        if (entity == null) return null;
        let item = new Booking();

        item.Mode = '';
        item.Time = entity.Time;
        item.ChangesAllowed = true;
        if (entity.PickUp != null) {
            item.PickUp = entity.PickUp;
        }
        if (entity.PickDown != null) {
            item.PickDown = entity.PickDown;
        }
        item.Options = new BookingOption();
        item.ViaAddress = entity.ViaAddress;
        item.Quotation = new QuotationItem();
        item.Quotation.RoutePath = entity.RoutePath;

        return item;
    }
}

export class BookingOption {
    HandBags: string;
    Luggages: string;
    SmallPets: string;
    OtherPets: string;
    Passengers: string;	
    SmallChilds: string;
    LargeChilds: string;
    
    constructor() {
        this.HandBags = '0';
        this.Luggages = '0';
        this.SmallPets = '0';
        this.OtherPets = '0';
        this.Passengers = '0';
        this.SmallChilds = '0';
        this.LargeChilds = '0';
    }
}

export class BookingSave {
    code: number;
    bref: string;
    message: string;
}

export class BookingSaveItem {
    Code: number;
    Bref: string;
    Message: string;

    public static ToItem(entity: BookingSave): BookingSaveItem {
        if (entity == null) return null;
        var item = new BookingSaveItem();
        //item.Code = 0;
        item.Code = entity.code;
        item.Bref = entity.bref;
        item.Message = entity.message;
        return item;
    }
}

export class BookingInfo {
    code: number;
    now: boolean;
    notes: string;
    price: number;
    photo: string;
    status: string;
    isasap: string;
    message: string;
    vehmake: string;
    picklat: number;
    picklng: number;
    dofflat: number;
    dofflng: number;
    ispaid: boolean;
    vehmodel: string;
    vehregno: string;
    custname: string;
    custphone: string;
    custemail: string;
    bookerref: string;
    totalfare: number;
    vehtypeid: number;
    childseat: string;
    vialocations: any;
    vehcolour: string;
    jobpartid: number;
    suitcases: number;
    flightnumber: any;
    passengers: number;
    drivername: string;
    pcolicence: string;
    journeytype: string;
    vehicletype: string;
    currencycode: string;
    gbptotalfare: number;
    doffcategory: string;
    pickcategory: string;
    meetandgreet: string;
    pickpostcode: string;
    droppostcode: string;
    passengername: string;
    pickupaddress: string;
    paymentmethod: string;
    paymentMethod: string;
    passengeremail: string;
    dropoffaddress: string;
    pickupdatetime: string;
    passengerphone: string;
    expflightarrival: string;
    routepath: Array<number[]>;
    passengerdialingcode: string;
    custcountryalpha2code: string;
    arrivingfromairportname: string;
    arrivingfromairportcode: string;

    constructor() {
        this.photo = '';
        this.ispaid = false;
        this.routepath = null;
    }
}

export class BookingInfoItem {
    Code: string;
    Photo: string;
    Time: TimeItem;
    Status: string;
    IsPaid: boolean;
    ViaAddress: any;
    VehMake: string;
    VehModel: string;
    VehRegNo: string;
    Contact: Contact;
    VehColour: string;
    JobPartId: number;
    PcoLicence: string;
    DriverName: string;
    PickUp: AddressItem;
    PickDown: AddressItem;
    RoutePath: LocationItem[];

    public static ToItem(entity: BookingInfo): BookingInfoItem {
        if (entity == null) return null;
        let item = new BookingInfoItem();

        item.Photo = entity.photo;
        item.Status = entity.status;
        item.VehMake = entity.vehmake;
        item.VehModel = entity.vehmodel;
        item.VehRegNo = entity.vehregno;
        item.VehColour = entity.vehcolour;
        item.JobPartId = entity.jobpartid;
        item.DriverName = entity.drivername;
        item.PcoLicence = entity.pcolicence;
        item.ViaAddress = entity.vialocations;
        item.IsPaid = entity.ispaid == null ? true : entity.ispaid;

        item.Time = new TimeItem();
        item.Time.Time = new Date(entity.pickupdatetime);
        item.Time.PickUpType = entity.now ? PickupType.Now : PickupType.Later;

        item.Contact = new Contact();
        item.Contact.Name = entity.passengername;
        item.Contact.Phone = entity.passengerphone;
        item.Contact.Email = entity.passengeremail;
        item.Contact.DialingCode = entity.passengerdialingcode;

        item.PickUp = new AddressItem();
        item.PickUp.Latitude = entity.picklat;
        item.PickUp.Longitude = entity.picklng;
        item.PickUp.Postcode = entity.pickpostcode;
        item.PickUp.Fulladdress = entity.pickupaddress;

        item.PickDown = new AddressItem();
        item.PickDown.Latitude = entity.dofflat;
        item.PickDown.Longitude = entity.dofflng;
        item.PickDown.Postcode = entity.droppostcode;
        item.PickDown.Fulladdress = entity.dropoffaddress;

        item.RoutePath = LocationItem.ToItemsFromNumber(entity.routepath);
        return item;
    }

}

export class BookingHistoryItem {
    Id: string;
    Name: string;
    IsShow: boolean;
    SubTitle: string;
    PageSize: number = 5;
    PageIndex: number = 0;
    Loading: boolean = false;
    TotalRecords: number = 0;
    Bookings: Booking[] = null;
    AllBookings: Booking[] = null;
    DescriptionHasNoBooking: string = '';
}

export class BookingParam {
    code: number;
    datetime: any;
    isASAP: string;
    message: string;
    pickup: Address;
    dropoff: Address;
    vehicleid: number;
    trackingcode: string;
    options: BookingOption;
}

export class UpdateBooking {
    Id: string;
    Status: string;
    JobpartId: number;
    ReturnJobpartId: number;
}

export class BookingPayment {
    name: string;
    code: string;
    phone: string;
    price: number;
    message: string;
    bookingdate: Date;
    jobpartid: number;
    vehicletype: string;
    clientsecret: string;
    pickupaddress: string;
    paymentmethod: string;
    dropoffaddress: string;
    paypalorderresponse: any;
}
