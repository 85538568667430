<div class="modal fade" id="modalFlight" role="dialog">
    <div class="modal-dialog" style="height: 100%;" *ngIf="data.FlightPickUp || data.FlightPickDown">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button id="closeButton" type="button" class="close" (click)="onClose()">&times;</button>
                <div *ngIf="data.FlightPickUp && data.FlightPickDown">
                    <div class="col-xs-6 col-sm-6 col-md-6 signin" (click)="choiceFlightTab(data.FLIGHT_TYPE.ONWARD)" [ngClass]="data.FlightType == data.FLIGHT_TYPE.ONWARD ? 'active' : ''">
                        <span>
                            <img [src]="iconFlight" />
                        </span>Flight Details
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 signup" (click)="choiceFlightTab(data.FLIGHT_TYPE.RETURN)" [ngClass]="data.FlightType == data.FLIGHT_TYPE.RETURN ? 'active' : ''">
                        Return Flight Details
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div *ngIf="data.FlightPickUp && !data.FlightPickDown">
                    <div class="col-xs-12 col-sm-12 col-md-12 modal-title">
                        <span>
                            <img [src]="iconFlight" />
                        </span>Flight Details
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div *ngIf="!data.FlightPickUp && data.FlightPickDown">
                    <div class="col-xs-12 col-sm-12 col-md-12 modal-title">
                        <span>
                            <img [src]="iconFlight" />
                        </span>Return Flight Details
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div [ngClass]="data.FlightType == data.FLIGHT_TYPE.ONWARD ? 'show' : 'hide'" class="modal-body">
                <div class="ui-control ui-control-haslabel">
                    <xr-textbox #FlightNumber
                                [hasLabel]="true"
                                [requied]="false"
                                [invalid]="false"
                                classInput="ui-input"
                                title="Flight Number"
                                editorId="flight-number"
                                [disabled]="disabledFlight"
                                [iconFirst]="iconEditorFlight"
                                (onChange)="onChangeFlightNumber()"
                                [model]="data.Booking.Flight.Number"
                                [editorType]="data.EDITOR_TYPE.TEXT">
                    </xr-textbox>
                </div>
                <div class="ui-control ui-control-haslabel">
                    <xr-airportlist #FlightAirportList
                                    [hasLabel]="true"
                                    [scrollOnTop]="false"
                                    [disabled]="disabledFlight"
                                    classInput="ui-input airport"
                                    editorId="flight-airport-list"
                                    placeholder="Arriving from Airport"
                                    (onChangeItem)="onChangedAirport($event)"
                                    (onSelectItem)="onSelectedAirport($event)"
                                    [selectedItem]="data.Booking.Flight.Airport"
                                    invalidMessage="Arriving from airport is required">
                    </xr-airportlist>
                </div>
                <div class="ui-control">
                    <span>We would like to keep track of your flight just incase of any delay</span>
                </div>
                <div class="ui-control">
                    <xr-checkbox #FlightCheckbox
                                 type="checkbox"
                                 editorId="check-flight-detail"
                                 (change)="onChangeIsFlightDetail()"
                                 [(ngModel)]="data.Booking.Flight.IsFlightDetail"
                                 title="I don't have flight details please follow the booking time">
                    </xr-checkbox>
                </div>
                <div class="center-button" style="text-align: center;">
                    <button type="button" class="ui-button" (click)="onSubmitFlight()">OK</button>
                </div>
                <div style="clear: both;"></div>
            </div>
            <div [ngClass]="data.FlightType == data.FLIGHT_TYPE.RETURN ? 'show' : 'hide'" class="modal-body">
                <div class="ui-control ui-control-haslabel">
                    <xr-textbox #ReturnFlightNumber
                                [hasLabel]="true"
                                [requied]="false"
                                [invalid]="false"
                                [scrollOnTop]="false"
                                classInput="ui-input"
                                title="Return Flight Number"
                                [iconFirst]="iconEditorFlight"
                                editorId="return-flight-number"
                                [disabled]="disabledReturnFlight"
                                [editorType]="data.EDITOR_TYPE.TEXT"
                                (onChange)="onChangeReturnFlightNumber()"
                                [model]="data.Booking.ReturnFlight.Number">
                    </xr-textbox>
                </div>
                <div class="ui-control ui-control-haslabel">
                    <xr-airportlist #ReturnFlightAirportList
                                    [hasLabel]="true"
                                    [scrollOnTop]="false"
                                    classInput="ui-input airport"
                                    [disabled]="disabledReturnFlight"
                                    placeholder="Arriving from Airport"
                                    editorId="flight-return-airport-list"
                                    (onChangeItem)="onChangedReturnAirport($event)"
                                    (onSelectItem)="onSelectedReturnAirport($event)"
                                    [selectedItem]="data.Booking.ReturnFlight.Airport"
                                    invalidMessage="Arriving from airport is required">
                    </xr-airportlist>
                </div>
                <div class="ui-control">
                    <span>We would like to keep track of your flight just incase of any delay</span>
                </div>
                <div class="ui-control">
                    <xr-checkbox #ReturnFlightCheckbox
                                 type="checkbox"
                                 editorId="check-return-flight-detail"
                                 (change)="onChangeIsReturnFlightDetail()"
                                 [(ngModel)]="data.Booking.ReturnFlight.IsFlightDetail"
                                 title="I don't have flight details please follow the booking time">
                    </xr-checkbox>
                </div>
                <div class="center-button" style="text-align: center;">
                    <button type="button" class="ui-button" (click)="onSubmitReturnFlight()">OK</button>
                </div>
                <div style="clear: both;"></div>
            </div>
        </div>
    </div>
</div>
