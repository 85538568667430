export class Airline {
    type: string;
    status: string;
    callsign: string;
    hub_code: string;
    iata_code: string;
    icao_code: string;
    fleet_size: string;
    country_iso2: string;
    date_founded: string;
    airline_name: string;
    country_name: string;
    fleet_average_age: string;
    iata_prefix_accounting: string;
}

export class AirlineItem {
    Code: string;
    Name: string;
    Country: string;
    IataCode?: string;
    FullAddress: string;
    CountryLowerCase?: string;

    public static ToItem(entity: Airline): AirlineItem {
        if (entity == null) return null;
        let item = new AirlineItem();
        item.Code = entity.icao_code;
        item.Name = entity.airline_name;
        item.IataCode = entity.iata_code;
        item.Country = entity.country_name;
        item.FullAddress = item.Code.length > 0
            ? item.Code + " - " + item.Name
            : item.Name;
        item.FullAddress = item.IataCode && item.IataCode.length > 0
            ? item.IataCode + " - " + item.FullAddress
            : item.FullAddress;
        if (entity.country_name) item.FullAddress += ' - ' + entity.country_name;
        if (entity.country_iso2) item.CountryLowerCase = entity.country_iso2.toLowerCase();
        return item;
    }

    public static ToItems(entities: Airline[]): AirlineItem[] {
        if (entities == null || entities.length == 0) return null;
        let items = new Array();
        entities.forEach(element => {
            items.push(AirlineItem.ToItem(element));
        });
        return items;
    }
}