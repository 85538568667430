import { Component } from '@angular/core';
import { DataService } from '../../../services/dataService';
import { UtilService } from '../../../services/utilService';
import { PaymentItem, PaymentProcessItem } from '../../../domain/payment';

@Component({
    selector: 'payment-process-dialog',
    templateUrl: './payment.process.dialog.component.html'
})
export class PaymentProcessDialogComponent {
    constructor(public data: DataService, public util: UtilService) {
    }

    onClose() {
        this.util.HideModal(this.data.MODAL_TYPE.PAYMENTPROCESS);
        this.data.ModalType = '';
    }
}