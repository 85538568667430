import { Dialog } from './../../../domain/dialog';
import { Component, ViewChild } from '@angular/core';
import { ApiService } from './../../../services/apiService';
import { UtilService } from './../../../services/utilService';
import { DataService } from './../../../services/dataService';
import { XrTextboxComponent } from '../../editors/textbox/textbox';

@Component({
    selector: 'forgot-password',
    templateUrl: './forgot.dialog.component.html',
    host: {
        '(document:keydown)': 'keyDownFunction($event)'
    }
})

export class ForgotDialogComponent {
    email: string = '';
    onSubmiting: boolean;
    submitMessage: string;
    @ViewChild('ForgotEmail') ForgotEmail: XrTextboxComponent;

    constructor(public service: ApiService, public data: DataService, public util: UtilService) {
        this.submitMessage = '';
        this.onSubmiting = false;
    }

    onSubmit() {
        /// Valid
        let valid = this.validSubmit();
        if (!valid) {
            return;
        }

        // Set value
        this.email = this.ForgotEmail.model;

        /// Submit
        this.onSubmiting = true;
        this.submitMessage = '';
        this.service.resetPassword(this.email)
            .subscribe(model => {
                this.onSubmiting = false;
                if (model.code == 1) {
                    this.data.ModalType = '';
                    this.util.HideModal(this.data.MODAL_TYPE.FORGOTPASSWORD);
                    this.data.ShowDialog.emit(new Dialog('A password reset link has been sent to your email', 'Message', this.data.DIALOG_TYPE.DIALOG));
                } else {
                    this.submitMessage = model.code == 0 ? 'Something went wrong, please try again.' : model.message;
                }
            });
    }

    validSubmit(): boolean {
        if (this.ForgotEmail) {
            var valid1 = this.ForgotEmail.checkValid();
            return valid1;
        }
        return false;
    }

    onInputChange() {
        this.submitMessage = '';
    }

    onClose() {
        /// Hide error message
        this.submitMessage = '';
        this.ForgotEmail.hideInvalid();
        this.util.HideModal(this.data.MODAL_TYPE.FORGOTPASSWORD);
        this.data.ModalType = '';
    }

    keyDownFunction(event: any) {
        if (this.data.ModalType == this.data.MODAL_TYPE.FORGOTPASSWORD) {
            if (event.keyCode == 13) {
                this.onSubmit();
            }
        }
    }
}