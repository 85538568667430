declare var jQuery: any;
import { AppConfig } from '../../../app.config';
import { SignIn } from '../../../domain/object/signin';
import { SignUp } from '../../../domain/object/signup';
import { ChangeDetectionStrategy, Component, afterNextRender } from '@angular/core';
import { UtilService } from './../../../services/utilService';
import { DataService } from './../../../services/dataService';

@Component({
    selector: 'xr-menu',
    templateUrl: "./menu.component.hyflytaxis.html",
})
export class XrMenuComponent {
    iconLogo: string;
    iconEmail: string;
    iconPhone: string;
    iconLogoWhite: string;
    iconEmailWhite: string;
    iconPhoneWhite: string;

    browser: boolean;
    menuAnchor: string;
    interval: any = null;
    menuActive: boolean = false;
    menuUserActive: boolean = false;

    constructor(public data: DataService, public util: UtilService) {
        this.menuAnchor = 'home';
        this.iconLogo = AppConfig.ASSETS + 'logo/logobg.png';
        this.iconEmail = AppConfig.ASSETS + 'icons/email.png';
        this.iconPhone = AppConfig.ASSETS + 'icons/telephone.png';
        this.iconLogoWhite = AppConfig.ASSETS + 'logo/logo_white.png';
        this.iconEmailWhite = AppConfig.ASSETS + 'icons/email_white.png';
        this.iconPhoneWhite = AppConfig.ASSETS + 'icons/phone_white.png';
        afterNextRender(() => {
            this.browser = true;
            setTimeout(() => {
                this.eventWindowScroll();
                this.util.InitAffixMenu();
            }, 1000);
        })
    }

    onOpenModal() {
        if (this.data.SignIn == null) this.data.SignIn = new SignIn();
        if (this.data.SignUp == null) this.data.SignUp = new SignUp();
        this.util.ShowModal(this.data.MODAL_TYPE.SIGNIN);
        this.data.SignInNavigate = '';
    }

    iconProfile() {
        return this.data.Profile != null
            ? AppConfig.ASSETS + 'icons/profile.png'
            : AppConfig.ASSETS + 'icons/notprofile.png';
    }

    scrollTo(anchor: string, outsite: boolean = false) {
        if (!outsite) {
            this.menuAnchor = anchor;
            this.util.ScrollTo(anchor);
        }
        else {
            window.location.href = '/' + anchor;
        }
    }

    scrollHomePageAnchor(anchor: string) {
        window.location.href = '/';
        sessionStorage.setItem("AnchorMenu", anchor);
    }

    redirectToPage(page: string) {
        if (this.util.IsMobile())
            this.hideMenu();
        this.util.GoToPage(page);
        this.toggleSideBarMenuUser(false);
    }

    toggleMenu() {
        if (AppConfig.NAME == 'deluxetransportation') {
            this.menuActive = !this.menuActive;
        } else {
            let $menuItem = jQuery('#menuItem');
            if ($menuItem && $menuItem.length > 0) {
                jQuery('#menuItemUser').removeClass('active');
                if ($menuItem.hasClass('active')) {
                    jQuery('html').removeClass("noscroll");
                    $menuItem.removeClass('active');
                    if (jQuery('#pickfloat-bottom').length > 0) {
                        jQuery('#pickfloat-bottom').css('opacity', 1);
                        jQuery('#pickfloat-bottom').css('z-index', 10);
                    }
                } else {
                    jQuery('html').addClass("noscroll");
                    $menuItem.addClass("active");
                    if (jQuery('#pickfloat-bottom').length > 0) {
                        jQuery('#pickfloat-bottom').css('opacity', 0);
                        jQuery('#pickfloat-bottom').css('z-index', 0);
                    }
                }
            } else {
                jQuery('#main-navbar-user').hide();
                let elm = jQuery('#main-navbar .navbar-nav').length > 0
                    ? jQuery('#main-navbar .navbar-nav')
                    : jQuery('#main-navbar .navbar-menu');

                if (jQuery('#main-navbar').is(':visible')) {
                    if (jQuery(elm).hasClass('open')) {
                        this.removeClass(elm, false);
                    } else {
                        this.addClass(elm, false);
                    }
                } else {
                    this.addClass(elm, false);
                }
            }
        }
        this.toggleProfile();
    }

    toggleMenuUser() {
        if (this.data.Profile == null) {
            let $menuItem = jQuery('#menuItem');
            if ($menuItem && $menuItem.length > 0 && $menuItem.hasClass('active')) {
                jQuery('html').removeClass("noscroll");
                $menuItem.removeClass('active');
                if (jQuery('#pickfloat-bottom').length > 0) {
                    jQuery('#pickfloat-bottom').css('opacity', 1);
                    jQuery('#pickfloat-bottom').css('z-index', 10);
                }
            }
            if (this.data.SignIn == null) this.data.SignIn = new SignIn();
            if (this.data.SignUp == null) this.data.SignUp = new SignUp();
            this.util.ShowModal(this.data.MODAL_TYPE.SIGNIN, 500);
            return;
        }
        let $menuItem = jQuery('#menuItemUser');
        if ($menuItem && $menuItem.length > 0) {
            jQuery('#menuItem').removeClass('active');
            if ($menuItem.hasClass('active')) {
                jQuery('html').removeClass("noscroll");
                $menuItem.removeClass('active');
                if (jQuery('#pickfloat-bottom').length > 0) {
                    jQuery('#pickfloat-bottom').css('opacity', 1);
                    jQuery('#pickfloat-bottom').css('z-index', 10);
                }
            } else {
                jQuery('html').addClass("noscroll");
                $menuItem.addClass("active");
                if (jQuery('#pickfloat-bottom').length > 0) {
                    jQuery('#pickfloat-bottom').css('opacity', 0);
                    jQuery('#pickfloat-bottom').css('z-index', 0);
                }
            }
        } else {
            jQuery('#main-navbar').hide();
            let elm = jQuery('#main-navbar-user .navbar-nav').length > 0
                ? jQuery('#main-navbar-user .navbar-nav')
                : jQuery('#main-navbar-user .navbar-menu');

            if (jQuery('#main-navbar-user').is(':visible')) {
                if (jQuery(elm).hasClass('open')) {
                    this.removeClass(elm, true);
                } else {
                    this.addClass(elm, true);
                }
            } else {
                this.addClass(elm, true);
            }
        }
        this.toggleProfile();
    }

    toggleMenuActive() {
        this.menuUserActive = false;
        this.menuActive = !this.menuActive;
    }

    toggleMenuUserActive() {
        this.menuActive = false;
        if (this.data.Profile == null) {
            let $menuItem = jQuery('#menuItem');
            if ($menuItem && $menuItem.length > 0 && $menuItem.hasClass('active')) {
                jQuery('html').removeClass("noscroll");
                $menuItem.removeClass('active');
                if (jQuery('#pickfloat-bottom').length > 0) {
                    jQuery('#pickfloat-bottom').css('opacity', 1);
                    jQuery('#pickfloat-bottom').css('z-index', 10);
                }
            }
            if (this.data.SignIn == null) this.data.SignIn = new SignIn();
            if (this.data.SignUp == null) this.data.SignUp = new SignUp();
            this.util.ShowModal(this.data.MODAL_TYPE.SIGNIN, 500);
            return;
        }
        this.menuUserActive = !this.menuUserActive;
    }

    toggleMenuUserRedirect(loginUrl: string) {
        if (this.data.Profile == null) {
            if (!loginUrl) loginUrl = '/signin.html?page=booking.html';
            window.location.href = loginUrl;
            return;
        }
        let $menuItem = jQuery('#menuItemUser');
        if ($menuItem && $menuItem.length > 0) {
            jQuery('#menuItem').removeClass('active');
            if ($menuItem.hasClass('active')) {
                jQuery('html').removeClass("noscroll");
                $menuItem.removeClass('active');
                if (jQuery('#pickfloat-bottom').length > 0) {
                    jQuery('#pickfloat-bottom').css('opacity', 1);
                    jQuery('#pickfloat-bottom').css('z-index', 10);
                }
            } else {
                jQuery('html').addClass("noscroll");
                $menuItem.addClass("active");
                if (jQuery('#pickfloat-bottom').length > 0) {
                    jQuery('#pickfloat-bottom').css('opacity', 0);
                    jQuery('#pickfloat-bottom').css('z-index', 0);
                }
            }
        } else {
            jQuery('#main-navbar').hide();
            let elm = jQuery('#main-navbar-user .navbar-nav').length > 0
                ? jQuery('#main-navbar-user .navbar-nav')
                : jQuery('#main-navbar-user .navbar-menu');

            if (jQuery('#main-navbar-user').is(':visible')) {
                if (jQuery(elm).hasClass('open')) {
                    this.removeClass(elm, true);
                } else {
                    this.addClass(elm, true);
                }
            } else {
                this.addClass(elm, true);
            }
        }
        this.toggleProfile();
    }

    toggleInlineMenu() {
        jQuery('#mobile-menu-user-nav').hide();
        jQuery('#mobile-menu-nav').toggle('slow');
    }

    toggleInlineMenuUser() {
        jQuery('#mobile-menu-nav').hide();
        if (this.data.Profile == null) {
            this.onOpenModal();
        } else {
            jQuery('#mobile-menu-user-nav').toggle('slow');
        }
    }

    toggleDropdownMenuUser() {
        jQuery('#profile-dropdown-menu').toggle();
    }

    toggleProfile() {
        if (this.data.Profile != null) {
            jQuery('.block-signin').hide();
            jQuery('.block-profile').show();
            jQuery('#profile-name-mobile').html(this.data.Profile.FirstName);
            jQuery('#navbar-menu-user').html('<img src="/assets/images/icons/profile.png" />');
        } else {
            jQuery('.block-signin').show();
            jQuery('.block-profile').hide();
            jQuery('#navbar-menu-user').html('<img src="/assets/images/icons/notprofile.png" />');
        }
    }

    onOpenModalSignIn() {
        if (this.data.SignIn == null) this.data.SignIn = new SignIn();
        if (this.data.SignUp == null) this.data.SignUp = new SignUp();
        this.util.ShowModal(this.data.MODAL_TYPE.SIGNIN, 500);
        this.data.SignInNavigate = '';
        this.hideMenu();
    }

    hideMenu() {
        this.menuActive = false;
        jQuery('#main-navbar').hide();
        jQuery('#main-navbar-user').hide();
        jQuery('html').removeClass("noscroll");

        let elmMenu = jQuery('#main-navbar .navbar-nav').length > 0
            ? jQuery('#main-navbar .navbar-nav')
            : jQuery('#main-navbar .navbar-menu');
        let elmMenuUser = jQuery('#main-navbar-user .navbar-nav').length > 0
            ? jQuery('#main-navbar-user .navbar-nav')
            : jQuery('#main-navbar-user .navbar-menu');
        jQuery(elmMenu).removeClass("open");
        jQuery(elmMenuUser).removeClass("open");

        let $menuItem = jQuery('#menuItem');
        if ($menuItem && $menuItem.length > 0 && $menuItem.hasClass('active')) {
            jQuery('html').removeClass("noscroll");
            $menuItem.removeClass('active');
        }
        $menuItem = jQuery('#menuItemUser');
        if ($menuItem && $menuItem.length > 0 && $menuItem.hasClass('active')) {
            jQuery('html').removeClass("noscroll");
            $menuItem.removeClass('active');
        }
        if (jQuery('#pickfloat-bottom').length > 0) {
            jQuery('#pickfloat-bottom').css('opacity', 1);
            jQuery('#pickfloat-bottom').css('z-index', 10);
        }
    }

    onLogout() {
        this.toggleSideBarMenuUser(false);
        this.util.OnLogout(() => {
            this.hideMenu();
            this.toggleProfile();
            jQuery('#mobile-menu-user-nav').hide();
        });
    }

    addClass(elm: any, menuUser: boolean) {
        let menu = menuUser
            ? jQuery('#main-navbar-user')
            : jQuery('#main-navbar');
        jQuery(menu).show();
        jQuery(elm).addClass("open");
        if (jQuery('#pickfloat-bottom').length > 0) {
            jQuery('#pickfloat-bottom').css('opacity', 0);
            jQuery('#pickfloat-bottom').css('z-index', 0);
        }
        jQuery('html').addClass("noscroll");

        let that = this;
        if (that.interval != null)
            window.clearInterval(that.interval);
        that.interval = window.setInterval(function () {
            if (jQuery('html').hasClass('noscroll')) {
                window.clearInterval(that.interval);
            } else {
                jQuery('html').addClass("noscroll");
            }
        }, 500);
    }
    removeClass(elm: any, menuUser: boolean) {
        let menu = menuUser
            ? jQuery('#main-navbar-user')
            : jQuery('#main-navbar');
        jQuery(menu).hide();
        jQuery(elm).removeClass("open");
        if (jQuery('#pickfloat-bottom').length > 0) {
            jQuery('#pickfloat-bottom').css('opacity', 1);
            jQuery('#pickfloat-bottom').css('z-index', 10);
        }
        jQuery('html').removeClass("noscroll");
        let that = this;
        if (that.interval != null)
            window.clearInterval(that.interval);
        that.interval = window.setInterval(function () {
            if (!jQuery('html').hasClass('noscroll')) {
                window.clearInterval(that.interval);
            } else {
                jQuery('html').removeClass("noscroll");
            }
        }, 500);
    }

    eventWindowScroll() {
        if (jQuery(".bar_line").length > 0) {
            jQuery(window).scroll(() => {
                let scrolling = jQuery(document).scrollTop(),
                    height = scrolling * 2 > 100
                        ? scrolling * 2
                        : 100;
                jQuery(".bar_line").css({
                    background: "#1fbfee",
                    height: height,
                });
            });
        }
    }
    toggleSideBarMenu(act: boolean) {
        if (jQuery(".sideMenu").length > 0) {
            if (act === true) {
                jQuery(".sideMenu").css({
                    display: "block"
                });
                jQuery(".menu_bar").animate({
                    right: "0"
                });
            } else if (act === false) {
                jQuery(".menu_bar").animate({
                    right: "-300px"
                },
                    function () {
                        jQuery(".sideMenu").css({
                            display: "none"
                        });
                    }
                );
            }
        }
    }
    toggleSideBarMenuUser(act: boolean) {
        if (jQuery(".sideMenu").length > 0) {
            if (act === true) {
                jQuery(".sideMenu").css({
                    display: "block"
                });
                jQuery(".menu_bar").animate({
                    right: "0"
                });
            } else if (act === false) {
                jQuery(".menu_bar").animate({
                    right: "-300px"
                },
                    () => {
                        jQuery(".sideMenu").css({
                            display: "none"
                        });
                        if (this.data.Profile == null) {
                            let $menuItem = jQuery('#menuItem');
                            if ($menuItem && $menuItem.length > 0 && $menuItem.hasClass('active')) {
                                jQuery('html').removeClass("noscroll");
                                $menuItem.removeClass('active');
                                if (jQuery('#pickfloat-bottom').length > 0) {
                                    jQuery('#pickfloat-bottom').css('opacity', 1);
                                    jQuery('#pickfloat-bottom').css('z-index', 10);
                                }
                            }
                            if (this.data.SignIn == null) this.data.SignIn = new SignIn();
                            if (this.data.SignUp == null) this.data.SignUp = new SignUp();
                            this.util.ShowModal(this.data.MODAL_TYPE.SIGNIN, 500);
                            return;
                        }
                    }
                );
            }
        }
    }
}
