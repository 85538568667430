import { PromotionItem } from './promotion';

export class Profile {
    code: number;
    phone: string;
    email: string;
    custid: number;
    message: string;
    surname: string;
    bookerid: number;
    firstname: string;
    dialingcode: string;
    smsmailinglist: string;
    emailmailinglist: string;
}

export class ProfileItem {
    Id: number;
    Code: number;
    Phone: string;
    Email: string;
    Message: string;
    BookerId: number;
    LastName: string;
    FirstName: string;
    DialingCode: string;
    SMSMailingList: string;
    EmailMailingList: string;

    public static ToItem(entity: Profile): ProfileItem {
        if (entity == null) return null;
        var item = new ProfileItem();
        item.Id = entity.custid;
        item.Code = entity.code;
        item.Phone = entity.phone;
        item.BookerId = entity.bookerid;
        if (item.Phone) {
            while (item.Phone.indexOf(' ') >= 0)
                item.Phone = item.Phone.replace(' ', '');
            if (item.Phone.indexOf('0') == 0)
                item.Phone = item.Phone.substr(1);
        }
        item.Email = entity.email;
        item.Message = entity.message;
        item.LastName = entity.surname;
        item.FirstName = entity.firstname;
        item.DialingCode = entity.dialingcode;
        item.SMSMailingList = entity.smsmailinglist;
        item.EmailMailingList = entity.emailmailinglist;
        return item;
    }

    public static ToItemFromPromotion(entity: PromotionItem): ProfileItem {
        if (entity == null) return null;
        var item = new ProfileItem();
        item.Id = entity.CustomerId;
        item.Code = entity.Code;
        item.Phone = entity.Phone;
        item.BookerId = entity.BookerId;
        if (item.Phone) {
            while (item.Phone.indexOf(' ') >= 0)
                item.Phone = item.Phone.replace(' ', '');
            if (item.Phone.indexOf('0') == 0)
                item.Phone = item.Phone.substr(1);
        }
        item.Email = entity.Email;
        item.Message = entity.Message;
        item.LastName = entity.LastName;
        item.FirstName = entity.FirstName;
        item.DialingCode = entity.DialingCode;
        item.SMSMailingList = entity.SmsMailingList;
        item.EmailMailingList = entity.EmailMailingList;
        return item;
    }
}
