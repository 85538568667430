<div [id]="parentId" class="datetimeWapper" [ngClass]="valid ? 'valid' : 'error'">
    <div *ngIf="!inputIsIos" (clickOutside)="hidePanel()">
        <span class="span-datetime-input">
            <input type="text" autocomplete="off" [(ngModel)]="value" readonly="readonly" [disabled]="disabled"
                (click)="handleInputClick()" [placeholder]="hasLabel ? '' : placeholder"
                [ngClass]="{'edited' : value, 'hasLabel': hasLabel, 'hasIcon': hasIcon }"
                class="ui-input ui-datetime-input ui-inputtext ui-corner-all ui-state-default ui-widget">
            <label *ngIf="hasLabel">{{ placeholder }}</label>
        </span>
        <span *ngIf="hasIcon" class="ui-icon">
            <img [src]="icon" width="28" height="28" />
        </span>
        <span *ngIf="iconRemove && checkSelectedItem()" class="ui-icon icon-second" (click)="handleClearClick()">
            <span [ngClass]="iconRemove">x</span>
        </span>        
        <span *ngIf="!disabled" class="ui-icon icon-last" (click)="handleInputClick()">
            <span class="ui-icon-dropdown glyphicon" [ngClass]="panelVisible ? glyphiconUp : glyphiconDown"></span>
        </span>
        <div class="ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all ui-shadow"
            [ngClass]="{'active' : panelVisible }">
            <div class="ui-header" *ngIf="showHeader">
                <a [ngClass]="{'active': pickType == PickupType.Now}" (click)="changePickType(PickupType.Now)">Asap</a>
                <a [ngClass]="{'active': pickType == PickupType.Later}"
                    (click)="changePickType(PickupType.Later)">Later</a>
            </div>
            <div [style.display]="pickType == PickupType.Later ? '' : 'none'">
                <div class="ui-minutepicker-header ui-widget-header ui-helper-clearfix ui-corner-all"
                    *ngIf="showHeaderMinute">
                    <a (click)="quickSetMinute(15)" [ngClass]="{'disabled' : minute > 15, 'active': active15 }">15
                        Min</a>
                    <a (click)="quickSetMinute(30)" [ngClass]="{'disabled' : minute > 30, 'active': active30 }"
                        style="margin: 0 15px;">30 Min</a>
                    <a (click)="quickSetMinute(60)" [ngClass]="{'disabled' : minute > 60, 'active': active60 }">60
                        Min</a>
                </div>
                <div class="col-xs-12 col-sm-7 col-md-7 ui-date-content">
                    <div class="section-date-inner">
                        <div *ngIf="dateTitle">
                            <div class="ui-date-title">{{ dateTitle }}</div>
                        </div>
                        <div class="datepickerinline"></div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-5 col-md-5 ui-time-content" [ngClass]="{'hasWaitingTime':showWaitingTime}">
                    <div class="section-time-inner">
                        <div *ngIf="timeTitle">
                            <div class="ui-time-title">{{ timeTitle }}</div>
                        </div>
                        <div class="ui-timepicker ui-widget-header">
                            <div class="col-xs-6 col-sm-6 col-md-6" style="padding: 0; padding-left: 10px;">
                                <p>Hour</p>
                                <a class="ui-hour-up" (click)="incrementHour()">
                                    <span class="glyphicon glyphicon-menu-up"></span>
                                </a>
                                <div class="ui_tpicker_hour_slider">
                                    <select class="span-number" (change)="changeHour($event.target.value)">
                                        <option *ngFor="let item of hours"
                                            [attr.selected]="item == valueHour ? true : null" [value]="item">
                                            {{ item | number : '2.0' }}</option>
                                    </select>
                                </div>
                                <a class="ui-hour-down" (click)="decrementHour()">
                                    <span class="glyphicon glyphicon-menu-down"></span>
                                </a>
                            </div>
                            <div class="col-xs-6 col-sm-6 col-md-6" style="padding: 0; padding-right: 10px;">
                                <p>Minute</p>
                                <a class="ui-minute-up" (click)="incrementMinute()">
                                    <span class="glyphicon glyphicon-menu-up"></span>
                                </a>
                                <div class="ui_tpicker_minute_slider">
                                    <select class="span-number" (change)="changeMinute($event.target.value)">
                                        <option *ngFor="let item of minutes"
                                            [attr.selected]="item == valueMinute ? true : null" [value]="item">
                                            {{ item | number : '2.0' }}</option>
                                    </select>
                                </div>
                                <a class="ui-hour-down" (click)="decrementMinute()">
                                    <span class="glyphicon glyphicon-menu-down"></span>
                                </a>
                            </div>
                            <div style="clear: both;"></div>
                        </div>
                        <div class="ui-waitingtime" *ngIf="showWaitingTime">
                            <p>Affter how many minutes to meet the driver from the landing time?</p>
                            <div>
                                <select class="span-number" (change)="changeWaitingMinute($event.target.value)">
                                    <option *ngFor="let item of waitingMinutes"
                                        [attr.selected]="item == valueWaitingMinute ? true : null" [value]="item">
                                        {{ item | number : '2.0' }}</option>
                                </select>
                                <span>minutes after flight has landed</span>
                            </div>
                        </div>
                        <div *ngIf="layoutType == 'triox'">
                            <div class="ui-footer">
                                <a (click)="onSelectDate()">Done</a>
                                <a (click)="hidePanel()" class="btnCancel">Close</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="clear: both;"></div>
                <div class="ui-footer" *ngIf="layoutType != 'triox'">
                    <a (click)="onSelectDate()">OK</a>
                    <a (click)="hidePanel()" class="btnCancel">Cancel</a>
                </div>
            </div>
        </div>
        <div class="hint-invalid" *ngIf="!valid && invalidMessage"><span>{{ invalidMessage }}</span></div>
    </div>
    <div *ngIf="inputIsIos">
        <span class="ui-icon" *ngIf="hasIcon">
            <img [src]="icon" />
        </span>
        <input autocomplete="off" [(ngModel)]="date" [id]="editorId" style="width: 100%;" type="datetime-local"
            [disabled]="disabled" [class]="classInput" [placeholder]="placeholder" (input)="onInput($event.target)"
            (change)="onDateChange($event.target.value)" />
        <div class="hint-invalid" *ngIf="!valid && invalidMessage"><span>{{ invalidMessage }}</span></div>
    </div>
</div>