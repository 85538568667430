export class ApiHelper {
    public static apiPrices = '/api/prices';
    public static apiSignIn = '/api/signIn';
    public static apiSignUp = '/api/signUp';
    public static apiComment = '/api/comment';
    public static apiDirection = '/api/direction';
    public static apiQuotation = '/api/quotation';
    public static apiPromoCode = '/api/promocode';
    public static apiPromotion = '/api/promotion';
    public static apiBookingChart = '/api/booking';
    public static apiSearchFlight = '/api/flightinfo';
    public static apiAddressBook = '/api/addressbook';
    public static apiBookingInfo = '/api/bookinginfo';
    public static apiDriverPhoto  = '/api/driverphoto';
    public static apiEmailReceipt = '/api/emailreceipt';
    public static apiVehicleTypes = '/api/vehicletypes';
    public static apiSavePassword = '/api/savepassword';
    public static apiTrackVehicle = '/api/trackvehicle';
    public static apiSearchAirport = '/api/searchairport';
    public static apiSearchAirline = '/api/searchairline';
    public static apiSearchAddress = '/api/searchaddress';
    public static apiNearestDriver = '/api/nearestdriver';
    public static apiConfiguration = '/api/configuration';
    public static apiResetPassword = '/api/resetpassword';
    public static apiUpdateProfile = '/api/updateprofile';
    public static apiCancelBooking = '/api/cancelbooking';
    public static apiUpdateBooking = '/api/updatebooking';
    public static apiBookerInvoice = '/api/bookerinvoice';
    public static apiPaymentIntent = '/api/paymentintent';
    public static apiBookingRequest = '/api/bookingrequest';
    public static apiChangePassword = '/api/changepassword';
    public static apiJourneyHistory = '/api/journeyhistory';
    public static apiBookingDetails = '/api/bookingdetails';
    public static apiCurrentBookings = '/api/currentbookings';
    public static apiBookerStatement = '/api/bookerstatement';
    public static apiSearchAdvanceFlight = '/api/searchflight';
    public static apiDriverFootprint  = '/api/driverFootprint';
    public static apiElectronicPayment = '/api/electronicpayment';
    public static apiElectronicPaymentBooking = '/api/createbooking';
    public static apiBookerInvoiceReport = '/api/bookerinvoicereport';
    public static apiStripePaymentIntent = '/api/stripepaymentintent';
    public static apiSearchNationalAddress = '/api/searchnationaladdress';
    public static apiElectronicPaymentBookingTest = '/api/createbookingTest';

    public static apiDomainValidate = '/api/validate';
    public static apiBookingParams = '/api/bookingparams';
    public static apiCustAppBaseUrl = '/api/custappbaseurl';
}