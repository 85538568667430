export class SignIn {
    public Email: string;
    public Password: string;
    public PasswordHash: string;
    public RememberMe: boolean = false;
    constructor() {
        this.Email = '';
        this.Password = '';
        this.PasswordHash = '';
        this.RememberMe = false;
    }
}