<div [id]="parentId" class="dropdownWapper" [ngClass]="valid ? 'valid' : 'error'">
    <div (clickOutside)="hidePanel()">
        <div class="ui-input ui-dropdown ui-widget ui-state-default ui-corner-all ui-helper-clearfix" 
            [ngClass]="{'notpadding': !icon && !iconFirst}">
            <label *ngIf="innerHtml" class="ui-dropdown-label ui-inputtext ui-corner-all" [innerHTML]="innerHtml" (click)="handleInputClick()">
            </label>
            <label *ngIf="!innerHtml" class="ui-dropdown-label ui-dropdown-placeholder" (click)="handleInputClick()">
                {{ placeholder }}
            </label>
            <span class="ui-icon" *ngIf="icon || iconFirst">
                <img [src]="icon || iconFirst" />
            </span>
            <span class="ui-icon icon-last" (click)="handleInputClick()">
                <span class="ui-icon-dropdown glyphicon" [ngClass]="panelVisible ? glyphiconUp : glyphiconDown"></span>
            </span>
            <div *ngIf="!alwayHidePanel" class="ui-dropdown-panel ui-widget-content ui-corner-all ui-helper-hidden ui-shadow" [ngClass]="{'active' : panelVisible, 'top' : position == PositionType.Top }">
                <ul class="ui-dropdown-items ui-dropdown-list ui-widget-content ui-widget ui-corner-all ui-helper-reset" *ngIf="items">
                    <li class="ui-dropdown-item ui-corner-all" *ngFor="let item of items" (click)="onSelectedChange(item)" [ngClass]="{'ui-state-highlight' : item.selected }">
                        <div [innerHTML]="item.label"></div>
                    </li>
                </ul>
            </div>
            <div class="hint-invalid"><span>{{ invalidMessage }}</span></div>
        </div>
    </div>
</div>