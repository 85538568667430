declare var jQuery: any;
declare var google: any;
declare var Stripe: any;
declare var ClientJS: any;
declare var Microsoft: any;
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { ApiService } from './apiService';
import { BaseItem } from '../domain/base';
import { AppConfig } from './../app.config';
import { IpInfoItem } from '../domain/ipinfo';
import { Contact } from './../domain/contact';
import { PromoItem } from './../domain/promo';
import { SignIn } from '../domain/object/signin';
import { SignUp } from '../domain/object/signup';
import { MapType } from '../domain/enum/map.type';
import * as EncBase64 from 'crypto-js/enc-base64';
import { PaymentItem } from './../domain/payment';
import { AddressItem } from './../domain/address';
import { ProfileItem } from './../domain/profile';
import { TimeLineItem } from '../domain/timeline';
import { Injectable, NgZone } from '@angular/core';
import * as HmacSHA256 from 'crypto-js/hmac-sha256';
import { TimeItem } from '../domain/object/timeitem';
import { PickupType } from '../domain/enum/pickup.type';
import { DataService, EventBooking } from './dataService';
import { Dialog, ConfirmDialog } from './../domain/dialog';
import { ConstantHelper } from '../helper/constant.helper';
import { ConfigurationItem } from '../domain/configuration';
import { environment } from '../../environments/environment';
import { Flight, FlightResultItem } from './../domain/flight';
import { FormatCurrencyHelper } from '../helper/format.currency.helper';
import { AutocompleteSearchType } from '../domain/enum/autocomplete.type';
import { Booking, BookingHistoryItem, UpdateBooking } from './../domain/booking';
import { VehicleItem, TrackVehicle, TrackVehicleItem, Vehicle } from './../domain/vehicle';
import { ElectronicBookingItem, ElectronicBookingStripeItem } from './../domain/electronic';


@Injectable()
export class UtilService {
    constructor(
        public ngZone: NgZone,
        public router: Router,
        public data: DataService,
        public service: ApiService) {
    }

    GetPageTitle(title: string) {
        if (!title || title.length <= 1) return AppConfig.TITLE;
        if (title == this.data.PAGE_TYPE.HOME) title = 'Home';
        else if (title == this.data.PAGE_TYPE.INVOICE) title = 'Invoice';
        else if (title == this.data.PAGE_TYPE.BOOKING) title = 'Booking';
        else if (title == this.data.PAGE_TYPE.PAYMENT) title = 'Payment';
        else if (title == this.data.PAGE_TYPE.DASHBOARD) title = 'Dashboard';
        else if (title == this.data.PAGE_TYPE.CONFIRMED) title = 'Confirmed';
        else if (title == this.data.PAGE_TYPE.UPDATEADDRESS) title = 'Places';
        else if (title == this.data.PAGE_TYPE.BOOKINGINFO) title = 'Booking Info';
        else if (title == this.data.PAGE_TYPE.BOOKINGHISTORY) title = 'My Booking';
        else if (title == this.data.PAGE_TYPE.QUICKBOOKING) title = 'Quick Booking';
        else if (title == this.data.PAGE_TYPE.RESETPASSWORD) title = 'Reset Password';
        else if (title == this.data.PAGE_TYPE.BOOKINGDETAIL) title = 'Booking Detail';
        else if (title == this.data.PAGE_TYPE.CHANGEPASSWORD) title = 'Change Password';
        else if (title == this.data.PAGE_TYPE.CONFIRMPASSWORD) title = 'Confirm Password';
        else if (title == this.data.PAGE_TYPE.COMMISSIONSTATEMENT) title = 'Commission Statement';
        title = title.substr(0, 1).toUpperCase() + title.substr(1);
        return AppConfig.TITLE + ' - ' + title;
    }
    ToDomain(url: string): string {
        url = (url && url.length > 0) ? url.match(/:\/\/(.[^/]+)/)[1] : '';
        url = url.replace('www.', '');
        return url;
    }
    ToDateString(date: Date): string {
        if (!date) return '';
        if (date == null) return '';
        if (typeof date == 'string')
            date = new Date(date);

        let message = '';
        let month = date.getMonth() + 1;
        message += (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
        message += '/' + (month < 10 ? '0' + month : month);
        message += '/' + date.getFullYear();
        return message;
    }
    ToTimeString(date: Date): string {
        if (!date) return '';
        if (date == null) return '';
        if (typeof date == 'string')
            date = new Date(date);

        let message = '';
        message += ' ' + (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
        message += ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
        return message;
    }
    ToDateTimeString(date: Date): string {
        if (!date) return '';
        if (date == null) return '';
        if (typeof date == 'string')
            date = new Date(date);

        let message = '';
        let month = date.getMonth() + 1;
        message += (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
        message += '/' + (month < 10 ? '0' + month : month);
        message += '/' + date.getFullYear();
        message += ' ' + (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
        message += ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
        return message;
    }
    ToQueryParam(url: string, key: string): string {
        if (!key || key.length == 0) return '';
        key = key.replace(/[\[\]]/g, "\\$&");
        let regex = new RegExp("[?&]" + key + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    InitIpInfo() {
        if (!this.data.IpInfo) {
            let countryJson = localStorage.getItem(this.data.LOCALSTORE_TYPE.COUNTRY);
            if (countryJson) {
                let model: IpInfoItem = JSON.parse(countryJson);
                if (model) {
                    this.data.IpInfo = model;
                    this.data.CountryCode = model.Country;
                    this.data.CurrencyCode = ConstantHelper.COUNTRY_MAP[model.Country];
                    this.data.SymbolCode = ConstantHelper.CURRENCY_MAP[this.data.CurrencyCode];
                }
            }
        }

        if (!this.data.IpInfo) {
            this.service.getIpInfo().subscribe(model => {
                this.data.IpInfo = model;
                this.data.CountryCode = model.Country;
                this.data.CurrencyCode = ConstantHelper.COUNTRY_MAP[model.Country];
                this.data.SymbolCode = ConstantHelper.CURRENCY_MAP[this.data.CurrencyCode];
                localStorage.setItem(this.data.LOCALSTORE_TYPE.COUNTRY, JSON.stringify(model));
            });
        }
    }
    CloseSignlar() {
        if (this.data.SignalrConnection) {
            this.data.SignalrForceConnected = true;
            this.data.SignalrConnection.stop();
            this.data.SignalrConnection = null;
            setTimeout(() => this.data.SignalrForceConnected = false, 2000);
        }
    }
    InitDateRanges() {
        /// DateRanges
        if (this.data.DateRanges == null || this.data.DateRanges.length == 0) {
            this.data.DateRanges = [];
            this.data.DateRanges.push({ label: '<span>Past 3 months</span>', value: 3 });
            this.data.DateRanges.push({ label: '<span>Past 6 months</span>', value: 6 });
            this.data.DateRanges.push({ label: '<span>Past 12 months</span>', value: 12 });
            this.data.DateRanges.push({ label: '<span>All</span>', value: 0 });
        }
    }
    InitOptionPets() {
        let items: any = [];
        for (let i = 0; i <= 2; i++) {
            items.push({ label: i.toString(), value: i.toString() });
        }
        return items;
    }
    InitExpiryYears() {
        let items: any = [];
        let year = (new Date()).getFullYear();
        for (let i = 0; i <= 50; i++) {
            items.push({ label: year.toString(), value: year.toString() });
            year += 1;
        }
        return items;
    }
    InitExpiryMonths() {
        let items: any = [];
        for (let i = 1; i <= 12; i++) {
            let month = i < 10 ? '0' + i.toString() : i.toString();
            items.push({ label: month, value: month });
        }
        return items;
    }
    async InitPrices() {
        /// Prices
        if (this.data.Prices != null && this.data.Prices.length > 0) {
            if (this.data.Booking.Price == null) {
                this.data.Booking.Price = this.data.Prices[1];
            }
        } else {
            await this.service.prices().subscribe(model => {
                this.data.Prices = model;
                if (this.data.Booking.Price == null) {
                    this.data.Booking.Price = this.data.Prices[1];
                }
                this.data.Prices = this.data.Prices.slice(0, 3);
            });
        }
    }
    InitOptionChilds() {
        let items: any = [];
        for (let i = 0; i <= 2; i++) {
            items.push({ label: i.toString(), value: i.toString() });
        }
        return items;
    }
    InitVehicleTypes() {
        /// Vehicles
        if (this.data.Vehicles != null && this.data.Vehicles.length > 0) {
            if (this.data.Booking.Vehicle == null) {
                this.data.Booking.Vehicle = this.data.Vehicles[0];
                if (this.data.Booking.Vehicle)
                    this.data.Booking.VehicleId = this.data.Booking.Vehicle.Id;
            }
        } else {
            let config = environment.configJson;
            if (config) {
                let model = VehicleItem.ToItems(config.vehicles.vehTypes);
                this.data.Vehicles = model;
                this.data.VehicleItems = VehicleItem.ToDropDownItems(model);
                if (this.data.Booking.Vehicle == null) {
                    this.data.Booking.Vehicle = this.data.Vehicles[0];
                    if (this.data.Booking.Vehicle)
                        this.data.Booking.VehicleId = this.data.Booking.Vehicle.Id;
                }
            } else {
                this.service.vehicletypes().subscribe((model: VehicleItem[]) => {
                    this.data.Vehicles = model;
                    this.data.VehicleItems = VehicleItem.ToDropDownItems(model);
                    if (this.data.Booking.Vehicle == null) {
                        this.data.Booking.Vehicle = this.data.Vehicles[0];
                        if (this.data.Booking.Vehicle)
                            this.data.Booking.VehicleId = this.data.Booking.Vehicle.Id;
                    }
                });
            }
        }
    }
    async InitConfiguration() {
        if (this.data.Configuration == null || !this.data.Configuration.IsLoad) {
            let config = environment.configJson;
            if (config) {
                let model = ConfigurationItem.ToItem(config.configuration);
                this.data.Configuration = model;
                if (this.data.Configuration.MapSource == 'bing')
                    AppConfig.MAP_TYPE = MapType.Bing;
                else
                    AppConfig.MAP_TYPE = MapType.Google;
                this.InitPaymentTypeItems();
                this.data.SignInType = this.data.Configuration.ShowSignupFirst
                    ? this.data.SIGNIN_TYPE.SIGNUP
                    : this.data.SIGNIN_TYPE.SIGNIN;
                if (this.data.Booking != null)
                    this.data.Booking.PaymentType = this.data.Configuration.DefaultPaymentMethod
                        ? this.data.Configuration.DefaultPaymentMethod
                        : AppConfig.PAYMENT_TYPE;
            } else {
                this.service.configuration(this.data.SandBox, this.data.CurrencyCode)
                    .subscribe(model => {
                        this.data.Configuration = model;
                        if (this.data.Configuration.MapSource == 'bing')
                            AppConfig.MAP_TYPE = MapType.Bing;
                        else
                            AppConfig.MAP_TYPE = MapType.Google;
                        this.InitPaymentTypeItems();
                        this.data.SignInType = this.data.Configuration.ShowSignupFirst
                            ? this.data.SIGNIN_TYPE.SIGNUP
                            : this.data.SIGNIN_TYPE.SIGNIN;
                        if (this.data.Booking != null)
                            this.data.Booking.PaymentType = this.data.Configuration.DefaultPaymentMethod
                                ? this.data.Configuration.DefaultPaymentMethod
                                : AppConfig.PAYMENT_TYPE;
                        this.data.LoadedConfiguration = true;
                    });
            }
        }
    }
    InitOptionBags(max: number) {
        let items: any = [];
        for (let i = 0; i <= max; i++) {
            items.push({ label: i.toString(), value: i.toString() });
        }
        return items;
    }
    async InitPaymentTypeItems() {
        /// PaymentTypeItems
        if (this.data.PaymentTypeItems == null || this.data.PaymentTypeItems.length == 0) {
            this.data.PaymentTypeItems = [];
            if (this.data.Configuration.IsCashAllowed) {
                this.data.PaymentTypeItems.push({ label: '<img src="./assets/images/paymentmethod/cash.png"> ' + this.data.PAYMENT_TYPE.CASH.toUpperCase(), value: this.data.PAYMENT_TYPE.CASH });
            }
            if (this.data.Configuration.IsCardAllowed) {
                this.data.PaymentTypeItems.push({ label: '<img src="./assets/images/paymentmethod/003-credit-card.png"> ' + this.data.PAYMENT_TYPE.CARD.toUpperCase(), value: this.data.PAYMENT_TYPE.CARD });
            }
            if (this.data.Configuration.IsPaypalAllowed) {
                this.data.PaymentTypeItems.push({ label: '<img src="./assets/images/paymentmethod/paypal.png"> ' + this.data.PAYMENT_TYPE.PAYPAL.toUpperCase(), value: this.data.PAYMENT_TYPE.PAYPAL });
            }

            this.data.PaymentClassName = '';
            if (this.data.PaymentTypeItems) {
                this.data.PaymentClassName = this.data.PaymentTypeItems.length == 1
                    ? 'col-xs-12'
                    : this.data.PaymentTypeItems.length == 2 ? 'col-xs-6' : 'col-xs-3';
            }
            this.data.PaymentClassName += ' payment-item';
        }
    }
    InitSignlar(jobPartId?: number) {
        if (this.data.SignalrConnection)
            return;

        try {
            if (!this.data.Configuration ||
                !this.data.Configuration.ChatApiUrl)
                return;
            let profile = this.data.Profile,
                ipClient = this.data.IpInfo.Ip,
                connection = jQuery.hubConnection(),
                deviceClient = (new ClientJS()).getFingerprint(),
                customer = profile == null
                    ? jobPartId
                        ? 'customer_' + ipClient + '_' + deviceClient + '_jobPartId=' + jobPartId
                        : 'customer_' + ipClient + '_' + deviceClient
                    : 'customer_' + ipClient + '_' + deviceClient + '_' + profile.Id,
                api = this.data.Configuration.ChatApiUrl.replace('http:', '').replace('https:', '');

            // config
            connection.url = api;
            connection.qs = { 'userName': customer };

            // notify
            let notificationHubProxy = connection.createHubProxy('NotificationHub');
            notificationHubProxy.on('OnNewData', (type: any, data: any) => {
                if (type == "statusUpdate") {
                    let trackVehice = <TrackVehicle>JSON.parse(data),
                        trackVehiceItem = TrackVehicleItem.ToItem(trackVehice);
                    trackVehiceItem.SignalrType = type;
                    trackVehiceItem.CurrentLat = trackVehice.currentlat;
                    trackVehiceItem.CurrentLng = trackVehice.currentlng;
                    this.data.TrackVehicleUpdate.emit(trackVehiceItem);
                } else if (type == "locationUpdate") {
                    let trackVehice = <TrackVehicle>JSON.parse(data),
                        trackVehiceItem = TrackVehicleItem.ToItem(trackVehice);
                    trackVehiceItem.SignalrType = type;
                    trackVehiceItem.CurrentLat = trackVehice.currentlat;
                    trackVehiceItem.CurrentLng = trackVehice.currentlng;
                    this.data.TrackVehicleUpdate.emit(trackVehiceItem);
                }
            });

            // connection
            connection.error(() => {
                console.log('error');
                setTimeout(() => {
                    if (!this.data.SignalrForceConnected) {
                        connection.start({ withCredentials: false }).done((data: any) => {
                            console.log('Now connected ' + data.transport.name + ', connection ID = ' + data.id);
                            this.data.TrackVehicleUpdateApi.emit();
                        }).fail((error: any) => {
                            if (!this.data.SignalrForceConnected) {
                                let interval = setInterval(() => {
                                    connection.start({ withCredentials: false }).done((data: any) => {
                                        clearInterval(interval);
                                        console.log('Now connected ' + data.transport.name + ', connection ID = ' + data.id);
                                    });
                                }, 10000);
                            }
                        });
                    }
                }, 5000);
            });
            connection.reconnected(() => {
                console.log('reconnected');
                this.data.TrackVehicleUpdateApi.emit();
                notificationHubProxy.invoke('Client_Reconnected', customer);
            });
            connection.disconnected(() => {
                console.log('disconnected');
                setTimeout(() => {
                    if (!this.data.SignalrForceConnected) {
                        connection.start({ withCredentials: false }).done((data: any) => {
                            console.log('Now connected ' + data.transport.name + ', connection ID = ' + data.id);
                            this.data.TrackVehicleUpdateApi.emit();
                        }).fail((error: any) => {
                            if (!this.data.SignalrForceConnected) {
                                let interval = setInterval(() => {
                                    connection.start({ withCredentials: false }).done((data: any) => {
                                        clearInterval(interval);
                                        console.log('Now connected ' + data.transport.name + ', connection ID = ' + data.id);
                                    });
                                }, 10000);
                            }
                        });
                    }
                }, 5000);
            });
            connection.start({ withCredentials: false }).done((data: any) => {
                console.log('Now connected ' + data.transport.name + ', connection ID = ' + data.id);
            }).fail((error: any) => {
                if (!this.data.SignalrForceConnected) {
                    let interval = setInterval(() => {
                        connection.start({ withCredentials: false }).done((data: any) => {
                            clearInterval(interval);
                            console.log('Now connected ' + data.transport.name + ', connection ID = ' + data.id);
                        });
                    }, 10000);
                }
            });
            this.data.SignalrConnection = connection;
        } catch { }
    }
    InitOptionLuggages(max: number) {
        let items: any = [];
        for (let i = 0; i <= max; i++) {
            items.push({ label: i.toString(), value: i.toString() });
        }
        return items;
    }
    InitOptionPassengers(max: number) {
        let items: any = [];
        for (let i = 1; i <= max; i++) {
            items.push({ label: i.toString(), value: i.toString() });
        }
        return items;
    }

    async InitEditorAutocomplete(index: number, searchType: AutocompleteSearchType) {
        if (searchType == AutocompleteSearchType.Bing) {
            setTimeout(async () => await this.InitEditorBingAutocomplete(index), 500);
        } else if (searchType == AutocompleteSearchType.Google) {
            setTimeout(async () => await this.InitEditorGoogleAutocomplete(index), 500);
        }
    }
    async InitEditorCountries(editorId: string = null) {
        if (editorId == null) editorId = 'elementCountry';
        if (jQuery('#' + editorId)) {
            jQuery('#' + editorId).countrySelect({
                defaultCountry: 'gb',
                responsiveDropdown: true
            });
            if (this.data.IpInfo && this.data.IpInfo.Country) {
                jQuery('#' + editorId).countrySelect("selectCountry", this.data.IpInfo.Country.toLowerCase());
            }
        }
    }
    async InitEditorPhone(editorId: string, dialCode: string, phone: string) {
        if (jQuery('#' + editorId).length > 0) {
            jQuery('#' + editorId).intlTelInput({
                autoFormat: false,
                customPlaceholder: '',
                initialCountry: 'auto',
                separateDialCode: true,
                autoPlaceholder: false,
                autoHideDialCode: false,
            });
            if (dialCode || phone) {
                phone = (phone.indexOf('+') == 0 || !dialCode) ? phone : (dialCode + phone);
                jQuery('#' + editorId).intlTelInput("setNumber", phone);
                let index = 0;
                let interval = setInterval(() => {
                    index += 1;
                    if (index >= 3)
                        clearInterval(interval);

                    if (jQuery('#' + editorId).length > 0) {
                        let value = jQuery('#' + editorId).val();
                        while (value.indexOf(' ') >= 0) value = value.replace(' ', '');
                        jQuery('#' + editorId).val(value);
                    }
                }, 1000);
            } else {
                if (this.data.IpInfo && this.data.IpInfo.Country) {
                    jQuery("#" + editorId).intlTelInput("setCountry", this.data.IpInfo.Country.toLowerCase());
                } else {
                    jQuery("#" + editorId).intlTelInput("setCountry", 'gb');
                }
            }
        }
    }
    InitAddressBooks(start: number, end: number, max: number = 0): Array<AddressItem> {
        let addressBooks: any = [];
        if (this.data.AddressBooks == null) return new Array<AddressItem>();

        /// AddressHome
        let addressHome: AddressItem;
        let addressHomes = this.data.AddressBooks.filter(item => {
            return item.PlaceName != null &&
                item.PlaceName.toLowerCase() == this.data.ADDRESS_TYPE.HOME.toLowerCase();
        });
        if (addressHomes != null && addressHomes.length > 0)
            addressHome = addressHomes[0];
        else {
            addressHome = new AddressItem();
            addressHome.PlaceName = this.data.ADDRESS_TYPE.HOME;
        }
        addressHome.IconPath = 'img/house.png';
        addressBooks.push(addressHome);

        /// AddressWork
        let addressWork: AddressItem;
        let addressWorks = this.data.AddressBooks.filter(item => {
            return item.PlaceName != null &&
                item.PlaceName.toLowerCase() == this.data.ADDRESS_TYPE.WORK.toLowerCase();
        });
        if (addressWorks != null && addressWorks.length > 0)
            addressWork = addressWorks[0];
        else {
            addressWork = new AddressItem();
            addressWork.PlaceName = this.data.ADDRESS_TYPE.WORK;
        }
        addressWork.IconPath = 'img/commercial.png';
        addressBooks.push(addressWork);

        /// Address other
        if (end > this.data.AddressBooks.length) end = this.data.AddressBooks.length;
        for (let i = start; i < end; i++) {
            if (max != 0 && addressBooks.length >= max) break;
            if (this.data.AddressBooks[i].PlaceName != null &&
                this.data.AddressBooks[i].PlaceName.length != 0) continue;
            addressBooks.push(this.data.AddressBooks[i]);
        }
        return addressBooks;
    }

    private async InitEditorBingAutocomplete(index: number) {
        Microsoft.Maps.loadModule('Microsoft.Maps.AutoSuggest', {
            callback: () => {
                let options = {
                    maxResults: 10,
                };

                let control = '';
                if (index == -1) control = '#pickup-google';
                else if (index == 100) control = '#dropoff-google';
                else control = '#via-google-' + (index + 1).toString();

                let controlValid = '';
                if (index == -1) controlValid = '#pickup-invalid';
                else if (index == 100) controlValid = '#dropoff-invalid';
                else controlValid = '#via-invalid-' + (index + 1).toString();

                let controlContainer = '';
                if (index == -1) controlContainer = '#pickup-google-container';
                else if (index == 100) controlContainer = '#dropoff-google-container';
                else controlContainer = '#via-google-' + (index + 1).toString() + '-container';

                let manager = new Microsoft.Maps.AutosuggestManager(options);
                manager.attachAutosuggest(control, controlContainer, (data: any) => {
                    let place = data;
                    place.index = index;
                    place.Fulladdress = data.address.formattedAddress;
                    AddressItem.ToPlaceBingItem(place, this.data.Configuration).then((item: AddressItem) => {
                        this.SelectedItem(item, item.Index);
                    });

                    jQuery(controlValid).hide();
                    if (index == -1) {
                        if (this.data.Booking.PickUp != null && this.data.Booking.PickUp.Valid) {
                            jQuery(control).val(this.data.Booking.PickUp.Fulladdress);
                        }
                    } else if (index == 100) {
                        if (this.data.Booking.PickDown != null && this.data.Booking.PickDown.Valid) {
                            jQuery(control).val(this.data.Booking.PickDown.Fulladdress);
                        }
                    } else if (index >= 0) {
                        if (this.data.Booking.ViaAddress[index] != null && this.data.Booking.ViaAddress[index].Valid) {
                            jQuery(control).val(this.data.Booking.ViaAddress[index].Fulladdress);
                        }
                    }
                });
            },
        });
    }
    private async InitEditorGoogleAutocomplete(index: number) {
        let latLng = {
            lat: 54.093409,
            lng: -2.89479
        };
        let circle = new google.maps.Circle({
            radius: 775000,
            center: latLng
        });
        let options = {
            // componentRestrictions: {
            //     country: ['at', 'gb', 'fr', 'de', 'hu', 'ie', 'be', 'dk', 'it', 'si', 'sk', 'pl', 'nl', 'ro', 'es', 'pt', 'se', 'fi', 'mt', 'cz', 'gr', 'ee', 'lu', 'lt', 'lv', 'cy', 'bg']
            // }
        };
        let found = false;
        let interval = window.setInterval(() => {
            if (found) {
                window.clearInterval(interval);
                return;
            }

            if (index == -1) {
                if (jQuery('#pickup-google').length > 0) {
                    found = true;
                    let pickup = new google.maps.places.Autocomplete(document.getElementById('pickup-google'), options);
                    pickup.setBounds(circle.getBounds());

                    google.maps.event.addListener(pickup, 'place_changed', () => {
                        google.maps.event.trigger(this, 'focus');
                        let place = pickup.getPlace();
                        place.index = index;
                        place.Fulladdress = jQuery('#pickup-google').val();
                        this.ngZone.run(() => {
                            AddressItem.ToPlaceGoogleItem(place, this.data.Configuration).then((item: AddressItem) => {
                                this.SelectedItem(item, item.Index);
                            });
                        });
                    });
                    jQuery('#pickup-google').change(() => {
                        jQuery('#pickup-invalid').hide();
                    });
                    if (this.data.Booking.PickUp != null && this.data.Booking.PickUp.Valid) {
                        jQuery('#pickup-google').val(this.data.Booking.PickUp.Fulladdress);
                    }
                }
            } else if (index == 100) {
                if (jQuery('#dropoff-google').length > 0) {
                    found = true;
                    let dropoff = new google.maps.places.Autocomplete(document.getElementById('dropoff-google'), options);
                    dropoff.setBounds(circle.getBounds());

                    google.maps.event.addListener(dropoff, 'place_changed', () => {
                        let place = dropoff.getPlace();
                        place.index = index;
                        place.Fulladdress = jQuery('#dropoff-google').val();
                        this.ngZone.run(() => {
                            AddressItem.ToPlaceGoogleItem(place, this.data.Configuration).then((item: AddressItem) => {
                                this.SelectedItem(item, item.Index);
                            });
                        });
                    });
                    jQuery('#dropoff-google').change(() => {
                        jQuery('#dropoff-invalid').hide();
                    });
                    if (this.data.Booking.PickDown != null && this.data.Booking.PickDown.Valid) {
                        jQuery('#dropoff-google').val(this.data.Booking.PickDown.Fulladdress);
                    }
                }
            } else if (index >= 0) {
                found = true;
                if (this.data.Booking.ViaAddress && this.data.Booking.ViaAddress.length > 0) {
                    let id = 'via-google-' + (index + 1).toString();
                    if (jQuery('#' + id).length > 0) {
                        let via = new google.maps.places.Autocomplete(document.getElementById(id), options);
                        via.setBounds(circle.getBounds());

                        google.maps.event.addListener(via, 'place_changed', () => {
                            let place = via.getPlace();
                            place.index = index;
                            place.Fulladdress = jQuery('#' + id).val();
                            this.ngZone.run(() => {
                                AddressItem.ToPlaceGoogleItem(place, this.data.Configuration).then((item: AddressItem) => {
                                    this.SelectedItem(item, item.Index);
                                });
                            });
                        });
                        jQuery('#' + id).change(() => {
                            jQuery('#via-invalid-' + id).hide();
                        });
                        if (this.data.Booking.ViaAddress[index] != null && this.data.Booking.ViaAddress[index].Valid)
                            jQuery('#' + id).val(this.data.Booking.ViaAddress[index].Fulladdress);
                    }
                }
            }
        }, 500);
    }

    private InitMapStyles() {
        let styledMapType = new google.maps.StyledMapType(
            [
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e9e9e9"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 29
                        },
                        {
                            "weight": 0.2
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 18
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dedede"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "saturation": 36
                        },
                        {
                            "color": "#333333"
                        },
                        {
                            "lightness": 40
                        }
                    ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        },
                        {
                            "lightness": 19
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#fefefe"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#fefefe"
                        },
                        {
                            "lightness": 17
                        },
                        {
                            "weight": 1.2
                        }
                    ]
                }
            ],
            { name: 'Styled Map' });
        return styledMapType;
    }
    private InitInfowindow() {
        if (AppConfig.MAP_TYPE == MapType.Google) {
            return new google.maps.InfoWindow({ size: new google.maps.Size(150, 50) });
        } else {
            return new Microsoft.Maps.Infobox(this.data.Map.getCenter(), {
                visible: false
            });
        }
    }
    private ShiftRight(elementId: string) {
        if (!this.CheckExistsMap(elementId)) return;

        let map = this.data.Map;
        let markers = this.data.Markers;
        if (jQuery(window).width() > 991 && jQuery('#form').length > 0) {
            let overlay = new google.maps.OverlayView();
            overlay.draw = function () { };
            overlay.onAdd = function () {
                const arrayMin = function arrayMin(arr: any) {
                    let len = arr.length, min = Infinity;
                    while (len--) {
                        if (arr[len] < min) {
                            min = arr[len];
                        }
                    }
                    return min;
                };
                const arrayMax = function arrayMax(arr: any) {
                    let len = arr.length, max = -Infinity;
                    while (len--) {
                        if (arr[len] > max) {
                            max = arr[len];
                        }
                    }
                    return max;
                };

                let projection = this.getProjection();
                let lats: any = [];
                markers.forEach(function (object: any) {
                    lats.push(projection.fromLatLngToDivPixel(object.position).x);
                });
                let shift = 0;
                let min = arrayMin(lats);
                let max = arrayMax(lats);
                let boundWidth = Math.abs(max - min);
                let mapWidth = jQuery('#' + elementId).outerWidth();
                let mapHeight = jQuery('#' + elementId).outerHeight();
                let current = { x: Math.round(mapWidth / 2), y: Math.round(mapHeight / 2) };
                let outerWidth = jQuery('#form').offset().left + jQuery('#form').outerWidth();

                shift = lats.length <= 1
                    ? Math.round(outerWidth / 4)
                    : Math.round(outerWidth / 2) + boundWidth / 2;
                map.setCenter(projection.fromContainerPixelToLatLng({ x: current.x - shift, y: current.y }));
                if (max + shift > mapWidth || min + shift > mapWidth) {
                    map.setZoom(map.getZoom() - 1);
                }
            };
            overlay.setMap(map);
        }
    }
    public BoundMarkers(elementId: string) {
        if (!this.CheckExistsMap(elementId)) return;
        if (AppConfig.MAP_TYPE == MapType.Google) {
            if (this.data.Markers != null && this.data.Markers.length > 0) {
                let bounds = new google.maps.LatLngBounds();
                this.data.Markers.forEach((object: any) => {
                    bounds.extend(object.position);
                });
                if (this.data.Map) {
                    this.data.Map.fitBounds(bounds);
                    google.maps.event.addListenerOnce(this.data.Map, 'bounds_changed', () => {
                        this.data.Map.setZoom(this.data.Map.getZoom());
                        if (this.data.Map.getZoom() > this.data.MAP_MAX_ZOOM) {
                            this.data.Map.setZoom(this.data.MAP_MAX_ZOOM);
                        }
                    });
                }
            }
        } else {
            if (this.data.Markers != null && this.data.Markers.length > 0) {
                let bounds: any[] = [];
                this.data.Markers.forEach((object: any) => {
                    bounds.push(object.getLocation());
                });
                let rect = Microsoft.Maps.LocationRect.fromLocations(bounds);
                this.data.Map.setView({ bounds: rect, padding: 80 });
            }
        }
    }
    public ResizeMap(heightExpand: number = 300) {
        if (AppConfig.MAP_TYPE == MapType.Google) {
            if (this.data.Map == null) return;
            if (!this.CheckExistsMap(this.data.MAP_ELEMENT.MAP)) return;

            let that = this;
            setTimeout(function () {
                if (jQuery(window).width() > 991) {
                    /// Check scroll time
                    if (jQuery('#stickyTime').length > 0) {
                        let scrollTop = jQuery(window).scrollTop() + 81;
                        let top = jQuery('#stickyTime').offset().top;
                        let pickTime = scrollTop > top;

                        /// Scroll
                        if (pickTime) {
                            top = jQuery('#stickyTime').offset().top - 81;
                            if (jQuery(window).scrollTop() == 0) top -= 81;
                            jQuery('body,html').scrollTop(top);
                        }
                    }

                    /// Resize
                    let height = jQuery('#pickup-container #form').outerHeight() + 60 + heightExpand;
                    if (jQuery('.dropdown-more-options').length > 0) {
                        if (jQuery('.dropdown-more-options').is(':visible')) {
                            height += 180;
                        }
                    }
                    if (height > 870) {
                        jQuery('#map').height(height);
                        google.maps.event.trigger(that.data.Map, 'resize');
                        jQuery('#pickup-container').innerHeight(height);
                    } else {
                        jQuery('#map').height(870);
                        google.maps.event.trigger(that.data.Map, 'resize');
                        jQuery('#pickup-container').innerHeight(height);
                    }
                }
            }, 10);
        }
    }
    public BoundRoute(elementId: string, coordinates: any) {
        if (!this.CheckExistsMap(elementId)) return;
        if (AppConfig.MAP_TYPE == MapType.Google) {
            if (coordinates && coordinates.length > 0) {
                let bounds = new google.maps.LatLngBounds();
                coordinates.forEach((object: any) => {
                    bounds.extend(object);
                });
                if (this.data.Map) {
                    this.data.Map.fitBounds(bounds);
                    google.maps.event.addListenerOnce(this.data.Map, 'bounds_changed', () => {
                        this.data.Map.setZoom(this.data.Map.getZoom());
                        if (this.data.Map.getZoom() > this.data.MAP_MAX_ZOOM) {
                            this.data.Map.setZoom(this.data.MAP_MAX_ZOOM);
                        }
                    });
                }
            }
        } else {
            if (coordinates && coordinates.length > 0) {
                let bounds: any[] = [];
                coordinates.forEach((object: any) => {
                    bounds.push({
                        latitude: object.lat,
                        longitude: object.lng,
                    });
                });
                if (this.data.Map) {
                    let rect = Microsoft.Maps.LocationRect.fromLocations(bounds);
                    this.data.Map.setView({ bounds: rect, padding: 80 });
                }
            }
        }
    }
    public DrawRoute(elementId: string, booking: Booking): any {
        this.ClearRoute(elementId, true, false);
        if (!this.CheckExistsMap(elementId)) return this.data.FlightPath;

        if (booking == null) return this.data.FlightPath;
        if (this.data.Map == null) return this.data.FlightPath;
        if (booking.Quotation == null) return this.data.FlightPath;
        if (booking.Quotation.RoutePath == null) return this.data.FlightPath;
        if (booking.Quotation.RoutePath.length == 0) return this.data.FlightPath;
        if (booking.PickUp == null || !booking.PickUp.Valid) return this.data.FlightPath;
        if (booking.PickDown == null || !booking.PickDown.Valid) return this.data.FlightPath;
        if (booking.ViaAddress != null && booking.ViaAddress.length > 0) {
            for (let i = 0; i < booking.ViaAddress.length; i++) {
                if (booking.ViaAddress[i] == null || !booking.ViaAddress[i].Valid) {
                    return this.data.FlightPath;
                }
            }
        }

        let flightPath: any,
            coordinates: any = [];
        booking.Quotation.RoutePath.forEach(element => {
            coordinates.push({ lat: element.Latitude, lng: element.Longitude });
        });
        if (AppConfig.MAP_TYPE == MapType.Google) {
            flightPath = new google.maps.Polyline({
                strokeWeight: 3,
                path: coordinates,
                strokeOpacity: 1.0,
                strokeColor: environment.name == 'triox' ? '#808080' : '#063D62',
            });
            flightPath.setMap(this.data.Map);
        } else {
            flightPath = new Microsoft.Maps.Polyline(coordinates.map((c: any) => {
                return new Microsoft.Maps.Location(c.lat, c.lng)
            }), {
                strokeThickness: 3,
                strokeColor: environment.name == 'triox' ? '#808080' : '#063D62',
            });
            flightPath.pathType = 'onward';
            this.data.Map.entities.push(flightPath);
        }
        return flightPath;
    }
    public UpdateMarkerLabel(address: AddressItem, marker: any) {
        if (marker == null || address == null || this.data.Map == null)
            return;

        if (AppConfig.MAP_TYPE == MapType.Google) {
            let label = address.Title ? {
                color: '#000000',
                text: address.Title,
                className: 'marker-label',
            } : null
            marker.setLabel(label);
        } else {

        }
    }
    public UpdateMarkerPosition(address: AddressItem, marker: any) {
        if (marker == null || address == null || this.data.Map == null) return;
        if (AppConfig.MAP_TYPE == MapType.Google) {
            marker.setPosition({
                lat: address.Latitude,
                lng: address.Longitude
            });
            this.data.Map.setCenter(marker.getPosition());
        } else {
            marker.setLocation(new Microsoft.Maps.Location(address.Latitude, address.Longitude));
        }
    }
    public DrawReturnRoute(elementId: string, booking: Booking): any {
        this.ClearRoute(elementId, false, true);
        if (!this.CheckExistsMap(elementId)) return this.data.FlightReturnPath;

        if (booking == null) return this.data.FlightReturnPath;
        if (this.data.Map == null) return this.data.FlightReturnPath;
        if (booking.Quotation == null) return this.data.FlightReturnPath;
        if (booking.Quotation.ReturnRoutePath == null) return this.data.FlightReturnPath;
        if (booking.Quotation.ReturnRoutePath.length == 0) return this.data.FlightReturnPath;
        if (booking.PickUp == null || !booking.PickUp.Valid) return this.data.FlightReturnPath;
        if (booking.PickDown == null || !booking.PickDown.Valid) return this.data.FlightReturnPath;
        if (booking.ViaAddress != null && booking.ViaAddress.length > 0) {
            for (let i = 0; i < booking.ViaAddress.length; i++) {
                if (booking.ViaAddress[i] == null || !booking.ViaAddress[i].Valid) {
                    return this.data.FlightReturnPath;
                }
            }
        }

        let flightPath: any,
            coordinates: any = [];
        booking.Quotation.ReturnRoutePath.forEach(element => {
            coordinates.push({ lat: element.Latitude, lng: element.Longitude });
        });

        if (AppConfig.MAP_TYPE == MapType.Google) {
            flightPath = new google.maps.Polyline({
                strokeWeight: 1.5,
                path: coordinates,
                strokeOpacity: 1.0,
                strokeColor: '#3699DD',
            });
            flightPath.setMap(this.data.Map);
        } else {
            flightPath = new Microsoft.Maps.Polyline(coordinates.map((c: any) => {
                return new Microsoft.Maps.Location(c.lat, c.lng)
            }), {
                strokeThickness: 3,
                strokeColor: '#3699DD',
            });
            flightPath.pathType = 'return';
            this.data.Map.entities.push(flightPath);
        }
        return flightPath;
    }
    public DrawMarker(elementId: string, address: AddressItem, i: number): any {
        if (this.data.Map == null) return null;
        if (!this.CheckExistsMap(elementId)) return null;
        if (this.data.Infowindow == null) this.data.Infowindow = this.InitInfowindow();

        let image: any;
        if (i == null || i == 9999) {
            image = {
                url: AppConfig.ASSETS + 'icons/map/vehicle.png',
            };
        } else if (i == -1) {
            image = {
                url: AppConfig.ASSETS + 'icons/map/pickup2.png',
            };
        } else if (i == 100) {
            image = {
                url: AppConfig.ASSETS + 'icons/map/dropoff2.png',
            };
        } else {
            image = {
                url: AppConfig.ASSETS + 'icons/map/via' + (i + 1) + '.png',
            };
        }

        if (address == null) return null;
        let addressLine = address.Line1 && address.Line2
            ? address.Line1 + '<br />' + address.Line2
            : null;
        if (AppConfig.MAP_TYPE == MapType.Google) {
            let marker = new google.maps.Marker({
                index: i,
                icon: image,
                draggable: false,
                map: this.data.Map,
                label: address.Title ? {
                    color: '#000000',
                    text: address.Title,
                    className: 'marker-label',
                } : null,
                position: {
                    lat: address.Latitude,
                    lng: address.Longitude
                },
                formatted_address: addressLine ? addressLine : '',
            });

            let infowindowMarker = this.data.Infowindow;
            google.maps.event.addListener(marker, 'click', () => {
                if (marker.formatted_address && marker.formatted_address != '') {
                    infowindowMarker.setContent(marker.formatted_address);
                }
                infowindowMarker.open(this.data.Map, marker);
            });
            return marker;
        } else {
            let marker = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(address.Latitude, address.Longitude), {
                index: i,
                color: 'green',
                icon: image.url,
            });
            marker.index = i;
            marker.color = 'green';
            marker.icon = image.url;

            marker.metadata = {
                title: address.Title,
                description: addressLine
            };
            let infowindowMarker = this.data.Infowindow;
            infowindowMarker.setMap(this.data.Map);
            Microsoft.Maps.Events.addHandler(marker, 'click', (e: any) => {
                if (e.target.metadata) {
                    //Set the infobox options with the metadata of the pushpin.
                    infowindowMarker.setOptions({
                        visible: true,
                        title: e.target.metadata.title,
                        location: e.target.getLocation(),
                        description: e.target.metadata.description,
                    });
                }
            });
            this.data.Map.entities.push(marker);
            return marker;
        }
    }
    public ClearRoute(elementId: string, onwardPath: boolean, returnPath: boolean) {
        if (!this.CheckExistsMap(elementId)) return;
        if (AppConfig.MAP_TYPE == MapType.Google) {
            if (onwardPath) {
                if (this.data.FlightPath != null)
                    this.data.FlightPath.setMap(null);
            }
            if (returnPath) {
                if (this.data.FlightReturnPath != null)
                    this.data.FlightReturnPath.setMap(null);
            }
        } else {
            if (this.data.Map) {
                if (onwardPath) {
                    for (var i = this.data.Map.entities.getLength() - 1; i >= 0; i--) {
                        var polyline = this.data.Map.entities.get(i);
                        if (polyline instanceof Microsoft.Maps.Polyline) {
                            if (polyline.pathType == 'onward') this.data.Map.entities.removeAt(i);
                        }
                    }
                }
                if (returnPath) {
                    for (var i = this.data.Map.entities.getLength() - 1; i >= 0; i--) {
                        var polyline = this.data.Map.entities.get(i);
                        if (polyline instanceof Microsoft.Maps.Polyline) {
                            if (polyline.pathType == 'return') this.data.Map.entities.removeAt(i);
                        }
                    }
                }
            }
        }
    }
    public RemoveMarkers(elementId: string, i?: number, clearRoute: boolean = true) {
        if (!this.CheckExistsMap(elementId))
            return;
        if (AppConfig.MAP_TYPE == MapType.Google) {
            if (clearRoute) this.ClearRoute(elementId, true, true);
            if (this.data.Markers == null)
                this.data.Markers = new Array();
            if (i != null && i != undefined) {
                for (let j = 0; j < this.data.Markers.length; j++) {
                    if (this.data.Markers[j].index == i) {
                        this.data.Markers[j].setMap(null);
                        this.data.Markers[j] = null;
                    }
                }
            } else {
                for (let j = 0; j < this.data.Markers.length; j++) {
                    this.data.Markers[j].setMap(null);
                    this.data.Markers[j] = null;
                }
            }
        } else {
            if (clearRoute) this.ClearRoute(elementId, true, true);
            if (this.data.Markers == null)
                this.data.Markers = new Array();
            if (i != null && i != undefined) {
                for (let j = 0; j < this.data.Markers.length; j++) {
                    if (this.data.Map && this.data.Markers[j].index == i) {
                        let pushpin = this.data.Map.entities.get(j);
                        if (pushpin instanceof Microsoft.Maps.Pushpin) {
                            this.data.Map.entities.removeAt(j);
                        }
                        this.data.Markers[j] = null;
                    }
                }
            } else {
                for (let j = 0; j < this.data.Markers.length; j++) {
                    let pushpin = this.data.Map.entities.get(j);
                    if (pushpin instanceof Microsoft.Maps.Pushpin && this.data.Map) {
                        this.data.Map.entities.removeAt(j);
                    }
                    this.data.Markers[j] = null;
                }
            }
        }
        this.data.Markers = this.data.Markers.filter(c => c != null);
        return;
    }
    public InitMarkers(elementId: string, booking: Booking, shift: boolean, i?: number) {
        if (!this.CheckExistsMap(elementId))
            return;
        if (booking == null) return;
        if (this.data.Map == null) return;

        if (this.data.Markers == null) this.data.Markers = new Array();
        for (let j = 0; j < this.data.Markers.length; j++) {
            if (AppConfig.MAP_TYPE == MapType.Google) {
                this.data.Markers[j].setMap(null);
            } else {
                let pushpin = this.data.Map.entities.get(j);
                if (pushpin instanceof Microsoft.Maps.Pushpin) {
                    this.data.Map.entities.removeAt(j);
                }
            }
        }
        this.data.Markers = new Array();
        if (booking.PickUp != null && booking.PickUp.Valid) {
            if (i == null || i == undefined || i == -1) {
                let marker = this.DrawMarker(elementId, booking.PickUp, -1);
                if (marker != null) {
                    this.data.Markers.push(marker);
                }
            }
        }
        if (booking.PickDown != null && booking.PickDown.Valid) {
            if (i == null || i == undefined || i == 100) {
                let marker = this.DrawMarker(elementId, booking.PickDown, 100);
                if (marker != null) this.data.Markers.push(marker);
            }
        }
        if (booking.ViaAddress != null && booking.ViaAddress.length > 0) {
            booking.ViaAddress.forEach((element, index) => {
                if (element != null && element.Valid) {
                    let marker = this.DrawMarker(elementId, element, index);
                    if (marker != null) this.data.Markers.push(marker);
                }
            });
        }

        /// Bound Markers
        this.BoundMarkers(elementId);

        /// Clear Route
        this.ClearRoute(elementId, true, true);

        /// Shift
        if (AppConfig.MAP_TYPE == MapType.Google) {
            if (shift && this.data.Markers != null && this.data.Markers.length > 0) {
                let that = this;
                window.setTimeout(function () {
                    that.ShiftRight(elementId);
                }, 500);
            }
        }
    }
    public InitMap(elementId: string, lat: number = null, lng: number = null, createMarker: boolean = false) {
        if (!this.CheckExistsMap(elementId))
            return this.data.Map;

        if (AppConfig.MAP_TYPE == MapType.Google) {
            this.data.Map = this.InitGoogleMap(elementId, lat, lng, createMarker);
        } else {
            this.data.Map = this.InitBingMap(elementId, lat, lng, createMarker);
        }
        return this.data.Map;
    }

    private InitBingMap(elementId: string, lat: number = null, lng: number = null, createMarker: boolean = false) {
        let map: any = null;
        if (Microsoft.Maps.MapTypeId) {
            if (lat && lng) {
                map = new Microsoft.Maps.Map(document.getElementById(elementId), {
                    zoom: this.data.MAP_MAX_ZOOM,
                    mapTypeId: Microsoft.Maps.MapTypeId.road,
                    center: new Microsoft.Maps.Location(lat, lng),
                });
            } else {
                lat = this.data.Configuration.BaseLocation.Latitude;
                lng = this.data.Configuration.BaseLocation.Longitude;
                map = new Microsoft.Maps.Map(document.getElementById(elementId), {
                    zoom: this.data.MAP_MAX_ZOOM,
                    mapTypeId: Microsoft.Maps.MapTypeId.road,
                    center: new Microsoft.Maps.Location(lat, lng),
                });
            }
        }

        if (createMarker) {
            let marker = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(lat, lng), {
                icon: 'https://s22.postimg.cc/5js66ae75/drone.png',
                color: 'green'
            });
            map.entities.push(marker);
        }
        return map;
    }
    private InitGoogleMap(elementId: string, lat: number = null, lng: number = null, createMarker: boolean = false) {
        let map: any = null,
            mapTypeIds = environment.name == 'triox'
                ? ['roadmap', 'satellite', 'styled_map']
                : ['roadmap', 'satellite'];

        if (lat && lng) {
            map = new google.maps.Map(document.getElementById(elementId), {
                zoomControl: true,
                scrollwheel: false,
                zoom: this.data.MAP_MAX_ZOOM,
                maxZoom: this.data.MAP_MAX_ZOOM,
                center: {
                    lat: lat,
                    lng: lng
                },
                mapTypeControl: false,
                mapTypeControlOptions: {
                    mapTypeIds: mapTypeIds
                },
                streetViewControl: false,
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_TOP
                },
                zoomControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_TOP
                }
            });
        } else {
            lat = this.data.Configuration.BaseLocation.Latitude;
            lng = this.data.Configuration.BaseLocation.Longitude;
            map = new google.maps.Map(document.getElementById(elementId), {
                zoomControl: true,
                scrollwheel: false,
                zoom: this.data.MAP_MAX_ZOOM,
                maxZoom: this.data.MAP_MAX_ZOOM,
                center: {
                    lat: lat,
                    lng: lng
                },
                mapTypeControl: false,
                mapTypeControlOptions: {
                    mapTypeIds: mapTypeIds
                },
                streetViewControl: false,
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_TOP
                },
                zoomControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_TOP
                }
            });
        }
        if (environment.name == 'triox') {
            let styledMapType = this.InitMapStyles();
            map.mapTypes.set('styled_map', styledMapType);
            map.setMapTypeId('styled_map');
        }

        if (createMarker) {
            let marker = new google.maps.Marker({
                map: map,
                draggable: false,
                position: {
                    lat: lat,
                    lng: lng
                },
                formatted_address: this.data.Configuration.Company,
            });

            let infowindowMarker = new google.maps.InfoWindow({ size: new google.maps.Size(150, 50) });
            google.maps.event.addListener(marker, 'click', function () {
                if (marker.formatted_address && marker.formatted_address != '') {
                    infowindowMarker.setContent(marker.formatted_address);
                }
                infowindowMarker.open(this.map, marker);
            });
        }
        return map;
    }

    public GetVehicleItem(vehicleId: number) {
        if (this.data.Vehicles && vehicleId > 0) {
            for (let i = 0; i < this.data.Vehicles.length; i++) {
                if (this.data.Vehicles[i].Id == vehicleId) {
                    return this.data.Vehicles[i];
                }
            }
        }
    }
    public GetDialCode(editorId: string): string {
        let dialCode: string = '';
        let elementCountry = jQuery('#' + editorId).intlTelInput('getSelectedCountryData');
        if (elementCountry)
            dialCode = elementCountry.dialCode;
        if (dialCode && dialCode.length > 0 && dialCode.indexOf('+') != 0)
            dialCode = '+' + dialCode;
        if (!dialCode) {
            dialCode = jQuery('#' + editorId + '-input .selected-dial-code').text();
        }
        return dialCode;
    }
    public GetPhoneNumber(phone: string): string {
        if (!phone || phone.length == 0) return '';
        if (phone.indexOf('0') == 0) phone = phone.substr(1);
        return phone;
    }
    public GetDialCountry(editorId: string): any {
        let dialCode = jQuery('#' + editorId).intlTelInput('getSelectedCountryData');
        if (!dialCode) {
            dialCode = jQuery('#' + editorId + '-input .selected-dial-code').text();
        }
        return dialCode;
    }
    public SetDialCode(editorId: string, dialCode: string): void {
        if (jQuery('#' + editorId) && dialCode) {
            let country: string;
            let countries = jQuery('#' + editorId).intlTelInput.getCountryData();
            if (countries) {
                dialCode = dialCode.replace('+', '');
                for (let i = 0; i < countries.length; i++) {
                    if (countries[i].dialCode == dialCode) {
                        country = countries[i].iso2;
                        break;
                    }
                }
                if (country) jQuery('#' + editorId).intlTelInput('setCountry', country);
            }
        }
    }

    OnRepeat(item: Booking) {
        this.data.Booking = this.CloneBooking(item, item.PickUp, item.PickDown);
        this.data.Booking.Time.PickUpType == PickupType.Later;
        this.data.storeBooking();
        this.GoToPage(this.data.PAGE_TYPE.BOOKING);
    }
    OnReturn(item: Booking) {
        this.data.Booking = this.CloneBooking(item, item.PickDown, item.PickUp);
        this.data.Booking.Time.PickUpType == PickupType.Later;
        this.data.storeBooking();
        this.GoToPage(this.data.PAGE_TYPE.BOOKING);
    }
    public OnResendLoading: boolean = false;
    OnResend(item: Booking) {
        this.OnResendLoading = true;
        this.service.emailReceipt(item)
            .subscribe(model => {
                this.OnResendLoading = false;
                if (model.Code == 1) {
                    this.data.ShowDialog.emit(new Dialog('The receipt has been sent to your email address', 'Message'));
                } else {
                    let error = model.Code == 0 ? 'Something went wrong, please try again.' : model.Message;
                    this.data.ShowDialog.emit(new Dialog(error, 'Error'));
                }
            });
    }
    OnLogout(accept: any = null) {
        let confirm = new ConfirmDialog("Do you want to sign out?", "Please Confirm", () => {
            this.data.CloseConfirmDialog.emit();
            this.data.SignoutComplete.emit();
            if (accept) accept();
        });
        this.data.ShowConfirmDialog.emit(confirm);
    }
    CloneBooking(item: Booking, pickUp: AddressItem, pickDown: AddressItem): Booking {
        let booking: Booking = new Booking();
        booking.PickUp = pickUp;
        booking.PickDown = pickDown;
        booking.Contact = item.Contact;
        booking.PaymentType = this.data.Configuration.DefaultPaymentMethod
            ? this.data.Configuration.DefaultPaymentMethod
            : AppConfig.PAYMENT_TYPE;
        if (this.data.Profile) {
            booking.Contact.CustomerId = this.data.Profile.Id;
        }
        booking.Time = new TimeItem();
        booking.Vehicle = this.GetVehicleItem(item.VehicleId);
        return booking;
    }

    public InitAffixMenu() {
        if (jQuery('#menus').length > 0) {
            jQuery('#menus').affix({
                offset: {
                    top: jQuery('#header').outerHeight(true)
                }
            });
        }
    }

    public CssFixedFooter() {
        if (jQuery('#footer')) {
            jQuery('#footer').css('position', 'fixed');
        }
    }
    public CssNotFixedFooter() {
        if (jQuery('#footer')) {
            window.setTimeout(function () {
                jQuery('#footer').css('position', 'relative');
            }, 200);
        }
    }


    HideMenu() {
        jQuery('#main-navbar').hide();
        jQuery('#main-navbar-user').hide();
        jQuery('html').removeClass("noscroll");

        let elmMenu = jQuery('#main-navbar .navbar-nav').length > 0
            ? jQuery('#main-navbar .navbar-nav')
            : jQuery('#main-navbar .navbar-menu');
        let elmMenuUser = jQuery('#main-navbar-user .navbar-nav').length > 0
            ? jQuery('#main-navbar-user .navbar-nav')
            : jQuery('#main-navbar-user .navbar-menu');
        jQuery(elmMenu).removeClass("open");
        jQuery(elmMenuUser).removeClass("open");

        let $menuItem = jQuery('#menuItem');
        if ($menuItem && $menuItem.length > 0 && $menuItem.hasClass('active')) {
            jQuery('html').removeClass("noscroll");
            $menuItem.removeClass('active');
        }
        $menuItem = jQuery('#menuItemUser');
        if ($menuItem && $menuItem.length > 0 && $menuItem.hasClass('active')) {
            jQuery('html').removeClass("noscroll");
            $menuItem.removeClass('active');
        }
        if (jQuery('#pickfloat-bottom').length > 0) {
            jQuery('#pickfloat-bottom').css('opacity', 1);
            jQuery('#pickfloat-bottom').css('z-index', 10);
        }
    }
    public HideAllModal(forceClose: boolean = false) {
        // forceClose
        if (forceClose) {
            let index = 0;
            let interval = setInterval(() => {
                index += 1;
                if (index >= 5) {
                    clearInterval(interval);
                }
                this.HideModal(this.data.MODAL_TYPE.PAYMENTPROCESS);
            }, 100);
        }

        this.HideModal(this.data.MODAL_TYPE.CARD);
        this.HideModal(this.data.MODAL_TYPE.ALERT);
        this.HideModal(this.data.MODAL_TYPE.PROMO);
        this.HideModal(this.data.MODAL_TYPE.SIGNIN);
        this.HideModal(this.data.MODAL_TYPE.FLIGHT);
        this.HideModal(this.data.MODAL_TYPE.CONTACT);
        this.HideModal(this.data.MODAL_TYPE.CONFIRM);
        this.HideModal(this.data.MODAL_TYPE.QUOTATION);
        this.HideModal(this.data.MODAL_TYPE.PAYMENTMETHOD);
        this.HideModal(this.data.MODAL_TYPE.PAYMENTPAYPAL);
        this.HideModal(this.data.MODAL_TYPE.FORGOTPASSWORD);
        this.HideModal(this.data.MODAL_TYPE.PAYMENTPROCESS);

        // forceClose
        if (forceClose) {
            let index = 0;
            let interval = setInterval(() => {
                index += 1;
                if (index >= 5) {
                    clearInterval(interval);
                }
                this.HideModal(this.data.MODAL_TYPE.PAYMENTPROCESS);
            }, 100);
        }
    }
    public HideModal(elementId: string) {
        let index = 0;
        let hideInterval = setInterval(() => {
            index += 1;
            if (index >= 10)
                clearInterval(hideInterval);
            if (jQuery('#' + elementId).length > 0) {
                jQuery('#' + elementId).modal('hide');
                jQuery('#' + elementId).data('bs.modal', null);
                if (!jQuery('#' + elementId).hasClass('in'))
                    clearInterval(hideInterval);
            }
        }, 300);
        let interval = setInterval(() => {
            if (!this.ToggleNoScroll(false)) {
                clearInterval(interval);
                return;
            }
        }, 100);
        if (jQuery('#' + this.data.MODAL_TYPE.CARD).length > 0) {
            if (jQuery('#' + this.data.MODAL_TYPE.CARD).css('opacity') != '1') {
                jQuery('#' + this.data.MODAL_TYPE.CARD).css('opacity', '1');
            }
        }
    }
    public ToggleNoScroll(toggle: boolean): boolean {
        if (toggle)
            jQuery('html').addClass('noscroll');
        else
            jQuery('html').removeClass('noscroll');
        return jQuery('html').hasClass('noscroll');
    }
    public ShowModal(elementId: string, timeout: number = 0) {
        if (jQuery('#' + elementId).length == 0)
            return;
        if (elementId == this.data.MODAL_TYPE.FLIGHT) {
            this.data.FlightPickUp = this.CheckPickUpAir();
            this.data.FlightPickDown = this.CheckPickDownAir();
            if (!this.data.FlightPickUp && !this.data.FlightPickDown)
                return;
        }
        if (this.IsMobile()) {
            jQuery('#main-navbar').hide();
            jQuery('#main-navbar-user').hide();
        }
        this.data.ModalType = elementId;
        if (timeout == -1) {
            jQuery('#' + elementId).removeData('modal').modal({
                backdrop: 'static',
                keyboard: false
            });
            this.ToggleNoScroll(true);
        } else if (timeout == 0) {
            setTimeout(() => {
                jQuery('#' + elementId).removeData('modal').modal({
                    backdrop: 'static',
                    keyboard: false
                });
                this.ToggleNoScroll(true);
            }, 100);
        } else {
            setTimeout(() => {
                jQuery('#' + elementId).removeData('modal').modal({
                    backdrop: 'static',
                    keyboard: false
                });
                this.ToggleNoScroll(true);
            }, timeout);
        }
    }

    public IsShowResendButton(item: Booking) {
        if (item == null) return false;
        return item.Status.toLowerCase() != 'cancelled';
    }
    public IsShowCancelButton(item: Booking) {
        if (item == null) return false;
        return item.Status.toLowerCase().indexOf('upcoming') >= 0 && this.data.Configuration.CanCancelBooking;
    }
    public IsShowPaymentButton(item: Booking) {
        if (item == null) return false;
        if (item.PaymentType == 'Cash')
            return false;
        if (!item.Status) item.Status = '';
        return !item.IsPaid && item.Bref && item.Status.toLowerCase().indexOf('cancel') < 0;
    }
    public IsShowTrackingButton(item: Booking) {
        if (item == null) return false;
        if (!item.Status) item.Status = '';
        return item.Status.toLowerCase().indexOf('started') >= 0 ||
            item.Status.toLowerCase().indexOf('arrived') >= 0 ||
            item.Status.toLowerCase().indexOf('finish') >= 0 ||
            item.Status.toLowerCase().indexOf('pob') >= 0;
    }
    public IsShowUpdateTimeout(item: Booking) {
        if (item.Bref && item.Bref.length > 0) return false;
        return this.data.validAll();
    }
    public PagingBookings(item: BookingHistoryItem): Array<Booking> {
        item.TotalRecords = (item.AllBookings && item.AllBookings.length) || 0;
        let start = item.PageIndex * item.PageSize;
        let end = (item.PageIndex + 1) * item.PageSize;
        if (end > item.TotalRecords) end = item.TotalRecords;
        return item.AllBookings.slice(start, end);
    }

    public checkIOS(): boolean {
        // return this.checkIOSPlatform();  
        return false;
    }
    public checkIOSPlatform(): boolean {
        let result = false;
        let iDevices = [
            'iPhone Simulator',
            'iPod Simulator',
            'iPhone',
            'iPod'
        ];
        if (!!navigator.platform) {
            while (iDevices.length) {
                if (navigator.platform == iDevices.pop()) {
                    result = true;
                    break;
                }
            }
        }
        return result;
    }

    public CreateHash(text: string) {
        let hash = HmacSHA256(text, this.data.Configuration.PublicKey);
        let hashInBase64 = EncBase64.stringify(hash);
        return hashInBase64;
    }
    public CheckPickUpAir(): boolean {
        if (this.data.Booking &&
            this.data.validPickUp() &&
            this.data.Booking.PickUp.Category &&
            this.data.Booking.PickUp.Category.toLowerCase().indexOf('air') >= 0)
            return true;
        return false;
    }
    public CheckDropOffAir(): boolean {
        if (this.data.Booking &&
            this.data.validPickDown() &&
            this.data.Booking.HasReturn &&
            this.data.Booking.PickDown.Category &&
            this.data.Booking.PickDown.Category.toLowerCase().indexOf('air') >= 0)
            return true;
        return false;
    }
    public CheckPickDownAir(): boolean {
        if (this.data.Booking &&
            this.data.validPickDown() &&
            this.data.Booking.PickDown.Category &&
            this.data.Booking.PickDown.Category.toLowerCase().indexOf('air') >= 0) {
            if (this.data.Booking.HasReturn &&
                this.data.Booking.ReturnDate != null) {
                if (this.data.Booking.ReturnEarliestTime == null)
                    this.data.Booking.ReturnEarliestTime = this.data.getReturnEarliestTime();
                return this.data.Booking.ReturnDate.getTime() > this.data.Booking.ReturnEarliestTime.getTime();
            }
        }
        return false;
    }
    public ComparePickAddress(pickup: AddressItem, dropoff: AddressItem) {
        if (pickup != null && dropoff != null) {
            let valid1 = pickup.Latitude == dropoff.Latitude;
            let valid3 = pickup.Postcode == dropoff.Postcode;
            let valid2 = pickup.Longitude == dropoff.Longitude;
            return valid1 && valid2 && valid3;
        } else {
            return false;
        }
    }

    public checkTickFlight(): boolean {
        if (this.data.Booking.Flight == null)
            return false;
        if (this.data.Booking.Flight.IsFlightDetail)
            return true;
        else {
            if (!this.data.Booking.Flight.Number)
                return false;
            if (this.data.Booking.Flight.AirportText.length == 0 &&
                this.data.Booking.Flight.Airport == null)
                return false;
        }
        return true;
    }
    public checkDontFlight(): boolean {
        if (this.data.Booking.Flight == null) this.data.Booking.Flight = new Flight();
        return this.data.Booking.Flight.IsFlightDetail;
    }
    public checkTickNoteToDriver(): boolean {
        return this.data.Booking.Note != null && this.data.Booking.Note != '';
    }
    public checkTickReturnFlight(): boolean {
        if (this.data.Booking.ReturnFlight == null)
            return false;
        if (this.data.Booking.ReturnFlight.IsFlightDetail)
            return true;
        else {
            if (!this.data.Booking.ReturnFlight.Number)
                return false;
            if (this.data.Booking.ReturnFlight.AirportText.length == 0 &&
                this.data.Booking.ReturnFlight.Airport == null)
                return false;
        }
        return true;
    }
    public checkDontReturnFlight(): boolean {
        if (this.data.Booking.ReturnFlight == null) this.data.Booking.ReturnFlight = new Flight();
        return this.data.Booking.ReturnFlight.IsFlightDetail;
    }
    public CheckFlight(booking: Booking = null) {
        if (booking == null) {
            return this.data.Booking.Flight != null && !this.data.Booking.Flight.IsFlightDetail;
        } else {
            return booking.Flight != null && !booking.Flight.IsFlightDetail;
        }
    }
    public CheckReturnFlight(booking: Booking = null) {
        if (booking == null) {
            return this.data.Booking.ReturnFlight != null && !this.data.Booking.ReturnFlight.IsFlightDetail;
        } else {
            return booking.ReturnFlight != null && !booking.ReturnFlight.IsFlightDetail;
        }
    }

    public OpenMenu() {
        if (this.data.IsOpenMenu) {
            this.HideModal(this.data.MODAL_TYPE.MENU);
        } else {
            this.ShowModal(this.data.MODAL_TYPE.MENU, -1);
        }
        this.data.IsOpenMenu = !this.data.IsOpenMenu;
    }
    public PaymentInline() {
        if (this.data.Booking.PaymentType.toLowerCase() == this.data.PAYMENT_TYPE.CARD) {
            if (this.data.Configuration.PaymentGateway == 'stripe') {
                if (this.data.Stripe) {
                    if (this.data.Payment)
                        this.data.Payment.Retry = false;
                    this.data.PaymentProcess.emit();
                }
            } else {
                this.data.ButtonPaymentType = this.data.BUTTON_PAYMENT_TYPE.PICKFLOAT_INLINE;

                if (this.data.ClientPayment != null) {
                    let that = this;
                    this.data.ClientPayment.tokenizeCard({
                        cvv: that.data.Payment.CVC,
                        number: that.data.Payment.CardNumber,
                        cardholderName: that.data.Payment.Name,
                        expirationYear: that.data.Payment.ExpiryYear,
                        expirationMonth: that.data.Payment.ExpiryMonth,
                        billingAddress: {
                            postalCode: that.data.Payment.PostalCode
                        }
                    }, function (err: any, nonce: any) {
                        if (err) {
                            this.data.ShowDialog.emit(new Dialog(err));
                            return;
                        }
                        that.data.ManualTimeout = true;
                        that.data.Payment.Nonce = nonce;
                        that.data.PaymentProcess.emit();
                    });
                }
                else {
                    this.data.ShowDialog.emit(new Dialog(this.data.ERROR_MESSAGE));
                }
            }
        } else if (this.data.Booking.PaymentType.toLowerCase() == this.data.PAYMENT_TYPE.PAYPAL) {
            this.OpenPayment();
        } else {
            this.data.ButtonPaymentType = this.data.BUTTON_PAYMENT_TYPE.PICKFLOAT_INLINE;
            this.data.PaymentProcess.emit();
        }
    }
    public OpenPayment(timeout: number = 0) {
        switch (this.data.Booking.PaymentType) {
            case this.data.PAYMENT_TYPE.CARD:
                {
                    this.data.Payment = PaymentItem.InitItem();
                    this.ShowModal(this.data.MODAL_TYPE.CARD, timeout);
                }
                break;
            case this.data.PAYMENT_TYPE.PAYPAL:
                {
                    if (this.data.PaymentPaypal && this.data.PaymentPaypal.paypal) {
                        this.data.ManualTimeout = true;
                        this.data.ButtonPaymentType = this.data.BUTTON_PAYMENT_TYPE.PICKFLOAT_PAYPAL;
                        this.data.PaymentPaypal.paypal.initAuthFlow();
                    } else {
                        this.data.ShowDialog.emit(new Dialog(this.data.ERROR_MESSAGE));
                    }
                }
                break;
            default:
                {
                    this.data.PaymentProcess.emit();
                }
                break;
        }
    }

    public ScrollTo(anchor: string) {
        if (this.data.IsOpenMenu) {
            this.HideModal(this.data.MODAL_TYPE.MENU);
            this.data.IsOpenMenu = false;
        }
        sessionStorage.setItem("AnchorMenu", anchor);
        this.CssNotFixedFooter();
        this.ScrollToMenuItem(anchor);
    }
    public ScrollToOffset(top: number) {
        jQuery('body,html').animate({ scrollTop: top });
        jQuery(window).scrollTop(top);
    }
    public ScrollToAnchor(anchor: string) {
        if (!jQuery('#' + anchor)) return;
        if (!jQuery('#' + anchor).offset()) return;

        let menuHeight = jQuery('#menus').length > 0 ? jQuery('#menus').outerHeight() : 0;
        let menuOffsetTop = jQuery('#menus').length > 0 ? jQuery('#menus').offset().top : 0;
        let headerHeight = jQuery('#header').length > 0 ? jQuery('#header').outerHeight() : 0;
        let menuPosition = jQuery('#menus').length > 0 ? jQuery('#menus').css('position') : '';
        let top = menuPosition == 'fixed'
            ? jQuery('#' + anchor).offset().top
            : jQuery('#' + anchor).offset().top - menuHeight;
        if (headerHeight > 0 && menuOffsetTop <= headerHeight)
            top -= menuHeight;
        jQuery('body,html').animate({ scrollTop: top });
    }
    public ScrollToMenuItem(anchor: string) {
        if (this.router.url == '' ||
            this.router.url == '/' ||
            this.router.url == 'home') {
            this.ScrollToAnchor(anchor);
            this.HideModal(this.data.MODAL_TYPE.MENU);
        } else {
            let that = this;
            this.GoToPage(this.data.PAGE_TYPE.HOME);
            setTimeout(function () {
                that.ScrollToAnchor(anchor);
            }, 100);
            this.HideModal(this.data.MODAL_TYPE.MENU);
        }
    }
    public ScrollToStickyInModal(sticky: string, modal: string) {
        sticky = '#' + sticky;
        if (jQuery(sticky).length > 0) {
            let offset = jQuery(sticky).offset();
            if (offset && offset.top > 0) {
                let top = jQuery('#' + modal).scrollTop() + offset.top;
                if (jQuery('#' + modal).length > 0) {
                    jQuery('#' + modal).scrollTop(top);
                }
            }
        }
    }
    public ScrollToSticky(sticky: string, menufix: boolean = true) {
        let name = sticky;
        sticky = '#' + sticky;
        if (jQuery(sticky).length > 0) {
            let offset = jQuery(sticky).offset();
            if (offset && offset.top > 0) {
                let menuHeight = jQuery('#menus').length > 0 ? jQuery('#menus').outerHeight() : 0;
                let top = menufix ? offset.top - menuHeight : offset.top;
                if (jQuery(window).scrollTop() == 0 && menufix) {
                    top -= menuHeight;
                }

                if (this.IsMobile()) {
                    if (name == this.data.STICKY_TYPE.PICKDATETIME) {
                        if (jQuery('#booking-page-step1').length > 0) {
                            jQuery('#booking-page-step1').animate({ scrollTop: 0 });
                            return;
                        }
                    } else if (name == this.data.STICKY_TYPE.PICKUP) {
                        if (jQuery('#booking-page-step1').length > 0) {
                            jQuery('#booking-page-step1').animate({ scrollTop: 0 });
                            return;
                        }
                    } else if (name == this.data.STICKY_TYPE.PICKDOWN) {
                        if (jQuery('#booking-page-step1').length > 0) {
                            jQuery('#booking-page-step1').animate({ scrollTop: jQuery('#booking-page-step1')[0].scrollHeight });
                            return;
                        }
                    } else if (name == this.data.STICKY_TYPE.CUSTOMER) {
                        if (jQuery('#booking-page-step4').length > 0) {
                            jQuery('#booking-page-step4').animate({ scrollTop: 0 });
                            return;
                        }
                    } else if (name == this.data.STICKY_TYPE.CONTACT) {
                        if (jQuery('#booking-page-step4').length > 0) {
                            jQuery('#booking-page-step4').animate({ scrollTop: jQuery('#booking-page-step4')[0].scrollHeight });
                            return;
                        }
                    } else if (name.indexOf('via') >= 0) {
                        return;
                    }
                }
                jQuery('body,html').animate({ scrollTop: top });
                jQuery(window).scrollTop(top);
            }
        }
    }

    /// Payment
    public JustPayment() {
        this.ShowPaymentProcess();
        this.ConfirmStripePayment();
    }
    public PaymentBooking() {
        this.ShowPaymentProcess();
        this.UpdateProcessButton(true, 'Booking...');
        this.data.Payment.CustomerId = this.data.Profile ? this.data.Profile.Id : 0;
        if (!this.data.Booking.Bref || this.data.Booking.Bref == '') {
            this.ElectronicPaymentBooking();
        } else {
            if (this.data.Booking.PaymentType.toLowerCase() == this.data.PAYMENT_TYPE.CASH) {
                this.ElectronicPaymentBooking();
            } else {
                this.ElectronicPaymentBooking();
            }
        }
    }
    private ElectronicPaymentBooking() {
        if (this.data.Booking.PaymentType.toLowerCase() == this.data.PAYMENT_TYPE.CARD &&
            this.data.Configuration.PaymentGateway == 'stripe') {
            this.ElectronicPaymentBookingStripe();
            return;
        }
        this.data.Booking.ContactDb = this.CreateContactDb();
        this.service.electronicPaymentBookingStripe(this.data.Booking, this.data.Payment, this.data.SandBox)
            .subscribe((model: ElectronicBookingItem) => {
                this.data.Payment.CVC = '';
                this.data.Booking.Bref = model.Bref;
                this.data.Booking.ReturnRef = model.ReturnRef;
                if (model.Code == 1) {
                    if (model.PaymentResult) {
                        if (model.PaymentResult.Code == 1 || model.PaymentResult.Code == 3) {
                            this.data.Booking.AuthCode = model.PaymentResult.AuthCode;
                            this.data.Booking.IsPaid = true;
                            this.RedirectToConfirmPage(false);
                        } else if (model.PaymentResult.Code == 0) {
                            if (this.data.Booking.PaymentType.toLowerCase() == this.data.PAYMENT_TYPE.CASH) {
                                this.data.Booking.AuthCode = model.PaymentResult.AuthCode;
                                this.data.Booking.IsPaid = true;
                                this.RedirectToConfirmPage(false);
                            } else {
                                if (this.data.ButtonPaymentType == this.data.BUTTON_PAYMENT_TYPE.DIALOG_CARD)
                                    this.HideModal(this.data.MODAL_TYPE.PAYMENTPROCESS);
                                else
                                    this.HideAllModal();
                                this.UpdateProcessButton(false);
                                this.data.ShowDialog.emit(new Dialog(model.Message));
                            }
                        }
                    } else {
                        if (this.data.ButtonPaymentType == this.data.BUTTON_PAYMENT_TYPE.DIALOG_CARD)
                            this.HideModal(this.data.MODAL_TYPE.PAYMENTPROCESS);
                        else
                            this.HideAllModal();
                        this.UpdateProcessButton(false);
                        this.data.ShowDialog.emit(new Dialog(model.Message));
                    }
                } else if (model.Code == 0 || model.Code == 2) {
                    if (this.data.ButtonPaymentType == this.data.BUTTON_PAYMENT_TYPE.DIALOG_CARD)
                        this.HideModal(this.data.MODAL_TYPE.PAYMENTPROCESS);
                    else
                        this.HideAllModal(true);
                    this.UpdateProcessButton(false);

                    let messageError = this.GetMessage(model);
                    setTimeout(() => this.data.ShowDialog.emit(new Dialog(messageError)), 1000);
                } else {
                    if (this.data.ButtonPaymentType == this.data.BUTTON_PAYMENT_TYPE.DIALOG_CARD)
                        this.HideModal(this.data.MODAL_TYPE.PAYMENTPROCESS);
                    else
                        this.HideAllModal(true);
                    this.UpdateProcessButton(false);
                    let messageError = this.GetMessage(model);
                    setTimeout(() => this.data.ShowDialog.emit(new Dialog(messageError)), 1000);
                }
            }, () => {
                if (this.data.ButtonPaymentType == this.data.BUTTON_PAYMENT_TYPE.DIALOG_CARD)
                    this.HideModal(this.data.MODAL_TYPE.PAYMENTPROCESS);
                else
                    this.HideAllModal(true);
                this.UpdateProcessButton(false);
                setTimeout(() => this.data.ShowDialog.emit(new Dialog(this.data.ERROR_MESSAGE)), 1000);
            });
    }
    private ConfirmStripePayment(retry: boolean = false) {
        let additionalData = {
            name: this.data.Payment.Name,
            address_city: this.data.Payment.City,
            address_zip: this.data.Payment.PostalCode,
            address_state: this.data.Payment.CountryCode,
            address_line1: this.data.Payment.AddressLine1,
        };
        let stripe = retry ? this.data.StripePopup || this.data.Stripe : this.data.Stripe;
        if (stripe) {
            this.UpdateProcessButton(true, 'Payment...');
            let clientSecret = this.data.Booking.ClientSecret;
            stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: this.data.StripeCardNumber || this.data.StripePopupCardNumber,
                    billing_details: {
                        name: additionalData.name,
                        address: {
                            city: additionalData.address_city,
                            line1: additionalData.address_line1,
                            state: additionalData.address_state,
                            postal_code: additionalData.address_zip,
                        },
                    },
                }
            }).then((result: any) => {
                console.log(result);
                let model: ElectronicBookingStripeItem = {
                    Code: 1,
                    Message: null,
                    PaymentResult: {
                        AdditionalInfo: additionalData,
                        AuthCode: this.data.Booking.AuthCode,
                        PaymentType: this.data.Booking.PaymentType,
                    },
                    ClientSecret: clientSecret,
                    Bref: this.data.Booking.Bref,
                    ReturnRef: this.data.Booking.ReturnRef,
                };
                let status = (result && result.paymentIntent && result.paymentIntent.status) || '';
                if (status == 'succeeded' || status.indexOf('succ') >= 0) {
                    this.paymentStripeSuccess(result, model);
                } else this.paymentStripeError(result);
            });
        }
    }
    private ElectronicPaymentBookingStripe(ev: any = null) {
        let retry = this.data.Payment.Retry;
        this.data.Booking.ContactDb = this.CreateContactDb();
        this.service.electronicPaymentBookingStripe(this.data.Booking, this.data.Payment, this.data.SandBox)
            .subscribe((model: ElectronicBookingStripeItem) => {
                this.data.Payment.CVC = '';
                this.data.Booking.Bref = model.Bref;
                this.data.Booking.ReturnRef = model.ReturnRef;
                this.data.Booking.ClientSecret = model.ClientSecret;
                if (model.Code == 1) {
                    try {
                        if (ev) {
                            let stripe = retry ? this.data.StripePopup || this.data.Stripe : this.data.Stripe;
                            stripe.confirmCardPayment(this.data.Configuration.StripePublicKey, { payment_method: ev.paymentMethod.id }, { handleActions: false })
                                .then((confirmResult: any) => {
                                    this.HideAllModal();
                                    if (confirmResult.error) {
                                        ev.complete('fail');
                                        this.paymentStripeError(confirmResult || ev);
                                    } else {
                                        ev.complete('success');
                                        if (confirmResult.paymentIntent.status === "requires_action" ||
                                            confirmResult.paymentIntent.status === "requires_source_action") {
                                            // Let Stripe.js handle the rest of the payment flow.
                                            stripe.confirmCardPayment(model.ClientSecret).then((result: any) => {
                                                this.HideAllModal();
                                                if (result && result.paymentIntent && result.paymentIntent.status &&
                                                    (result.paymentIntent.status == 'succeeded' || result.paymentIntent.status.indexOf('succ') >= 0)) {
                                                    this.paymentStripeSuccess(result, model);
                                                } else {
                                                    this.paymentStripeError(result);
                                                }
                                            });
                                        } else {
                                            this.paymentStripeSuccess(confirmResult, model);
                                        }
                                    }
                                });
                        } else {
                            this.ConfirmStripePayment(retry);
                        }
                    } catch (e) {
                        this.paymentStripeError(e);
                    }
                } else if (model.Code == 0 || model.Code == 2) {
                    if (this.data.ButtonPaymentType == this.data.BUTTON_PAYMENT_TYPE.DIALOG_CARD)
                        this.HideModal(this.data.MODAL_TYPE.PAYMENTPROCESS);
                    else
                        this.HideAllModal(true);
                    this.UpdateProcessButton(false);
                    let messageError = this.GetMessage(model);
                    setTimeout(() => this.data.ShowDialog.emit(new Dialog(messageError)), 1000);
                } else {
                    if (this.data.ButtonPaymentType == this.data.BUTTON_PAYMENT_TYPE.DIALOG_CARD)
                        this.HideModal(this.data.MODAL_TYPE.PAYMENTPROCESS);
                    else
                        this.HideAllModal(true);
                    this.UpdateProcessButton(false);
                    let messageError = this.GetMessage(model);
                    setTimeout(() => this.data.ShowDialog.emit(new Dialog(messageError)), 1000);
                }
            }, () => {
                if (this.data.ButtonPaymentType == this.data.BUTTON_PAYMENT_TYPE.DIALOG_CARD)
                    this.HideModal(this.data.MODAL_TYPE.PAYMENTPROCESS);
                else
                    this.HideAllModal(true);
                this.UpdateProcessButton(false);
                setTimeout(() => this.data.ShowDialog.emit(new Dialog(this.data.ERROR_MESSAGE)), 1000);
            });
    }

    public NavegatePromo() {
        if (this.data.Booking.Promo == null)
            this.data.Booking.Promo = new PromoItem();
        this.ShowModal(this.data.MODAL_TYPE.PROMO, 500);
    }
    public NavigateSignIn() {
        this.data.SignInType = this.data.Configuration.ShowSignupFirst
            ? this.data.SIGNIN_TYPE.SIGNUP
            : this.data.SIGNIN_TYPE.SIGNIN;
        if (this.data.SignIn == null) this.data.SignIn = new SignIn();
        if (this.data.SignUp == null) this.data.SignUp = new SignUp();
        this.ShowModal(this.data.MODAL_TYPE.SIGNIN, 500);
    }
    public NavigateEditContact() {
        if (this.data.Booking.Contact == null)
            this.data.Booking.Contact = new Contact();
        this.ShowModal(this.data.MODAL_TYPE.CONTACT, 500);
    }
    public NavigateFlightDetail() {
        if (this.data.Booking.Flight == null)
            this.data.Booking.Flight = new Flight();
        this.data.FlightType = this.data.FLIGHT_TYPE.ONWARD;
        this.ShowModal(this.data.MODAL_TYPE.FLIGHT);
    }
    public NavigateNoteToDriver() {
        this.ShowModal(this.data.MODAL_TYPE.NOTEDRIVER, 500);
    }
    public NavigateConfirmBooking() {
        if (this.data.Profile == null) {
            this.data.SignInType = this.data.Configuration.ShowSignupFirst
                ? this.data.SIGNIN_TYPE.SIGNUP
                : this.data.SIGNIN_TYPE.SIGNIN;
            if (this.data.SignIn == null) this.data.SignIn = new SignIn();
            if (this.data.SignUp == null) this.data.SignUp = new SignUp();
            this.ShowModal(this.data.MODAL_TYPE.SIGNIN, 500);
        } else {
            this.data.SignInNavigate = '';
            if (this.data.Booking.Flight.Number != '' &&
                this.data.Booking.Flight.AirportText != '') {
                if (this.data.PopupPaymentCard) {
                    this.OpenPayment(500);
                    return;
                } else {
                    this.PaymentInline();
                    return;
                }
            } else {
                if (this.data.Booking.Flight.IsFlightDetail) {
                    if (this.data.PopupPaymentCard) {
                        this.OpenPayment(500);
                        return;
                    } else {
                        this.PaymentInline();
                        return;
                    }
                }
            }
            if (this.data.PopupPaymentCard) {
                this.OpenPayment(500);
            } else {
                this.PaymentInline();
            }
        }
    }
    public NeedNavegateNoteToDriver() {
        if (this.data.Booking) {
            if (!this.checkTickNoteToDriver()) {
                return true;
            }
        }
        return false;
    }
    public NeedNavegateFlightDetail() {
        if (this.data.Booking &&
            this.data.Booking.PickUp &&
            this.data.Booking.PickUp.Category &&
            this.data.Booking.PickUp.Category.toLowerCase().indexOf('air') >= 0) {
            if (!this.checkTickFlight() && !this.checkDontFlight()) {
                return true;
            }
        }
        return false;
    }
    public NavigateReturnFlightDetail() {
        if (this.data.Booking.ReturnFlight == null)
            this.data.Booking.ReturnFlight = new Flight();
        this.data.FlightType = this.data.FLIGHT_TYPE.RETURN;
        this.ShowModal(this.data.MODAL_TYPE.FLIGHT);
    }
    public NeedNavegateReturnFlightDetail() {
        if (this.data.Booking &&
            this.data.Booking.PickDown &&
            this.data.Booking.PickDown.Category &&
            this.data.Booking.HasReturn && this.data.Booking.ReturnDate != null &&
            this.data.Booking.PickDown.Category.toLowerCase().indexOf('air') >= 0) {
            if (!this.checkTickReturnFlight() && !this.checkDontReturnFlight()) {
                return true;
            }
        }
        return false;
    }

    public CheckExistsMap(elementId: string) {
        if (this.data.LoadedScriptMap) {
            let element = document.getElementById(elementId);
            if (AppConfig.MAP_TYPE == MapType.Google) {
                if (element && google) {
                    element.style.display = 'block';
                    return true;
                }
            } else {
                if (element && Microsoft) {
                    element.style.display = 'block';
                    return true;
                }
            }
        }
        return false;
    }
    public ToggleValidReturnDate(show: boolean = true) {
        let valid = this.data.validReturnDate();
        this.data.Booking.Time.IsValidReturn = true;
        if (!valid) {
            if (show) {
                let date = this.data.Booking.ReturnEarliestTime
                    ? this.ToDateTimeString(this.data.Booking.ReturnEarliestTime)
                    : this.ToDateTimeString(this.data.Booking.Time.Time);
                if (!date || date.length == 0) {
                    jQuery('#pick-return-date').hide();
                    jQuery('#pick-return-date').html('');
                    jQuery('#pick-return-date').removeClass('ui-datetime-invalid');
                } else {
                    jQuery('#pick-return-date').show();
                    this.data.Booking.Time.IsValidReturn = false;
                    jQuery('#pick-return-date').addClass('ui-datetime-invalid');
                    jQuery('#pick-return-date').html('<span class="glyphicon glyphicon-warning-sign"></span>The earliest possible return date is ' + date);
                }
            } else {
                jQuery('#pick-return-date').hide();
                jQuery('#pick-return-date').html('');
                jQuery('#pick-return-date').removeClass('ui-datetime-invalid');
            }
        } else {
            jQuery('#pick-return-date').hide();
            jQuery('#pick-return-date').html('');
            jQuery('#pick-return-date').removeClass('ui-datetime-invalid');
        }
    }

    public GetTotalFare() {
        if (this.data.validAll()) {
            let vehicleId = this.data.Booking.VehicleId,
                additionalPrice = this.data.Booking.Quotation.AdditionalPrices &&
                    this.data.Booking.Quotation.AdditionalPrices.find(c => c.VehTypeId == vehicleId);
            if (this.data.Booking.Promo && this.data.Booking.Promo.Code == 1) {
                let fare = (additionalPrice && additionalPrice.TotalFare) || this.data.Booking.Quotation.GbpTotalFare || this.data.Booking.Quotation.TotalFare;
                let total = fare ? fare - this.data.Booking.Promo.Value : 0;
                return FormatCurrencyHelper.formatLocaleCurrency(total, this.data.CurrencyCodeSupport);
            } else {
                let total = (additionalPrice && additionalPrice.TotalFare) || this.data.Booking.Quotation.GbpTotalFare || this.data.Booking.Quotation.TotalFare;
                return FormatCurrencyHelper.formatLocaleCurrency(total, this.data.CurrencyCodeSupport);
            }
        } else {
            return '';
        }
    }
    public GetOnwardFare() {
        if (this.data.validAll()) {
            let vehicleId = this.data.Booking.VehicleId,
                additionalPrice = this.data.Booking.Quotation.AdditionalPrices &&
                    this.data.Booking.Quotation.AdditionalPrices.find(c => c.VehTypeId == vehicleId);
            let total = (additionalPrice && additionalPrice.Fare) || this.data.Booking.Quotation.GbpFare || this.data.Booking.Quotation.OnwardFare;
            return FormatCurrencyHelper.formatLocaleCurrency(total, this.data.CurrencyCodeSupport);
        }
        return FormatCurrencyHelper.formatLocaleCurrency(0, this.data.CurrencyCodeSupport);
    }
    public GetReturnFare() {
        if (this.data.validAll() && this.data.validReturnDate()) {
            let vehicleId = this.data.Booking.VehicleId,
                additionalPrice = this.data.Booking.Quotation.AdditionalPrices &&
                    this.data.Booking.Quotation.AdditionalPrices.find(c => c.VehTypeId == vehicleId);
            let total = (additionalPrice && additionalPrice.ReturnFare) || this.data.Booking.Quotation.GbpReturnFare || this.data.Booking.Quotation.ReturnTotalFare;
            return FormatCurrencyHelper.formatLocaleCurrency(total, this.data.CurrencyCodeSupport);
        }
        return FormatCurrencyHelper.formatLocaleCurrency(0, this.data.CurrencyCodeSupport);
    }
    public GetTotalFareTicket() {
        let vehicleId = this.data.Booking.VehicleId,
            additionalPrice = this.data.Booking.Quotation.AdditionalPrices &&
                this.data.Booking.Quotation.AdditionalPrices.find(c => c.VehTypeId == vehicleId);
        if (this.data.Booking.Promo && this.data.Booking.Promo.Code == 1) {
            let fare = (additionalPrice && additionalPrice.TotalFare) || this.data.Booking.Quotation.GbpTotalFare || this.data.Booking.Quotation.TotalFare;
            let total = fare ? fare - this.data.Booking.Promo.Value : 0;
            return FormatCurrencyHelper.formatLocaleCurrency(total, this.data.CurrencyCodeSupport);
        } else {
            let total = (additionalPrice && additionalPrice.TotalFare) || this.data.Booking.Quotation.GbpTotalFare || this.data.Booking.Quotation.TotalFare;
            return FormatCurrencyHelper.formatLocaleCurrency(total, this.data.CurrencyCodeSupport);
        }
    }
    public GetBref(booking: Booking = null) {
        let temp = booking != null ? booking : this.data.Booking;
        let bref = temp.Bref;
        if (temp.ReturnRef && temp.ReturnRef != '' && temp.ReturnRef != '0')
            bref += '/' + temp.ReturnRef;
        return bref;
    }
    public GetFlightName(flight: Flight = null) {
        if (flight != null) {
            let flightAirport = flight.AirportText;
            if (flightAirport == null || flightAirport.length == 0) {
                flightAirport = flight.Airport != null ? flight.Airport.Name : '';
            }
            return flightAirport;
        } else {
            let flightAirport = this.data.Booking.Flight.AirportText;
            if (flightAirport == null || flightAirport.length == 0) {
                flightAirport = this.data.Booking.Flight.Airport != null ? this.data.Booking.Flight.Airport.Name : '';
            }
            return flightAirport;
        }
    }
    public GetPhoneFromContact(contact: Contact) {
        if (contact == null) return '';
        let phone = '+' + contact.DialingCode + contact.Phone;
        phone = phone.replace('++', '+').replace('++', '+').replace('++', '+');
        return phone;
    }
    public GetPhoneFromProfile(profile: ProfileItem) {
        if (profile == null) return '';
        let phone = '+' + profile.DialingCode + profile.Phone;
        phone = phone.replace('++', '+').replace('++', '+').replace('++', '+');
        return phone;
    }
    public GetReturnFlightName(flight: Flight = null) {
        if (flight != null) {
            let flightAirport = flight.AirportText;
            if (flightAirport == null || flightAirport.length == 0) {
                flightAirport = flight.Airport != null ? flight.Airport.Name : '';
            }
            return flightAirport;
        } else {
            let flightAirport = this.data.Booking.ReturnFlight.AirportText;
            if (flightAirport == null || flightAirport.length == 0) {
                flightAirport = this.data.Booking.ReturnFlight.Airport != null ? this.data.Booking.ReturnFlight.Airport.Name : '';
            }
            return flightAirport;
        }
    }

    public ShowPaymentProcess() {
        if (jQuery('#' + this.data.MODAL_TYPE.CARD).length > 0)
            jQuery('#' + this.data.MODAL_TYPE.CARD).css('opacity', '0.6');
        if (this.data.ButtonPaymentType != this.data.BUTTON_PAYMENT_TYPE.DIALOG_CARD)
            this.HideAllModal();
        let description = 'We are processing your request. Please do not refresh or close the browser';
        this.data.PaymentProcessItem.Type = this.data.PAYMENT_PROCESS_STATUS.PROCESSING;
        this.data.PaymentProcessItem.Description = description;
        this.ShowModal(this.data.MODAL_TYPE.PAYMENTPROCESS, 500);
    }
    public CreateContactDb(): Contact {
        let contact = new Contact();
        if (this.data.Booking.Customer != null) {
            contact.Name = this.data.Booking.Customer.Name;
            contact.Email = this.data.Booking.Customer.Email;
            contact.Phone = this.data.Booking.Customer.Phone;
            contact.Password = this.data.Booking.Customer.Password;
            contact.BookerId = this.data.Booking.Customer.BookerId;
            contact.LastName = this.data.Booking.Customer.LastName;
            contact.FirstName = this.data.Booking.Customer.FirstName;
            contact.CustomerId = this.data.Booking.Customer.CustomerId;
            contact.DialingCode = this.data.Booking.Customer.DialingCode;
            contact.AsAccount = this.data.Booking.Customer.Password ? true : false;
            contact.PasswordHash = this.CreateHash(this.data.Booking.Customer.Password);
        }
        if (this.data.Profile != null) {
            contact.Password = '';
            contact.AsAccount = false;
            contact.PasswordHash = '';
            if (this.data.Profile.Email)
                contact.Email = this.data.Profile.Email;
            if (this.data.Profile.Phone)
                contact.Phone = this.data.Profile.Phone;
            if (this.data.Profile.Id)
                contact.CustomerId = this.data.Profile.Id;
            if (this.data.Profile.BookerId)
                contact.BookerId = this.data.Profile.BookerId;
            if (this.data.Profile.LastName)
                contact.LastName = this.data.Profile.LastName;
            if (this.data.Profile.FirstName)
                contact.FirstName = this.data.Profile.FirstName;
            if (this.data.Profile.DialingCode)
                contact.DialingCode = this.data.Profile.DialingCode;
            contact.Name = contact.FirstName + ", " + contact.LastName;
        }
        return contact;
    }
    public RedirectToConfirmPage(external: boolean = false) {
        if (external) {
            this.data.storeBooking();
            this.GoToPage(this.data.PAGE_TYPE.CONFIRMED, null, true);
        } else {
            let that = this;
            this.HideAllModal();
            let interval = window.setInterval(function () {
                if (!jQuery('#' + that.data.MODAL_TYPE.PAYMENTPROCESS).is(':visible')) {
                    window.clearInterval(interval);
                    return;
                }
                that.HideModal(that.data.MODAL_TYPE.PAYMENTPROCESS);
            }, 100);

            /// Navigate
            this.data.storeBooking();
            this.UpdateProcessButton(false);
            this.data.Payment = PaymentItem.InitItem();

            this.GoToPage(this.data.PAGE_TYPE.CONFIRMED);

            /// Close popup
            this.data.PaymentProcessItem.Type = this.data.PAYMENT_PROCESS_STATUS.SUCCESS;
            this.HideModal(this.data.MODAL_TYPE.PAYMENTPROCESS);
        }
    }
    public UpdateProcessButton(loading: boolean, message: string = 'Processing') {
        if (loading) {
            if (jQuery('#btnConfirm').length > 0) {
                jQuery('#btnConfirm').prop("disabled", true);
                jQuery('#btnConfirm').html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> ' + message);
            }
            if (jQuery('#btnConfirmMobile').length > 0) {
                jQuery('#btnConfirmMobile').prop("disabled", true);
                jQuery('#btnConfirmMobile').html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> ' + message);
            }
            if (jQuery('#btnConfirmDialog').length > 0) {
                jQuery('#btnConfirmDialog').prop("disabled", true);
                jQuery('#btnConfirmDialog').html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> ' + message);
            }
            if (jQuery('#btnConfirmMobileStep').length > 0) {
                jQuery('#btnConfirmMobileStep').prop("disabled", true);
                jQuery('#btnConfirmMobileStep').html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> ' + message);
            }
        } else {
            if (jQuery('#btnConfirm').length > 0) {
                jQuery('#btnConfirm').prop("disabled", false);
                jQuery('#btnConfirm').html('<span>Book Now</span>');
            }
            if (jQuery('#btnConfirmMobile').length > 0) {
                jQuery('#btnConfirmMobile').prop("disabled", false);
                jQuery('#btnConfirmMobile').html('<span>Confirm</span>');
            }
            if (jQuery('#btnConfirmDialog').length > 0) {
                jQuery('#btnConfirmDialog').prop("disabled", false);
                jQuery('#btnConfirmDialog').html('<span>Pay Now</span>');
            }
            if (jQuery('#btnConfirmMobileStep').length > 0) {
                jQuery('#btnConfirmMobileStep').prop("disabled", false);
                if (this.data.Booking.Step == 4) {
                    jQuery('#btnConfirmMobileStep').html('<span>Confirm</span>');
                } else {
                    jQuery('#btnConfirmMobileStep').html('<span>Next</span>');
                }
            }
        }
        if (loading) this.data.LoadingPrice = loading;
        else {
            this.GetTotalFare();
            this.data.LoadingPrice = loading;
        }
    }
    public UpdateProcessButtonMax2(loading: boolean, message: string = 'Processing') {
        if (loading) {
            if (jQuery('#btnConfirm').length > 0) {
                jQuery('#btnConfirm').prop("disabled", true);
                jQuery('#btnConfirm').html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> ' + message);
            }
            if (jQuery('#btnConfirmMobile').length > 0) {
                jQuery('#btnConfirmMobile').prop("disabled", true);
                jQuery('#btnConfirmMobile').html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> ' + message);
            }
            if (jQuery('#btnConfirmDialog').length > 0) {
                jQuery('#btnConfirmDialog').prop("disabled", true);
                jQuery('#btnConfirmDialog').html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> ' + message);
            }
            if (jQuery('#btnConfirmMobileStep').length > 0) {
                jQuery('#btnConfirmMobileStep').prop("disabled", true);
                jQuery('#btnConfirmMobileStep').html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> ' + message);
            }
        } else {
            if (jQuery('#btnConfirm').length > 0) {
                jQuery('#btnConfirm').prop("disabled", false);
                jQuery('#btnConfirm').html('<span>Book Now</span>');
            }
            if (jQuery('#btnConfirmMobile').length > 0) {
                jQuery('#btnConfirmMobile').prop("disabled", false);
                jQuery('#btnConfirmMobile').html('<span>Book Now</span>');
            }
            if (jQuery('#btnConfirmDialog').length > 0) {
                jQuery('#btnConfirmDialog').prop("disabled", false);
                jQuery('#btnConfirmDialog').html('<span>Pay Now</span>');
            }
            if (jQuery('#btnConfirmMobileStep').length > 0) {
                jQuery('#btnConfirmMobileStep').prop("disabled", false);
                if (this.data.Booking.Step == 2) {
                    jQuery('#btnConfirmMobileStep').html('<span>Book Now</span>');
                } else {
                    jQuery('#btnConfirmMobileStep').html('<span>Next</span>');
                }
            }
        }
        if (loading) this.data.LoadingPrice = loading;
        else {
            this.GetTotalFare();
            this.data.LoadingPrice = loading;
        }
    }
    public UpdateProcessButtonMax3(loading: boolean, message: string = 'Processing') {
        if (loading) {
            if (jQuery('#btnConfirm').length > 0) {
                jQuery('#btnConfirm').prop("disabled", true);
                jQuery('#btnConfirm').html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> ' + message);
            }
            if (jQuery('#btnConfirmMobile').length > 0) {
                jQuery('#btnConfirmMobile').prop("disabled", true);
                jQuery('#btnConfirmMobile').html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> ' + message);
            }
            if (jQuery('#btnConfirmDialog').length > 0) {
                jQuery('#btnConfirmDialog').prop("disabled", true);
                jQuery('#btnConfirmDialog').html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> ' + message);
            }
            if (jQuery('#btnConfirmMobileStep').length > 0) {
                jQuery('#btnConfirmMobileStep').prop("disabled", true);
                jQuery('#btnConfirmMobileStep').html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> ' + message);
            }
        } else {
            if (jQuery('#btnConfirm').length > 0) {
                jQuery('#btnConfirm').prop("disabled", false);
                jQuery('#btnConfirm').html('<span>Book Now</span>');
            }
            if (jQuery('#btnConfirmMobile').length > 0) {
                jQuery('#btnConfirmMobile').prop("disabled", false);
                jQuery('#btnConfirmMobile').html('<span>Confirm</span>');
            }
            if (jQuery('#btnConfirmDialog').length > 0) {
                jQuery('#btnConfirmDialog').prop("disabled", false);
                jQuery('#btnConfirmDialog').html('<span>Pay Now</span>');
            }
            if (jQuery('#btnConfirmMobileStep').length > 0) {
                jQuery('#btnConfirmMobileStep').prop("disabled", false);
                if (this.data.Booking.Step == 3) {
                    jQuery('#btnConfirmMobileStep').html('<span>Confirm</span>');
                } else {
                    jQuery('#btnConfirmMobileStep').html('<span>Next</span>');
                }
            }
        }
        if (loading) this.data.LoadingPrice = loading;
        else {
            this.GetTotalFare();
            this.data.LoadingPrice = loading;
        }
    }
    public GoToPage(item: string, params: any = null, external: boolean = false, extension: boolean = true) {
        if (item == '' || item == '/' ||
            item == 'home' || item == 'home.html' ||
            item == 'index' || item == 'index.html') {
            window.location.href = '/';
            return;
        }
        if (extension) {
            if (item != '/' && item.indexOf('.html') < 0)
                item = item + ".html";
        }
        if (external) {
            window.location.href = item.indexOf('/') == 0 ? item : '/' + item;
            return;
        }
        this.HideAllModal();
        if (this.IsMobile())
            this.HideMenu();
        jQuery('body,html').animate({ scrollTop: 0 });

        let dropdown = jQuery('#header .dropdown-menu'); if (dropdown) dropdown.hide();
        if (this.data.UpdateTimeout !== null) {
            if (item != this.data.PAGE_TYPE.BOOKING && item != this.data.PAGE_TYPE.MENU)
                this.data.UpdateTimeout.unsubscribe();
        }
        if (params) this.router.navigate([item, params]);
        else this.router.navigate([item]);
    }

    public ContinueStep(scrollTop: boolean = true) {
        if (this.data.Booking.Step == null || this.data.Booking.Step == 0)
            this.data.Booking.Step = 1;
        this.SetCountryData();
        switch (this.data.Booking.Step) {
            case 1:
                {
                    // Kiểm tra tính hợp lý của toàn bộ dữ liệu
                    if (!this.data.validPickUp()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.PICKUP));
                        return;
                    }
                    if (!this.data.validPickDown()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.DROPOFF));
                        return;
                    }
                    if (!this.data.validViaAddress()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.VIAADDRESS));
                        return;
                    }
                    if (!this.data.validTimeExpired()) {
                        this.data.Booking.Time.IsValid = false;
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.DATETIME, this.data.DATETIME_POSITION_TYPE.PICKUP_BOOKING));
                        return;
                    }
                    if (!this.data.validReturnDate()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.RETURNDATE));
                        return;
                    }
                    if (environment.flightInline && !environment.allowFlightNull) {
                        if (!this.data.validFlight()) {
                            this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.FLIGHT));
                            return;
                        }
                        if (!this.data.validReturnFlight()) {
                            this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.RETURNFLIGHT));
                            return;
                        }
                    }

                    this.data.Booking.Step += 1;
                }
                break;
            case 2:
                {
                    if (!this.data.validVehicle()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.VEHICLE));
                        return;
                    }
                    this.data.Booking.Step += 1;
                }
                break;
            case 3:
                {
                    this.data.PopupPaymentCard = false;
                    // Kiểm tra tình trạng login (sử dụng config để xác định booking as guest hay không)
                    if (!environment.allowGuest) {
                        if (this.data.Profile == null) {
                            this.data.SignInNavigate = this.data.SIGNIN_NAVIGATE_TYPE.FLIGHT;
                            this.NavigateSignIn();
                            return;
                        }
                    }

                    // Kiểm tra thông tin Customer khi là Booker
                    if (this.IsBooker() && !this.data.validCustomer()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.CUSTOMER));
                        return;
                    }

                    // Kiểm tra thông tin Customer
                    if (!this.data.validContact()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.CONTACT));
                        return;
                    }

                    // Kiểm tra fligh khi nó là 1 thành phần popup
                    if (!environment.flightInline) {
                        if (this.NeedNavegateFlightDetail()) {
                            this.data.SignInNavigate = '';
                            this.NavigateFlightDetail();
                            return;
                        }

                        if (this.NeedNavegateReturnFlightDetail()) {
                            this.data.SignInNavigate = '';
                            this.NavigateReturnFlightDetail();
                            return;
                        }
                    }

                    // create payment
                    this.data.Booking.Step += 1;
                }
                break;
            case 4:
                {
                    if (this.data.Booking.PaymentType.toLowerCase() == this.data.PAYMENT_TYPE.CARD) {
                        if (!this.data.validPayment()) {
                            this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.PAYMENT));
                            return;
                        }
                    }
                    this.PaymentInline();
                }
                break;
        }

        this.UpdateProcessButton(false);
        if (scrollTop) {
            jQuery('body,html').animate({ scrollTop: 0 });
            jQuery(window).scrollTop(0);
        }
    }
    public ContinueStepMax2(scrollTop: boolean = true) {
        if (this.data.Booking.Step == null || this.data.Booking.Step == 0)
            this.data.Booking.Step = 1;
        this.SetCountryData();
        switch (this.data.Booking.Step) {
            case 1:
                {
                    // Kiểm tra tính hợp lý của toàn bộ dữ liệu
                    if (!this.data.validPickUp()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.PICKUP));
                        return;
                    }
                    if (!this.data.validPickDown()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.DROPOFF));
                        return;
                    }
                    if (!this.data.validViaAddress()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.VIAADDRESS));
                        return;
                    }
                    if (!this.data.validTimeExpired()) {
                        this.data.Booking.Time.IsValid = false;
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.DATETIME, this.data.DATETIME_POSITION_TYPE.PICKUP_BOOKING));
                        return;
                    }
                    if (!this.data.validReturnDate()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.RETURNDATE));
                        return;
                    }
                    if (!this.data.validVehicle()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.VEHICLE));
                        return;
                    }
                    if (environment.flightInline && !environment.allowFlightNull) {
                        if (!this.data.validFlight()) {
                            this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.FLIGHT));
                            return;
                        }
                        if (!this.data.validReturnFlight()) {
                            this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.RETURNFLIGHT));
                            return;
                        }
                    }

                    this.data.Booking.Step += 1;
                }
                break;
            case 2:
                {
                    this.data.PopupPaymentCard = false;
                    // Kiểm tra tình trạng login
                    if (!environment.allowGuest) {
                        if (this.data.Profile == null) {
                            this.data.SignInNavigate = this.data.SIGNIN_NAVIGATE_TYPE.FLIGHT;
                            this.NavigateSignIn();
                            return;
                        }
                    }

                    // Kiểm tra thông tin Customer khi là Booker
                    if (this.IsBooker() && !this.data.validCustomer()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.CUSTOMER));
                        return;
                    }

                    // Kiểm tra thông tin Customer
                    if (!this.data.validContact()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.CONTACT));
                        return;
                    }

                    // Kiểm tra thông tin payment
                    if (this.data.Booking.PaymentType.toLowerCase() == this.data.PAYMENT_TYPE.CARD) {
                        if (!this.data.validPayment()) {
                            this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.PAYMENT));
                            return;
                        }
                    }

                    // Kiểm tra fligh khi nó là 1 thành phần popup
                    if (!environment.flightInline) {
                        if (this.NeedNavegateFlightDetail()) {
                            this.data.SignInNavigate = '';
                            this.NavigateFlightDetail();
                            return;
                        }

                        if (this.NeedNavegateReturnFlightDetail()) {
                            this.data.SignInNavigate = '';
                            this.NavigateReturnFlightDetail();
                            return;
                        }
                    }
                    this.PaymentInline();
                }
                break;
        }

        this.UpdateProcessButtonMax2(false);
        if (scrollTop) {
            jQuery('body,html').animate({ scrollTop: 0 });
            jQuery(window).scrollTop(0);
        }
    }
    public ContinueStepMax3(scrollTop: boolean = true) {
        if (this.data.Booking.Step == null || this.data.Booking.Step == 0)
            this.data.Booking.Step = 1;
        this.SetCountryData();
        switch (this.data.Booking.Step) {
            case 1:
                {
                    // Kiểm tra tính hợp lý của toàn bộ dữ liệu
                    if (!this.data.validPickUp()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.PICKUP));
                        return;
                    }
                    if (!this.data.validPickDown()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.DROPOFF));
                        return;
                    }
                    if (!this.data.validViaAddress()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.VIAADDRESS));
                        return;
                    }
                    if (!this.data.validTimeExpired()) {
                        this.data.Booking.Time.IsValid = false;
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.DATETIME, this.data.DATETIME_POSITION_TYPE.PICKUP_BOOKING));
                        return;
                    }
                    if (!this.data.validReturnDate()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.RETURNDATE));
                        return;
                    }
                    if (!this.data.validVehicle()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.VEHICLE));
                        return;
                    }
                    if (environment.flightInline && !environment.allowFlightNull) {
                        if (!this.data.validFlight()) {
                            this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.FLIGHT));
                            return;
                        }
                        if (!this.data.validReturnFlight()) {
                            this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.RETURNFLIGHT));
                            return;
                        }
                    }

                    this.data.Booking.Step += 1;
                }
                break;
            case 2:
                {
                    this.data.PopupPaymentCard = false;
                    // Kiểm tra tình trạng login
                    if (!environment.allowGuest) {
                        if (this.data.Profile == null) {
                            this.data.SignInNavigate = this.data.SIGNIN_NAVIGATE_TYPE.FLIGHT;
                            this.NavigateSignIn();
                            return;
                        }
                    }

                    // Kiểm tra thông tin Customer khi là Booker
                    if (this.IsBooker() && !this.data.validCustomer()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.CUSTOMER));
                        return;
                    }

                    // Kiểm tra thông tin Customer
                    if (!this.data.validContact()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.CONTACT));
                        return;
                    }

                    // Kiểm tra fligh khi nó là 1 thành phần popup
                    if (!environment.flightInline) {
                        if (this.NeedNavegateFlightDetail()) {
                            this.data.SignInNavigate = '';
                            this.NavigateFlightDetail();
                            return;
                        }

                        if (this.NeedNavegateReturnFlightDetail()) {
                            this.data.SignInNavigate = '';
                            this.NavigateReturnFlightDetail();
                            return;
                        }
                    }
                    this.data.Booking.Step += 1;
                }
                break;
            case 3:
                {
                    if (this.data.Booking.PaymentType.toLowerCase() == this.data.PAYMENT_TYPE.CARD) {
                        if (!this.data.validPayment()) {
                            this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.PAYMENT));
                            return;
                        }
                    }
                    this.PaymentInline();
                }
                break;
        }

        this.UpdateProcessButtonMax3(false);
        if (scrollTop) {
            jQuery('body,html').animate({ scrollTop: 0 });
            jQuery(window).scrollTop(0);
        }
    }
    public ContinueStepMax3Payment(scrollTop: boolean = true) {
        console.log('xxx');
        if (this.data.Booking.Step == null || this.data.Booking.Step == 0)
            this.data.Booking.Step = 1;
        this.SetCountryData();
        switch (this.data.Booking.Step) {
            case 1:
                {
                    // Kiểm tra tính hợp lý của toàn bộ dữ liệu
                    if (!this.data.validPickUp()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.PICKUP));
                        return;
                    }
                    if (!this.data.validPickDown()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.DROPOFF));
                        return;
                    }
                    if (!this.data.validViaAddress()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.VIAADDRESS));
                        return;
                    }
                    if (!this.data.validTimeExpired()) {
                        this.data.Booking.Time.IsValid = false;
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.DATETIME, this.data.DATETIME_POSITION_TYPE.PICKUP_BOOKING));
                        return;
                    }
                    if (!this.data.validReturnDate()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.RETURNDATE));
                        return;
                    }
                    if (!this.data.validVehicle()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.VEHICLE));
                        return;
                    }
                    if (environment.flightInline && !environment.allowFlightNull) {
                        if (!this.data.validFlight()) {
                            this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.FLIGHT));
                            return;
                        }
                        if (!this.data.validReturnFlight()) {
                            this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.RETURNFLIGHT));
                            return;
                        }
                    }

                    this.data.Booking.Step += 1;
                }
                break;
            case 2:
                {
                    if (this.data.Booking.PaymentType.toLowerCase() == this.data.PAYMENT_TYPE.CARD) {
                        if (!this.data.validPayment()) {
                            this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.PAYMENT));
                            return;
                        }
                    }
                    this.data.Booking.Step += 1;
                }
                break;
            case 3:
                {
                    this.data.PopupPaymentCard = false;
                    // Kiểm tra tình trạng login
                    if (!environment.allowGuest) {
                        if (this.data.Profile == null) {
                            this.data.SignInNavigate = this.data.SIGNIN_NAVIGATE_TYPE.FLIGHT;
                            this.NavigateSignIn();
                            return;
                        }
                    }

                    // Kiểm tra thông tin Customer khi là Booker
                    if (this.IsBooker() && !this.data.validCustomer()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.CUSTOMER));
                        return;
                    }

                    // Kiểm tra thông tin Customer
                    if (!this.data.validContact()) {
                        this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.CONTACT));
                        return;
                    }

                    // Kiểm tra fligh khi nó là 1 thành phần popup
                    if (!environment.flightInline) {
                        if (this.NeedNavegateFlightDetail()) {
                            this.data.SignInNavigate = '';
                            this.NavigateFlightDetail();
                            return;
                        }

                        if (this.NeedNavegateReturnFlightDetail()) {
                            this.data.SignInNavigate = '';
                            this.NavigateReturnFlightDetail();
                            return;
                        }
                    }
                    this.PaymentInline();
                }
                break;
        }

        this.UpdateProcessButtonMax3(false);
        if (scrollTop) {
            jQuery('body,html').animate({ scrollTop: 0 });
            jQuery(window).scrollTop(0);
        }
    }
    public PreviousStep(step: number = null, scrollTop: boolean = true) {
        if (step == null) {
            if (this.data.Booking.Step > 1)
                this.data.Booking.Step -= 1;
        } else {
            if (this.data.Booking.Step >= step)
                this.data.Booking.Step = step;
        }
        if (this.data.Booking.Step <= 3 && this.data.Payment != null) {
            this.data.Payment.CVC = '';
        }

        if (jQuery('#btnConfirmMobileStep').length > 0) {
            jQuery('#btnConfirmMobileStep').prop("disabled", false);
            if (this.data.Booking.Step == 4) {
                jQuery('#btnConfirmMobileStep').html('<span>Confirm</span>');
            } else {
                jQuery('#btnConfirmMobileStep').html('<span>Next</span>');
            }
        }
        if (scrollTop) {
            jQuery('body,html').animate({ scrollTop: 0 });
            jQuery(window).scrollTop(0);
        }
    }
    public PreviousStepMax2(step: number = null, scrollTop: boolean = true) {
        if (step == null) {
            if (this.data.Booking.Step > 1)
                this.data.Booking.Step -= 1;
        } else {
            if (this.data.Booking.Step >= step)
                this.data.Booking.Step = step;
        }
        if (this.data.Booking.Step <= 1 && this.data.Payment != null) {
            this.data.Payment.CVC = '';
        }

        if (jQuery('#btnConfirmMobileStep').length > 0) {
            jQuery('#btnConfirmMobileStep').prop("disabled", false);
            if (this.data.Booking.Step == 2) {
                jQuery('#btnConfirmMobileStep').html('<span>Confirm</span>');
            } else {
                jQuery('#btnConfirmMobileStep').html('<span>Next</span>');
            }
        }
        if (scrollTop) {
            jQuery('body,html').animate({ scrollTop: 0 });
            jQuery(window).scrollTop(0);
        }
    }
    public PreviousStepMax3(step: number = null, scrollTop: boolean = true) {
        if (step == null) {
            if (this.data.Booking.Step > 1)
                this.data.Booking.Step -= 1;
        } else {
            if (this.data.Booking.Step >= step)
                this.data.Booking.Step = step;
        }
        if (this.data.Booking.Step <= 2 && this.data.Payment != null) {
            this.data.Payment.CVC = '';
        }

        if (jQuery('#btnConfirmMobileStep').length > 0) {
            jQuery('#btnConfirmMobileStep').prop("disabled", false);
            if (this.data.Booking.Step == 3) {
                jQuery('#btnConfirmMobileStep').html('<span>Confirm</span>');
            } else {
                jQuery('#btnConfirmMobileStep').html('<span>Next</span>');
            }
        }
        if (scrollTop) {
            jQuery('body,html').animate({ scrollTop: 0 });
            jQuery(window).scrollTop(0);
        }
    }


    public SetCountryData() {
        // country
        let elementCountry = jQuery('#payment-country');
        if (elementCountry && elementCountry.length > 0) {
            let countryData = elementCountry.countrySelect("getSelectedCountryData");
            if (countryData) {
                this.data.Payment.Country = countryData.name;
                this.data.Payment.CountryCode = countryData.iso2;
            }
        }

        // popup country
        elementCountry = jQuery('#dialog-payment-country');
        if (elementCountry && elementCountry.length > 0) {
            let countryData = elementCountry.countrySelect("getSelectedCountryData");
            if (countryData) {
                this.data.Payment.Country = countryData.name;
                this.data.Payment.CountryCode = countryData.iso2;
            }
        }
    }
    public SelectedItem(item: AddressItem, i: number) {
        if (i == -1) {
            jQuery('#pickup-google').blur();
            jQuery('#pickup-google').attr('readonly', 'readonly');
            this.data.Booking.PickUp = item;
            if (this.data.Booking.PickUp != null) this.data.Booking.PickUp.Valid = true;
            if (item.Postcode.length == 0) {
                let event = new EventBooking(this.data.TARGET_EVENT_TYPE.PICKUP, i, false);
                event.Message = this.data.ERROR_TRY_OTHER_ADDRESS_MESSAGE;
                event.Type = this.data.ERROR_TYPE.INVALID;
                this.data.BookingItemError.emit(event);
            } else if (this.ComparePickAddress(this.data.Booking.PickUp, this.data.Booking.PickDown)) {
                let event = new EventBooking(this.data.TARGET_EVENT_TYPE.PICKUP, i);
                event.Message = this.data.ERROR_MESSAGE;
                event.ClearInput = true;
                this.data.BookingItemError.emit(event);
                this.data.ShowDialog.emit(new Dialog(event.Message, 'Error', '', () => {
                    setTimeout(() => {
                        if (this.IsMobile())
                            this.ScrollToSticky('pickup-google', false);
                    }, 500);
                }));
            } else {
                this.data.BookingItemChange.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.PICKUP, i));
            }
            window.setTimeout(function () {
                jQuery('#pickup-google').blur();
                jQuery('#pickup-google').removeAttr('readonly');
            }, 500);
        } else if (i == 100) {
            jQuery('#dropoff-google').blur();
            jQuery('#dropoff-google').attr('readonly', 'readonly');
            this.data.Booking.PickDown = item;
            if (this.data.Booking.PickDown != null) this.data.Booking.PickDown.Valid = true;
            if (item.Postcode.length == 0) {
                let event = new EventBooking(this.data.TARGET_EVENT_TYPE.DROPOFF, i, false);
                event.Message = this.data.ERROR_TRY_OTHER_ADDRESS_MESSAGE;
                event.Type = this.data.ERROR_TYPE.INVALID;
                this.data.BookingItemError.emit(event);
            } else if (this.ComparePickAddress(this.data.Booking.PickUp, this.data.Booking.PickDown)) {
                let event = new EventBooking(this.data.TARGET_EVENT_TYPE.DROPOFF, i);
                event.Message = this.data.ERROR_MESSAGE;
                event.ClearInput = true;
                this.data.BookingItemError.emit(event);
                this.data.ShowDialog.emit(new Dialog(event.Message, 'Error', '', () => {
                    setTimeout(() => {
                        if (this.IsMobile())
                            this.ScrollToSticky('dropoff-google', false);
                    }, 500);
                }));
            } else {
                this.data.BookingItemChange.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.DROPOFF, i));
            }
            window.setTimeout(function () {
                jQuery('#dropoff-google').blur();
                jQuery('#dropoff-google').removeAttr('readonly');
            }, 500);
        } else if (i >= 0) {
            let editorId = '#via- google -' + (i + 1).toString();
            jQuery(editorId).blur();
            jQuery(editorId).attr('readonly', 'readonly');
            this.data.Booking.ViaAddress[i] = item;
            if (this.data.Booking.ViaAddress[i] != null) this.data.Booking.ViaAddress[i].Valid = true;
            if (item.Postcode.length == 0) {
                let event = new EventBooking(this.data.TARGET_EVENT_TYPE.VIAADDRESS, i, false);
                event.Message = this.data.ERROR_TRY_OTHER_ADDRESS_MESSAGE;
                event.Type = this.data.ERROR_TYPE.INVALID;
                this.data.BookingItemError.emit(event);
            } else if (this.ComparePickAddress(this.data.Booking.PickUp, this.data.Booking.ViaAddress[i])) {
                let event = new EventBooking(this.data.TARGET_EVENT_TYPE.VIAADDRESS, i);
                event.Message = this.data.ERROR_MESSAGE;
                event.ClearInput = true;
                this.data.BookingItemError.emit(event);
                this.data.ShowDialog.emit(new Dialog(event.Message, 'Error', '', () => {
                    setTimeout(() => {
                        if (this.IsMobile())
                            this.ScrollToSticky('via-google-' + (i + 1).toString(), false);
                    }, 500);
                }));
            } else if (this.ComparePickAddress(this.data.Booking.PickDown, this.data.Booking.ViaAddress[i])) {
                let event = new EventBooking(this.data.TARGET_EVENT_TYPE.VIAADDRESS, i);
                event.Message = this.data.ERROR_MESSAGE;
                event.ClearInput = true;
                this.data.BookingItemError.emit(event);
                this.data.ShowDialog.emit(new Dialog(event.Message, 'Error', '', () => {
                    setTimeout(() => {
                        if (this.IsMobile())
                            this.ScrollToSticky(editorId, false);
                    }, 500);
                }));
            } else {
                this.data.BookingItemChange.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.VIAADDRESS, i));
            }
            window.setTimeout(function () {
                jQuery(editorId).blur();
                jQuery(editorId).removeAttr('readonly');
            }, 500);
        }
    }
    public SetLandingTimeWait(returnFlight: boolean = false) {
        if (!returnFlight) {
            if (this.data.Booking.Flight.LandingTime) {
                if (typeof (this.data.Booking.Flight.LandingTime) == 'string')
                    this.data.Booking.Flight.LandingTime = new Date(this.data.Booking.Flight.LandingTime);
                let landingTimeMin = _.cloneDeep(this.data.Booking.Flight.LandingTime);
                landingTimeMin.setMinutes(landingTimeMin.getMinutes() + 30);

                this.data.Booking.Flight.LandingTimeMin = _.cloneDeep(landingTimeMin);
                if (!this.data.Booking.Time.Time) {
                    let pickTime = _.cloneDeep(landingTimeMin);
                    if (!this.data.validTimeExpired(true)) {
                        let earliestTime = _.cloneDeep(this.data.Booking.Time.EarliestTime),
                            earliestTimeMinutes = earliestTime.getMinutes(),
                            plusMinutes = 5 - earliestTimeMinutes % 5;
                        earliestTime.setMinutes(earliestTime.getMinutes() + plusMinutes);
                        this.data.Booking.Time.Time = earliestTime > pickTime ? earliestTime : pickTime;
                    } else this.data.Booking.Time.Time = pickTime;
                }
                this.data.FlightValidDateTime = this.data.Booking.Time.Time >= landingTimeMin;

                let waitingTime = _.cloneDeep(this.data.Booking.Time.Time),
                    waitingTimeMinutes = this.data.Booking.Flight.IncludedWaiting ? 60 : 60;
                waitingTime.setMinutes(waitingTime.getMinutes() + waitingTimeMinutes);
                this.data.Booking.Flight.LandingTimeWait = waitingTime;

                if (this.data.Booking.Flight.LandingTimeWait && this.data.Booking.Flight.LandingTime) {
                    this.data.Booking.TimeLineItems = [];
                    let itemPickTime: TimeLineItem = this.data.Booking.Time.Time ? {
                        Date: this.data.Booking.Time.Time,
                        Icon: '/assets/images/pickup/picktime.png',
                        Description: 'Driver arrives, free waiting time starts',
                    } : null;
                    if (itemPickTime) this.data.Booking.TimeLineItems.push(itemPickTime);
                    let itemLandingTime: TimeLineItem = this.data.Booking.Flight.LandingTime ? {
                        Date: this.data.Booking.Flight.LandingTime,
                        Icon: '/assets/images/pickup/landing.png',
                        Description: 'Landing Time',
                    } : null;
                    if (itemLandingTime) this.data.Booking.TimeLineItems.push(itemLandingTime);
                    let itemWaitingTime: TimeLineItem = this.data.Booking.Flight.LandingTimeWait ? {
                        Date: this.data.Booking.Flight.LandingTimeWait,
                        Icon: '/assets/images/pickup/waiting.png',
                        Description: 'Free waiting time ends, charges may apply',
                    } : null;
                    if (itemWaitingTime) this.data.Booking.TimeLineItems.push(itemWaitingTime);
                    this.data.Booking.TimeLineItems = _.sortBy(this.data.Booking.TimeLineItems, c => c.Date);
                    let startTime = this.data.Booking.TimeLineItems[0],
                        middleTime = this.data.Booking.TimeLineItems[1],
                        endTime = this.data.Booking.TimeLineItems[this.data.Booking.TimeLineItems.length - 1],
                        totalTimes = endTime.Date.getTime() - startTime.Date.getTime(),
                        diffTimes = middleTime.Date.getTime() - startTime.Date.getTime(),
                        pickTimePercent = diffTimes / totalTimes * 100;
                    if (pickTimePercent < 20) pickTimePercent = 20;
                    else if (pickTimePercent > 60) pickTimePercent = 60;
                    this.data.Booking.TimeLinePercent = pickTimePercent;
                }
            }
        } else {
            if (this.data.Booking.ReturnFlight.LandingTime) {
                if (typeof (this.data.Booking.ReturnFlight.LandingTime) == 'string')
                    this.data.Booking.ReturnFlight.LandingTime = new Date(this.data.Booking.ReturnFlight.LandingTime);
                let landingTimeMin = _.cloneDeep(this.data.Booking.ReturnFlight.LandingTime);
                landingTimeMin.setMinutes(landingTimeMin.getMinutes() + 30);

                this.data.Booking.ReturnFlight.LandingTimeMin = _.cloneDeep(landingTimeMin);
                if (!this.data.Booking.ReturnDate) {
                    let pickTime = _.cloneDeep(landingTimeMin);
                    if (!this.data.Booking.ReturnEarliestTime)
                        this.data.Booking.ReturnEarliestTime = this.data.getReturnEarliestTime();
                    if (!this.data.validReturnDate()) {
                        let earliestTime = _.cloneDeep(this.data.Booking.ReturnEarliestTime),
                            earliestTimeMinutes = earliestTime.getMinutes(),
                            plusMinutes = 5 - earliestTimeMinutes % 5;
                        earliestTime.setMinutes(earliestTime.getMinutes() + plusMinutes);
                        this.data.Booking.ReturnDate = earliestTime > pickTime ? earliestTime : pickTime;
                    } else this.data.Booking.ReturnDate = pickTime;
                }
                this.data.ReturnFlightValidDateTime = this.data.Booking.ReturnDate >= landingTimeMin;

                let waitingTime = _.cloneDeep(this.data.Booking.ReturnDate),
                    waitingTimeMinutes = this.data.Booking.Flight.IncludedWaiting ? 60 : 60;
                waitingTime.setMinutes(waitingTime.getMinutes() + waitingTimeMinutes);
                this.data.Booking.ReturnFlight.LandingTimeWait = waitingTime;

                if (this.data.Booking.ReturnFlight.LandingTimeWait && this.data.Booking.ReturnFlight.LandingTime) {
                    this.data.Booking.ReturnTimeLineItems = [];
                    let itemPickTime: TimeLineItem = this.data.Booking.ReturnDate ? {
                        Date: this.data.Booking.ReturnDate,
                        Icon: '/assets/images/pickup/picktime.png',
                        Description: 'Driver arrives, free waiting time starts',
                    } : null;
                    if (itemPickTime) this.data.Booking.ReturnTimeLineItems.push(itemPickTime);
                    let itemLandingTime: TimeLineItem = this.data.Booking.ReturnFlight.LandingTime ? {
                        Date: this.data.Booking.ReturnFlight.LandingTime,
                        Icon: '/assets/images/pickup/landing.png',
                        Description: 'Landing Time',
                    } : null;
                    if (itemLandingTime) this.data.Booking.ReturnTimeLineItems.push(itemLandingTime);
                    let itemWaitingTime: TimeLineItem = this.data.Booking.ReturnFlight.LandingTimeWait ? {
                        Date: this.data.Booking.ReturnFlight.LandingTimeWait,
                        Icon: '/assets/images/pickup/waiting.png',
                        Description: 'Free waiting time ends, charges may apply',
                    } : null;
                    if (itemWaitingTime) this.data.Booking.ReturnTimeLineItems.push(itemWaitingTime);
                    this.data.Booking.ReturnTimeLineItems = _.sortBy(this.data.Booking.ReturnTimeLineItems, c => c.Date);
                    let startTime = this.data.Booking.ReturnTimeLineItems[0],
                        middleTime = this.data.Booking.ReturnTimeLineItems[1],
                        endTime = this.data.Booking.ReturnTimeLineItems[this.data.Booking.ReturnTimeLineItems.length - 1],
                        totalTimes = endTime.Date.getTime() - startTime.Date.getTime(),
                        diffTimes = middleTime.Date.getTime() - startTime.Date.getTime(),
                        pickTimePercent = diffTimes / totalTimes * 100;
                    if (pickTimePercent < 20) pickTimePercent = 20;
                    else if (pickTimePercent > 60) pickTimePercent = 60;
                    this.data.Booking.ReturnTimeLinePercent = pickTimePercent;
                }
            }
        }
        this.data.storeBooking();
    }
    public CheckSelectedFlight(returnFlight: boolean = false) {
        if (!returnFlight) {
            this.data.FlightValidLanding = true;
            this.data.FlightValidDateTime = true;
            let pickUpCode = this.data.Booking.PickUp && this.data.Booking.PickUp.Code,
                flightCode = this.data.Booking.Flight && this.data.Booking.Flight.AirportCode;
            if (flightCode)
                this.data.FlightValidLanding = pickUpCode === flightCode;
            this.SetLandingTimeWait(returnFlight);
        } else {
            this.data.ReturnFlightValidLanding = true;
            this.data.ReturnFlightValidDateTime = true;
            let pickUpCode = this.data.Booking.PickDown && this.data.Booking.PickDown.Code,
                flightCode = this.data.Booking.ReturnFlight && this.data.Booking.ReturnFlight.AirportCode;
            if (flightCode)
                this.data.ReturnFlightValidLanding = pickUpCode === flightCode;
            this.SetLandingTimeWait(returnFlight);
        }
    }
    public SelectedFlight(airport: FlightResultItem, returnFlight: boolean = false) {
        if (airport == null) {
            if (!returnFlight) {
                this.data.Booking.Flight.Number = null;
                this.data.Booking.Flight.Airport = null;
                this.data.Booking.Flight.AirportText = null;
                this.data.Booking.Flight.LandingTime = null;
                this.data.Booking.Flight.LandingTimeWait = null;
            } else {
                this.data.Booking.ReturnFlight.Number = null;
                this.data.Booking.ReturnFlight.Airport = null;
                this.data.Booking.ReturnFlight.AirportText = null;
                this.data.Booking.ReturnFlight.LandingTime = null;
                this.data.Booking.ReturnFlight.LandingTimeWait = null;
            }
        } else {
            if (!returnFlight) {
                this.data.Booking.Flight.Airport = {
                    Code: airport.AirportCode,
                    Name: airport.AirportText,
                    FullAddress: airport.FullAddress,
                };
                this.data.Booking.Flight.Number = airport.Number;
                this.data.Booking.Flight.AirportCode = airport.AirportCode;
                this.data.Booking.Flight.AirportText = airport.AirportText;
                this.data.Booking.Flight.LandingTime = airport.LandingTime;
                this.CheckSelectedFlight(returnFlight);
            } else {
                this.data.Booking.ReturnFlight.Airport = {
                    Code: airport.AirportCode,
                    Name: airport.AirportText,
                    FullAddress: airport.FullAddress,
                };
                this.data.Booking.ReturnFlight.Number = airport.Number;
                this.data.Booking.ReturnFlight.AirportCode = airport.AirportCode;
                this.data.Booking.ReturnFlight.AirportText = airport.AirportText;
                this.data.Booking.ReturnFlight.LandingTime = airport.LandingTime;
                this.CheckSelectedFlight(returnFlight);
            }
        }
    }

    public IsBooker() {
        return this.data.Profile && this.data.Profile.BookerId ? true : false;
    }
    public IsMobile(): boolean {
        let width = jQuery(window).length > 0 ? jQuery(window).width() : jQuery('body').width();
        return width <= this.data.WINDOW_SIZE.PHONE;
    }
    public IsInputFocus(): boolean {
        return (jQuery('input[type=text],input[type=tel],input[type=email],textarea').is(':focus') || jQuery('.ui-autocomplete-panel').hasClass('active')) ||
            (jQuery('#pickcard .country-list').length > 0 && !jQuery('#pickcard .country-list').hasClass('hide'));
    }

    public CreateStripePayment() {
        let cardNumber = jQuery('#payment-card-number');
        if (cardNumber && cardNumber.length > 0) {
            let elementStyles = {
                base: {
                    lineHeight: '50px',
                    borderRadius: '0px',
                    '::placeholder': {
                        color: '#aaa',
                    },
                    ':-webkit-autofill': {
                        color: '#211f1f',
                    },
                }
            };
            if (environment.name == 'marblex') {
                elementStyles = {
                    base: {
                        lineHeight: '50px',
                        borderRadius: '4px',
                        '::placeholder': {
                            color: '#aaa',
                        },
                        ':-webkit-autofill': {
                            color: '#211f1f',
                        },
                    }
                };
            }
            if (this.data.Configuration.StripePublicKey) {
                this.data.Stripe = Stripe(this.data.Configuration.StripePublicKey);
                let elements = this.data.Stripe.elements();
                this.data.StripeCardNumber = elements.create('cardNumber', {
                    style: elementStyles
                });
                this.data.StripeCardNumber.mount('#payment-card-number');

                this.data.StripeCardExpiry = elements.create('cardExpiry', {
                    style: elementStyles
                });
                this.data.StripeCardExpiry.mount('#payment-card-expiry');

                this.data.StripeCardCvc = elements.create('cardCvc', {
                    style: elementStyles
                });
                this.data.StripeCardCvc.mount('#payment-card-cvc');

                // apple, google pay
                let paymentButton = jQuery('#payment-request-button');
                if (paymentButton && paymentButton.length > 0) {
                    let paymentRequest = this.data.Stripe.paymentRequest();
                    let prButton = elements.create('paymentRequestButton', {
                        paymentRequest: paymentRequest,
                    });
                    paymentRequest.canMakePayment().then((result: any) => {
                        if (result) {
                            prButton.mount('#payment-request-button');
                        } else {
                            document.getElementById('payment-request-button').style.display = 'none';
                        }
                    });
                    paymentRequest.on('paymentmethod', (ev: any) => {
                        this.ElectronicPaymentBookingStripe(ev);
                    });
                }
            }
        }
    }
    public CreateStripePopupPayment() {
        if (!this.data.Configuration.StripePublicKey)
            return;
        let cardNumber = jQuery('#payment-popup-card-number');
        if (cardNumber && cardNumber.length > 0) {
            let elementStyles = {
                base: {
                    lineHeight: '50px',
                    '::placeholder': {
                        color: '#aaa',
                    },
                    ':-webkit-autofill': {
                        color: '#211f1f',
                    },
                }
            };
            this.data.StripePopup = Stripe(this.data.Configuration.StripePublicKey);
            let elements = this.data.StripePopup.elements();
            this.data.StripePopupCardNumber = elements.create('cardNumber', {
                style: elementStyles
            });
            this.data.StripePopupCardNumber.mount('#payment-popup-card-number');

            this.data.StripePopupCardExpiry = elements.create('cardExpiry', {
                style: elementStyles
            });
            this.data.StripePopupCardExpiry.mount('#payment-popup-card-expiry');

            this.data.StripePopupCardCvc = elements.create('cardCvc', {
                style: elementStyles
            });
            this.data.StripePopupCardCvc.mount('#payment-popup-card-cvc');

            // apple, google pay
            let paymentButton = jQuery('#payment-popup-request-button');
            if (paymentButton && paymentButton.length > 0) {
                let paymentRequest = this.data.StripePopup.paymentRequest();
                let prButton = elements.create('paymentRequestButton', {
                    paymentRequest: paymentRequest,
                });
                paymentRequest.canMakePayment().then((result: any) => {
                    if (result) {
                        prButton.mount('#payment-popup-request-button');
                    } else {
                        document.getElementById('payment-popup-request-button').style.display = 'none';
                    }
                });
                paymentRequest.on('paymentmethod', (ev: any) => {
                    this.ElectronicPaymentBookingStripe(ev);
                });
            }
        }
    }

    private paymentStripeError(result: any = null) {
        this.HideAllModal();
        this.data.Payment.CVC = null;
        this.UpdateProcessButton(false);
        if (result)
            console.log('Call Stripe [ERROR]: ', result);
        else
            console.log('Call Stripe [ERROR]');
        if (this.data.StripeCardCvc) this.data.StripeCardCvc.clear();
        if (this.data.StripePopupCardCvc) this.data.StripePopupCardCvc.clear();
        setTimeout(() => {
            this.data.ShowDialog.emit(new Dialog(this.data.ERROR_MESSAGE_CARD));
        }, 1000);
    }
    private paymentStripeSuccess(result: any, model: ElectronicBookingStripeItem = null) {
        this.HideAllModal();
        this.UpdateProcessButton(false);
        let update: UpdateBooking = {
            Id: result.paymentIntent.id,
            Status: result.paymentIntent.status,
            JobpartId: parseInt(this.data.Booking.Bref),
            ReturnJobpartId: this.data.Booking.ReturnRef
                ? parseInt(this.data.Booking.ReturnRef)
                : null,
        };
        this.service.updateBooking(update).subscribe((resultUpdate: BaseItem) => {
            console.log('Call Stripe [SUCCESS]: Update Booking');
            if (resultUpdate.Code == 1) {
                this.data.Booking.IsPaid = true;
                this.data.Booking.AuthCode = model && model.PaymentResult && model.PaymentResult.AuthCode;
                if (model) this.RedirectToConfirmPage(false);
                else this.GoToPage(this.data.PAGE_TYPE.CONFIRMED, this.data.Booking.Bref);
            } else {
                if (this.data.StripeCardCvc) this.data.StripeCardCvc.clear();
                let errorMessage = resultUpdate.Message || this.data.ERROR_MESSAGE;
                if (this.data.StripePopupCardCvc) this.data.StripePopupCardCvc.clear();
                this.data.ShowDialog.emit(new Dialog(errorMessage));
            }
        });
    }

    Notification(title: string, body: string) {
        // Let's check if the browser supports notifications
        if (!("Notification" in window)) {
            this.data.ShowDialog.emit(new Dialog(body, title, this.data.DIALOG_TYPE.DIALOG));
            return;
        }

        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === "granted") {
            // If it's okay let's create a notification
            new Notification(title, {
                body: body,
            });
            return;
        }

        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== "denied") {
            Notification.requestPermission().then((permission) => {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    new Notification(title, {
                        body: body,
                    });
                } else this.data.ShowDialog.emit(new Dialog(body, title, this.data.DIALOG_TYPE.DIALOG));
            });
        } else this.data.ShowDialog.emit(new Dialog(body, title, this.data.DIALOG_TYPE.DIALOG));
    }

    public GetMessage(model: any) {
        if (model.Code == 0 || model.code == 0) {
            return this.data.ERROR_MESSAGE;
        } else if (model.Code == 2) {
            return model.message || model.Message || this.data.ERROR_MESSAGE;
        }
        return this.data.ERROR_MESSAGE;
    }
}
