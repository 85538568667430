import { Component } from '@angular/core';
import { Dialog } from './../../../domain/dialog';
import { SignIn } from '../../../domain/object/signin';
import { SignUp } from '../../../domain/object/signup';
import { DataService } from './../../../services/dataService';
import { UtilService } from './../../../services/utilService';

@Component({
    selector: 'alert-dialog',
    templateUrl: './alert.dialog.component.html',
    host: {
        '(document:keydown)': 'keyDownFunction($event)'
    }
})

export class AlertDialogComponent {
    dialog: Dialog;
    constructor(public data: DataService, public util: UtilService) {
        this.dialog = new Dialog(this.data.ERROR_MESSAGE);
        this.data.ShowDialog.subscribe((model: any) => {
            this.dialog = typeof model == 'string' ? new Dialog(model.toString()) : model;
            if (this.dialog.Content.indexOf('Stripe') > 0) 
                this.dialog.Content = this.data.ERROR_MESSAGE;
            this.util.ShowModal(this.data.MODAL_TYPE.ALERT, 200);
        });
    }

    onClose() {
        this.util.HideModal(this.data.MODAL_TYPE.ALERT);
        this.data.ManualTimeout = false;
        if (this.dialog.OkFunction) {
            this.dialog.OkFunction();
        }
    }

    openSignin() {
        this.data.SignInType = this.data.SIGNIN_TYPE.SIGNIN;
        if (this.data.SignIn == null) this.data.SignIn = new SignIn();
        if (this.data.SignUp == null) this.data.SignUp = new SignUp();

        this.util.HideModal(this.data.MODAL_TYPE.ALERT);
        this.util.ShowModal(this.data.MODAL_TYPE.SIGNIN, 500);
    }

    openSignup() {
        this.data.SignInType = this.data.SIGNIN_TYPE.SIGNUP;
        if (this.data.SignIn == null) this.data.SignIn = new SignIn();
        if (this.data.SignUp == null) this.data.SignUp = new SignUp();

        this.util.HideModal(this.data.MODAL_TYPE.MENU);
        this.util.ShowModal(this.data.MODAL_TYPE.SIGNIN, 500);
    }

    keyDownFunction(event: any) {
        if (this.data.ModalType == this.data.MODAL_TYPE.ALERT) {
            if (event.keyCode == 13) {
                if (this.dialog.Title == this.data.DIALOG_TYPE.SIGNIN)
                    this.openSignin();
                else
                    this.onClose();
            }
        }
    }
}