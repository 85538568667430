import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./modules/booking/booking.module').then(m => m.BookingModule) },
  { path: 'book/', loadChildren: () => import('./modules/booking/booking.module').then(m => m.BookingModule) },
  { path: 'booking', loadChildren: () => import('./modules/booking/booking.module').then(m => m.BookingModule) },
  { path: 'payment', loadChildren: () => import('./modules/payment/payment.module').then(m => m.PaymentModule) },
  { path: 'booking/', loadChildren: () => import('./modules/booking/booking.module').then(m => m.BookingModule) },
  { path: 'bookings', loadChildren: () => import('./modules/booking/booking.module').then(m => m.BookingModule) },
  { path: 'booking.html', loadChildren: () => import('./modules/booking/booking.module').then(m => m.BookingModule) },
  { path: 'payment.html', loadChildren: () => import('./modules/payment/payment.module').then(m => m.PaymentModule) },
  { path: 'litebooking', loadChildren: () => import('./modules/litebooking/litebooking.module').then(m => m.LiteBookingModule) },
  { path: 'quickbooking', loadChildren: () => import('./modules/quickbooking/quickbooking.module').then(m => m.QuickBookingModule) },
  { path: 'litebooking.html', loadChildren: () => import('./modules/litebooking/litebooking.module').then(m => m.LiteBookingModule) },
  { path: 'quickbooking.html', loadChildren: () => import('./modules/quickbooking/quickbooking.module').then(m => m.QuickBookingModule) },

  { path: 'invoice', loadChildren: () => import('./modules/account/invoice/invoice.module').then(m => m.InvoiceModule) },
  { path: 'tracking', loadChildren: () => import('./modules/account/tracking/tracking.module').then(m => m.TrackingModule) },
  { path: 'invoice.html', loadChildren: () => import('./modules/account/invoice/invoice.module').then(m => m.InvoiceModule) },
  { path: 'tracking/:id', loadChildren: () => import('./modules/account/tracking/tracking.module').then(m => m.TrackingModule) },
  { path: 'dashboard', loadChildren: () => import('./modules/account/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'tracking.html', loadChildren: () => import('./modules/account/tracking/tracking.module').then(m => m.TrackingModule) },
  { path: 'tracking.html/:id', loadChildren: () => import('./modules/account/tracking/tracking.module').then(m => m.TrackingModule) },
  { path: 'dashboard.html', loadChildren: () => import('./modules/account/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'bookinginfo', loadChildren: () => import('./modules/account/booking.info/booking.info.module').then(m => m.BookingInfoModule) },
  { path: 'profile', loadChildren: () => import('./modules/account/update.profile/update.profile.module').then(m => m.UpdateProfileModule) },
  { path: 'commission', loadChildren: () => import('./modules/account/commission/commission.module').then(m => m.CommissionStatementModule) },
  { path: 'bookinginfo.html', loadChildren: () => import('./modules/account/booking.info/booking.info.module').then(m => m.BookingInfoModule) },
  { path: 'profile.html', loadChildren: () => import('./modules/account/update.profile/update.profile.module').then(m => m.UpdateProfileModule) },
  { path: 'resetpassword', loadChildren: () => import('./modules/account/reset.password/reset.password.module').then(m => m.ResetPasswordModule) },
  { path: 'bookingdetail', loadChildren: () => import('./modules/account/booking.detail/booking.detail.module').then(m => m.BookingDetailModule) },
  { path: 'commission.html', loadChildren: () => import('./modules/account/commission/commission.module').then(m => m.CommissionStatementModule) },
  { path: 'addressbook.html', loadChildren: () => import('./modules/account/update.address/update.address.module').then(m => m.UpdateAddressModule) },
  { path: 'bookingdetail/:id', loadChildren: () => import('./modules/account/booking.detail/booking.detail.module').then(m => m.BookingDetailModule) },
  { path: 'bookinghistory', loadChildren: () => import('./modules/account/booking.history/booking.history.module').then(m => m.BookingHistoryModule) },
  { path: 'changepassword', loadChildren: () => import('./modules/account/update.password/update.password.module').then(m => m.UpdatePasswordModule) },
  { path: 'resetpassword.html', loadChildren: () => import('./modules/account/reset.password/reset.password.module').then(m => m.ResetPasswordModule) },
  { path: 'bookingdetail.html', loadChildren: () => import('./modules/account/booking.detail/booking.detail.module').then(m => m.BookingDetailModule) },
  { path: 'confirmpassword', loadChildren: () => import('./modules/account/confirm.password/confirm.password.module').then(m => m.ConfirmPasswordModule) },
  { path: 'bookinghistory.html', loadChildren: () => import('./modules/account/booking.history/booking.history.module').then(m => m.BookingHistoryModule) },
  { path: 'changepassword.html', loadChildren: () => import('./modules/account/update.password/update.password.module').then(m => m.UpdatePasswordModule) },
  { path: 'bookingdetail.html/:id', loadChildren: () => import('./modules/account/booking.detail/booking.detail.module').then(m => m.BookingDetailModule) },
  { path: 'resetpassword/:id/:code', loadChildren: () => import('./modules/account/reset.password/reset.password.module').then(m => m.ResetPasswordModule) },
  { path: 'confirmpassword.html', loadChildren: () => import('./modules/account/confirm.password/confirm.password.module').then(m => m.ConfirmPasswordModule) },
  { path: 'resetpassword.html/:id/:code', loadChildren: () => import('./modules/account/reset.password/reset.password.module').then(m => m.ResetPasswordModule) },

  { path: 'ticket', loadChildren: () => import('./modules/account/ticket/ticket.module').then(m => m.TicketModule) },
  { path: 'ticket/:id', loadChildren: () => import('./modules/account/ticket/ticket.module').then(m => m.TicketModule) },
  { path: 'ticket.html', loadChildren: () => import('./modules/account/ticket/ticket.module').then(m => m.TicketModule) },
  { path: 'ticket.html/:id', loadChildren: () => import('./modules/account/ticket/ticket.module').then(m => m.TicketModule) },
  { path: 'confirmed', loadChildren: () => import('./modules/account/ticket/ticket.module').then(m => m.TicketModule) },
  { path: 'confirmed/:id', loadChildren: () => import('./modules/account/ticket/ticket.module').then(m => m.TicketModule) },
  { path: 'confirmed.html', loadChildren: () => import('./modules/account/ticket/ticket.module').then(m => m.TicketModule) },
  { path: 'confirmed.html/:id', loadChildren: () => import('./modules/account/ticket/ticket.module').then(m => m.TicketModule) },
  { path: '**', redirectTo: '/booking.html' }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)],
})
export class AppRouting { }