import { MapType } from "../domain/enum/map.type";

export class MapService {

    public loadStripeScript(complete: any) {
        let node: any = document.createElement('script');
        node.type = 'text/javascript';
        node.src = 'https://js.stripe.com/v3';
        document.getElementsByTagName('head')[0].appendChild(node);
        if (node.readyState) { // only required for IE <9
            node.onreadystatechange = () => {
                if (node.readyState === "loaded" || node.readyState === "complete") {
                    node.onreadystatechange = null;
                    complete();
                }
            };
        } else { //Others
            node.onload = () => {
                complete();
            };
        }
    }

    public loadPaypalScript(key: string, complete: any) {
        let node: any = document.createElement('script');
        node.type = 'text/javascript';
        node.src = 'https://www.paypal.com/sdk/js?client-id=' + key + '&currency=GBP&disable-funding=card';
        document.getElementsByTagName('head')[0].appendChild(node);
        if (node.readyState) { // only required for IE <9
            node.onreadystatechange = () => {
                if (node.readyState === "loaded" || node.readyState === "complete") {
                    node.onreadystatechange = null;
                    complete();
                }
            };
        } else { //Others
            node.onload = () => {
                complete();
            };
        }
    }

    public async mapLoading(key: string, type: MapType) {
        let loadAPI = new Promise((resolve) => {
            this.loadScript(key, type, () => {
                resolve(true);
            });
        });
        return loadAPI;
    }

    private loadScript(key: string, type: MapType, complete: any) {
        let node: any = document.createElement('script');
        node.type = 'text/javascript';
        node.src = type == MapType.Google
            ? 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&language=en&key=' + key
            : 'https://www.bing.com/api/maps/mapcontrol?key=' + key;
        document.getElementsByTagName('head')[0].appendChild(node);
        if (node.readyState) { // only required for IE <9
            node.onreadystatechange = () => {
                if (node.readyState === "loaded" || node.readyState === "complete") {
                    node.onreadystatechange = null;
                    complete();
                }
            };
        } else { //Others
            node.onload = () => {
                complete();
            };
        }
    }
}