import { Component, ViewChild } from '@angular/core';
import { ProfileItem } from './../../../domain/profile';
import { ApiService } from './../../../services/apiService';
import { UtilService } from './../../../services/utilService';
import { TemplateType } from '../../../domain/enum/templatetype';
import { XrTextboxComponent } from '../../editors/textbox/textbox';
import { environment } from '../../../../environments/environment';
import { DataService, EventBooking } from './../../../services/dataService';

@Component({
    selector: 'signin-dialog',
    styleUrls: ['./signin.dialog.component.scss'],
    templateUrl: './signin.dialog.component.html',
    host: {
        '(document:keydown)': 'keyDownFunction($event)'
    }
})

export class SignInDialogComponent {
    onSubmitingSignIn: boolean;
    onSubmitingSignUp: boolean;
    submitMessageSignIn: string;
    submitMessageSignUp: string;

    @ViewChild('SignInEmail') SignInEmail: XrTextboxComponent;
    @ViewChild('SignInPassword') SignInPassword: XrTextboxComponent;

    @ViewChild('SignUpEmail') SignUpEmail: XrTextboxComponent;
    @ViewChild('SignUpPhone') SignUpPhone: XrTextboxComponent;
    @ViewChild('SignUpLastName') SignUpLastName: XrTextboxComponent;
    @ViewChild('SignUpPassword') SignUpPassword: XrTextboxComponent;
    @ViewChild('SignUpFirstName') SignUpFirstName: XrTextboxComponent;
    @ViewChild('SignUpConfirmPassword') SignUpConfirmPassword: XrTextboxComponent;

    constructor(public service: ApiService, public data: DataService, public util: UtilService) {
        this.submitMessageSignIn = '';
        this.onSubmitingSignIn = false;
    }

    choiceSignIn(type: string) {
        this.data.SignInType = type;
    }

    navigate() {
        switch (this.data.SignInNavigate) {
            case this.data.SIGNIN_NAVIGATE_TYPE.HOME:
                {
                    this.util.GoToPage(this.data.PAGE_TYPE.HOME);
                }
                break;
            case this.data.SIGNIN_NAVIGATE_TYPE.ADD_PROMO:
                {
                    this.util.NavegatePromo();
                }
                break;
            case this.data.SIGNIN_NAVIGATE_TYPE.EDIT_CONTACT:
                {
                    this.util.NavigateEditContact();
                    this.data.SignInNavigate = this.data.SIGNIN_NAVIGATE_TYPE.NOTE_TO_DRIVER;
                }
                break;
            case this.data.SIGNIN_NAVIGATE_TYPE.FLIGHT:
                {
                    if (this.util.NeedNavegateFlightDetail()) {
                        this.util.NavigateFlightDetail();
                        this.data.SignInNavigate = this.data.SIGNIN_NAVIGATE_TYPE.EDIT_CONTACT;
                    } else if (this.util.NeedNavegateReturnFlightDetail()) {
                        this.util.NavigateReturnFlightDetail();
                        this.data.SignInNavigate = this.data.SIGNIN_NAVIGATE_TYPE.EDIT_CONTACT;
                    } else {
                        this.util.NavigateEditContact();
                        this.data.SignInNavigate = this.data.SIGNIN_NAVIGATE_TYPE.NOTE_TO_DRIVER;
                    }
                }
                break;
            case this.data.SIGNIN_NAVIGATE_TYPE.CONFIRM_BOOKING:
                {
                    this.util.NavigateConfirmBooking();
                }
                break;
        }
    }

    onSignIn() {
        /// Valid
        let valid = this.validSignIn();
        if (!valid) {
            return;
        }

        // Set value
        this.data.SignIn.Email = this.SignInEmail.model;
        this.data.SignIn.Password = this.SignInPassword.model;
        this.data.SignIn.PasswordHash = this.util.CreateHash(this.data.SignIn.Password);

        /// Submit
        this.onSubmitingSignIn = true;
        this.submitMessageSignIn = '';
        this.service.signIn(this.data.SignIn)
            .subscribe((model: ProfileItem) => {
                this.onSubmitingSignIn = false;
                if (model.Code == 1) {
                    this.data.Profile = model;
                    this.data.storeProfile(this.data.SignIn.RememberMe);
                    this.data.SigninComplete.emit(this.data.Profile.Id);

                    this.util.HideModal(this.data.MODAL_TYPE.SIGNIN);
                    this.data.ModalType = '';
                    if (this.data.SignInNavigate != '') {
                        if (environment.name == 't360') this.navigate();
                        else {
                            if (this.data.Booking && this.data.Booking.Contact) {
                                this.data.Booking.Contact.Password = '';
                                this.data.Booking.Contact.AsAccount = false;
                                this.data.Booking.Contact.PasswordHash = '';
                            }

                            // Kiểm tra thông tin Customer khi là Booker
                            if (this.util.IsBooker() && !this.data.validCustomer()) {
                                this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.CUSTOMER));
                                return;
                            }

                            // Kiểm tra thông tin Customer
                            if (!this.data.validContact()) {
                                this.data.ShowInvalid.emit(new EventBooking(this.data.TARGET_EVENT_TYPE.CONTACT));
                                return;
                            }

                            // Kiểm tra fligh khi nó là 1 thành phần popup
                            if (!environment.flightInline) {
                                if (this.util.NeedNavegateFlightDetail()) {
                                    this.data.SignInNavigate = this.data.SIGNIN_NAVIGATE_TYPE.CONFIRM_BOOKING;
                                    this.util.NavigateFlightDetail();
                                    return;
                                }

                                if (this.util.NeedNavegateReturnFlightDetail()) {
                                    this.data.SignInNavigate = this.data.SIGNIN_NAVIGATE_TYPE.CONFIRM_BOOKING;
                                    this.util.NavigateReturnFlightDetail();
                                    return;
                                }
                            }
                            this.continueStep();
                        }
                    }
                } else {
                    this.data.SignIn.Password = '';
                    this.data.SignIn.PasswordHash = '';
                    this.submitMessageSignIn = model.Code == 0 ? this.data.ERROR_MESSAGE : model.Message;
                }
            });
    }

    onSignUp() {
        /// Valid
        let valid = this.validSignUp();
        if (!valid) {
            return;
        }

        // Set value
        this.data.SignUp.Email = this.SignUpEmail.model;
        this.data.SignUp.Surname = this.SignUpLastName.model;
        this.data.SignUp.Password = this.SignUpPassword.model;
        this.data.SignUp.Firstname = this.SignUpFirstName.model;
        this.data.SignUp.ConfirmPassword = this.SignUpConfirmPassword.model;
        this.data.SignUp.Phone = this.util.GetPhoneNumber(this.SignUpPhone.model);
        this.data.SignUp.PasswordHash = this.util.CreateHash(this.data.SignUp.Password);
        this.data.SignUp.DialingCode = this.util.GetDialCode(this.SignUpPhone.editorId);

        /// Submit
        this.onSubmitingSignUp = true;
        this.submitMessageSignUp = '';
        this.service.signUp(this.data.SignUp)
            .subscribe(model => {
                this.onSubmitingSignUp = false;
                if (model.Code == 1) {
                    this.data.Profile = model;
                    this.data.storeProfile(true);
                    this.data.SigninComplete.emit(this.data.Profile.Id);
                    this.util.HideModal(this.data.MODAL_TYPE.SIGNIN);
                    this.data.ModalType = '';
                    if (this.data.SignInNavigate != '') {
                        this.navigate();
                    } else {
                        this.util.GoToPage('dashboard.html');
                    }
                } else {
                    this.data.SignUp.Password = '';
                    this.data.SignUp.PasswordHash = '';
                    this.data.SignUp.ConfirmPassword = '';
                    this.submitMessageSignUp = model.Code == 0 ? this.data.ERROR_MESSAGE : model.Message;
                }
            });
    }

    openModal() {
        this.onClose();
        this.util.ShowModal(this.data.MODAL_TYPE.FORGOTPASSWORD, 500);
    }

    continueStep() {
        switch (environment.template) {
            case TemplateType.Wizard:
                this.util.ContinueStep();
                break;
            case TemplateType.GateWay:
                this.util.ContinueStepMax2();
                break;
            case TemplateType.WizardT:
                this.util.ContinueStepMax3();
                break;
            case TemplateType.Overflow:
                this.util.NavigateConfirmBooking();
                break;
            case TemplateType.WizardX:
                this.util.ContinueStepMax3Payment();
                break;
        }
    }

    validSignIn(): boolean {
        if (this.SignInEmail) {
            var valid1 = this.SignInEmail.checkValid();
            var valid2 = this.SignInPassword.checkValid();
            return valid1 && valid2;
        }
        return false;
    }

    validSignUp(): boolean {
        var valid1 = this.SignUpFirstName.checkValid();
        var valid2 = this.SignUpLastName.checkValid();
        var valid3 = this.SignUpEmail.checkValid();
        var valid4 = this.SignUpPhone.checkValid();
        var valid5 = this.SignUpPassword.checkValid();
        var valid6 = this.SignUpConfirmPassword.checkValid();
        var valid7 = this.SignUpPassword.model == this.SignUpConfirmPassword.model;
        if (!valid7) this.SignUpConfirmPassword.showInvalid('Password and Confirm password not match');
        return valid1 && valid2 && valid3 && valid4 && valid5 && valid6 && valid7;
    }

    onInputChange() {
        this.submitMessageSignIn = '';
        this.submitMessageSignUp = '';
    }

    onClose() {
        /// Hide error message
        this.submitMessageSignIn = '';
        this.SignInEmail.hideInvalid();
        this.SignInPassword.hideInvalid();

        this.submitMessageSignUp = '';
        this.SignUpEmail.hideInvalid();
        this.SignUpPhone.hideInvalid();
        this.SignUpPassword.hideInvalid();
        this.SignUpLastName.hideInvalid();
        this.SignUpFirstName.hideInvalid();
        this.SignUpConfirmPassword.hideInvalid();

        this.util.HideModal(this.data.MODAL_TYPE.SIGNIN);
        this.data.ModalType = '';
    }

    keyDownFunction(event: any) {
        if (this.data.ModalType == this.data.MODAL_TYPE.SIGNIN) {
            if (event.keyCode == 13) {
                if (this.data.SignInType == this.data.SIGNIN_TYPE.SIGNIN) {
                    this.onSignIn();
                } else if (this.data.SignInType == this.data.SIGNIN_TYPE.SIGNUP) {
                    this.onSignUp();
                }
            }
        }
    }
}