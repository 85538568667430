export class Commission {
    Via: number;
    Cash: number;
    Card: number;
    Note: string;
    Price: number;
    Status: string;
    JobPartID: number;
    BookerRef: string;
    Commission: number;
    BookerName: string;
    BookerAddress: string;
    PaymentMethod: string;
    BookingDateTime: Date;
    PickupAddress: string;
    DropoffAddress: string;
    PickupPostcode: string;
    DropoffPostcode: string;
}

export class CommissionItem {
    Via: number;
    Cash: number;
    Card: number;
    Note: string;
    Price: number;
    Status: string;
    JobPartId: number;
    BookerRef: string;
    Commission: number;
    BookerName: string;
    BookerAddress: string;
    PaymentMethod: string;
    BookingDateTime: Date;
    PickupAddress: string;
    DropoffAddress: string;
    PickupPostCode: string;
    DropoffPostCode: string;

    public static ToItem(entity: Commission): CommissionItem {
        if (entity == null) return null;
        let item = new CommissionItem();

        item.Via = entity.Via;
        item.Note = entity.Note;
        item.Status = entity.Status;
        item.JobPartId = entity.JobPartID;
        item.BookerRef = entity.BookerRef;
        item.BookerName = entity.BookerName;
        item.PaymentMethod = item.PaymentMethod;
        item.BookerAddress = entity.BookerAddress;
        item.PickupAddress = entity.PickupAddress;
        item.DropoffAddress = entity.DropoffAddress;
        item.PickupPostCode = entity.PickupPostcode;
        item.DropoffPostCode = entity.DropoffPostcode;
        item.Card = entity.Card == null ? 0 :entity.Card;
        item.Cash = entity.Cash == null ? 0 : entity.Cash;
        item.Price = entity.Price == null ? 0 : entity.Price;
        item.Commission = entity.Commission == null ? 0 : entity.Commission;
        item.BookingDateTime = entity.BookingDateTime == null ? null : new Date(entity.BookingDateTime);
        return item;
    }

    public static ToItems(entities: Commission[]): CommissionItem[] {
        if (entities == null || entities.length == 0) return new Array<CommissionItem>();
        let items = new Array();
        entities.forEach(element => {
            items.push(CommissionItem.ToItem(element));
        });
        return items;
    }
}

export class CommissionFilter {
    ToDate: Date;
    FromDate: Date;
    BookerId: number;
}