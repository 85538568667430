declare var jQuery: any;
import { AppConfig } from './../../../app.config';
import { Contact } from './../../../domain/contact';
import { SignIn } from '../../../domain/object/signin';
import { SignUp } from '../../../domain/object/signup';
import { DataService } from './../../../services/dataService';
import { UtilService } from './../../../services/utilService';
import { XrTextboxComponent } from '../../editors/textbox/textbox';
import { ChangeDetectionStrategy, Component, ViewChild, afterNextRender } from '@angular/core';

@Component({
    selector: 'contact-dialog',
    templateUrl: './contact.dialog.component.html',
    host: {
        '(document:keydown)': 'keyDownFunction($event)'
    }
})

export class ContactDialogComponent {
    browser: boolean;
    iconPassenger: string;
    disabled: boolean = false;
    disabledCustomer: boolean = false;
    disabledForCustomer: boolean = false;

    @ViewChild('ContactName') ContactName: XrTextboxComponent;
    @ViewChild('ContactEmail') ContactEmail: XrTextboxComponent;
    @ViewChild('ContactPhone') ContactPhone: XrTextboxComponent;

    @ViewChild('CustomerPhone') CustomerPhone: XrTextboxComponent;
    @ViewChild('CustomerEmail') CustomerEmail: XrTextboxComponent;
    @ViewChild('CustomerOurRef') CustomerOurRef: XrTextboxComponent;
    @ViewChild('CustomerLastName') CustomerLastName: XrTextboxComponent;
    @ViewChild('CustomerFirstName') CustomerFirstName: XrTextboxComponent;

    constructor(public data: DataService, public util: UtilService) {
        this.iconPassenger = AppConfig.ASSETS + 'icons/icon-13.png';
        afterNextRender(() => {
            this.browser = true;
            this.disabled = this.data.Profile != null && !this.util.IsBooker();
            this.data.ContactType = this.util.IsBooker() ? this.data.CUSTOMER_TYPE.CUSTOMER : this.data.CUSTOMER_TYPE.PASSENGER;
            this.data.SigninComplete.subscribe(() => {
                this.data.ContactType = this.util.IsBooker() ? this.data.CUSTOMER_TYPE.CUSTOMER : this.data.CUSTOMER_TYPE.PASSENGER;
                if (!this.util.IsBooker()) {
                    if (this.data.Profile != null) {
                        if (this.disabled)
                            this.data.Booking.Contact = Contact.ToItemFromProfile(this.data.Profile);

                        if (this.ContactName) {
                            this.ContactName.model = this.data.Booking.Contact.Name;
                            this.ContactName.hideInvalid();
                        }
                        if (this.ContactEmail) {
                            this.ContactEmail.model = this.data.Booking.Contact.Email;
                            this.ContactEmail.hideInvalid();
                        }
                        if (this.ContactPhone) {
                            this.ContactPhone.model = this.data.Booking.Contact.Phone;
                            this.ContactPhone.dialingCode = this.data.Booking.Contact.DialingCode;
                            this.ContactPhone.hideInvalid();
                            this.ContactPhone.setDialingCode();
                        }

                        if (this.CustomerPhone) this.CustomerPhone.hideInvalid();
                        if (this.CustomerEmail) this.CustomerEmail.hideInvalid();
                        if (this.CustomerFirstName) this.CustomerFirstName.hideInvalid();
                    }
                }
            });
            this.data.SignoutComplete.subscribe(() => {
                this.data.ContactType = this.util.IsBooker() ? this.data.CUSTOMER_TYPE.CUSTOMER : this.data.CUSTOMER_TYPE.PASSENGER;
                if (this.disabled) {
                    this.data.Booking.Contact = new Contact();
                    if (this.ContactName) {
                        this.ContactName.model = '';
                        this.ContactName.hideInvalid();
                    }
                    if (this.ContactPhone) {
                        this.ContactPhone.model = '';
                        this.ContactPhone.hideInvalid();
                    }
                    if (this.ContactEmail) {
                        this.ContactEmail.model = '';
                        this.ContactEmail.hideInvalid();
                    }
                }
                this.data.storeBooking();
            });
        })
    }

    onSubmit() {
        /// Valid
        let valid = this.validSubmit();
        if (!valid) {
            return;
        }

        // Set value
        this.data.Booking.Contact.Name = this.ContactName.model;
        this.data.Booking.Contact.Email = this.ContactEmail.model;
        this.data.Booking.Contact.Phone = this.util.GetPhoneNumber(this.ContactPhone.model);
        this.data.Booking.Contact.DialingCode = this.util.GetDialCode(this.ContactPhone.editorId);

        /// Submit
        this.util.HideModal(this.data.MODAL_TYPE.CONTACT);

        /// Navigate
        switch (this.data.SignInNavigate) {
            case this.data.SIGNIN_NAVIGATE_TYPE.NOTE_TO_DRIVER:
                {
                    if (this.util.NeedNavegateNoteToDriver()) {
                        if (this.data.PopupPaymentNoteToDriver) {
                            this.util.NavigateNoteToDriver();
                            this.data.SignInNavigate = this.data.SIGNIN_NAVIGATE_TYPE.CONFIRM_BOOKING;
                        } else {
                            this.data.SignInNavigate = this.data.SIGNIN_NAVIGATE_TYPE.CONFIRM_BOOKING;
                            this.util.NavigateConfirmBooking();
                        }
                    } else {
                        this.util.NavigateConfirmBooking();
                    }
                }
                break;
            case this.data.SIGNIN_NAVIGATE_TYPE.CONFIRM_BOOKING:
                {
                    this.util.NavigateConfirmBooking();
                }
                break;
        }
    }

    validSubmit(): boolean {
        if (this.disabled) {
            if (this.ContactName) this.ContactName.hideInvalid();
            if (this.ContactPhone) this.ContactPhone.hideInvalid();
            if (this.ContactEmail) this.ContactEmail.hideInvalid();
            return true;
        }
        if (this.util.IsBooker()) {
            var valid1 = this.ContactName.checkValid();
            var valid2 = this.ContactPhone.checkValid();
            var valid3 = this.ContactEmail.checkValid();
            var temp1 = valid1 && valid2 && valid3;
            if (!temp1) this.data.ContactType = this.data.CUSTOMER_TYPE.PASSENGER;

            var valid4 = this.CustomerPhone.checkValid();
            var valid5 = this.CustomerEmail.checkValid();
            var valid6 = this.CustomerFirstName.checkValid();
            var temp2 = valid4 && valid5 && valid6;
            if (!temp2) this.data.ContactType = this.data.CUSTOMER_TYPE.CUSTOMER;
            return temp1 && temp2;
        }
        var valid1 = this.ContactName.checkValid();
        var valid2 = this.ContactPhone.checkValid();
        var valid3 = this.ContactEmail.checkValid();
        var temp = valid1 && valid2 && valid3;
        if (!temp) this.data.ContactType = this.data.CUSTOMER_TYPE.PASSENGER;
        return temp;
    }

    onClose() {
        /// Hide error message
        this.ContactName.hideInvalid();
        this.ContactEmail.hideInvalid();
        this.ContactPhone.hideInvalid();
        this.util.HideModal(this.data.MODAL_TYPE.CONTACT);

        // Set value
        this.data.Booking.Contact.Name = this.ContactName.model;
        this.data.Booking.Contact.Email = this.ContactEmail.model;
        this.data.Booking.Contact.Phone = this.util.GetPhoneNumber(this.ContactPhone.model);
        this.data.Booking.Contact.DialingCode = this.util.GetDialCode(this.ContactPhone.editorId);
        this.data.ModalType = '';
    }

    keyDownFunction(event: any) {
        if (event.keyCode == 13) {
            this.onSubmit();
        }
    }

    handleInputChange() {
        if (this.data.Booking.Contact == null) this.data.Booking.Contact = Contact.ToItemFromProfile(this.data.Profile);
        if (this.disabledCustomer) {
            if (this.ContactPhone) {
                this.ContactPhone.hideInvalid();
                this.ContactPhone.model = this.data.Booking.Customer.Phone;
                this.ContactPhone.dialingCode = this.data.Booking.Customer.DialingCode;
                if (this.ContactPhone.model) {
                    this.ContactPhone.model = this.ContactPhone.model.replace(' ', '');
                    jQuery('#' + this.ContactPhone.editorId).val(this.ContactPhone.model);
                }
                var country = this.util.GetDialCountry(this.CustomerPhone.editorId);
                this.util.SetDialCode(this.ContactPhone.editorId, country);
            }
            if (this.ContactEmail) {
                this.ContactEmail.hideInvalid();
                this.ContactEmail.model = this.data.Booking.Customer.Email;
            }
            if (this.ContactName) {
                if (this.data.Booking.Customer.FirstName.length > 0 || this.data.Booking.Customer.LastName.length > 0)
                    this.ContactName.model = this.data.Booking.Customer.FirstName + ' ' + this.data.Booking.Customer.LastName;
                else
                    this.ContactName.model = '';
            }
        }
    }
    onTickUseMyDetails() {
        if (this.disabled) {
            if (this.data.Profile != null) {
                this.data.Booking.Contact = Contact.ToItemFromProfile(this.data.Profile);
                if (this.ContactName) {
                    this.ContactName.model = this.data.Booking.Contact.Name;
                    this.ContactName.hideInvalid();
                }
                if (this.ContactEmail) {
                    this.ContactEmail.model = this.data.Booking.Contact.Email;
                    this.ContactEmail.hideInvalid();
                }
                if (this.ContactPhone) {
                    this.ContactPhone.model = this.data.Booking.Contact.Phone;
                    this.ContactPhone.hideInvalid();
                    if (this.ContactPhone.model) {
                        this.ContactPhone.model = this.ContactPhone.model.replace(' ', '');
                        jQuery('#' + this.ContactPhone.editorId).val(this.ContactPhone.model);
                    }
                }
            }
        }
    }
    onTickUseCustomerDetails() {
        if (this.disabledCustomer) {
            if (this.util.IsBooker()) {
                if (this.data.Booking.Customer == null) this.data.Booking.Customer = new Contact();
                if (this.ContactName) {
                    this.ContactName.hideInvalid();
                    this.ContactName.model = this.data.Booking.Customer.FirstName + ' ' + this.data.Booking.Customer.LastName;
                }
                if (this.ContactPhone) {
                    this.ContactPhone.hideInvalid();
                    this.ContactPhone.model = this.data.Booking.Customer.Phone;
                    this.ContactPhone.dialingCode = this.data.Booking.Customer.DialingCode;
                    if (this.ContactPhone.model) {
                        this.ContactPhone.model = this.ContactPhone.model.replace(' ', '');
                        jQuery('#' + this.ContactPhone.editorId).val(this.ContactPhone.model);
                    }
                    var country = this.util.GetDialCountry(this.CustomerPhone.editorId);
                    this.util.SetDialCode(this.ContactPhone.editorId, country);
                }
            }
        }
    }
    onTickUseMyDetailsForCustomer() {
        if (this.disabledForCustomer) {
            if (this.util.IsBooker()) {
                this.data.Booking.Customer = Contact.ToItemFromProfile(this.data.Profile);
                if (this.CustomerEmail) {
                    this.CustomerEmail.hideInvalid();
                    this.CustomerEmail.model = this.data.Booking.Customer.Email;
                }
                if (this.CustomerPhone) {
                    this.CustomerPhone.hideInvalid();
                    this.CustomerPhone.model = this.data.Booking.Customer.Phone;
                    if (this.CustomerPhone.model) {
                        this.CustomerPhone.model = this.CustomerPhone.model.replace(' ', '');
                        jQuery('#' + this.CustomerPhone.editorId).val(this.CustomerPhone.model);
                    }
                }
                if (this.CustomerLastName) this.CustomerLastName.model = this.data.Booking.Customer.LastName;
                if (this.CustomerFirstName) {
                    this.CustomerFirstName.hideInvalid();
                    this.CustomerFirstName.model = this.data.Booking.Customer.FirstName;
                }
            }
        }
    }

    onInputChange(data) {
        if (this.data.Booking.Contact == null) this.data.Booking.Contact = new Contact();
        if (this.data.Booking.Customer == null) this.data.Booking.Customer = new Contact();
        if (data == 'CustomerEmail') {
            this.data.Booking.Customer.Email = this.CustomerEmail.model;
            if (this.disabledCustomer) {
                this.data.Booking.Contact.Email = this.data.Booking.Customer.Email;
            }
        }
        if (data == 'CustomerOurRef') {
            this.data.Booking.Customer.OurRef = this.CustomerOurRef.model;
        }
        if (data == 'CustomerLastName') {
            this.data.Booking.Customer.LastName = this.CustomerLastName.model;
            if (this.disabledCustomer) {
                this.data.Booking.Contact.LastName = this.data.Booking.Customer.LastName;
                this.data.Booking.Contact.Name = this.data.Booking.Customer.FirstName + ' ' + this.data.Booking.Customer.LastName;
            }
        }
        if (data == 'CustomerFirstName') {
            this.data.Booking.Customer.FirstName = this.CustomerFirstName.model;
            if (this.disabledCustomer) {
                this.data.Booking.Contact.FirstName = this.data.Booking.Customer.FirstName;
                this.data.Booking.Contact.Name = this.data.Booking.Customer.FirstName + ' ' + this.data.Booking.Customer.LastName;
            }
        }
        if (data == 'CustomerPhone') {
            if (this.CustomerPhone) {
                this.data.Booking.Customer.Phone = this.util.GetPhoneNumber(this.CustomerPhone.model);
                this.data.Booking.Customer.DialingCode = this.util.GetDialCode(this.CustomerPhone.editorId);
            }
            if (this.disabledCustomer) {
                this.data.Booking.Contact.Phone = this.data.Booking.Customer.Phone;
                this.data.Booking.Contact.DialingCode = this.data.Booking.Customer.DialingCode;
            }
        }
    }
    choiceContactTab(tab: string) {
        this.data.ContactType = tab;
        if (this.data.ContactType == this.data.CUSTOMER_TYPE.PASSENGER) {
            this.handleInputChange();
        }
    }

    onOpenModal() {
        this.util.HideModal(this.data.MODAL_TYPE.CONTACT);
        if (this.data.SignIn == null) this.data.SignIn = new SignIn();
        if (this.data.SignUp == null) this.data.SignUp = new SignUp();
        this.util.ShowModal(this.data.MODAL_TYPE.SIGNIN);
    }
}