import { BookingPayment } from "./booking";

export class Electronic {
    code: number;
    message: string;
    authcode: string;
    additionalinfo: any;
    paypaltransid: string;
    paypalpaymentid: string;
}

export class ElectronicBooking {
    bref: string;
    code: number;
    message: string;
    returnref: string;
    paymentresult: Electronic;
}

export class ElectronicBookingStripe {
    bref: string;
    code: number;
    message: string;
    jobpartid: string;
    returnref: string;
    clientSecret: string;
    returnJobpartId: string;
    returnjobpartid: string;
    paymentresult: Electronic;
}

export class ElectronicItem {
    Code?: number;
    Message?: string;
    AuthCode: string;
    PaymentType: string;
    AdditionalInfo?: any;
    PaypalTransId?: string;
    PaypalPaymentId?: string;

    public static ToItem(entity: Electronic): ElectronicItem {
        if (entity == null) return null;
        var item = new ElectronicItem();
        item.Code = entity.code;
        item.Message = entity.message;
        item.AuthCode = entity.authcode;
        item.PaypalTransId = entity.paypaltransid;
        item.AdditionalInfo = entity.additionalinfo;
        item.PaypalPaymentId = entity.paypalpaymentid;
        return item;
    }
}

export class ElectronicBookingItem {
    Code: number;
    Bref: string;
    Message: string;
    ReturnRef: string;
    PaymentResult: ElectronicItem;

    public static ToItem(entity: ElectronicBooking): ElectronicBookingItem {
        if (entity == null) return null;
        var item = new ElectronicBookingItem();
        item.Code = entity.code;
        item.Bref = entity.bref;
        item.Message = entity.message;
        item.ReturnRef = entity.returnref;
        item.PaymentResult = ElectronicItem.ToItem(entity.paymentresult);
        return item;
    }
}

export class ElectronicBookingStripeItem {
    Code: number;
    Bref: string;
    Message: string;
    ReturnRef: string;
    ClientSecret: string;
    PaymentResult: ElectronicItem;

    public static ToItem(entity: ElectronicBookingStripe): ElectronicBookingStripeItem {
        if (entity == null) return null;
        var item = new ElectronicBookingStripeItem();
        item.Code = entity.code;
        item.Message = entity.message;
        item.ClientSecret = entity.clientSecret;
        item.Bref = entity.bref || entity.jobpartid;
        item.ClientSecret = entity.clientSecret
            ? entity.clientSecret.replace('"', '').replace('"', '')
            : null;
        item.PaymentResult = ElectronicItem.ToItem(entity.paymentresult);
        item.ReturnRef = entity.returnref || entity.returnJobpartId || entity.returnjobpartid;
        return item;
    }

    public static ToItemFromBooking(entity: BookingPayment): ElectronicBookingStripeItem {
        if (entity == null) return null;
        var item = new ElectronicBookingStripeItem();
        item.PaymentResult = null;
        item.Message = entity.message;
        item.Code = parseInt(entity.code);
        item.Bref = entity.jobpartid.toString();
        item.ClientSecret = entity.clientsecret
            ? entity.clientsecret.replace('"', '').replace('"', '')
            : null;
        return item;
    }
}