import * as _ from 'lodash';
import { AirlineItem } from '../../../domain/airline';
import { AirportItem } from '../../../domain/airport';
import { ApiService } from '../../../services/apiService';
import { UtilService } from './../../../services/utilService';
import { DataService } from './../../../services/dataService';
import { XrTextboxComponent } from '../../editors/textbox/textbox';
import { XrDateTimeComponent } from '../../editors/datetime/datetime';
import { ChangeDetectionStrategy, Component, ViewChild, afterNextRender } from '@angular/core';
import { XrAirportListComponent } from '../../editors/ariportlist/airportlist';
import { XrAirlineListComponent } from '../../editors/airlinelist/airlinelist';
import { FlightParamSearch, FlightResultItem, Flight } from '../../../domain/flight';

@Component({
    selector: 'flight-search-dialog',
    templateUrl: './flight.search.dialog.component.html',
    host: {
        '(document:keydown)': 'keyDownFunction($event)'
    }
})

export class FlightSearchDialogComponent {
    browser: boolean;
    onSubmited: boolean;
    onSubmiting: boolean;
    disabledFlight: boolean;
    enterFlightNumber: boolean;
    disabledReturnFlight: boolean;
    flightOptions: FlightResultItem[];
    flightBestMatchs: FlightResultItem[];
    item: FlightParamSearch = new FlightParamSearch();
    @ViewChild('FlightNumber') FlightNumber: XrTextboxComponent;
    @ViewChild('FlightDateTime') FlightDateTime: XrDateTimeComponent;
    @ViewChild('FlightAirline') FlightAirline: XrAirlineListComponent;
    @ViewChild('FlightAirport') FlightAirport: XrAirportListComponent;

    constructor(public data: DataService, public util: UtilService, public service: ApiService) {
        afterNextRender(() => {
            this.browser = true;
        })
    }

    onClose() {
        this.util.HideModal(this.data.MODAL_TYPE.FLIGHTSEARCH);
        this.data.ModalType = '';
    }
    onSubmit() {
        if (!this.validSubmitFlight()) {
            return;
        }

        this.onSubmited = false;
        this.onSubmiting = true;
        this.enterFlightNumber = false;
        this.item.PickUpCode = this.data.Booking.PickUp.Code;
        this.service.searchAdvanceFlights(this.item).subscribe((items: FlightResultItem[]) => {
            if (items && items.length > 0) {
                this.flightBestMatchs = [items.shift()];
                if (items.length > 0) {
                    this.flightOptions = items;
                }
            }
            this.onSubmited = true;
            this.onSubmiting = false;
        });
    }
    keyDownFunction(event: any) {
        if (this.data.ModalType == this.data.MODAL_TYPE.FLIGHT) {
            if (event.keyCode == 13) {
                this.onSubmit();
            }
        }
    }
    validSubmitFlight() {
        let valid1: boolean = true,
            valid2: boolean = true;
        if (!this.item.DateTime) {
            valid1 = false;
            this.FlightDateTime.showInvalid('Datetime is required!');
        }
        if (!this.item.Airline) {
            valid2 = false;
            this.FlightAirline.showInvalid('Airline is required!');
        }
        return valid1 && valid2;
    }

    onEnterFlightNumber() {
        this.enterFlightNumber = !this.enterFlightNumber;
    }
    onChangeFlightNumber() {
        if (this.FlightNumber)
            this.FlightNumber.hideInvalid();
    }
    onSelectDate($event: any) {
        if ($event != null) {
            this.item.DateTime = new Date($event.Date);
            this.FlightDateTime.hideInvalid();
        } else this.item.DateTime = null;
    }
    onChangedAirport($event: any) {
        if ($event == null || $event.data == null || $event.data == '')
            this.item.Airport = null;
    }
    onSelectedAirport($event: any) {
        let airport = $event == null ? null : <AirportItem>$event.data;
        this.item.Airport = airport;
        if (this.FlightAirport)
            this.FlightAirport.hideInvalid();
    }
    onChangedAirline($event: any) {
        if ($event == null || $event.data == null || $event.data == '')
            this.item.Airline = null;
    }
    onSelectedAirline($event: any) {
        let airline = $event == null ? null : <AirlineItem>$event.data;
        this.item.Airline = airline;
        if (this.FlightAirline)
            this.FlightAirline.hideInvalid();
    }
    onSelectedFlight(airport: FlightResultItem) {
        this.util.SelectedFlight(airport);
        this.onClose();
    }
    onSubmitFlightNumber() {
        this.data.Booking.Flight = new Flight();
        this.data.Booking.Flight.Airport = new AirportItem();
        this.data.Booking.Flight.Number = this.FlightNumber.model;
        this.data.Booking.Flight.Airport.FullAddress = this.FlightNumber.model;
        this.onClose();
    }
}