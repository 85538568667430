<div class="modal fade" id="modalPaymentMethod" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content" *ngIf="browser">
            <div class="modal-header">
                <button id="closeButtonFlight" type="button" class="close" (click)="onClose()">&times;</button>
                <div class="col-xs-12 col-sm-12 col-md-12 modal-title">
                    <span>
                        <img [src]="iconPayment" />
                    </span>Payment method
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="modal-body">
                <div class="control">
                    <div class="col-xs-6 payment-item" (click)="choicePaymentType(data.PAYMENT_TYPE.CARD)" [ngClass]="data.Booking.PaymentType == data.PAYMENT_TYPE.CARD ? 'active' : ''">
                        <div class="payment-title">
                            By card
                        </div>
                        <div class="payment-image">
                            <img *ngIf="data.Booking.PaymentType != data.PAYMENT_TYPE.CARD" [src]="iconCard" />
                            <img *ngIf="data.Booking.PaymentType == data.PAYMENT_TYPE.CARD" [src]="iconCardWhite" />
                        </div>
                    </div>
                    <div class="col-xs-6 payment-item" (click)="choicePaymentType(data.PAYMENT_TYPE.PAYPAL)" [ngClass]="data.Booking.PaymentType == data.PAYMENT_TYPE.PAYPAL ? 'active' : ''">
                        <div class="payment-title">
                            Paypal
                        </div>
                        <div class="payment-image">
                            <img [src]="iconPaypal" />
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="center-button" style="margin-top: 20px; text-align: center;">
                    <button type="button" class="ui-button" (click)="onSubmit()">OK</button>
                </div>
                <div style="clear: both;"></div>
            </div>
        </div>
    </div>
</div>