declare var jQuery: any;
import { AppConfig } from '../../../app.config';
import { PickupType } from '../../../domain/enum/pickup.type';
import { DataService } from './../../../services/dataService';
import { UtilService } from './../../../services/utilService';
import { environment } from '../../../../environments/environment';
import { Component, Input, Output, EventEmitter, AfterViewChecked, OnInit, AfterContentInit, DoCheck } from '@angular/core';

@Component({
    selector: 'xr-datetime',
    templateUrl: 'datetime.html',
    styleUrls: ['datetime.scss']
})
export class XrDateTimeComponent implements OnInit, AfterContentInit, AfterViewChecked, DoCheck {
    icon: string = '';
    valueHour: number;
    value: string = '';
    inputIsIos: boolean;
    hours: number[] = [];
    parentId: string = '';
    PickupType = PickupType;
    active15: boolean = false;
    active30: boolean = false;
    active60: boolean = false;
    panelVisible: boolean = false;

    valueMinute: number;
    minutes: number[] = [];
    waitingMinutes: number[] = [];
    valueWaitingMinute: number = 60;

    glyphiconUp: string;
    glyphiconDown: string;

    @Input() date: Date;
    @Input() editorId: string;
    @Input() dateTitle: string;
    @Input() timeTitle: string;
    @Input() glyphicon: string;
    @Input() iconRemove: string;
    @Input() layoutType: string;
    @Input() minute: number = 0;
    @Input() placeholder: string;
    @Input() pickType: PickupType;
    @Input() maxDate: Date = null;
    @Input() valid: boolean = true;
    @Input() stepMinute: number = 1;
    @Input() invalidMessage: string;
    @Input() classInput: string = '';
    @Input() hasIcon: boolean = true;
    @Input() hasLabel: boolean = false;
    @Input() disabled: boolean = false;
    @Input() forceDown: boolean = true;
    @Input() minDate: Date = new Date();
    @Input() showHeader: boolean = false;
    @Input() scrollOnTop: boolean = false;
    @Input() showWaitingTime: boolean = false;
    @Input() showHeaderMinute: boolean = true;

    @Output() onClickItem = new EventEmitter();
    @Output() objectChange = new EventEmitter();
    @Output() onPanelHidden = new EventEmitter();
    @Output() onPanelVisible = new EventEmitter();
    @Output() waitingTimeChange: EventEmitter<any>;
    @Output('dateChange') dateChange = new EventEmitter();

    ngOnInit() {
        for (let i = 0; i <= 23; i++) this.hours.push(i);
        for (let i = 0; i <= 59; i++) {
            if (this.stepMinute) {
                if (i % this.stepMinute == 0)
                    this.minutes.push(i);
            } else this.minutes.push(i);
        }
        for (let i = 0; i <= 180; i++) {
            if (i % 15 == 0)
                this.waitingMinutes.push(i);
        }
        if (this.date) {
            this.valueHour = this.date.getHours();
            this.valueMinute = this.date.getMinutes();
        } else {
            let date = new Date();
            this.valueHour = date.getHours();
            this.valueMinute = date.getMinutes();
            if (this.minutes.indexOf(this.valueMinute) == -1) {
                this.minutes.forEach((minute: number) => {
                    if (minute >= this.valueMinute) this.valueMinute = minute;
                });
            }
        }
        setTimeout(() => {
            this.value = this.toDateString(this.date);
        }, 1000);
        this.parentId = this.editorId + '-input';
        if (this.util.checkIOS()) {
            this.classInput = 'ui-input';
        }
        if (this.hasLabel) this.classInput = this.classInput + ' hasLabel';
        this.icon = AppConfig.ASSETS + 'editor/date-journey.png';
        if (!this.glyphicon) this.glyphicon = 'menu';
        this.glyphiconUp = 'glyphicon-' + this.glyphicon + '-up';
        this.glyphiconDown = 'glyphicon-' + this.glyphicon + '-down';
        if (this.layoutType == 'triox' && !this.dateTitle)
            this.dateTitle = 'DateTime';
    }

    ngDoCheck() {
        let dateString = this.toDateString(this.date);
        if (this.value != dateString) {
            this.value = dateString;
            this.valueHour = this.date.getHours();
            this.valueMinute = this.date.getMinutes();
            let $input = jQuery("#" + this.parentId + " .datepickerinline");
            $input.datepicker("setDate", this.date);

        }
    }

    ngAfterContentInit() {
        setTimeout(() => {
            this.initDateTimePicker();
        }, 1000);
    }

    ngAfterViewChecked() {
        var elements = jQuery('input[type="datetime-local"]');
        if (elements && elements.length > 0) {
            for (var i = 0; i < elements.length; i++) {
                if (jQuery(elements[i]).val().length > 0) {
                    jQuery(elements[i]).addClass('full');
                } else {
                    jQuery(elements[i]).removeClass('full');
                }
            }
        }
    }

    constructor(public data: DataService, public util: UtilService) {
        this.layoutType = environment.name;
        this.inputIsIos = this.util.checkIOS();
        this.waitingTimeChange = new EventEmitter();
        if (this.classInput.length == 0) {
            this.classInput = 'ui-input';
        }
        if (this.classInput.indexOf('ui-datetime-input') < 0) {
            this.classInput += ' ui-datetime-input';
        }
        if (!this.pickType) this.pickType = PickupType.Later;
    }

    private toDateString(date: Date): string {
        if (this.pickType == PickupType.Now) {
            return 'ASAP';
        } else {
            if (date && date != null) {
                let value = ('0' + date.getDate()).slice(-2) + '/' +
                    ('0' + (date.getMonth() + 1)).slice(-2) + '/' +
                    date.getFullYear() + ' ' + date.toTimeString().slice(0, 5);
                return value;
            }
        }
        return '';
    }

    private parseDateString(date: string): Date {
        date = date.replace('T', '-');
        var parts = date.split('-');
        var timeParts = parts[3].split(':');

        // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
        var day = parseInt(parts[2]);
        var year = parseInt(parts[0]);
        var month = parseInt(parts[1]) - 1;
        var hour = parseInt(timeParts[0]);
        var minute = parseInt(timeParts[1]);
        return new Date(year, month, day, hour, minute);
    }

    public onDateChange(value: string): void {
        this.deactiveQuickMinute();
        if (value !== this.value) {
            var parsedDate = this.parseDateString(value);
            this.value = value;
            this.pickType = PickupType.Later;
            this.objectChange.emit({ Date: parsedDate, Type: this.pickType });
        }
    }

    public onSelectDate(): any {
        let date: Date;
        if (this.active15 || this.active30 || this.active60) {
            date = this.date;
        } else {
            let $input = jQuery("#" + this.parentId + " .datepickerinline");
            date = $input.datepicker("getDate");
            this.deactiveQuickMinute();
        }
        if (date) {
            this.hidePanel();
            let dateValue = <Date>date;
            dateValue.setHours(this.valueHour);
            dateValue.setMinutes(this.valueMinute);
            this.value = this.toDateString(dateValue);
            this.objectChange.emit({ Date: date, Type: this.pickType });
        }
    }

    public onInput(target: any): void {
        if (jQuery(target).val().length > 0) {
            jQuery(target).addClass('full');
        } else {
            jQuery(target).removeClass('full');
        }
    }

    handleInputClick() {
        if (this.disabled)
            return;
        this.panelVisible = !this.panelVisible;
        this.scrollPoint();
        if (this.panelVisible) this.onPanelVisible.emit();
        else this.onPanelHidden.emit();
    }

    public onClick() {
        this.onClickItem.emit();
    }

    hidePanel() {
        this.panelVisible = false;
        this.onPanelHidden.emit();
    }
    showPanel() {
        this.panelVisible = true;
        this.scrollPoint();
        if (this.panelVisible) this.onPanelVisible.emit();
        else this.onPanelHidden.emit();
    }
    clearInput() {
        this.date = null;
        this.panelVisible = false;
        this.onPanelHidden.emit();
        this.value = this.toDateString(this.date);
        this.objectChange.emit(null);
    }
    hideInvalid() {
        this.valid = true;
    }
    showInvalid(message: string = '') {
        this.valid = false;
        if (message)
            this.invalidMessage = message;
    }

    scrollPoint() {
        if (this.util.IsMobile()) {
            if (this.scrollOnTop) {
                this.util.ScrollToSticky(this.parentId, false);
            }
        }
    }

    initDateTimePicker() {
        let $input = jQuery("#" + this.parentId + " .datepickerinline"),
            $icon = jQuery("#" + this.parentId + " .icon-last");
        if ($input.length > 0) {
            if (this.util.checkIOS()) {
                $input.clone().attr('type', 'datetime-local').insertAfter($input).prev().remove();
                $input.change(() => {
                    if ($input.val().length > 0) {
                        $input.attr('placeholder', '');
                    } else {
                        $input.attr('placeholder', 'Date & Time');
                    }
                    this.scrollPoint();
                    this.objectChange.emit($input.val());
                });
                $icon.click(() => {
                    $input.focus();
                });
            } else {
                $input.prop('readonly', 'readonly');
                $input.datepicker({
                    prevText: '',
                    nextText: '',
                    oneLine: true,
                    dateFormat: 'dd/mm/yy',
                    showMonthAfterYear: false,
                    defaultDate: this.date || new Date(),
                });
            }
        }
    }

    public panelIsVisible(): boolean {
        return this.panelVisible;
    }

    changeHour(value: any) {
        this.valueHour = parseInt(value);
    }
    changeMinute(value: any) {
        this.valueMinute = parseInt(value);
    }
    changeWaitingMinute(value: any) {
        this.valueWaitingMinute = parseInt(value);
        this.waitingTimeChange.emit(this.valueWaitingMinute);
    }
    changePickType(type: PickupType) {
        this.pickType = type;
        if (this.pickType == PickupType.Now)
            this.onSelectDate();
        if (this.panelVisible) this.onPanelVisible.emit();
        else this.onPanelHidden.emit();
    }
    incrementHour() {
        this.deactiveQuickMinute();
        if (!this.valueHour) this.valueHour = 0;
        this.valueHour += 1;
        if (this.valueHour >= 24)
            this.valueHour = 23;
    }
    decrementHour() {
        this.deactiveQuickMinute();
        if (!this.valueHour) this.valueHour = 0;
        this.valueHour -= 1;
        if (this.valueHour <= 0)
            this.valueHour = 0;
    }
    incrementMinute() {
        this.deactiveQuickMinute();
        if (!this.valueMinute) this.valueMinute = 0;
        this.valueMinute += 1;
        if (this.valueMinute >= 59)
            this.valueMinute = 59;
    }
    decrementMinute() {
        this.deactiveQuickMinute();
        if (!this.valueMinute) this.valueMinute = 0;
        this.valueMinute -= 1;
        if (this.valueMinute <= 0)
            this.valueMinute = 0;
    }

    quickSetMinute(minute: number) {
        let date = new Date();
        date.setMinutes(date.getMinutes() + minute);
        this.date = date;
        if (this.date) {
            this.hidePanel();
            this.valueHour = this.date.getHours();
            this.valueMinute = this.date.getMinutes();
            this.value = this.toDateString(this.date);
            this.objectChange.emit({ Date: this.date, Type: this.pickType });
        }

        this.deactiveQuickMinute();
        if (minute == 15) this.active15 = true;
        else if (minute == 30) this.active30 = true;
        else if (minute == 60) this.active60 = true;
    }

    deactiveQuickMinute() {
        this.active15 = false;
        this.active30 = false;
        this.active60 = false;
    }

    handleClearClick() {
        this.clearInput();
    }

    checkSelectedItem() {
        return this.date;
    }
}
