<div class="container-fluid menus" id="menus" *ngIf="browser">
    <div class="container">
        <nav class="navbar navbar-default">
            <div class="container-fluid">
                <div class="navbar-header">
                    <a class="navbar-brand" href="https://hyflytaxis.co.uk/">
                        <img alt="Brand" [src]="iconLogo" width="135" height="45">
                    </a>
                    <div>
                        <button class="navbar-toggle" (click)="toggleMenuActive()">
                            <span class="sr-only">Toggle navigation</span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>
                        <button id="navbar-menu-user" class="navbar-toggle" (click)="toggleMenuUserActive()">
                            <img [src]="iconProfile()" width="26" height="26" />
                        </button>
                    </div>
                </div>
                <div class="main-navbar">
                    <ul class="nav navbar-nav" [ngClass]="{'open':menuActive}">
                        <li><a href="https://hyflytaxis.co.uk/">Home</a></li>
                        <li><a href="https://hyflytaxis.co.uk/about-us">About Us</a></li>
                        <li><a href="https://hyflytaxis.co.uk/airport-taxi">Airport Taxi</a></li>
                        <li>
                            <a>
                                Services
                                <span class="sub-arrow mr-2"><i class="fas fa-caret-down"></i></span>
                            </a>
                            <div class="submenu">
                                <a href="https://hyflytaxis.co.uk/student-transport-services">Student Transport Services</a>
                                <a href="https://hyflytaxis.co.uk/wedding-car-services">Wedding Car Services</a>
                                <a href="https://hyflytaxis.co.uk/corporate-meeting-events">Corporate Meeting Events</a>
                                <a href="https://hyflytaxis.co.uk/executive-taxi-service">Executive Taxi Service</a>
                            </div>
                        </li>
                        <li><a href="https://hyflytaxis.co.uk/account">Open An Account</a></li>
                        <li><a href="https://hyflytaxis.co.uk/become-a-driver">Become a Driver</a></li>
                        <li><a href="https://www.taxirent.co.uk">Taxi Rent</a></li>
                        <li><a href="https://royaleventcars.co.uk">Wedding Cars</a></li>
                    </ul>
                    <ul class="nav navbar-nav mobile" *ngIf="data.Profile != null" [ngClass]="{'open':menuUserActive}">
                        <li><a (click)="util.GoToPage('dashboard.html')">Dashboard</a></li>
                        <li *ngIf="util.IsBooker()"><a (click)="util.GoToPage('commission')">Commissions</a></li>
                        <li *ngIf="util.IsBooker()"><a (click)="util.GoToPage('invoice')">Invoices</a></li>
                        <li><a (click)="util.GoToPage('bookinghistory')">My Bookings</a></li>
                        <li><a (click)="util.GoToPage('addressbook')">My Places</a></li>
                        <li><a (click)="util.OnLogout(null)">Sign out</a></li>
                    </ul>
                    <ul class="nav navbar-nav">
                        <li class="nav-item-profile">
                            <a class="nav-item-link" *ngIf="data.Profile == null" (click)="onOpenModal()">Sign In</a>
                            <a class="nav-item-link" *ngIf="data.Profile != null">{{data.Profile.FirstName}}</a>
                            <div *ngIf="data.Profile != null" class="submenu">
                                <a (click)="util.GoToPage('dashboard.html')">Dashboard</a>
                                <a *ngIf="util.IsBooker()" (click)="util.GoToPage('commission')">Commissions</a>
                                <a *ngIf="util.IsBooker()" (click)="util.GoToPage('invoice')">Invoices</a>
                                <a (click)="util.GoToPage('bookinghistory')">My Bookings</a>
                                <a (click)="util.GoToPage('addressbook')">My Places</a>
                                <a (click)="util.OnLogout(null)">Sign out</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</div>