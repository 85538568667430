<div class="modal fade" id="modalForgotPassword" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button id="closeButtonForgot" type="button" class="close" (click)="onClose()">&times;</button>
                <div class="col-xs-12 col-sm-12 col-md-12 modal-title">
                    FORGOT PASSWORD
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="modal-body">
                <div class="ui-control">
                    <xr-textbox #ForgotEmail
                                title="Email"
                                [model]="email"
                                [requied]="true"
                                [invalid]="true"
                                placeholder="Email"
                                classInput="ui-input"
                                editorId="forgot-email"
                                editorName="forgot-email"
                                (onChange)="onInputChange()"
                                [editorType]="data.EDITOR_TYPE.EMAIL">
                    </xr-textbox>
                </div>
                <div class="center-button" style="margin-bottom: 0;">
                    <div class="api-invalid" *ngIf="submitMessage != ''">
                        {{submitMessage}}
                    </div>
                    <button type="submit" class="ui-button" (click)="onSubmit()">
                        <span *ngIf="!onSubmiting">
                            OK
                        </span>
                        <span *ngIf="onSubmiting">
                            <span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> Processing
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>