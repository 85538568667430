import { Location, LocationItem } from './location';

export class Driver {
    code: number;
    message: string;
    driverid: number;
    lastname: string;
    distance: number;
    firstname: string;
    traveltime: number;
    drivernumber: string;
    userlocation: Location;
    currentposition: Location;
}

export class DriverItem {
    Code: number;
    Message: string;
    DriverId: number;
    LastName: string;
    Distance: number;
    FirstName: string;
    TravelTime: number;
    DriverNumber: string;
    UserLocation: LocationItem;
    CurrentLocation: LocationItem;

    public static ToItem(entity: Driver): DriverItem {
        if (entity == null) return null;
        let item = new DriverItem();
        item.Code = entity.code;
        item.Message = entity.message;
        item.DriverId = entity.driverid;
        item.LastName = entity.lastname;
        item.Distance = entity.distance;
        item.FirstName = entity.firstname;
        item.TravelTime = entity.traveltime;
        item.DriverNumber = entity.drivernumber;
        item.UserLocation = LocationItem.ToItem(entity.userlocation);
        item.CurrentLocation = LocationItem.ToItem(entity.currentposition);
        return item;
    }
}

export class DriverPhoto {
    code: number;
    photo: string;
    message: string;
}

export class DriverPhotoItem {
    Code: number;
    Photo: string;
    Message: string;

    public static ToItem(entity: DriverPhoto): DriverPhotoItem {
        if (entity == null) return null;
        let item = new DriverPhotoItem();
        item.Code = entity.code;
        item.Photo = entity.photo;
        item.Message = entity.message;
        return item;
    }
}

export class DriverFootprint {
    code: number;
    message: string;
    distance: string;
    coordinates: any;
    traveltime: string;
}

export class DriverFootprintItem {
    Code: number;
    Message: string;
    Distance: string;
    Traveltime: string;
    RoutePath: LocationItem[];

    public static ToItem(entity: DriverFootprint): DriverFootprintItem {
        if (entity == null) return null;
        let item = new DriverFootprintItem();
        item.Code = entity.code;
        item.Message = entity.message;
        item.Distance = entity.distance;
        item.Traveltime = entity.traveltime;
        item.RoutePath = LocationItem.ToItemsFromNumber(entity.coordinates);
        return item;
    }
}
