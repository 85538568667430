declare var jQuery: any;
import { ConstantHelper } from "../helper/constant.helper";
import { ConfigurationItem } from "./configuration";

export class Address {
    icao: string;
    order: number;
    line1: string;
    line2: string;
    outcode: string;
    category: string;
    postcode: string;
    latitude: number;
    iconpath: string;
    longitude: number;
    fulladdress: string;
}

export class AddressBook {
    id: number;
    name: string;
    line1: string;
    line2: string;
    outcode: string;
    iconpath: string;
    category: string;
    postcode: string;
    latitude: number;
    longitude: number;
    placename: string;
    fulladdress: string;
}

export class AddressItem {
    Id: number;
    Code: string;
    Name: string;
    Index: number;
    Order: number;
    Line1: string;
    Line2: string;
    First: boolean;
    Valid: boolean;
    Title?: string;
    PlaceId: string;
    Country?: string;
    Category: string;
    Postcode: string;
    Latitude: number;
    IconPath: string;
    Longitude: number;
    PlaceName: string;
    Fulladdress: string;
    CategoryGoogle: string;

    constructor() {
        this.Valid = true;
    }

    public static ToItem(entity: Address): AddressItem {
        if (entity == null) return null;
        let item = new AddressItem();
        item.Id = 0;
        item.Name = '';
        item.First = false;
        item.Code = entity.icao;
        item.Order = entity.order;
        item.Line1 = entity.line1;
        item.Line2 = entity.line2;
        item.Category = entity.category;
        item.Postcode = entity.postcode;
        item.Latitude = entity.latitude;
        item.Longitude = entity.longitude;
        item.Fulladdress = entity.fulladdress;
        if (entity.iconpath) item.IconPath = entity.iconpath;
        else {
            item.IconPath = item.Category == 'Air'
                ? 'img/plane.png'
                : null;
        }
        return item;
    }

    public static ToItems(addresses: Address[]): AddressItem[] {
        if (addresses == null || addresses.length == 0) return null;
        let items = new Array();
        addresses.forEach(element => {
            items.push(AddressItem.ToItem(element));
        });
        items.sort(function (a, b) { return a.Order - b.Order });
        return items;
    }

    public static ToAddressBookItem(address: AddressBook): AddressItem {
        if (address == null) return null;
        let item = new AddressItem();
        item.IconPath = '';
        item.First = false;
        item.Id = address.id;
        item.Name = address.name;
        item.Line1 = address.line1;
        item.Line2 = address.line2;
        item.Category = address.category;
        item.Postcode = address.postcode;
        item.Latitude = address.latitude;
        item.Longitude = address.longitude;
        item.PlaceName = address.placename;
        item.Fulladdress = address.fulladdress;
        item.IconPath = address.iconpath != null && address.iconpath.length > 0
            ? address.iconpath
            : 'img/plane.png';
        return item;
    }

    public static ToAddressBookItems(addresses: AddressBook[]): AddressItem[] {
        if (addresses == null || addresses.length == 0) return new Array<AddressItem>();
        let items = new Array();
        addresses.forEach(element => {
            items.push(AddressItem.ToAddressBookItem(element));
        });
        return items;
    }

    public static async ToPlaceBingItem(data: any, configuration: ConfigurationItem): Promise<AddressItem> {
        if (data == null) return null;
        let item = new AddressItem();
        item.Postcode = '';
        item.Index = data.index;
        item.IconPath = data.icon;
        item.PlaceId = data.entityId;
        item.Name = data.address.formattedAddress;
        if (data.location) {
            item.Latitude = data.location.latitude;
            item.Longitude = data.location.longitude;
        }
        item.Country = data.address.countryRegionISO2 || data.address.countryRegion;
        item.Fulladdress = data.Fulladdress ? data.Fulladdress : data.address.formattedAddress;
        if (data.Fulladdress.indexOf('Airport') > 0) {
            item.Category = 'Air';
            item.CategoryGoogle = 'Air';
        }
        let postCode = data.address.postalCode;
        if (!postCode) {
            if (configuration && configuration.GoogleApiKey) {
                let googleApiKey = configuration.GoogleApiKey || 'AIzaSyCEukqvKS67Yv87cJuEmWE1I4TRkQ6cL2Q';
                let url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + item.Latitude + ',' + item.Longitude + '&components=country:GB&key=' + googleApiKey;
                await jQuery.get(url).then((data: any) => {
                    postCode = this.GetPostCodeFromGooglePlace(data);
                });
            }
        }
        if (!postCode) {
            if (configuration && configuration.BingApiKey) {
                let bingApiKey = configuration.BingApiKey || 'AuPUbNkDipB7ZkSacA6fNTJmcHY3mVRvsIxPHUNCK9KEdKu3VULEMKo4xmK2MMKf';
                let url = '//dev.virtualearth.net/REST/v1/Locations/' + item.Latitude + ',' + item.Longitude + '?key=' + bingApiKey;
                await jQuery.get(url).then((data: any) => {
                    let resourceItem = data.resourceSets && data.resourceSets.length > 0 ? data.resourceSets[0].resources : null;
                    let address = resourceItem && resourceItem.length > 0 ? resourceItem[0].address : null;
                    postCode = address && address.postalCode;
                });
            }
        }
        if (!postCode) {
            await jQuery.get('https://plus.codes/api?address=' + item.Latitude + ',' + item.Longitude).then((data: any) => {
                postCode = data && data.plus_code && data.plus_code.global_code;
            });
        }
        item.Postcode = postCode;
        return item;
    }

    public static async ToPlaceGoogleItem(address: any, configuration: ConfigurationItem): Promise<AddressItem> {
        if (address == null) return null;
        let item = new AddressItem();
        item.Postcode = '';
        item.Name = address.name;
        item.Index = address.index;
        item.IconPath = address.icon;
        item.PlaceId = address.place_id;
        if (address.geometry) {
            item.Latitude = address.geometry.location.lat();
            item.Longitude = address.geometry.location.lng();
        }
        item.Fulladdress = address.Fulladdress ? address.Fulladdress : address.name;
        if (address.types && address.types.length > 0) {
            for (let i = 0; i < address.types.length; i++) {
                if (address.types[i].indexOf('airport') >= 0) {
                    item.Category = 'Air';
                    item.CategoryGoogle = 'Air';
                    break;
                }
            }
        }
        let postCode = this.GetPostCodeFromGooglePlace(address);
        if (!postCode) {
            if (configuration && configuration.GoogleApiKey) {
                let googleApiKey = configuration.GoogleApiKey || 'AIzaSyCEukqvKS67Yv87cJuEmWE1I4TRkQ6cL2Q';
                let url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + item.Latitude + ',' + item.Longitude + '&components=country:GB&key=' + googleApiKey;
                await jQuery.get(url).then((data: any) => {
                    postCode = this.GetPostCodeFromGooglePlace(data);
                });
            }
        }
        if (!postCode) {
            if (configuration && configuration.BingApiKey) {
                let bingApiKey = configuration.BingApiKey || 'AuPUbNkDipB7ZkSacA6fNTJmcHY3mVRvsIxPHUNCK9KEdKu3VULEMKo4xmK2MMKf';
                let url = '//dev.virtualearth.net/REST/v1/Locations/' + item.Latitude + ',' + item.Longitude + '?key=' + bingApiKey;
                await jQuery.get(url).then((data: any) => {
                    let resourceItem = data.resourceSets && data.resourceSets.length > 0 ? data.resourceSets[0].resources : null;
                    let address = resourceItem && resourceItem.length > 0 ? resourceItem[0].address : null;
                    postCode = address && address.postalCode;
                });
            }
        }
        if (!postCode) {
            await jQuery.get('https://plus.codes/api?address=' + item.Latitude + ',' + item.Longitude).then((data: any) => {
                postCode = data && data.plus_code && data.plus_code.global_code;
            });
        }
        item.Postcode = postCode;
        return item;
    }

    private static GetPostCodeFromGooglePlace(address: any) {
        let postCode = '',
            address_components = address.results && address.results.length > 0
                ? address.results[0].address_components
                : address.address_components;
        if (address_components &&
            address_components.length > 0) {
            for (let i = 0; i < address_components.length; i++) {
                let found = false;
                let component = address_components[i];
                if (component.types && component.types.length > 0) {
                    for (let j = 0; j < component.types.length; j++) {
                        if (component.types[j].indexOf('postal_code') >= 0) {
                            found = true;
                            break;
                        }
                    }
                    if (found) {
                        postCode = component.long_name;
                    }
                }
                if (postCode.length > 0) break;
            }
        }
        return postCode;
    }

}

export class ViaAddress {
    lat: number;
    lng: number;
    address: string;
    postcode: string;

    public static ToViaAddress(address: AddressItem): ViaAddress {
        if (address == null) return null;
        let item = new ViaAddress();
        item.lat = address.Latitude;
        item.lng = address.Longitude;
        item.postcode = address.Postcode;
        item.address = address.Fulladdress;
        return item;
    }
}