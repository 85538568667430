import { Component } from '@angular/core';
import { ConfirmDialog } from './../../../domain/dialog';
import { DataService } from './../../../services/dataService';
import { UtilService } from './../../../services/utilService';

@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm.dialog.component.html',
    host: {
        '(document:keydown)': 'keyDownFunction($event)'
    }
})

export class ConfirmDialogComponent {
    loading: boolean;
    dialog: ConfirmDialog;

    constructor(public data: DataService, public util: UtilService) {
        this.loading = false;
        this.dialog = new ConfirmDialog('Content');
        this.data.ShowConfirmDialog.subscribe((model: any) => {
            this.dialog = model;
            this.util.ShowModal(this.data.MODAL_TYPE.CONFIRM);
        });
        this.data.CloseConfirmDialog.subscribe(() => {
            this.loading = false;
            this.onClose();
        });
    }

    onClose() {
        this.util.HideModal(this.data.MODAL_TYPE.CONFIRM);
        this.data.ModalType = '';
    }

    onOk() {
        if (this.dialog.OkFunction) {
            this.loading = true;
            this.dialog.OkFunction();
        }
        this.data.ModalType = '';
    }

    onCancel() {
        if (this.dialog.CancelFunction)
            this.dialog.CancelFunction();
        this.onClose();
    }

    keyDownFunction(event: any) {
        if (this.data.ModalType == this.data.MODAL_TYPE.CONFIRM) {
            if (event.keyCode == 13) {
                this.onOk();
            }
        }
    }
}