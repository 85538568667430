import { AppConfig } from '../../../app.config';
import { Flight } from './../../../domain/flight';
import { Component, ViewChild } from '@angular/core';
import { AirportItem } from './../../../domain/airport';
import { TimeItem } from './../../../domain/object/timeitem';
import { UtilService } from './../../../services/utilService';
import { DataService } from './../../../services/dataService';
import { TemplateType } from '../../../domain/enum/templatetype';
import { XrTextboxComponent } from '../../editors/textbox/textbox';
import { environment } from '../../../../environments/environment';
import { XrDateTimeComponent } from '../../editors/datetime/datetime';
import { XrCheckboxComponent } from '../../editors/checkbox/checkbox';
import { XrAirportListComponent } from '../../editors/ariportlist/airportlist';

@Component({
    selector: 'flight-dialog',
    templateUrl: './flight.dialog.component.html',
    host: {
        '(document:keydown)': 'keyDownFunction($event)'
    }
})

export class FlightDialogComponent {
    iconFlight: string;
    iconAirport: string;
    iconEditorFlight: string;

    disabledFlight: boolean;
    disabledReturnFlight: boolean;

    @ViewChild('FlightNumber') FlightNumber: XrTextboxComponent;
    @ViewChild('FlightAirport') FlightAirport: XrTextboxComponent;
    @ViewChild('FlightArrival') FlightArrival: XrDateTimeComponent;
    @ViewChild('FlightCheckbox') FlightCheckbox: XrCheckboxComponent;
    @ViewChild('FlightAirportList') FlightAirportList: XrAirportListComponent;

    @ViewChild('ReturnFlightNumber') ReturnFlightNumber: XrTextboxComponent;
    @ViewChild('ReturnFlightAirport') ReturnFlightAirport: XrTextboxComponent;
    @ViewChild('ReturnFlightArrival') ReturnFlightArrival: XrDateTimeComponent;
    @ViewChild('ReturnFlightCheckbox') ReturnFlightCheckbox: XrCheckboxComponent;
    @ViewChild('ReturnFlightAirportList') ReturnFlightAirportList: XrAirportListComponent;

    constructor(public data: DataService, public util: UtilService) {
        this.iconEditorFlight = AppConfig.ASSETS + 'editor/flight.png';
        this.iconFlight = AppConfig.ASSETS + 'icons/icon-11.png';
        this.iconAirport = AppConfig.ASSETS + 'editor/via.png';
    }

    onClose() {
        // Set value flight
        this.data.Booking.Flight.Number = this.FlightNumber.model;
        this.data.Booking.Flight.AirportText = this.FlightAirport ? this.FlightAirport.model : '';

        // Set value return-flight
        this.data.Booking.ReturnFlight.Number = this.ReturnFlightNumber ? this.ReturnFlightNumber.model : '';
        this.data.Booking.ReturnFlight.AirportText = this.ReturnFlightAirport ? this.ReturnFlightAirport.model : '';

        this.util.HideModal(this.data.MODAL_TYPE.FLIGHT);
        this.data.ModalType = '';
    }
    navigate() {
        /// Navigate
        switch (this.data.SignInNavigate) {
            case this.data.SIGNIN_NAVIGATE_TYPE.RETURN_FLIGHT:
                {
                    if (this.util.NeedNavegateReturnFlightDetail()) {
                        this.data.FlightType = this.data.FLIGHT_TYPE.RETURN;
                        this.data.SignInNavigate = this.data.SIGNIN_NAVIGATE_TYPE.EDIT_CONTACT;
                    } else {
                        this.util.NavigateEditContact();
                        this.data.SignInNavigate = this.data.SIGNIN_NAVIGATE_TYPE.NOTE_TO_DRIVER;
                    }
                }
                break;
            case this.data.SIGNIN_NAVIGATE_TYPE.NOTE_TO_DRIVER:
                {
                    if (this.data.PopupPaymentNoteToDriver) {
                        if (this.util.NeedNavegateNoteToDriver()) {
                            this.util.NavigateNoteToDriver();
                            this.data.SignInNavigate = this.data.SIGNIN_NAVIGATE_TYPE.CONFIRM_BOOKING;
                        }
                    } else {
                        this.data.SignInNavigate = this.data.SIGNIN_NAVIGATE_TYPE.CONFIRM_BOOKING;
                        this.util.NavigateConfirmBooking();
                    }
                }
                break;
            case this.data.SIGNIN_NAVIGATE_TYPE.EDIT_CONTACT:
                {
                    if (this.util.NeedNavegateReturnFlightDetail()) {
                        this.data.FlightType = this.data.FLIGHT_TYPE.RETURN;
                        this.data.SignInNavigate = this.data.SIGNIN_NAVIGATE_TYPE.EDIT_CONTACT;
                    } else {
                        if (this.data.PopupPaymentContact) {
                            this.util.NavigateEditContact();
                            this.data.SignInNavigate = this.data.SIGNIN_NAVIGATE_TYPE.NOTE_TO_DRIVER;
                        } else {
                            this.data.SignInNavigate = this.data.SIGNIN_NAVIGATE_TYPE.CONFIRM_BOOKING;
                            this.util.NavigateConfirmBooking();
                        }
                    }
                }
                break;
            case this.data.SIGNIN_NAVIGATE_TYPE.CONFIRM_BOOKING:
                this.continueStep();
                break;
            default:
                this.continueStep();
                break;
        }
    }
    choiceFlightTab(tab: string) {
        this.data.FlightType = tab;
    }
    keyDownFunction(event: any) {
        if (this.data.ModalType == this.data.MODAL_TYPE.FLIGHT) {
            if (event.keyCode == 13) {
                if (this.data.FlightType == this.data.FLIGHT_TYPE.ONWARD) {
                    this.onSubmitFlight();
                } else {
                    this.onSubmitReturnFlight();
                }
            }
        }
    }

    onChangeFlightNumber() {
        if (this.FlightNumber) this.FlightNumber.hideInvalid();
        if (this.FlightCheckbox) this.FlightCheckbox.hideInvalid();
    }
    onChangeFlightAirport() {
        if (this.FlightAirport) this.FlightAirport.hideInvalid();
        if (this.FlightCheckbox) this.FlightCheckbox.hideInvalid();
        if (this.FlightAirportList) this.FlightAirportList.hideInvalid();
    }
    onSelectFlightDate($event) {
        if ($event != null) {
            if (this.data.Booking.Flight)
                this.data.Booking.Flight = new Flight();
            if (this.data.Booking.Flight.ArrivingFrom == null)
                this.data.Booking.Flight.ArrivingFrom = new TimeItem();
            this.data.Booking.Flight.ArrivingFrom.PickUpType = $event.Type;
            this.data.Booking.Flight.ArrivingFrom.Time = new Date($event.Date);
        }
        if (this.data.Booking.Flight.ArrivingFrom) {
            if (this.FlightArrival) this.FlightArrival.hideInvalid();
            if (this.FlightCheckbox) this.FlightCheckbox.hideInvalid();
        }
    }
    onChangedAirport($event: any) {
        if (this.data.Booking.Flight == null)
            this.data.Booking.Flight = new Flight();
        this.data.Booking.Flight.AirportText = $event.data;
        if ($event == null || $event.data == null || $event.data == '')
            this.data.Booking.Flight.Airport = null;
    }
    onChangeIsFlightDetail() {
        if (this.FlightCheckbox) this.FlightCheckbox.hideInvalid();
        if (this.data.Booking.Flight == null) this.data.Booking.Flight = new Flight();
        if (this.data.Booking.Flight.IsFlightDetail) {
            this.disabledFlight = true;
            if (this.FlightNumber) this.FlightNumber.hideInvalid();
            if (this.FlightAirport) this.FlightAirport.hideInvalid();
            if (this.FlightArrival) this.FlightArrival.hideInvalid();
            if (this.FlightAirportList) this.FlightAirportList.hideInvalid();
            this.data.Booking.Flight.Number = '';
            this.data.Booking.Flight.Airport = null;
            this.data.Booking.Flight.AirportText = '';
            this.data.Booking.Flight.ArrivingFrom = new TimeItem();
        } else this.disabledFlight = false;
    }
    onSelectedAirport($event: any) {
        var airport = $event == null ? null : <AirportItem>$event.data;
        this.iconAirport = airport != null && airport.IconPath.length > 0
            ? airport.IconPath
            : './assets/images/editor/via.png';
        this.data.Booking.Flight.Airport = airport;
        if (this.data.Booking.Flight.Airport != null)
            this.data.Booking.Flight.AirportText = '';
        if (this.FlightAirport) this.FlightAirport.hideInvalid();
        if (this.FlightAirportList) this.FlightAirportList.hideInvalid();
        if (this.FlightCheckbox) {
            if (this.data.Booking.Flight.Number != null && this.data.Booking.Flight.Number != '')
                if (this.FlightCheckbox) this.FlightCheckbox.hideInvalid();
        }
    }

    continueStep() {
        switch (environment.template) {
            case TemplateType.Wizard:
                this.util.ContinueStep();
                break;
            case TemplateType.GateWay:
                this.util.ContinueStepMax2();
                break;
            case TemplateType.WizardT:
                this.util.ContinueStepMax3();
                break;
            case TemplateType.Overflow:
                this.util.NavigateConfirmBooking();
                break;
            case TemplateType.WizardX:
                this.util.ContinueStepMax3Payment();
                break;
        }
    }
    onSubmitFlight() {
        /// Valid
        let valid = this.validSubmitFlight();
        if (!valid) {
            return;
        }

        /// Set value
        this.data.Booking.Flight.Number = this.FlightNumber.model;
        this.data.Booking.Flight.AirportText = this.FlightAirport ? this.FlightAirport.model : '';

        /// Check return-flight
        if (this.util.NeedNavegateReturnFlightDetail()) {
            this.data.FlightType = this.data.FLIGHT_TYPE.RETURN;
            return;
        } else {
            this.util.HideModal(this.data.MODAL_TYPE.FLIGHT);
        }

        /// Navigate
        this.navigate();
    }
    validSubmitFlight(): boolean {
        if (!this.data.Booking.Flight.IsFlightDetail) {
            var valid1 = this.FlightNumber.model && this.FlightNumber.model != '' && this.FlightNumber.model.trim() != '';
            if (!valid1) {
                if (this.FlightNumber) this.FlightNumber.showInvalid('Flight number is required');
            } else {
                if (this.FlightNumber) this.FlightNumber.hideInvalid();
            }
            var valid2 = false;
            if (this.FlightAirport) {
                valid2 = this.FlightAirport.model && this.FlightAirport.model != '' && this.FlightAirport.model.trim() != '';
                if (!valid2) {
                    if (this.FlightAirport) this.FlightAirport.showInvalid('Arriving from is required');
                } else {
                    if (this.FlightAirport) this.FlightAirport.hideInvalid();
                }
            } else if (this.FlightAirportList) {
                valid2 = this.data.Booking.Flight.Airport != null;
                if (!valid2) {
                    this.FlightAirportList.showInvalid('Arriving from airport is required');
                } else {
                    this.FlightAirportList.hideInvalid();
                }
            }
            if (!valid1 || !valid2) {
                var valid3 = this.data.Booking.Flight.IsFlightDetail;
                if (!valid3) {
                    if (this.FlightCheckbox) this.FlightCheckbox.showInvalid('Please tick this box to confirm if flight details are not available');
                } else {
                    if (this.FlightCheckbox) this.FlightCheckbox.hideInvalid();
                }
            }
            return valid1 && valid2;
        } else {
            if (this.FlightNumber) this.FlightNumber.hideInvalid();
            if (this.FlightAirport) this.FlightAirport.hideInvalid();
            if (this.FlightArrival) this.FlightArrival.hideInvalid();
            if (this.FlightCheckbox) this.FlightCheckbox.hideInvalid();
            if (this.FlightAirportList) this.FlightAirportList.hideInvalid();
            return true;
        }
    }

    onChangeReturnFlightNumber() {
        if (this.ReturnFlightNumber) this.ReturnFlightNumber.hideInvalid();
        if (this.ReturnFlightCheckbox) this.ReturnFlightCheckbox.hideInvalid();
    }
    onChangeReturnFlightAirport() {
        if (this.ReturnFlightAirport) this.ReturnFlightAirport.hideInvalid();
        if (this.ReturnFlightCheckbox) this.ReturnFlightCheckbox.hideInvalid();
        if (this.ReturnFlightAirportList) this.ReturnFlightAirportList.hideInvalid();
    }
    onSelectReturnFlightDate($event) {
        if ($event != null) {
            if (this.data.Booking.ReturnFlight)
                this.data.Booking.ReturnFlight = new Flight();
            if (this.data.Booking.ReturnFlight.ArrivingFrom == null)
                this.data.Booking.ReturnFlight.ArrivingFrom = new TimeItem();
            this.data.Booking.ReturnFlight.ArrivingFrom.PickUpType = $event.Type;
            this.data.Booking.ReturnFlight.ArrivingFrom.Time = new Date($event.Date);
        }
        if (this.data.Booking.ReturnFlight.ArrivingFrom) {
            if (this.ReturnFlightArrival) this.ReturnFlightArrival.hideInvalid();
            if (this.ReturnFlightCheckbox) this.ReturnFlightCheckbox.hideInvalid();
        }
    }
    onChangedReturnAirport($event: any) {
        if (this.data.Booking.ReturnFlight == null)
            this.data.Booking.ReturnFlight = new Flight();
        this.data.Booking.ReturnFlight.AirportText = $event.data;
        if ($event == null || $event.data == null || $event.data == '')
            this.data.Booking.ReturnFlight.Airport = null;
    }
    onChangeIsReturnFlightDetail() {
        if (this.ReturnFlightCheckbox) this.ReturnFlightCheckbox.hideInvalid();
        if (this.data.Booking.ReturnFlight == null) this.data.Booking.ReturnFlight = new Flight();
        if (this.data.Booking.ReturnFlight.IsFlightDetail) {
            this.disabledReturnFlight = true;
            if (this.ReturnFlightNumber) this.ReturnFlightNumber.hideInvalid();
            if (this.ReturnFlightAirport) this.ReturnFlightAirport.hideInvalid();
            if (this.ReturnFlightArrival) this.ReturnFlightArrival.hideInvalid();
            if (this.ReturnFlightAirportList) this.ReturnFlightAirportList.hideInvalid();
            this.data.Booking.ReturnFlight.Number = '';
            this.data.Booking.ReturnFlight.Airport = null;
            this.data.Booking.ReturnFlight.AirportText = '';
            this.data.Booking.ReturnFlight.ArrivingFrom = new TimeItem();
        } else this.disabledReturnFlight = false;
    }
    onSelectedReturnAirport($event: any) {
        var airport = $event == null ? null : <AirportItem>$event.data;
        this.iconAirport = airport != null && airport.IconPath.length > 0
            ? airport.IconPath
            : './assets/images/editor/via.png';
        this.data.Booking.ReturnFlight.Airport = airport;
        if (this.data.Booking.ReturnFlight.Airport != null)
            this.data.Booking.ReturnFlight.AirportText = '';
        if (this.ReturnFlightAirport) this.ReturnFlightAirport.hideInvalid();
        if (this.ReturnFlightAirportList) this.ReturnFlightAirportList.hideInvalid();
        if (this.ReturnFlightCheckbox) {
            if (this.data.Booking.ReturnFlight.Number != null && this.data.Booking.ReturnFlight.Number != '')
                if (this.ReturnFlightCheckbox) this.ReturnFlightCheckbox.hideInvalid();
        }
    }

    onSubmitReturnFlight() {
        /// Valid
        let valid = this.validSubmitReturnFlight();
        if (!valid) {
            return;
        }

        // Set value
        this.data.Booking.ReturnFlight.Number = this.ReturnFlightNumber ? this.ReturnFlightNumber.model : '';
        this.data.Booking.ReturnFlight.AirportText = this.ReturnFlightAirport ? this.ReturnFlightAirport.model : '';

        /// Check return-flight
        if (this.util.NeedNavegateFlightDetail()) {
            this.data.FlightType = this.data.FLIGHT_TYPE.ONWARD;
            return;
        } else {
            this.util.HideModal(this.data.MODAL_TYPE.FLIGHT);
        }

        /// Navigate
        this.navigate();
    }
    validSubmitReturnFlight(): boolean {
        if (!this.data.Booking.ReturnFlight.IsFlightDetail) {
            var valid1 = this.ReturnFlightNumber.model && this.ReturnFlightNumber.model != '' && this.ReturnFlightNumber.model.trim() != '';
            if (!valid1) {
                if (this.ReturnFlightNumber) this.ReturnFlightNumber.showInvalid('Flight number is required');
            } else {
                if (this.ReturnFlightNumber) this.ReturnFlightNumber.hideInvalid();
            }
            var valid2 = false;
            if (this.ReturnFlightAirport) {
                valid2 = this.ReturnFlightAirport.model && this.ReturnFlightAirport.model != '' && this.ReturnFlightAirport.model.trim() != '';
                if (!valid2) {
                    if (this.ReturnFlightAirport) this.ReturnFlightAirport.showInvalid('Arriving from is required');
                } else {
                    if (this.ReturnFlightAirport) this.ReturnFlightAirport.hideInvalid();
                }
            } else if (this.ReturnFlightAirportList) {
                valid2 = this.data.Booking.ReturnFlight.Airport != null;
                if (!valid2) {
                    this.ReturnFlightAirportList.showInvalid('Arriving from airport is required');
                } else {
                    this.ReturnFlightAirportList.hideInvalid();
                }
            }
            if (!valid1 || !valid2) {
                var valid3 = this.data.Booking.ReturnFlight.IsFlightDetail;
                if (!valid3) {
                    if (this.ReturnFlightCheckbox) this.ReturnFlightCheckbox.showInvalid('Please tick this box to confirm if flight details are not available');
                } else {
                    if (this.ReturnFlightCheckbox) this.ReturnFlightCheckbox.hideInvalid();
                }
            }
            return valid1 && valid2;
        } else {
            if (this.ReturnFlightNumber) this.ReturnFlightNumber.hideInvalid();
            if (this.ReturnFlightAirport) this.ReturnFlightAirport.hideInvalid();
            if (this.ReturnFlightArrival) this.ReturnFlightArrival.hideInvalid();
            if (this.ReturnFlightCheckbox) this.ReturnFlightCheckbox.hideInvalid();
            if (this.ReturnFlightAirportList) this.ReturnFlightAirportList.hideInvalid();
            return true;
        }
    }
}