<div class="modal fade" id="modalQuotation" role="dialog">
    <div class="modal-dialog" style="height: 100%;">

        <!-- Modal content-->
        <div class="modal-content" *ngIf="browser">
            <div class="modal-header">
                <button id="closeButtonQuotation" type="button" class="close" (click)="onCancel()">&times;</button>
                <div class="col-xs-12 col-sm-12 col-md-12 modal-title">
                    Quotation Expired
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="modal-body">
                <p class="quotation-message">
                    Opps, your quotation has expired. Please try again or call our friendly team on <span><a style="color: red;" href="tel:{{data.Configuration.Phone}}" target="_top">{{data.Configuration.Phone}}</a></span>
                </p>
                <div *ngIf="data.Booking.Time.IsShow">
                    <div class="ui-control">
                        <xr-datetime [showHeader]="false" classInput="ui-input" placeholder="Date & Time" [(date)]="data.Booking.Time.Time" (objectChange)="onSelectDate($event)" [pickType]="data.Booking.Time.PickUpType" [minute]="data.Booking.Quotation.TravelTime">
                        </xr-datetime>
                    </div>
                    <div class="ui-datetime-invalid" style="display: block; color: red; font-size: 16px; margin-top: 10px; padding: 10px 20px; background-color: #f97203; color: #fff;" *ngIf="!data.Booking.Time.IsValid">
                        <span class="glyphicon glyphicon-warning-sign"></span> The earliest time we can pickup from this address is {{ data.Booking.Time.EarliestTime | datex }}
                    </div>
                </div>
                <div class="center-button" style="text-align: center;">
                    <button type="button" class="ui-button" (click)="onSubmit()">
                        <span *ngIf="!onSubmiting">
                            Re-Quote
                        </span>
                        <span *ngIf="onSubmiting">
                            <span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> Processing
                        </span>
                    </button>
                    <button type="button" class="ui-button btn-cancel" (click)="onCancel()">
                        <span>
                            Cancel
                        </span>
                    </button>
                </div>
                <div style="clear: both;"></div>
            </div>
        </div>
    </div>
</div>