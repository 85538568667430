export class SignUp {
    public Email: string;
    public Phone: string;
    public Surname: string;
    public Password: string;
    public Firstname: string;
    public DialingCode: string;
    public PasswordHash: string;
    public ConfirmPassword: string;

    constructor() {
        this.Email = '';
        this.Phone = '';
        this.Surname = '';
        this.Password = '';
        this.Firstname = '';
        this.PasswordHash = '';
        this.DialingCode = '+44';
        this.ConfirmPassword = '';
    }
}