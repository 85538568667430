<div class="modal fade" id="modalContact" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content" *ngIf="browser">
            <div class="modal-header">
                <button id="closeButtonContact" type="button" class="close" (click)="onClose()">&times;</button>
                <div *ngIf="!util.IsBooker()">
                    <div class="col-xs-12 col-sm-12 col-md-12 modal-title">
                        <span>
                            <img [src]="iconPassenger" />
                        </span>Main Passenger
                        <span class="clearfix"></span>
                    </div>
                </div>
                <div *ngIf="util.IsBooker()">
                    <div class="col-xs-6 col-sm-6 col-md-6 signin" (click)="choiceContactTab(data.CUSTOMER_TYPE.CUSTOMER)" [ngClass]="data.ContactType == data.CUSTOMER_TYPE.CUSTOMER ? 'active' : ''">
                        <span>
                            <img [src]="iconPassenger" />
                        </span>Customer
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 signup" (click)="choiceContactTab(data.CUSTOMER_TYPE.PASSENGER)" [ngClass]="data.ContactType == data.CUSTOMER_TYPE.PASSENGER ? 'active' : ''">
                        Main Passenger
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div class="modal-body">
                <div [ngClass]="data.ContactType == data.CUSTOMER_TYPE.PASSENGER ? 'show' : 'hide'">
                    <span *ngIf="data.Profile == null" class="modal-link" style="position: absolute; right: 32px; top: 2px;">
                        Already a member? <a (click)="onOpenModal()">SignIn</a>
                    </span>
                    <div class="ui-control">
                        <xr-textbox #ContactName title="Name" [requied]="true" [invalid]="false" placeholder="Name" classInput="ui-input" [model]="data.Booking.Contact.Name" editorId="popup-booking-contact-name" editorName="popup-booking-contact-name" [disabled]="disabled || disabledCustomer" [editorType]="data.EDITOR_TYPE.TEXT">
                        </xr-textbox>
                    </div>
                    <div class="ui-control">
                        <xr-textbox #ContactPhone title="Phone" [requied]="true" [invalid]="false" placeholder="Phone" classInput="ui-input" [model]="data.Booking.Contact.Phone" [editorType]="data.EDITOR_TYPE.PHONE" editorId="popup-booking-contact-phone" editorName="popup-booking-contact-phone" [disabled]="disabled || disabledCustomer" [dialingCode]="data.Booking.Contact.DialingCode">
                        </xr-textbox>
                    </div>
                    <div class="ui-control">
                        <xr-textbox #ContactEmail title="Email" [invalid]="true" [requied]="false" placeholder="Email" classInput="ui-input" [model]="data.Booking.Contact.Email" editorId="popup-booking-contact-email" editorName="popup-booking-contact-email" [disabled]="disabled || disabledCustomer" [editorType]="data.EDITOR_TYPE.EMAIL">
                        </xr-textbox>
                    </div>
                    <div class="ui-control" *ngIf="!util.IsBooker()">
                        <label class="ui-checkbox control--checkbox">
                            Use my details
                            <input type="checkbox" [(ngModel)]="disabled" (change)="onTickUseMyDetails()" />
                            <span class="control__indicator"></span>
                        </label>
                    </div>
                    <div class="ui-control" *ngIf="util.IsBooker()">
                        <label class="ui-checkbox control--checkbox">
                            Use customer details
                            <input type="checkbox" [(ngModel)]="disabledCustomer" (change)="onTickUseCustomerDetails()" />
                            <span class="control__indicator"></span>
                        </label>
                    </div>
                </div>
                <div [ngClass]="data.ContactType == data.CUSTOMER_TYPE.CUSTOMER ? 'show' : 'hide'">
                    <div class="ui-control">
                        <xr-textbox #CustomerOurRef title="Our Ref" [requied]="false" [invalid]="false" [hasLabel]="false" [scrollOnTop]="false" classInput="ui-input" placeholder="Our Ref" [editorType]="data.EDITOR_TYPE.TEXT" [model]="data.Booking.Customer.OurRef" editorId="popup-booking-customer-ourref" editorName="popup-booking-customer-ourref" (onChange)="onInputChange('CustomerOurRef')">
                        </xr-textbox>
                    </div>
                    <div class="ui-control">
                        <div class="col-xs-12 col-sm-6 customer-firstname">
                            <xr-textbox #CustomerFirstName title="First Name" [requied]="true" [hasLabel]="false" [invalid]="false" [scrollOnTop]="false" classInput="ui-input" placeholder="First Name" [disabled]="disabledForCustomer" [editorType]="data.EDITOR_TYPE.TEXT" [model]="data.Booking.Customer.FirstName" editorId="popup-booking-customer-first-name" editorName="popup-booking-customer-first-name" (onChange)="onInputChange('CustomerFirstName')">
                            </xr-textbox>
                        </div>
                        <div class="col-xs-12 col-sm-6 customer-lastname">
                            <xr-textbox #CustomerLastName title="Last Name" [requied]="false" [hasLabel]="false" [invalid]="false" [scrollOnTop]="false" classInput="ui-input" placeholder="Last Name" [disabled]="disabledForCustomer" [editorType]="data.EDITOR_TYPE.TEXT" [model]="data.Booking.Customer.LastName" editorId="popup-booking-customer-last-name" editorName="popup-booking-customer-last-name" (onChange)="onInputChange('CustomerLastName')">
                            </xr-textbox>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="ui-control">
                        <xr-textbox #CustomerPhone title="Phone" [requied]="true" [invalid]="false" [hasLabel]="false" placeholder="Phone" classInput="ui-input" [disabled]="disabledForCustomer" [model]="data.Booking.Customer.Phone" [editorType]="data.EDITOR_TYPE.PHONE" editorId="popup-booking-customer-phone" editorName="popup-booking-customer-phone" (onChange)="onInputChange('CustomerPhone')" [dialingCode]="data.Booking.Customer.DialingCode">
                        </xr-textbox>
                    </div>
                    <div class="ui-control">
                        <xr-textbox #CustomerEmail title="Email" [invalid]="true" [requied]="false" [hasLabel]="false" placeholder="Email" classInput="ui-input" [disabled]="disabledForCustomer" [model]="data.Booking.Customer.Email" [editorType]="data.EDITOR_TYPE.EMAIL" editorId="popup-booking-customer-email" editorName="popup-booking-customer-email" (onChange)="onInputChange('CustomerEmail')">
                        </xr-textbox>
                    </div>
                    <div class="ui-control">
                        <label class="ui-checkbox control--checkbox">
                            Use my details
                            <input type="checkbox" [(ngModel)]="disabledForCustomer" (change)="onTickUseMyDetailsForCustomer()" />
                            <span class="control__indicator"></span>
                        </label>
                    </div>
                </div>
                <div class="center-button" style="text-align: center;">
                    <button type="button" class="ui-button btn btn-ok" (click)="onSubmit()">OK</button>
                </div>
                <div style="clear: both;"></div>
            </div>
        </div>
    </div>
</div>