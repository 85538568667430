declare var jQuery: any;
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Component, forwardRef, Input, Output, EventEmitter, OnInit } from '@angular/core';

const noopCheckBox = () => {
};

export const INPUT_CHECKBOX_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => XrCheckboxComponent),
    multi: true
};

@Component({
    selector: 'xr-checkbox',
    templateUrl: 'checkbox.html',
    styleUrls: ['checkbox.scss'],
    providers: [INPUT_CHECKBOX_VALUE_ACCESSOR]
})

export class XrCheckboxComponent implements OnInit, ControlValueAccessor {
    parentId: string;
    model: any = null;
    error: string = '';

    @Input() name: string;
    @Input() title: string;
    @Input() style: string;
    @Input() editorId: string;
    @Input() type: string = 'checkbox';
    @Input() classInput: string = 'control__indicator';
    @Output() change: EventEmitter<any> = new EventEmitter();

    ngOnInit() {
        this.parentId = this.editorId + '-input';
        if (this.type == 'checkbox-radio')
            this.classInput = 'control__indicator control__indicator_radio';
    }

    constructor() {

    }

    onValueChange() {
        jQuery('#' + this.parentId).addClass('valid');
        jQuery('#' + this.parentId).removeClass('error');
        this.change.emit();
    }

    hideInvalid() {
        jQuery('#' + this.parentId).addClass('valid');
        jQuery('#' + this.parentId).removeClass('error');
    }

    showInvalid(message: string) {
        this.error = message;
        jQuery('#' + this.parentId).addClass('error');
        jQuery('#' + this.parentId).removeClass('valid');
    }

    set value(v: any) {
        if (v !== this.model) {
            this.model = v;
            this.onChangeCallback(v);
        }
    }
    get value(): any {
        return this.model;
    };

    writeValue(value: any) {
        if (value !== this.model) {
            this.model = value;
        }
    }
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }
    registerOnTouched(fn: any) {
    }

    private onChangeCallback: (_: any) => void = noopCheckBox;
}
