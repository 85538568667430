import { Config } from "../app/domain/object/config";
import { PaymentType } from "../app/domain/enum/payment.type";
import { TemplateType } from "../app/domain/enum/templatetype";
import { TemplateCarType } from "../app/domain/enum/template.car.type";

export const environment: Config = {
    source: 1,
    allowGuest: true,
    name: 'hyflytaxis',
    payment: PaymentType.Card,
    template: TemplateType.Wizard,
    templateCar: TemplateCarType.Wizard,
    api: '//xoeraapis.com/customer/hyflytaxis',
    title: 'Taxi in Cambridge | Affordable Cab Service - HYFLY Taxis',
};
