export class UtilityHelper {
    public static ToLocalTime(date: Date, gmt: boolean = false): string {
        if (date == null) return '';
        var tzo = -date.getTimezoneOffset(),
            dif = tzo >= 0 ? '+' : '-',
            pad = function (num: number) {
                var norm = Math.abs(Math.floor(num));
                return (norm < 10 ? '0' : '') + norm;
            };
        var result = date.getFullYear()
            + '-' + pad(date.getMonth() + 1)
            + '-' + pad(date.getDate())
            + 'T' + pad(date.getHours())
            + ':' + pad(date.getMinutes())
            + ':' + pad(date.getSeconds());
        if (gmt) result += dif + pad(tzo / 60) + ':' + pad(tzo % 60);
        else result += '.000Z';
        return result;
    }
    public static ToFlightTime(date: Date): string {
        if (date == null) return '';
        let year = date.getFullYear(),
            dateString = date.toDateString(),
            month = dateString.split(' ')[1],
            day = date.getDate() + this.GetDaySuffix(date.getDate());
        return day + ' ' + month + ' ' + year;
    }
    public static ToFlightTimeNoSuffix(date: Date): string {
        if (date == null) return '';
        let day = date.getDate(),
            year = date.getFullYear(),
            dateString = date.toDateString(),
            month = dateString.split(' ')[1];
        return day + ' ' + month + ' ' + year;
    }
    public static ToFlightTimeJustTime(date: Date): string {
        if (date == null) return '';
        var pad = function (num: number) {
                var norm = Math.abs(Math.floor(num));
                return (norm < 10 ? '0' : '') + norm;
            };
        var result = pad(date.getHours()) + ':' + pad(date.getMinutes());
        return result;
    }
    private static GetDaySuffix(day: number) {
        switch (day) {
            case 1:
            case 21:
            case 31:
                return "st";
            case 2:
            case 22:
                return "nd";
            case 3:
            case 23:
                return "rd";
            default:
                return "th";
        }
    }
}