<div [id]="parentId" class="text-input">
    <label class="ui-checkbox control--checkbox" *ngIf="type == 'checkbox' || type == 'checkbox-radio'" [for]="editorId">
        {{ title }}
        <input [id]="editorId" type="checkbox" [attr.name]="name" [(ngModel)]="value" (change)="onValueChange()" />
        <span [class]="classInput"></span>
    </label>
    <label class="ui-checkbox control--radio" *ngIf="type == 'radio'" [for]="editorId">
        {{ title }}
        <input [id]="editorId" type="radio" [attr.name]="name" [checked]="value" (change)="onValueChange()" />
        <span [class]="classInput"></span>
    </label>
    <div class="ui-invalid">{{ error }}</div>
</div>