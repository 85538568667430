export enum AutocompleteType {
    Pickup = 1,
    Dropoff,
    ViaAddress
}

export enum AutocompleteSearchType {
    Google = 1,
    Bing = 2,
    Xoera,
}