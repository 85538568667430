declare var jQuery: any;
import { AppConfig } from '../../../app.config';
import { ApiService } from './../../../services/apiService';
import { DataService } from './../../../services/dataService';
import { UtilService } from './../../../services/utilService';
import { DropDownType } from '../../../domain/enum/dropdown.type';
import { XrTextboxComponent } from '../../editors/textbox/textbox';
import { environment } from '../../../../environments/environment';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild, afterNextRender } from '@angular/core';

@Component({
    selector: 'card-dialog',
    styleUrls: ['./card.dialog.component.scss'],
    templateUrl: './card.dialog.component.html',
    host: {
        '(document:keydown)': 'keyDownFunction($event)'
    }
})
export class CardDialogComponent {
    browser: boolean;
    iconCard: string;
    iconMoney: string;
    iconPaypal: string;
    DropDownType = DropDownType;

    show: boolean;
    token: string;
    expiryYears: any;
    expiryMonths: any;
    client: any = null;
    stripe: boolean = false;

    @ViewChild('PaymentCVC') PaymentCVC: XrTextboxComponent;
    @ViewChild('PaymentCity') PaymentCity: XrTextboxComponent;
    @ViewChild('PaymentName') PaymentName: XrTextboxComponent;
    @ViewChild('PaymentCountry') PaymentCountry: XrTextboxComponent;
    @ViewChild('PaymentPostalCode') PaymentPostalCode: XrTextboxComponent;
    @ViewChild('PaymentCardNumber') PaymentCardNumber: XrTextboxComponent;
    @ViewChild('PaymentAddressLine1') PaymentAddressLine1: XrTextboxComponent;
    @ViewChild('PaymentAddressLine2') PaymentAddressLine2: XrTextboxComponent;

    constructor(public service: ApiService, public data: DataService, public util: UtilService) {
        this.expiryYears = this.util.InitExpiryYears();
        this.expiryMonths = this.util.InitExpiryMonths();
        this.iconCard = AppConfig.ASSETS + 'icons/icon-18.png';
        this.iconMoney = AppConfig.ASSETS + 'icons/money2.png';
        this.iconPaypal = AppConfig.ASSETS + 'icons/pay-with-paypal.png';
        afterNextRender(() => {
            this.browser = true;
            this.initStripe();
        })
    }

    onClose() {
        if (this.PaymentCVC) this.PaymentCVC.hideInvalid();
        if (this.PaymentCity) this.PaymentCity.hideInvalid();
        if (this.PaymentName) this.PaymentName.hideInvalid();
        if (this.PaymentCountry) this.PaymentCountry.hideInvalid();
        if (this.PaymentCardNumber) this.PaymentCardNumber.hideInvalid();
        if (this.PaymentPostalCode) this.PaymentPostalCode.hideInvalid();
        if (this.PaymentAddressLine1) this.PaymentAddressLine1.hideInvalid();
        if (this.PaymentAddressLine2) this.PaymentAddressLine2.hideInvalid();
        this.util.HideModal(this.data.MODAL_TYPE.CARD);
        this.data.ModalType = '';
    }

    onSubmit() {
        /// Valid
        this.data.ButtonPaymentType = this.data.BUTTON_PAYMENT_TYPE.DIALOG_CARD;
        let valid = this.validSubmit();
        if (!valid) {
            return;
        }

        /// Set value
        this.data.Payment.CVC = this.PaymentCVC && this.PaymentCVC.model;
        this.data.Payment.City = this.PaymentCity && this.PaymentCity.model;
        this.data.Payment.Name = this.PaymentName && this.PaymentName.model;
        this.data.Payment.PostalCode = this.PaymentPostalCode && this.PaymentPostalCode.model;
        this.data.Payment.AddressLine1 = this.PaymentAddressLine1 && this.PaymentAddressLine1.model;
        this.data.Payment.AddressLine2 = this.PaymentAddressLine2 && this.PaymentAddressLine2.model;
        this.data.Payment.Amount = this.data.Booking.Promo.Code == 1
            ? this.data.Booking.Quotation.TotalFare - this.data.Booking.Promo.Value
            : this.data.Booking.Quotation.TotalFare;
        this.data.Payment.CardNumber = this.PaymentCardNumber && this.PaymentCardNumber.model.toString().replace(/ /g, '');
        if (this.data.Payment.ExpiryYear == null) this.data.Payment.ExpiryYear = this.expiryYears[0].value;
        if (this.data.Payment.ExpiryMonth == null) this.data.Payment.ExpiryMonth = this.expiryMonths[0].value;

        // country
        let elementCountry = jQuery('#' + this.PaymentCountry.editorId);
        if (elementCountry && elementCountry.length > 0) {
            let countryData = elementCountry.countrySelect("getSelectedCountryData");
            if (countryData) {
                this.data.Payment.Country = countryData.name;
                this.data.Payment.CountryCode = countryData.iso2;
            }
        }

        /// payment
        if (this.data.Configuration.PaymentGateway == 'stripe') {
            jQuery('#card-dialog-message').html('');
            this.data.PaymentProcess.emit();
        } else {
            jQuery('#card-dialog-message').html('');
            if (this.client != null) {
                var that = this;
                this.client.tokenizeCard({
                    cvv: that.data.Payment.CVC,
                    number: that.data.Payment.CardNumber,
                    cardholderName: that.data.Payment.Name,
                    expirationYear: that.data.Payment.ExpiryYear,
                    expirationMonth: that.data.Payment.ExpiryMonth,
                    billingAddress: {
                        postalCode: that.data.Payment.PostalCode
                    }
                }, function (err: any, nonce: any) {
                    if (err) {
                        jQuery('#card-dialog-message').html(err);
                        return;
                    }
                    that.data.ManualTimeout = true;
                    that.data.Payment.Nonce = nonce;
                    that.data.PaymentProcess.emit();
                });
            } else {
                jQuery('#card-dialog-message').html(this.data.ERROR_MESSAGE);
            }
        }
    }

    initStripe() {
        setTimeout(() => {
            if (environment.paymentPopup) {
                if (this.data.Configuration.PaymentGateway == 'stripe' && !this.stripe) {
                    this.util.CreateStripePopupPayment();
                    this.stripe = true;
                }
            }
        }, 1000);
    }

    onInputChange(data: any) {
        jQuery('#message').html('');
        if (data == 'PaymentCVC') this.data.Payment.CVC = this.PaymentCVC.model;
        else if (data == 'PaymentCity') this.data.Payment.City = this.PaymentCity.model;
        else if (data == 'PaymentName') this.data.Payment.Name = this.PaymentName.model;
        else if (data == 'PaymentPostalCode') this.data.Payment.PostalCode = this.PaymentPostalCode.model;
        else if (data == 'PaymentAddressLine1') this.data.Payment.AddressLine1 = this.PaymentAddressLine1.model;
        else if (data == 'PaymentAddressLine2') this.data.Payment.AddressLine2 = this.PaymentAddressLine2.model;
        else if (data == 'PaymentCardNumber') this.data.Payment.CardNumber = this.PaymentCardNumber.model.toString().replace(/ /g, '');
        if (!this.data.Payment.ExpiryMonth) this.data.Payment.ExpiryMonth = this.expiryMonths[0].value;
        if (!this.data.Payment.ExpiryYear) this.data.Payment.ExpiryYear = this.expiryYears[0].value;
        if (!this.data.Payment.Amount) {
            this.data.Payment.Amount = this.data.Booking.Promo.Code == 1
                ? this.data.Booking.Quotation.TotalFare - this.data.Booking.Promo.Value
                : this.data.Booking.Quotation.TotalFare;
        }
    }

    onSubmitPaypal() {
        this.data.ButtonPaymentType = this.data.BUTTON_PAYMENT_TYPE.DIALOG_PAYPAL;
        jQuery('#card-dialog-message').html('');
        if (this.data.PaymentPaypal && this.data.PaymentPaypal.paypal) {
            this.data.ManualTimeout = true;
            this.data.PaymentPaypal.paypal.initAuthFlow();
        } else {
            jQuery('#card-dialog-message').html(this.data.ERROR_MESSAGE);
        }
    }

    onSelectedMonth($event: any) {
        this.data.Payment.ExpiryMonth = $event;
    }

    onSelectedYear($event: any) {
        this.data.Payment.ExpiryYear = $event;
    }

    validSubmit(): boolean {
        if (this.data.Configuration.PaymentGateway != 'stripe') {
            var valid1 = this.PaymentCVC.checkValid();
            var valid2 = this.PaymentName.checkValid();
            var valid3 = this.PaymentCity.checkValid();
            var valid4 = this.PaymentCountry.checkValid();
            var valid5 = this.PaymentCardNumber.checkValid();
            var valid6 = this.PaymentPostalCode.checkValid();
            var valid7 = this.PaymentAddressLine1.checkValid();
            var valid8 = this.PaymentAddressLine2.checkValid();
            return valid1 && valid2 && valid3 && valid4 && valid5 && valid6 && valid7 && valid8;
        } else {
            var valid2 = this.PaymentName.checkValid();
            var valid3 = this.PaymentCity.checkValid();
            var valid4 = this.PaymentCountry.checkValid();
            var valid6 = this.PaymentPostalCode.checkValid();
            var valid7 = this.PaymentAddressLine1.checkValid();
            var valid8 = this.PaymentAddressLine2.checkValid();
            return valid2 && valid3 && valid4 && valid6 && valid7 && valid8;
        }
    }

    keyDownFunction(event: any) {
        if (this.data.ModalType == this.data.MODAL_TYPE.CARD) {
            if (event.keyCode == 13) {
                this.onSubmit();
            }
        }
    }
}