import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from './../services/utilService';

@Pipe({
    name: 'datex'
})

export class DatexPipe implements PipeTransform {
    constructor(public util: UtilService) {
    }

    transform(value: Date): string {
        return this.util.ToDateTimeString(value);
    }
}