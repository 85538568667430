declare var jQuery: any;
import { Subscription } from 'rxjs';
import { Flight } from './../domain/flight';
import { Contact } from './../domain/contact';
import { PriceItem } from './../domain/price';
import { Expried } from './../domain/expried';
import { PromoItem } from './../domain/promo';
import { Booking } from './../domain/booking';
import { IpInfoItem } from './../domain/ipinfo';
import { SignIn } from '../domain/object/signin';
import { SignUp } from '../domain/object/signup';
import { ServiceItem } from './../domain/service';
import { InvoiceItem } from './../domain/invoice';
import { AddressItem } from './../domain/address';
import { PromiseItem } from './../domain/promise';
import { ProfileItem } from './../domain/profile';
import { TimeItem } from '../domain/object/timeitem';
import { QuotationItem } from './../domain/quotation';
import { PickupType } from '../domain/enum/pickup.type';
import { Injectable, EventEmitter } from '@angular/core';
import { ConstantHelper } from '../helper/constant.helper';
import { environment } from '../../environments/environment';
import { ConfigurationItem } from './../domain/configuration';
import { VehicleItem, TrackVehicleItem } from './../domain/vehicle';
import { PaymentItem, PaymentTokenItem, PaymentProcessItem } from './../domain/payment';

@Injectable()
export class DataService {
    /// Map
    Map: any = null;
    Markers: Array<any>;
    FlightPath: any = null;
    Infowindow: any = null;
    LoadedScriptMap: boolean;
    MAP_MAX_ZOOM: number = 18;
    FlightReturnPath: any = null;
    LoadedConfiguration: boolean;
    public MAP_ELEMENT = {
        MAP: 'map',
        MAP_FLOAT: 'map-float',
        MAP_FEEDBACK: 'map-feedback',
        MAP_TRACKING: 'map-tracking',
        MAP_CONFIRMED: 'map-confirmed',
        MAP_BOOKING_INFO: 'map-bookinginfo',
        MAP_BOOKING_DETAIL: 'map-booking-detail',
    };

    /// Stripe
    Stripe: any;
    StripePopup: any;
    StripeCardCvc: any;
    StripeCardNumber: any;
    StripeCardExpiry: any;
    StripePopupCardCvc: any;
    StripePopupCardNumber: any;
    StripePopupCardExpiry: any;

    /// Object
    SignIn: SignIn;
    SignUp: SignUp;
    PageTitle: string;
    CountryCode: string;
    PrevBookerId: number;
    LoadingPrice: boolean;
    ModalType: string = '';
    MoreOptions: string = '';
    PageCmsType: string = '';
    IpInfo: IpInfoItem = null;
    ButtonPaymentType: number;
    DateTimeIsTimeout: boolean;
    FirstLoad: boolean = false;
    IsOpenMenu: boolean = false;
    SignInNavigate: string = '';
    TimeCallQuotation: number = 0;
    ManualTimeout: boolean = false;
    EditInvoiceItem: InvoiceItem = null;
    EditAddressItem: AddressItem = null;
    PaymentProcessItem: PaymentProcessItem = new PaymentProcessItem();

    /// Currency
    SymbolCode: string;
    CurrencyCode: string;
    CurrencyCodeSupport: string;

    PriceIndex: number = 1;
    ServiceIndex: number = 0;
    PromiseIndex: number = 0;

    /// Tracking
    SandBox: boolean;
    TrackingCode: string;
    SignalrConnection: any;
    TrackingZipCode: number;
    RunModeTest: boolean = false;
    SignalrForceConnected: boolean;

    /// Promise
    Promises: PromiseItem[];
    PromiseItem: PromiseItem = null;

    /// Flight
    FlightPickUp: boolean = false;
    FlightPickDown: boolean = false;
    ShowPickUpFlight: boolean = false;
    ShowDropOffFlight: boolean = false;
    FlightValidLanding: boolean = true;
    FlightValidDateTime: boolean = true;
    ReturnFlightValidLanding: boolean = true;
    ReturnFlightValidDateTime: boolean = true;

    /// PaymentMethod
    PaymentClassName: string = '';

    /// Prices
    Prices: PriceItem[];

    /// Services
    Services: ServiceItem[];

    /// Vehicles
    VehicleItems: any[];
    Vehicles: VehicleItem[];

    /// DateRanges
    DateRanges: any[];

    /// AddressBooks
    AddressBooks: AddressItem[];

    /// Payment
    ClientPayment: any = null;
    PaymentPaypal: any = null;
    PaymentTypeItems: any[] = [];
    Expried: Expried = new Expried();
    PaymentToken: PaymentTokenItem = null;
    Payment: PaymentItem = PaymentItem.InitItem();

    /// Popup Payment
    PopupPaymentCard: boolean = false;
    PopupPaymentFlight: boolean = false;
    PopupPaymentContact: boolean = false;
    PopupPaymentNoteToDriver: boolean = false;

    LiteBooking: boolean = false;
    private booking: Booking = null;
    private profile: ProfileItem = null;
    Configuration: ConfigurationItem = new ConfigurationItem();

    /// Event Dialog
    ShowDialog: EventEmitter<any> = new EventEmitter<any>();
    ShowConfirmDialog: EventEmitter<any> = new EventEmitter<any>();
    CloseConfirmDialog: EventEmitter<any> = new EventEmitter<any>();

    /// Event
    UpdateTimeout: Subscription = null;
    ShowInvalid: EventEmitter<any> = new EventEmitter<any>();
    UpdatePrice: EventEmitter<any> = new EventEmitter<any>();
    UpdateMyPlace: EventEmitter<any> = new EventEmitter<any>();
    UpdateInvoice: EventEmitter<any> = new EventEmitter<any>();
    PaymentProcess: EventEmitter<any> = new EventEmitter<any>();
    SigninComplete: EventEmitter<any> = new EventEmitter<any>();
    SignoutComplete: EventEmitter<any> = new EventEmitter<any>();
    BookingItemClear: EventEmitter<any> = new EventEmitter<any>();
    BookingItemError: EventEmitter<any> = new EventEmitter<any>();
    BookingItemChange: EventEmitter<any> = new EventEmitter<any>();
    UpdateVehicleValid: EventEmitter<any> = new EventEmitter<any>();
    BookingItemTimeout: EventEmitter<any> = new EventEmitter<any>();
    BookingItemComplete: EventEmitter<any> = new EventEmitter<any>();
    UpdateProcessButton: EventEmitter<any> = new EventEmitter<any>();
    TrackVehicleUpdateApi: EventEmitter<any> = new EventEmitter<any>();
    TrackVehicleUpdate: EventEmitter<TrackVehicleItem> = new EventEmitter<TrackVehicleItem>();

    public get Booking(): Booking {
        if (this.booking == null) {
            let json = sessionStorage.getItem(this.LOCALSTORE_TYPE.BOOKING);
            this.booking = JSON.parse(json);
            if (this.booking)
                this.booking.ReturnDate = null;
        }
        if (this.booking == null) {
            this.booking = new Booking(this.Configuration.DefaultPaymentMethod);
        }

        // Flight
        if (this.booking.Flight == null) this.booking.Flight = new Flight();
        if (this.booking.ReturnFlight == null) this.booking.ReturnFlight = new Flight();

        // Contact
        if (this.booking.Contact == null) this.booking.Contact = new Contact();
        if (this.booking.Customer == null) this.booking.Customer = new Contact();
        this.booking.Contact.BookerId = (this.profile && this.profile.BookerId) || 0;

        // Time
        if (this.booking.Time != null) {
            if (this.booking.Time.Time != null && typeof this.booking.Time.Time != 'object')
                this.booking.Time.Time = new Date(this.booking.Time.Time);
            if (this.booking.Time.EarliestTime != null && typeof this.booking.Time.EarliestTime != 'object')
                this.booking.Time.EarliestTime = new Date(this.booking.Time.EarliestTime);
        } else {
            this.booking.Time = new TimeItem();
        }
        if (this.booking.ReturnDate != null && typeof this.booking.ReturnDate != 'object')
            this.booking.ReturnDate = new Date(this.booking.ReturnDate);

        // ViaAddress
        if (this.booking.ViaAddress == null) {
            this.booking.ViaAddress = new Array();
        }

        // Promo
        if (this.booking.Promo == null)
            this.booking.Promo = new PromoItem();

        // Quotation
        if (this.booking.Quotation == null)
            this.booking.Quotation = new QuotationItem();

        // Vehicle
        if (this.booking.Vehicle == null && this.Vehicles != null && this.Vehicles.length > 0)
            this.booking.Vehicle = this.Vehicles[0];

        // Price
        if (this.booking.Price == null && this.Prices != null && this.Prices.length > 0)
            this.booking.Price = this.Prices[1];

        // Step
        if (this.booking.Step <= 0) this.booking.Step = 1;
        return this.booking;
    }
    public get Profile(): ProfileItem {
        if (this.profile == null) {
            let json = sessionStorage.getItem(this.LOCALSTORE_TYPE.ACCOUNT);
            if (json == null || json.length == 0)
                json = localStorage.getItem(this.LOCALSTORE_TYPE.ACCOUNT);
            this.profile = JSON.parse(json);
        }
        return this.profile;
    }
    public set Booking(value: Booking) {
        if (value != null) {
            sessionStorage.setItem(this.LOCALSTORE_TYPE.BOOKING, JSON.stringify(value));
            this.booking = value;
        } else {
            sessionStorage.removeItem(this.LOCALSTORE_TYPE.BOOKING);
            this.booking = null;
        }
    }
    public set Profile(value: ProfileItem) {
        if (value != null) {
            this.profile = value;
        } else {
            localStorage.removeItem(this.LOCALSTORE_TYPE.ACCOUNT);
            sessionStorage.removeItem(this.LOCALSTORE_TYPE.ACCOUNT);
            this.profile = null;
        }

        // Contact
        if (this.profile != null) {
            if (this.booking == null) {
                this.booking = new Booking(this.Configuration.DefaultPaymentMethod);
            }
        }
    }

    /// Valid
    public validAll(): boolean {
        return this.validPickUp() &&
            this.validVehicle() &&
            this.validPickDown() &&
            this.validDateTime() &&
            this.validTotalFare() &&
            this.validViaAddress();
    }
    public validPickUp(): boolean {
        return this.booking.PickUp != null && this.booking.PickUp.Valid;
    }
    public validAddress(): boolean {
        return this.validPickUp() &&
            this.validPickDown() &&
            this.validViaAddress();
    }
    public validVehicle(): boolean {
        return this.booking.Vehicle != null && this.booking.Vehicle.Valid;
    }
    public validPayment(): boolean {
        if (this.Payment == null) return false;
        if (!this.Payment.Name || this.Payment.Name == '' || this.Payment.Name.trim() == '') return false;
        if (!this.Payment.City || this.Payment.City == '' || this.Payment.City.trim() == '') return false;

        if (this.Configuration.PaymentGateway != 'stripe') {
            if (!this.Payment.CVC || this.Payment.CVC == '' || this.Payment.CVC.trim() == '') return false;
            if (!this.Payment.CardNumber || this.Payment.CardNumber == '' || this.Payment.CardNumber.trim() == '') return false;
            else {
                if (!jQuery.payment.validateCardNumber(this.Payment.CardNumber)) {
                    return false;
                }
            }
        }

        if (!this.Payment.PostalCode || this.Payment.PostalCode == '' || this.Payment.PostalCode.trim() == '') return false;
        if (!this.Payment.AddressLine1 || this.Payment.AddressLine1 == '' || this.Payment.AddressLine1.trim() == '') return false;
        return true;
    }
    public validContact(): boolean {
        if (this.booking.Contact == null) return false;
        if (this.Profile) {
            if (!this.booking.Contact.Name && !this.booking.Contact.FirstName) return false;
        }
        if (!this.booking.Contact.Phone || this.booking.Contact.Phone == '' || this.booking.Contact.Phone.trim() == '') return false;
        if (!this.booking.Contact.FirstName || this.booking.Contact.FirstName == '' || this.booking.Contact.FirstName.trim() == '') return false;
        if (environment.allowGuest && !this.Profile) {
            if (!this.booking.Contact.Email || this.booking.Contact.Email == '' || this.booking.Contact.Email.trim() == '') return false;
        }
        if (this.booking.Contact.Email) {
            if (!ConstantHelper.REGEX_EMAIL.test(this.booking.Contact.Email)) {
                return false;
            }
        }
        if (this.booking.Contact.AsAccount && (!this.booking.Contact.Password || this.booking.Contact.Password == '' || this.booking.Contact.Password.trim() == ''))
            return false;
        return true;
    }
    public validCustomer(): boolean {
        if (this.booking.Customer == null) return false;
        if (this.booking.Customer.Email && this.booking.Customer.Email != '' && this.booking.Customer.Email.trim() != '') {
            if (!ConstantHelper.REGEX_EMAIL.test(this.booking.Customer.Email)) {
                return false;
            }
        }
        if (!this.booking.Customer.Phone || this.booking.Customer.Phone == '' || this.booking.Customer.Phone.trim() == '') return false;
        if (!this.booking.Customer.FirstName || this.booking.Customer.FirstName == '' || this.booking.Customer.FirstName.trim() == '') return false;
        return true;
    }
    public validPickDown(): boolean {
        return this.booking.PickDown != null && this.booking.PickDown.Valid;
    }
    public validDateTime(): boolean {
        if (this.booking.Time.PickUpType == PickupType.Now) return true;
        if (this.booking.Time.PickUpType == PickupType.Unknow) return false;
        return this.booking.Time.Time != null;
    }
    public validTotalFare(): boolean {
        return this.booking.Quotation && this.booking.Quotation.TotalFare > 0;
    }
    public validQuotation(): boolean {
        return this.booking.Quotation != null &&
            this.booking.Quotation.TotalFare > 0 &&
            this.booking.Quotation.RoutePath != null &&
            this.booking.Quotation.RoutePath.length != 0;
    }
    public validViaAddress(): boolean {
        if (this.booking.ViaAddress == null) return true;
        if (this.booking.ViaAddress.length == 0) return true;

        let index: number = -1;
        for (let i = 0; i < this.booking.ViaAddress.length; i++) {
            if (this.booking.ViaAddress[i] == null || !this.booking.ViaAddress[i].Valid) {
                index = i;
                break;
            }
        }
        if (index >= 0) return false;
        return true;
    }
    public validReturnDate(): boolean {
        if (!this.booking.HasReturn) return true;
        if (this.booking.ReturnDate == null) return false;
        this.booking.ReturnEarliestTime = this.getReturnEarliestTime();
        return this.booking.ReturnDate.getTime() >= this.booking.ReturnEarliestTime.getTime();
    }
    public validDrawReturnPath(): boolean {
        return this.booking.HasReturn && this.booking.ReturnDate != null;
    }
    public validTimeExpired(update: boolean = false): boolean {
        if (update) {
            var date = new Date(); date.setSeconds(0); date.setMilliseconds(0);
            date.setMinutes(date.getMinutes() + this.booking.Quotation.TravelTime);
            this.booking.Time.EarliestTime = date;
        }
        if (this.booking.Time.PickUpType == PickupType.Now) return true;
        if (this.booking.Time.PickUpType == PickupType.Unknow) return false;
        return this.booking.Time.Time != null && this.booking.Time.Time >= this.booking.Time.EarliestTime;
    }
    public validFlight(): boolean {
        if (this.Booking &&
            this.Booking.PickUp &&
            this.Booking.PickUp.Category &&
            this.Booking.PickUp.Category.toLowerCase().indexOf('air') >= 0) {
            if (this.Booking.Flight == null)
                return false;
            if (this.Booking.Flight.IsFlightDetail)
                return true;
            else {
                if (this.Booking.Flight.Number.length == 0)
                    return false;
                if (!this.Booking.Flight.AirportText)
                    this.Booking.Flight.AirportText = '';
                if (this.Booking.Flight.AirportText.length == 0 &&
                    this.Booking.Flight.Airport == null)
                    return false;
            }
            return true;
        }
        return true;
    }
    public validReturnFlight(): boolean {
        if (this.Booking &&
            this.Booking.PickDown &&
            this.booking.HasReturn &&
            this.Booking.PickDown.Category &&
            this.Booking.PickDown.Category.toLowerCase().indexOf('air') >= 0) {
            if (this.Booking.ReturnFlight == null)
                return false;
            if (this.Booking.ReturnFlight.IsFlightDetail)
                return true;
            else {
                if (this.Booking.ReturnFlight.Number.length == 0)
                    return false;
                if (!this.Booking.ReturnFlight.AirportText)
                    this.Booking.ReturnFlight.AirportText = '';
                if (this.Booking.ReturnFlight.AirportText.length == 0 &&
                    this.Booking.ReturnFlight.Airport == null)
                    return false;
            }
            return true;
        }
        return true;
    }
    public validAirPortAndHasFlight(): boolean {
        if (!this.Booking.Flight) this.Booking.Flight = new Flight();
        if (this.Booking &&
            this.Booking.PickUp &&
            this.Booking.PickUp.Category &&
            this.Booking.PickUp.Category.toLowerCase().indexOf('air') >= 0) {
            if (!this.Booking.Flight.IsFlightDetail) {
                var valid1 = this.Booking.Flight.Number && this.Booking.Flight.Number.trim().length > 0;
                var valid2 = this.Booking.Flight.Airport != null || (this.booking.Flight.AirportCode && this.booking.Flight.AirportCode.trim().length > 0);
                return valid1 && valid2;
            }
        }
        return false;
    }
    public validAirPortAndHasReturnFlight(): boolean {
        if (!this.Booking.Flight) this.Booking.Flight = new Flight();
        if (this.Booking &&
            this.Booking.PickDown &&
            this.Booking.PickDown.Category &&
            this.Booking.PickDown.Category.toLowerCase().indexOf('air') >= 0) {
            if (!this.Booking.ReturnFlight.IsFlightDetail) {
                var valid1 = this.Booking.ReturnFlight.Number && this.Booking.ReturnFlight.Number.trim().length > 0;
                var valid2 = this.Booking.ReturnFlight.Airport != null || (this.booking.ReturnFlight.AirportCode && this.booking.ReturnFlight.AirportCode.trim().length > 0);
                return valid1 && valid2;
            }
        }
        return false;
    }

    storeBooking() {
        sessionStorage.removeItem(this.LOCALSTORE_TYPE.BOOKING);
        if (this.booking != null) {
            sessionStorage.setItem(this.LOCALSTORE_TYPE.BOOKING, JSON.stringify(this.booking));
        }
    }

    clearBooking() {
        this.booking = null;
        sessionStorage.removeItem(this.LOCALSTORE_TYPE.BOOKING);
    }

    storeProfile(remember: boolean) {
        localStorage.removeItem(this.LOCALSTORE_TYPE.ACCOUNT);
        sessionStorage.removeItem(this.LOCALSTORE_TYPE.ACCOUNT);
        if (this.profile != null) {
            if (remember)
                localStorage.setItem(this.LOCALSTORE_TYPE.ACCOUNT, JSON.stringify(this.profile));
            else
                sessionStorage.setItem(this.LOCALSTORE_TYPE.ACCOUNT, JSON.stringify(this.profile));
        }
    }

    public getReturnEarliestTime(): Date {
        if (this.booking.Time == null) return new Date();
        var time = new Date();
        if (this.booking.Time.PickUpType == PickupType.Now) {
            time = this.booking.Time.EarliestTime == null
                ? new Date()
                : new Date(this.booking.Time.EarliestTime.getTime());
        } else {
            time = this.booking.Time.Time == null
                ? new Date()
                : new Date(this.booking.Time.Time.getTime());
        }
        var minutes = this.booking.Quotation != null
            ? this.booking.Quotation.JourneyTime | this.booking.Quotation.TravelTime
            : 0;
        time.setMinutes(time.getMinutes() + minutes);
        return time;
    }

    public MENU_TYPE = {
        MYPLACE: 'myplace',
        SIGNOUT: 'signout',
        INVOICE: 'invoice',
        DASHBOARD: 'dashboard',
        MYACCOUNT: 'myaccount',
        MYBOOKING: 'mybooking',
        COMMISSION: 'commission',
    };
    public PAGE_TYPE = {
        HOME: '/',
        MENU: 'menu.html',
        POLICY: 'policy.html',
        COOKIE: 'cookie.html',
        BOOKING: 'booking.html',
        PAYMENT: 'payment.html',
        PROFILE: 'profile.html',
        BOOKINGALONE: 'booking',
        INVOICE: 'invoice.html',
        TRACKING: 'tracking.html',
        DASHBOARD: 'dashboard.html',
        CONFIRMED: 'confirmed.html',
        BOOKINGINFO: 'bookinginfo.html',
        QUICKBOOKING: 'quickbooking.html',
        UPDATEADDRESS: 'addressbook.html',
        BOOKINGDETAIL: 'bookingdetail.html',
        RESETPASSWORD: 'resetpassword.html',
        BOOKINGHISTORY: 'bookinghistory.html',
        CHANGEPASSWORD: 'changepassword.html',
        COMMISSIONSTATEMENT: 'commission.html',
        CONFIRMPASSWORD: 'confirmpassword.html',
        QUICKBOOKINGINVALID: 'quickbookinginvalid.html',
    };
    public MODAL_TYPE = {
        MENU: 'modalMenu',
        CARD: 'modalCard',
        ALERT: 'modalAlert',
        PROMO: 'modalPromo',
        SIGNIN: 'modalSignIn',
        FLIGHT: 'modalFlight',
        CONFIRM: 'modalConfirm',
        CONTACT: 'modalContact',
        NOTEDRIVER: 'modalNote',
        DATETIME: 'modalDateTime',
        QUOTATION: 'modalQuotation',
        FLIGHTSEARCH: 'modalFlightSearch',
        PAYMENTPAYPAL: 'modalPaymentPaypal',
        PAYMENTMETHOD: 'modalPaymentMethod',
        UPDATEPROFILE: 'modalUpdateProfile',
        UPDATEADDRESS: 'modalUpdateAddress',
        UPDATEINVOICE: 'modalUpdateInvoice',
        UPDATEPASSWORD: 'modalUpdatePassword',
        FORGOTPASSWORD: 'modalForgotPassword',
        PAYMENTPROCESS: 'modalPaymentProcess',
    };
    public SCROLL_TYPE = {
        CAR: 'car',
        PICKUP: 'pickup',
        CONTACT: 'contact',
        PICKTIME: 'picktime',
    };
    public SIGNIN_TYPE = {
        SIGNIN: 'signin',
        SIGNUP: 'signup'
    };
    public FLIGHT_TYPE = {
        ONWARD: 'onward',
        RETURN: 'return'
    };
    public CUSTOMER_TYPE = {
        CUSTOMER: 'customer',
        PASSENGER: 'passenger'
    };
    public MINUTE_TYPE = {
        ZEZO: 0,
        FIFTEEN: 15,
        THIRTY: 30,
        SIXTIES: 60,
    };
    public EDITOR_TYPE = {
        TEXT: 1,
        PHONE: 2,
        EMAIL: 3,
        COUNTRY: 4,
        CARDCVC: 5,
        POSTCODE: 6,
        TEXTAREA: 7,
        PASSWORD: 8,
        CARDNUMBER: 9,
        CONFIRMPASSWORD: 10
    };
    public DIALOG_TYPE = {
        CONFIRM: 'confirm',
        DIALOG: 'dialog',
        SIGNIN: 'signin',
    };
    public STICKY_TYPE = {
        FLIGHT: 'stickyFlight',
        PICKUP: 'stickyPickUp',
        CONTACT: 'stickyContact',
        VEHICLE: 'stickyVehicle',
        CUSTOMER: 'stickyCustomer',
        PICKDOWN: 'stickyPickDown',
        ADDRETURN: 'stickyAddReturn',
        PICKDATETIME: 'stickyDateTime',
        PAYMENTCITY: 'stickyPaymentCity',
        PAYMENTNAME: 'stickyPaymentName',
        NOTETODRIVER: 'stickyNoteToDriver',
        RETURNFLIGHT: 'stickyReturnFlight',
        PICKVIALOCATION: 'stickyViaLocation',
        PAYMENTEXPIRY: 'stickyPaymentExpiry',
        PAYMENTCOUNTRY: 'stickyPaymentCountry',
        PICKRETURNDATETIME: 'stickyReturnDateTime',
        PAYMENTCARDNUMBER: 'stickyPaymentCardNumber',
        PAYMENTADDRESSLINE1: 'stickyPaymentAddressLine1',
        PAYMENTADDRESSLINE2: 'stickyPaymentAddressLine2',
    }
    public PAYMENT_TYPE = {
        CARD: 'card',
        CASH: 'cash',
        PAYPAL: 'paypal',
        ACCOUNT: 'account'
    };
    public CONTACT_TYPE = {
        MAIN: 'main',
        SUGGEST: 'suggest'
    };
    public ADDRESS_TYPE = {
        HOME: 'home',
        WORK: 'work',
    };
    public LOCALSTORE_TYPE = {
        BOOKING: 'booking',
        ACCOUNT: 'account',
        COUNTRY: 'country',
    };
    public DIALOG_TITLE_TYPE = {
        ERROR: 'Error',
        MESSAGE: 'Message',
        QUESTION: 'Question',
    };
    public TARGET_EVENT_TYPE = {
        PICKUP: 'pickup',
        FLIGHT: 'flight',
        SINGLE: 'single',
        CONTACT: 'contact',
        PAYMENT: 'payment',
        DROPOFF: 'dropoff',
        VEHICLE: 'vehicle',
        REQUOTE: 'requote',
        DATETIME: 'datetime',
        PICKTIME: 'picktime',
        CUSTOMER: 'customer',
        VIAADDRESS: 'viaaddress',
        WAITRETURN: 'waitreturn',
        RETURNDATE: 'returndate',
        RETURNFLIGHT: 'returnflight',
    };
    public BUTTON_PAYMENT_TYPE = {
        DIALOG_CARD: 1,
        DIALOG_PAYPAL: 2,
        PICKFLOAT_PAYPAL: 3,
        PICKFLOAT_INLINE: 4,
    };
    public SIGNIN_NAVIGATE_TYPE = {
        HOME: 'home',
        FLIGHT: 'flight',
        NOTHING: 'nothing',
        ADD_PROMO: 'add_promo',
        EDIT_CONTACT: 'edit_contact',
        RETURN_FLIGHT: 'return_flight',
        NOTE_TO_DRIVER: 'note_to_driver',
        CONFIRM_BOOKING: 'confirm_booking',
    };
    public BOOKING_HISTORY_TYPE = {
        TODAY: 'today-bookings',
        PROCESS: 'booking-process',
        PENDING: 'booking-pending',
        HISTORY: 'booking-history',
    }
    public PAYMENT_METHOD_INLINE = {
        INLINE: 'inline',
        POPUP: 'popup',
    };
    public DATETIME_POSITION_TYPE = {
        QUICK_BOOKING: 1,
        PICKUP_BOOKING: 2,
        QUOTATION_DIALOG: 3,
    };
    public PAYMENT_PROCESS_STATUS = {
        PROCESSING: 0,
        SUCCESS: 1,
        ERRORPAYMENT: 2,
        ERRORBOOKING: 3,
        ERRORPAYMENTBOOKING: 4,
    };
    public WINDOW_SIZE = {
        PHONE: 567,
    };
    public ERROR_TYPE = {
        REQUIED: 1,
        INVALID: 2
    };
    public ERROR_MESSAGE = 'Something went wrong, please try again.';
    public ERROR_TRY_OTHER_ADDRESS_MESSAGE = 'Try a more specific address.';
    public ERROR_MESSAGE_VEHICLE = 'No vehicle found with selected capacity';
    public ERROR_PAYMENT_MESSAGE = 'Payment information invalid, please try again.';
    public ERROR_MESSAGE_CARD = 'It was not possible to process the payment, please check the card details and try again.';
    public ERROR_ADDRESS_OUTSIDE = 'The selected address cannot be used as it falls outside our service area. Please select an alternative address';

    SignInType: string = this.SIGNIN_TYPE.SIGNIN;
    FlightType: string = this.FLIGHT_TYPE.ONWARD;
    ContactType: string = this.CUSTOMER_TYPE.PASSENGER;
}

export class EventBooking {
    Type: number;
    Index: number;
    Target: string;
    Message: string;
    ClearInput: boolean;

    constructor(target: string, index: number = null, clearInput = true) {
        this.Type = 1;
        this.Index = index;
        this.Target = target;
        this.ClearInput = clearInput;
    }
}
