export class Base {
    id: number;
    code: number;
    message: string;
}
export class BaseItem {
    Id: number;
    Code: number;
    Message: string;

    public static ToItem(entity: Base): BaseItem {
        if (entity == null) return null;
        let item = new BaseItem();
        item.Id = entity.id;
        item.Code = entity.code;
        item.Message = entity.message;
        return item;
    }
}

export class BaseUrl {
    code: number;
    baseurl: string;
    message: string;
    bookingpageurl: string;
}
export class BaseUrlItem {
    Code: number;
    Message: string;
    BaseUrl: string;
    BookingPageUrl: string;

    public static ToItem(entity: BaseUrl): BaseUrlItem {
        if (entity == null) return null;
        let item = new BaseUrlItem();
        item.Code = entity.code;
        item.Message = entity.message;
        item.BaseUrl = entity.baseurl;
        item.BookingPageUrl = entity.bookingpageurl;
        return item;
    }
}
