<div class="modal fade" id="modalAlert" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button id="closeButtonAlert" type="button" class="close" (click)="onClose()">&times;</button>
                <div class="col-xs-12 col-sm-12 col-md-12 modal-title">
                    {{ dialog.Title }}
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="modal-body">
                <p style="margin-top: 20px; text-align: center;">{{ dialog.Content }}</p>
                <div class="center-button" *ngIf="dialog.Type == data.DIALOG_TYPE.DIALOG" style="text-align: center;">
                    <button type="button" class="ui-button" (click)="onClose()" style="width: 160px;">
                        <span>
                            OK
                        </span>
                    </button>
                </div>
                <div class="center-button" *ngIf="dialog.Type == data.DIALOG_TYPE.SIGNIN" style="text-align: center;">
                    <button type="submit" class="ui-button" (click)="openSignin()">
                        <span>
                            SIGN IN
                        </span>
                    </button>
                    <button type="button" class="ui-button btn-cancel" (click)="onClose()">
                        <span>
                            CANCEL
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>