export class Invoice {
    id: number;
    dateto: Date;
    datefrom: Date;
    issuedate: Date;
    issuedbyuser: string;
}

export class InvoiceItem {
    Id: number;
    DateTo: Date;
    DateFrom: Date;
    IssueDate: Date;
    BookerId: number;
    EditAble: boolean;
    IssuedByUser: string;

    public static ToItem(entity: Invoice): InvoiceItem {
        if (entity == null) return null;
        let item = new InvoiceItem();

        item.Id = entity.id;
        item.DateTo = entity.dateto;
        item.DateFrom = entity.datefrom;
        item.IssueDate = entity.issuedate;
        item.IssuedByUser = entity.issuedbyuser;
        item.EditAble = entity.issuedbyuser == "Me" || entity.issuedbyuser == "me";
        return item;
    }

    public static ToItems(entities: Invoice[]): InvoiceItem[] {
        if (entities == null || entities.length == 0) return new Array<InvoiceItem>();
        let items = new Array();
        entities.forEach(element => {
            items.push(InvoiceItem.ToItem(element));
        });
        return items;
    }
}