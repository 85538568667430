export class PaymentItem {
    CVC: string;
    City: string;
    Name: string;
    Nonce: string;
    Amount: number;
    Expiry: string;
    Country: string;
    Retry?: boolean;
    CardNumber: string;
    CustomerId: number;
    ExpiryYear: string;
    PostalCode: string;
    ExpiryMonth: string;
    CountryCode: string;
    AddressLine1: string;
    AddressLine2: string;

    public static InitItem(retry?: boolean): PaymentItem {
        var item = new PaymentItem();
        item.CVC = '';
        item.City = '';
        item.Name = '';
        item.Nonce = '';
        item.Amount = 0;
        item.Retry = retry;
        item.CustomerId = 0;
        item.CardNumber = '';
        item.PostalCode = '';
        item.AddressLine1 = '';
        item.AddressLine2 = '';
        var month = ((new Date()).getMonth() + 1);
        item.ExpiryYear = ((new Date()).getFullYear()).toString();
        item.ExpiryMonth = month < 10 ? '0' + month.toString() : month.toString();
        return item;
    }
}

export class PaymentToken {
    code: number;
    token: string;
    message: string;
}

export class PaymentTokenItem {
    Code: number;
    Token: string;
    Message: string;

    public static ToItem(entity: PaymentToken): PaymentTokenItem {
        if (entity == null) return null;
        var item = new PaymentTokenItem();
        item.Code = entity.code;
        item.Token = entity.token;
        item.Message = entity.message;
        return item;
    }
}

export class PaymentProcessItem {
    Type: number;
    Description: string;
}